import { User } from '@cdw-selline/common/types';
import { CollectionsTable } from '@cdw-selline/ui/components';
import { ROUTES } from '@cdw-selline/ui/constants';
import { useHealthCheck, useUsers } from '@cdw-selline/ui/hooks';
import { getCurrentUser } from '@cdw-selline/ui/state';
import '@cdw-selline/ui/theme';
import { themeGlobals } from '@cdw-selline/ui/theme';
import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AdminConfigSettingsCollectionPage } from '../collections/admin-config-settings/AdminConfigSettingsCollectionPage';
import { AgiloftExpenseTypesCollectionPage } from '../collections/agiloft-expense-types/AgiloftExpenseTypeCollectionPage';
import { AgiloftTravelNoticeTypesCollectionPage } from '../collections/agiloft-travel-notice-types/AgiloftTravelNoticeTypesCollectionPage';
import { CdwRatesCollectionPage } from '../collections/cdw-rates-collection-page/CdwRatesCollectionPage';
import { CiscoAnnuityPartNumberCollectionPage } from '../collections/cisco-annuity-part-numbers/CiscoAnnuityPartNumbersCollectionPage';
import { CustomersCollectionPage } from '../collections/customers-collection-page/CustomersCollectionPage';
import { EmailTemplatesCollectionPage } from '../collections/email-templates/EmailTemplatesCollectionPage';
import { PhasesCollectionPage } from '../collections/phases-collection-page/PhasesCollectionPage';
import { PracticesCollectionPage } from '../collections/practices-collection-page/PracticesCollectionPage';
import { ProposalTemplatesCollectionPage } from '../collections/proposal-templates-collection-page/ProposalTemplatesCollectionPage';
import { RolesCollectionPage } from '../collections/roles-collection-page/RolesCollectionPage';
import ScopeGroupsCollectionPage from '../collections/scope-groups-collection-page/ScopeGroupsCollectionPage';
import ScopeItemsCollectionPage from '../collections/scope-items-collection-page/ScopeItemsCollectionPage';
import { ServiceItemsCollectionPage } from '../collections/service-items-collection-page/ServiceItemsCollectionPage';
import { ServicePracticesCollectionPage } from '../collections/service-practices-collection-page/ServicePracticesCollectionPage';
import { ServiceSkillsCollectionPage } from '../collections/service-skills-collection-page/ServiceSkillsCollectionPage';
import { SowSectionsCollectionPage } from '../collections/sow-sections-collection-page/SowSectionsCollectionPage';
import { TaskGroupsCollectionPage } from '../collections/task-groups-collection-page/TaskGroupsCollectionPage';
import { TasksCollectionPage } from '../collections/tasks-collection-page/TasksCollectionPage';
import { TiersCollectionPage } from '../collections/tiers-collection-page/TiersCollectionPage';
import AdminListTasksPage from '../admin-list-tasks/AdminListTasksPage';
import { ServiceSuppliersCollectionPage } from '../collections/service-suppliers-collection-page/ServiceSuppliersCollectionPage';

const routes = [
  { 
    path: ROUTES.HOME,
    Component: CollectionsTable,
    access: ['admin', 'contentAdmin'],
  },
  { 
    path: ROUTES.ADMIN_DASHBOARD,
    Component: CollectionsTable,
    access: ['admin', 'contentAdmin'],
  },
  {
    path: ROUTES.ADMIN_CUSTOMERS,
    Component: CustomersCollectionPage,
    access: ['admin'],
  },
  {
    path: ROUTES.ADMIN_PRACTICES,
    Component: PracticesCollectionPage,
    access: ['admin'],
  },
  {
    path: ROUTES.ADMIN_ROLES,
    Component: RolesCollectionPage,
    access: ['admin'],
  },
  {
    path: ROUTES.ADMIN_CDW_RATES,
    Component: CdwRatesCollectionPage,
    access: ['admin'],
  },
  {
    path: ROUTES.ADMIN_SOW_SECTIONS,
    Component: SowSectionsCollectionPage,
    access: ['admin', 'contentAdmin'],
  },
  {
    path: ROUTES.ADMIN_PROPOSAL_TEMPLATES,
    Component: ProposalTemplatesCollectionPage,
    access: ['admin'],
  },
  {
    path: ROUTES.ADMIN_TASKS,
    Component: TasksCollectionPage,
    access: ['admin', 'contentAdmin'],
  },
  {
    path: ROUTES.ADMIN_TASK_GROUPS,
    Component: TaskGroupsCollectionPage,
    access: ['admin', 'contentAdmin'],
  },
  {
    path: ROUTES.ADMIN_TIERS,
    Component: TiersCollectionPage,
    access: ['admin'],
  },
  {
    path: ROUTES.ADMIN_PHASES,
    Component: PhasesCollectionPage,
    access: ['admin'],
  },
  {
    path: ROUTES.ADMIN_SCOPE_GROUPS,
    Component: ScopeGroupsCollectionPage,
    access: ['admin'],
  },
  {
    path: ROUTES.ADMIN_SCOPE_ITEMS,
    Component: ScopeItemsCollectionPage,
    access: ['admin'],
  },
  {
    path: `${ROUTES.ADMIN_TASKS}/:id`,
    Component: TasksCollectionPage,
    access: ['admin'],
  },
  {
    path: `${ROUTES.ADMIN_CISCO_ANNUITY_PART_NUMBERS}`,
    Component: CiscoAnnuityPartNumberCollectionPage,
    access: ['admin'],
  },
  {
    path: ROUTES.ADMIN_CONFIG_SETTINGS,
    Component: AdminConfigSettingsCollectionPage,
    access: ['admin'],
  },
  {
    path: ROUTES.EMAIL_TEMPLATES,
    Component: EmailTemplatesCollectionPage,
    access: ['admin'],
  },
  {
    path: ROUTES.ADMIN_SERVICE_PRACTICES,
    Component: ServicePracticesCollectionPage,
    access: ['admin'],
  },
  {
    path: ROUTES.ADMIN_SERVICE_ITEMS,
    Component: ServiceItemsCollectionPage,
    access: ['admin'],
  },
  {
    path: ROUTES.ADMIN_SERVICE_SKILLS,
    Component: ServiceSkillsCollectionPage,
    access: ['admin'],
  },
  {
    path: ROUTES.ADMIN_SERVICE_SUPPLIERS,
    Component: ServiceSuppliersCollectionPage,
    access: ['admin'],
  },
  {
    path: ROUTES.AGILOFT_EXPENSE_TYPES,
    Component: AgiloftExpenseTypesCollectionPage,
    access: ['admin'],
  },
  {
    path: ROUTES.AGILOFT_TRAVEL_NOTICE_TYPES,
    Component: AgiloftTravelNoticeTypesCollectionPage,
    access: ['admin'],
  },
  {
    path: `${ROUTES.ADMIN_LIST_TASKS}/:taskGroupId`,
    Component: AdminListTasksPage,
    access: ['admin'],
  },
];

export const AdminDashboardPage = () => {
  useHealthCheck();
  const currentUser = getCurrentUser();
  const { data, loading, error } = useUsers({ email: currentUser.email });

  const allowAccess = (access: string[], user: User) => {
    return (user?.admin && access.includes('admin')) || 
      (user?.contentAdmin && access.includes('contentAdmin'));
  };

  return (
    <Box sx={{ ...themeGlobals.containerMargins }}>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Routes>
          {routes.map(({ path, Component, access }) => (
              <Route
                key={`admin-routes-${path}`}
                path={path}
                element={loading ? null : allowAccess(access, data[0]) ? (
                  <Component />
                ) : (
                  <Typography>
                    Admin user status required to access Admin Collections
                  </Typography>
                )
              }
              />
          ))}
        </Routes>
      </Stack>
    </Box>
  );
};

export default AdminDashboardPage;
