/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import {
  GraphQLResolveInfo,
  GraphQLScalarType,
  GraphQLScalarTypeConfig,
} from 'graphql';
import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & {
  [P in K]-?: NonNullable<T[P]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  ANY: { input: any; output: any };
  DATE: { input: any; output: any };
  HTML: { input: any; output: any };
  /** Textfield Accepting Int or String Values */
  IntOrString: { input: any; output: any };
  JSON: { input: any; output: any };
  MongoFilter: { input: any; output: any };
  StringOrArray: { input: any; output: any };
  _FieldSet: { input: any; output: any };
};

export type ActiveSiteTask = {
  __typename?: 'ActiveSiteTask';
  category?: Maybe<Scalars['String']['output']>;
  hours?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  noteCount?: Maybe<Scalars['Int']['output']>;
  overtime?: Maybe<Scalars['Boolean']['output']>;
  primaryRole?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['IntOrString']['output']>;
  secondaryRole?: Maybe<Scalars['String']['output']>;
  siteId?: Maybe<Scalars['String']['output']>;
  taskId?: Maybe<Scalars['String']['output']>;
  totalHours?: Maybe<Scalars['Float']['output']>;
};

export type ActiveSiteWithTasks = {
  __typename?: 'ActiveSiteWithTasks';
  siteId?: Maybe<Scalars['String']['output']>;
  siteName?: Maybe<Scalars['String']['output']>;
  siteTotalHours?: Maybe<Scalars['Float']['output']>;
  tasks?: Maybe<Array<Maybe<ActiveSiteTask>>>;
};

export type AddEstimatorTasksByTaskGroupResponse = {
  __typename?: 'AddEstimatorTasksByTaskGroupResponse';
  hasBillingTypeChanged?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type AddProjectProposalInput = {
  estimatorId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['IntOrString']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  projectItemId?: InputMaybe<Scalars['String']['input']>;
  templateId?: InputMaybe<Scalars['String']['input']>;
};

export type AddProjectUserResponse = {
  __typename?: 'AddProjectUserResponse';
  id?: Maybe<Scalars['ID']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type AdminConfigSettings = {
  __typename?: 'AdminConfigSettings';
  category?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['StringOrArray']['output']>;
  valueType?: Maybe<Scalars['String']['output']>;
};

export type AdminConfigSettingsInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['StringOrArray']['input']>;
  valueType?: InputMaybe<Scalars['String']['input']>;
};

export type AdminPractice = {
  __typename?: 'AdminPractice';
  bidAssurance?: Maybe<Scalars['ANY']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  managedSvc?: Maybe<Scalars['ANY']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  peerReviewEmail?: Maybe<Scalars['String']['output']>;
  practiceId?: Maybe<Scalars['String']['output']>;
  practiceUsernames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  psm?: Maybe<Scalars['String']['output']>;
  salesForceId?: Maybe<Scalars['String']['output']>;
  serviceLocationId?: Maybe<Scalars['String']['output']>;
  web?: Maybe<Scalars['String']['output']>;
};

export type AdminPracticeInput = {
  bidAssurance?: InputMaybe<Scalars['ANY']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  managedSvc?: InputMaybe<Scalars['ANY']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  peerReviewEmail?: InputMaybe<Scalars['String']['input']>;
  practiceId?: InputMaybe<Scalars['String']['input']>;
  practiceUsernames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  psm?: InputMaybe<Scalars['String']['input']>;
  salesForceId?: InputMaybe<Scalars['String']['input']>;
  serviceLocationId?: InputMaybe<Scalars['String']['input']>;
  web?: InputMaybe<Scalars['String']['input']>;
};

export type AdminRole = {
  __typename?: 'AdminRole';
  agiloftRole?: Maybe<Scalars['String']['output']>;
  /** Should be a Boolean but comes from ultron as 0 or 1 */
  cdwRateId?: Maybe<Scalars['String']['output']>;
  coOwners?: Maybe<Array<Maybe<RoleTeam>>>;
  createdAt?: Maybe<Scalars['DATE']['output']>;
  id?: Maybe<Scalars['IntOrString']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Array<Maybe<RoleTeam>>>;
  practice?: Maybe<Scalars['String']['output']>;
  projectManager?: Maybe<Scalars['ANY']['output']>;
  rate?: Maybe<Scalars['String']['output']>;
  testers?: Maybe<Array<Maybe<RoleTeam>>>;
};

export type AdminRoleInput = {
  agiloftRole?: InputMaybe<Scalars['String']['input']>;
  /** Should be a Boolean but comes from ultron as 0 or 1 */
  cdwRateId?: InputMaybe<Scalars['String']['input']>;
  coOwners?: InputMaybe<Array<InputMaybe<RoleTeamInput>>>;
  id?: InputMaybe<Scalars['IntOrString']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<Array<InputMaybe<RoleTeamInput>>>;
  practice?: InputMaybe<Scalars['String']['input']>;
  projectManager?: InputMaybe<Scalars['ANY']['input']>;
  rate?: InputMaybe<Scalars['String']['input']>;
  testers?: InputMaybe<Array<InputMaybe<RoleTeamInput>>>;
};

export type AdminTaskGroup = {
  __typename?: 'AdminTaskGroup';
  agiloftBlendedScopeType?: Maybe<Scalars['String']['output']>;
  agiloftClauses?: Maybe<Scalars['String']['output']>;
  agiloftDefaultPrintTemplate?: Maybe<Scalars['String']['output']>;
  agiloftExhibits?: Maybe<Scalars['String']['output']>;
  agiloftManagedScopeType?: Maybe<Scalars['String']['output']>;
  agiloftOutcomeScopeType?: Maybe<Scalars['String']['output']>;
  agiloftPrintTemplateTypeOptions?: Maybe<
    Array<Maybe<Scalars['String']['output']>>
  >;
  agiloftRecurringServicesFees?: Maybe<Scalars['String']['output']>;
  agiloftScopeType?: Maybe<Scalars['String']['output']>;
  agiloftServiceCategory?: Maybe<Scalars['String']['output']>;
  bidAssuranceGroup?: Maybe<Scalars['String']['output']>;
  bidAssuranceUrl?: Maybe<Scalars['String']['output']>;
  businessOutcome?: Maybe<Scalars['HTML']['output']>;
  businessOutcomeHelp?: Maybe<Scalars['HTML']['output']>;
  businessOutcomeIncluded?: Maybe<Scalars['Boolean']['output']>;
  coOwners?: Maybe<Array<Maybe<TaskGroupTeam>>>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  executiveSummaryDrivers?: Maybe<Scalars['HTML']['output']>;
  executiveSummaryDriversHelp?: Maybe<Scalars['HTML']['output']>;
  executiveSummaryDriversIncluded?: Maybe<Scalars['Boolean']['output']>;
  executiveSummaryOverview?: Maybe<Scalars['HTML']['output']>;
  executiveSummaryOverviewHelp?: Maybe<Scalars['HTML']['output']>;
  executiveSummaryOverviewIncluded?: Maybe<Scalars['Boolean']['output']>;
  fixedFee?: Maybe<Scalars['IntOrString']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  inTestingTaskGroupId?: Maybe<Scalars['String']['output']>;
  itemsProvidedToCustomers?: Maybe<Array<Maybe<ItemsProvidedToCustomers>>>;
  lastReviewedBy?: Maybe<Scalars['String']['output']>;
  lastReviewedOn?: Maybe<Scalars['IntOrString']['output']>;
  minPmHours?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['IntOrString']['output']>;
  originalTaskGroupId?: Maybe<Scalars['String']['output']>;
  /** blob */
  outcomeIncluded?: Maybe<Scalars['Boolean']['output']>;
  outcomeProjectScope?: Maybe<Scalars['HTML']['output']>;
  outcomeProjectScopeIncluded?: Maybe<Scalars['Boolean']['output']>;
  outcomeProjectScopePhases?: Maybe<Scalars['HTML']['output']>;
  outcomeProjectScopePhasesHelp?: Maybe<Scalars['HTML']['output']>;
  outcomeProjectScopePhasesIncluded?: Maybe<Scalars['Boolean']['output']>;
  outcomeSowFooter?: Maybe<Scalars['HTML']['output']>;
  outcomeSowHeader?: Maybe<Scalars['HTML']['output']>;
  outcomeSummary?: Maybe<Scalars['HTML']['output']>;
  owner?: Maybe<Array<Maybe<TaskGroupTeam>>>;
  pmPercent?: Maybe<Scalars['Float']['output']>;
  pmRole?: Maybe<Scalars['String']['output']>;
  practice?: Maybe<Scalars['String']['output']>;
  proposalContent?: Maybe<Scalars['HTML']['output']>;
  proposalTemplateIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  published?: Maybe<Scalars['IntOrString']['output']>;
  riskPercent?: Maybe<Scalars['IntOrString']['output']>;
  setPm?: Maybe<Scalars['Boolean']['output']>;
  sowScopeFooter?: Maybe<Scalars['HTML']['output']>;
  /** blob */
  sowScopeHeader?: Maybe<Scalars['HTML']['output']>;
  sowSections?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status?: Maybe<Scalars['String']['output']>;
  taskGroupCategories?: Maybe<Array<Maybe<Category>>>;
  taskGroupTeam?: Maybe<Array<Maybe<TaskGroupTeam>>>;
  technicalOutcome?: Maybe<Scalars['HTML']['output']>;
  technicalOutcomeHelp?: Maybe<Scalars['HTML']['output']>;
  technicalOutcomeIncluded?: Maybe<Scalars['Boolean']['output']>;
  testers?: Maybe<Array<Maybe<TaskGroupTeam>>>;
  version?: Maybe<Scalars['IntOrString']['output']>;
};

export type AdminTaskGroupInput = {
  agiloftBlendedScopeType?: InputMaybe<Scalars['String']['input']>;
  agiloftClauses?: InputMaybe<Scalars['String']['input']>;
  agiloftDefaultPrintTemplate?: InputMaybe<Scalars['String']['input']>;
  agiloftExhibits?: InputMaybe<Scalars['String']['input']>;
  agiloftManagedScopeType?: InputMaybe<Scalars['String']['input']>;
  agiloftOutcomeScopeType?: InputMaybe<Scalars['String']['input']>;
  agiloftPrintTemplateTypeOptions?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  agiloftRecurringServicesFees?: InputMaybe<Scalars['String']['input']>;
  agiloftScopeType?: InputMaybe<Scalars['String']['input']>;
  agiloftServiceCategory?: InputMaybe<Scalars['String']['input']>;
  bidAssuranceGroup?: InputMaybe<Scalars['String']['input']>;
  bidAssuranceUrl?: InputMaybe<Scalars['String']['input']>;
  businessOutcome?: InputMaybe<Scalars['HTML']['input']>;
  businessOutcomeHelp?: InputMaybe<Scalars['HTML']['input']>;
  businessOutcomeIncluded?: InputMaybe<Scalars['Boolean']['input']>;
  coOwners?: InputMaybe<Array<InputMaybe<TaskGroupTeamInput>>>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdOn?: InputMaybe<Scalars['String']['input']>;
  executiveSummaryDrivers?: InputMaybe<Scalars['HTML']['input']>;
  executiveSummaryDriversHelp?: InputMaybe<Scalars['HTML']['input']>;
  executiveSummaryDriversIncluded?: InputMaybe<Scalars['Boolean']['input']>;
  executiveSummaryOverview?: InputMaybe<Scalars['HTML']['input']>;
  executiveSummaryOverviewHelp?: InputMaybe<Scalars['HTML']['input']>;
  executiveSummaryOverviewIncluded?: InputMaybe<Scalars['Boolean']['input']>;
  fixedFee?: InputMaybe<Scalars['ANY']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  inTestingTaskGroupId?: InputMaybe<Scalars['String']['input']>;
  itemsProvidedToCustomers?: InputMaybe<
    Array<InputMaybe<ItemsProvidedToCustomersInput>>
  >;
  lastReviewedBy?: InputMaybe<Scalars['String']['input']>;
  lastReviewedOn?: InputMaybe<Scalars['IntOrString']['input']>;
  minPmHours?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['IntOrString']['input']>;
  originalTaskGroupId?: InputMaybe<Scalars['String']['input']>;
  /** blob */
  outcomeIncluded?: InputMaybe<Scalars['Boolean']['input']>;
  outcomeProjectScope?: InputMaybe<Scalars['HTML']['input']>;
  outcomeProjectScopeIncluded?: InputMaybe<Scalars['Boolean']['input']>;
  outcomeProjectScopePhases?: InputMaybe<Scalars['HTML']['input']>;
  outcomeProjectScopePhasesHelp?: InputMaybe<Scalars['HTML']['input']>;
  outcomeProjectScopePhasesIncluded?: InputMaybe<Scalars['Boolean']['input']>;
  outcomeSowFooter?: InputMaybe<Scalars['HTML']['input']>;
  outcomeSowHeader?: InputMaybe<Scalars['HTML']['input']>;
  outcomeSummary?: InputMaybe<Scalars['HTML']['input']>;
  owner?: InputMaybe<Array<InputMaybe<TaskGroupTeamInput>>>;
  pmPercent?: InputMaybe<Scalars['Float']['input']>;
  pmRole?: InputMaybe<Scalars['String']['input']>;
  practice?: InputMaybe<Scalars['String']['input']>;
  proposalContent?: InputMaybe<Scalars['HTML']['input']>;
  proposalTemplateIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  published?: InputMaybe<Scalars['ANY']['input']>;
  riskPercent?: InputMaybe<Scalars['IntOrString']['input']>;
  setPm?: InputMaybe<Scalars['Boolean']['input']>;
  sowScopeFooter?: InputMaybe<Scalars['HTML']['input']>;
  /** blob */
  sowScopeHeader?: InputMaybe<Scalars['HTML']['input']>;
  sowSections?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status?: InputMaybe<Scalars['String']['input']>;
  taskGroupTeam?: InputMaybe<Array<InputMaybe<TaskGroupTeamInput>>>;
  technicalOutcome?: InputMaybe<Scalars['HTML']['input']>;
  technicalOutcomeHelp?: InputMaybe<Scalars['HTML']['input']>;
  technicalOutcomeIncluded?: InputMaybe<Scalars['Boolean']['input']>;
  testers?: InputMaybe<Array<InputMaybe<TaskGroupTeamInput>>>;
  version?: InputMaybe<Scalars['IntOrString']['input']>;
};

export type AdminTierInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AdminUser = {
  __typename?: 'AdminUser';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  something?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type AgiloftApiLog = {
  __typename?: 'AgiloftAPILog';
  agiloftSOWId?: Maybe<Scalars['String']['output']>;
  api?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  payload?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type AgiloftApiResponse = {
  __typename?: 'AgiloftApiResponse';
  buildContinue?: Maybe<Scalars['Boolean']['output']>;
  buildIndex?: Maybe<Scalars['Int']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type AgiloftExpenseType = {
  __typename?: 'AgiloftExpenseType';
  agiloftDefaultExpenseClause?: Maybe<Scalars['String']['output']>;
  expenseClauses?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  expenseTypeName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  managedServicesExpenseClause?: Maybe<Scalars['String']['output']>;
  showExpenseMiles?: Maybe<Scalars['Boolean']['output']>;
};

export type AgiloftExpenseTypeInput = {
  agiloftDefaultExpenseClause?: InputMaybe<Scalars['String']['input']>;
  expenseClauses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  expenseTypeName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  managedServicesExpenseClause?: InputMaybe<Scalars['String']['input']>;
  showExpenseMiles?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AgiloftRole = {
  __typename?: 'AgiloftRole';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AgiloftSow = {
  __typename?: 'AgiloftSOW';
  custom_225653673?: Maybe<Scalars['IntOrString']['output']>;
  customerLegalName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  projectName?: Maybe<Scalars['String']['output']>;
  project_scope_clause_id?: Maybe<Scalars['String']['output']>;
  solutionArchitect?: Maybe<Scalars['String']['output']>;
};

export type AgiloftSowConnectionInput = {
  buildIndex?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  projectItemId?: InputMaybe<Scalars['String']['input']>;
};

export type AgiloftToken = {
  __typename?: 'AgiloftToken';
  access_token?: Maybe<Scalars['String']['output']>;
  expires_in?: Maybe<Scalars['Int']['output']>;
  token_type?: Maybe<Scalars['String']['output']>;
};

export type AgiloftTravelNoticeType = {
  __typename?: 'AgiloftTravelNoticeType';
  agiloftDefaultTravelNoticeClause?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  showTravelNoticePenaltyAmount?: Maybe<Scalars['Boolean']['output']>;
  travelNoticeClauses?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  travelNoticeTypeName?: Maybe<Scalars['String']['output']>;
};

export type AgiloftTravelNoticeTypeInput = {
  agiloftDefaultTravelNoticeClause?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  showTravelNoticePenaltyAmount?: InputMaybe<Scalars['Boolean']['input']>;
  travelNoticeClauses?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  travelNoticeTypeName?: InputMaybe<Scalars['String']['input']>;
};

export type BidAssuranceNote = {
  __typename?: 'BidAssuranceNote';
  createdAt?: Maybe<Scalars['DATE']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
};

export type BidAssuranceNoteInput = {
  createdAt?: InputMaybe<Scalars['DATE']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
};

export type BidAssuranceStatusHistory = {
  __typename?: 'BidAssuranceStatusHistory';
  status?: Maybe<BidAssuranceStatusType>;
  statusReason?: Maybe<Scalars['String']['output']>;
  taskGroupId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DATE']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type BidAssuranceStatusHistoryInput = {
  status?: InputMaybe<BidAssuranceStatusType>;
  statusReason?: InputMaybe<Scalars['String']['input']>;
  taskGroupId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DATE']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

export enum BidAssuranceStatusType {
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Denied = 'Denied',
  Escalated = 'Escalated',
  Requested = 'Requested',
  Started = 'Started',
}

export type Category = {
  __typename?: 'Category';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CategoryInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CdwOpenOrdersWorkList = {
  __typename?: 'CdwOpenOrdersWorkList';
  poCode?: Maybe<Scalars['String']['output']>;
};

export type CdwRate = {
  __typename?: 'CdwRate';
  agiloftRole?: Maybe<Scalars['String']['output']>;
  cost?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  practice?: Maybe<Scalars['String']['output']>;
  practiceName?: Maybe<Scalars['String']['output']>;
};

export type CdwRateInput = {
  agiloftRole?: InputMaybe<Scalars['String']['input']>;
  cost?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  practice?: InputMaybe<Scalars['String']['input']>;
};

export type CiscoAnnuity = {
  __typename?: 'CiscoAnnuity';
  _id?: Maybe<Scalars['ID']['output']>;
  activeCiscoQuote?: Maybe<Scalars['String']['output']>;
  actualStartDate?: Maybe<Scalars['DATE']['output']>;
  cdwBillingId?: Maybe<Scalars['String']['output']>;
  changeType?: Maybe<Scalars['String']['output']>;
  ciscoCapital?: Maybe<Scalars['Boolean']['output']>;
  ciscoQuotes?: Maybe<Array<Maybe<CiscoQuote>>>;
  ciscoSmartAccount?: Maybe<Scalars['String']['output']>;
  ciscoSubscriptionId?: Maybe<Scalars['String']['output']>;
  ciscoVirtualAccount?: Maybe<Scalars['String']['output']>;
  cofCreatedAt?: Maybe<Scalars['DATE']['output']>;
  cofCreatedBy?: Maybe<Scalars['String']['output']>;
  cofDeniedReason?: Maybe<Scalars['String']['output']>;
  cofRequestedAt?: Maybe<Scalars['DATE']['output']>;
  cofRequestedBy?: Maybe<Scalars['String']['output']>;
  cofSignedAt?: Maybe<Scalars['DATE']['output']>;
  cofSignedBy?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Array<Maybe<CiscoAnnuityComment>>>;
  contacts?: Maybe<Array<Maybe<CiscoAnnuityContact>>>;
  createdAt?: Maybe<Scalars['DATE']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  customerAdminSameAsBilling?: Maybe<Scalars['Boolean']['output']>;
  customerBillingAccount?: Maybe<Scalars['String']['output']>;
  customerPo?: Maybe<Scalars['String']['output']>;
  customerPurchaseOrder?: Maybe<Scalars['String']['output']>;
  dealId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastModifiedAt?: Maybe<Scalars['DATE']['output']>;
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  orderDeniedReason?: Maybe<Scalars['String']['output']>;
  orderPlacedAt?: Maybe<Scalars['DATE']['output']>;
  orderPlacedBy?: Maybe<Scalars['String']['output']>;
  previousStatus?: Maybe<Scalars['String']['output']>;
  processVersion?: Maybe<Scalars['Int']['output']>;
  projectItemId?: Maybe<Scalars['ID']['output']>;
  quotedAt?: Maybe<Scalars['DATE']['output']>;
  quotedBy?: Maybe<Scalars['String']['output']>;
  requestWordCopy?: Maybe<Scalars['Boolean']['output']>;
  smartAccount?: Maybe<Scalars['String']['output']>;
  stateContract?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  statusHistory?: Maybe<Array<Maybe<CiscoAnnuityStatusHistory>>>;
  subscriptionNumber?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  virtualAccount?: Maybe<Scalars['String']['output']>;
  webOrderId?: Maybe<Scalars['String']['output']>;
};

export type CiscoAnnuityCategory = {
  __typename?: 'CiscoAnnuityCategory';
  allowCdwDiscountEdit?: Maybe<Scalars['Boolean']['output']>;
  allowCustomerDiscountEdit?: Maybe<Scalars['Boolean']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type CiscoAnnuityCategoryInput = {
  allowCdwDiscountEdit?: InputMaybe<Scalars['Boolean']['input']>;
  allowCustomerDiscountEdit?: InputMaybe<Scalars['Boolean']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CiscoAnnuityComment = {
  __typename?: 'CiscoAnnuityComment';
  _id?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['DATE']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  note?: Maybe<Scalars['String']['output']>;
};

export type CiscoAnnuityCommentInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
};

export type CiscoAnnuityContact = {
  __typename?: 'CiscoAnnuityContact';
  _id?: Maybe<Scalars['ID']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  type?: Maybe<CiscoAnnuityContactType>;
};

export type CiscoAnnuityContactInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<CiscoAnnuityContactType>;
};

export enum CiscoAnnuityContactType {
  Cdw = 'CDW',
  CustomerBilling = 'CUSTOMER_BILLING',
  EDelivery = 'E_DELIVERY',
  WebexPlatformAdministrator = 'WEBEX_PLATFORM_ADMINISTRATOR',
}

export type CiscoAnnuityInput = {
  activeCiscoQuote?: InputMaybe<Scalars['String']['input']>;
  actualStartDate?: InputMaybe<Scalars['DATE']['input']>;
  cdwBillingId?: InputMaybe<Scalars['String']['input']>;
  changeType?: InputMaybe<Scalars['String']['input']>;
  ciscoCapital?: InputMaybe<Scalars['Boolean']['input']>;
  ciscoQuotes?: InputMaybe<Array<InputMaybe<CiscoQuoteInput>>>;
  ciscoSmartAccount?: InputMaybe<Scalars['String']['input']>;
  ciscoSubscriptionId?: InputMaybe<Scalars['String']['input']>;
  ciscoVirtualAccount?: InputMaybe<Scalars['String']['input']>;
  cofCreatedAt?: InputMaybe<Scalars['DATE']['input']>;
  cofCreatedBy?: InputMaybe<Scalars['String']['input']>;
  cofDeniedReason?: InputMaybe<Scalars['String']['input']>;
  cofRequestedAt?: InputMaybe<Scalars['DATE']['input']>;
  cofRequestedBy?: InputMaybe<Scalars['String']['input']>;
  cofSignedAt?: InputMaybe<Scalars['DATE']['input']>;
  cofSignedBy?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<Array<InputMaybe<CiscoAnnuityCommentInput>>>;
  contacts?: InputMaybe<Array<InputMaybe<CiscoAnnuityContactInput>>>;
  createdAt?: InputMaybe<Scalars['DATE']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  customerAdminSameAsBilling?: InputMaybe<Scalars['Boolean']['input']>;
  customerBillingAccount?: InputMaybe<Scalars['String']['input']>;
  customerPo?: InputMaybe<Scalars['String']['input']>;
  customerPurchaseOrder?: InputMaybe<Scalars['String']['input']>;
  dealId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  lastModifiedAt?: InputMaybe<Scalars['DATE']['input']>;
  lastModifiedBy?: InputMaybe<Scalars['String']['input']>;
  orderDeniedReason?: InputMaybe<Scalars['String']['input']>;
  orderPlacedAt?: InputMaybe<Scalars['DATE']['input']>;
  orderPlacedBy?: InputMaybe<Scalars['String']['input']>;
  previousStatus?: InputMaybe<Scalars['String']['input']>;
  processVersion?: InputMaybe<Scalars['Int']['input']>;
  projectItemId?: InputMaybe<Scalars['ID']['input']>;
  quotedAt?: InputMaybe<Scalars['DATE']['input']>;
  quotedBy?: InputMaybe<Scalars['String']['input']>;
  requestWordCopy?: InputMaybe<Scalars['Boolean']['input']>;
  smartAccount?: InputMaybe<Scalars['String']['input']>;
  stateContract?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  subscriptionNumber?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  virtualAccount?: InputMaybe<Scalars['String']['input']>;
  webOrderId?: InputMaybe<Scalars['String']['input']>;
};

export type CiscoAnnuityLine = {
  __typename?: 'CiscoAnnuityLine';
  _id?: Maybe<Scalars['ID']['output']>;
  annuityAudio?: Maybe<Scalars['Int']['output']>;
  annuityAutoRenewTerm?: Maybe<Scalars['Int']['output']>;
  annuityBillingCycle?: Maybe<Scalars['String']['output']>;
  annuityTerm?: Maybe<Scalars['Int']['output']>;
  audioTier?: Maybe<Scalars['Int']['output']>;
  autoRenew?: Maybe<Scalars['Boolean']['output']>;
  billingAmountChangeType?: Maybe<Scalars['String']['output']>;
  billingAmountNetChange?: Maybe<Scalars['Float']['output']>;
  billingCycle?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  cdwDiscount?: Maybe<Scalars['Float']['output']>;
  cdwPrice?: Maybe<Scalars['Float']['output']>;
  cdwTotalPrice?: Maybe<Scalars['Float']['output']>;
  chargeType?: Maybe<Scalars['String']['output']>;
  contractAmountChangeType?: Maybe<Scalars['String']['output']>;
  contractAmountNetChange?: Maybe<Scalars['Float']['output']>;
  creditAmount?: Maybe<Scalars['Float']['output']>;
  creditJson?: Maybe<Scalars['String']['output']>;
  creditType?: Maybe<Scalars['String']['output']>;
  currentContractAmount?: Maybe<Scalars['Float']['output']>;
  customerDiscount?: Maybe<Scalars['Float']['output']>;
  customerPrice?: Maybe<Scalars['Float']['output']>;
  customerTermTotal?: Maybe<Scalars['Float']['output']>;
  customerTotalPrice?: Maybe<Scalars['Float']['output']>;
  decimalPlaces?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  discountJson?: Maybe<Scalars['String']['output']>;
  extendedNetPrice?: Maybe<Scalars['Float']['output']>;
  extentionDatetime0?: Maybe<Scalars['String']['output']>;
  extentionDatetime1?: Maybe<Scalars['String']['output']>;
  extentionNumber0?: Maybe<Scalars['String']['output']>;
  extentionNumber1?: Maybe<Scalars['String']['output']>;
  extentionValue0?: Maybe<Scalars['String']['output']>;
  extentionValue1?: Maybe<Scalars['String']['output']>;
  extentionValue2?: Maybe<Scalars['String']['output']>;
  extentionValue3?: Maybe<Scalars['String']['output']>;
  extentionValue4?: Maybe<Scalars['String']['output']>;
  extentionValue5?: Maybe<Scalars['String']['output']>;
  gp?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  includeInQuote?: Maybe<Scalars['Boolean']['output']>;
  isDiscount?: Maybe<Scalars['Boolean']['output']>;
  isRateTable?: Maybe<Scalars['Boolean']['output']>;
  leadTime?: Maybe<Scalars['String']['output']>;
  line?: Maybe<Scalars['String']['output']>;
  lineChangeType?: Maybe<Scalars['String']['output']>;
  lineId?: Maybe<Scalars['String']['output']>;
  newBillingAmount?: Maybe<Scalars['Float']['output']>;
  newContractAmount?: Maybe<Scalars['Float']['output']>;
  noGPImpact?: Maybe<Scalars['Boolean']['output']>;
  offerDetailsJson?: Maybe<Scalars['String']['output']>;
  oldQuantity?: Maybe<Scalars['Int']['output']>;
  originalUnitListPrice?: Maybe<Scalars['Float']['output']>;
  parent?: Maybe<Scalars['ID']['output']>;
  partNumber?: Maybe<Scalars['String']['output']>;
  pricingTerm?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  quantityChange?: Maybe<Scalars['Int']['output']>;
  quantityChangeType?: Maybe<Scalars['String']['output']>;
  quotableNonOrderableProduct?: Maybe<Scalars['String']['output']>;
  quoteId?: Maybe<Scalars['String']['output']>;
  rateTableLink?: Maybe<Scalars['String']['output']>;
  rateTableName?: Maybe<Scalars['String']['output']>;
  serviceDuration?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['DATE']['output']>;
  term?: Maybe<Scalars['Float']['output']>;
  timeBoundCreditAmount?: Maybe<Scalars['Float']['output']>;
  timeBoundCreditDuration?: Maybe<Scalars['Float']['output']>;
  timeBoundCreditFreeToCustomer?: Maybe<Scalars['Boolean']['output']>;
  timeBoundCreditFrom?: Maybe<Scalars['String']['output']>;
  timeBoundCreditKeep?: Maybe<Scalars['Float']['output']>;
  timeBoundCreditName?: Maybe<Scalars['String']['output']>;
  timeBoundCreditType?: Maybe<Scalars['String']['output']>;
  topLevel?: Maybe<Scalars['Boolean']['output']>;
  totalAmount?: Maybe<Scalars['Float']['output']>;
  totalListPrice?: Maybe<Scalars['Float']['output']>;
  unitListPrice?: Maybe<Scalars['Float']['output']>;
  unitNetPrice?: Maybe<Scalars['Float']['output']>;
  unitOfMeasure?: Maybe<Scalars['String']['output']>;
  useCiscoTotalAmount?: Maybe<Scalars['Boolean']['output']>;
  userBillingCycle?: Maybe<Scalars['String']['output']>;
  userTerm?: Maybe<Scalars['Float']['output']>;
};

export type CiscoAnnuityLineInput = {
  annuityAudio?: InputMaybe<Scalars['Int']['input']>;
  annuityAutoRenewTerm?: InputMaybe<Scalars['Int']['input']>;
  annuityBillingCycle?: InputMaybe<Scalars['String']['input']>;
  annuityTerm?: InputMaybe<Scalars['Int']['input']>;
  audioTier?: InputMaybe<Scalars['Int']['input']>;
  autoRenew?: InputMaybe<Scalars['Boolean']['input']>;
  billingAmountChangeType?: InputMaybe<Scalars['String']['input']>;
  billingAmountNetChange?: InputMaybe<Scalars['Float']['input']>;
  billingCycle?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  cdwDiscount?: InputMaybe<Scalars['Float']['input']>;
  chargeType?: InputMaybe<Scalars['String']['input']>;
  contractAmountChangeType?: InputMaybe<Scalars['String']['input']>;
  contractAmountNetChange?: InputMaybe<Scalars['Float']['input']>;
  creditAmount?: InputMaybe<Scalars['Float']['input']>;
  creditJson?: InputMaybe<Scalars['String']['input']>;
  creditType?: InputMaybe<Scalars['String']['input']>;
  currentContractAmount?: InputMaybe<Scalars['Float']['input']>;
  customerDiscount?: InputMaybe<Scalars['Float']['input']>;
  decimalPlaces?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discount?: InputMaybe<Scalars['Float']['input']>;
  discountJson?: InputMaybe<Scalars['String']['input']>;
  extendedNetPrice?: InputMaybe<Scalars['Float']['input']>;
  extentionDatetime0?: InputMaybe<Scalars['String']['input']>;
  extentionDatetime1?: InputMaybe<Scalars['String']['input']>;
  extentionNumber0?: InputMaybe<Scalars['String']['input']>;
  extentionNumber1?: InputMaybe<Scalars['String']['input']>;
  extentionValue0?: InputMaybe<Scalars['String']['input']>;
  extentionValue1?: InputMaybe<Scalars['String']['input']>;
  extentionValue2?: InputMaybe<Scalars['String']['input']>;
  extentionValue3?: InputMaybe<Scalars['String']['input']>;
  extentionValue4?: InputMaybe<Scalars['String']['input']>;
  extentionValue5?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  includeInQuote?: InputMaybe<Scalars['Boolean']['input']>;
  isDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  isRateTable?: InputMaybe<Scalars['Boolean']['input']>;
  leadTime?: InputMaybe<Scalars['String']['input']>;
  line?: InputMaybe<Scalars['String']['input']>;
  lineChangeType?: InputMaybe<Scalars['String']['input']>;
  lineId?: InputMaybe<Scalars['String']['input']>;
  newBillingAmount?: InputMaybe<Scalars['Float']['input']>;
  newContractAmount?: InputMaybe<Scalars['Float']['input']>;
  noGPImpact?: InputMaybe<Scalars['Boolean']['input']>;
  offerDetailsJson?: InputMaybe<Scalars['String']['input']>;
  oldQuantity?: InputMaybe<Scalars['Int']['input']>;
  originalUnitListPrice?: InputMaybe<Scalars['Float']['input']>;
  parent?: InputMaybe<Scalars['ID']['input']>;
  partNumber?: InputMaybe<Scalars['String']['input']>;
  pricingTerm?: InputMaybe<Scalars['Float']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  quantityChange?: InputMaybe<Scalars['Int']['input']>;
  quantityChangeType?: InputMaybe<Scalars['String']['input']>;
  quotableNonOrderableProduct?: InputMaybe<Scalars['String']['input']>;
  quoteId?: InputMaybe<Scalars['String']['input']>;
  rateTableLink?: InputMaybe<Scalars['String']['input']>;
  rateTableName?: InputMaybe<Scalars['String']['input']>;
  serviceDuration?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['DATE']['input']>;
  term?: InputMaybe<Scalars['Float']['input']>;
  timeBoundCreditAmount?: InputMaybe<Scalars['Float']['input']>;
  timeBoundCreditDuration?: InputMaybe<Scalars['Float']['input']>;
  timeBoundCreditFreeToCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  timeBoundCreditFrom?: InputMaybe<Scalars['String']['input']>;
  timeBoundCreditKeep?: InputMaybe<Scalars['Float']['input']>;
  timeBoundCreditName?: InputMaybe<Scalars['String']['input']>;
  timeBoundCreditType?: InputMaybe<Scalars['String']['input']>;
  topLevel?: InputMaybe<Scalars['Boolean']['input']>;
  totalAmount?: InputMaybe<Scalars['Float']['input']>;
  unitListPrice?: InputMaybe<Scalars['Float']['input']>;
  unitNetPrice?: InputMaybe<Scalars['Float']['input']>;
  unitOfMeasure?: InputMaybe<Scalars['String']['input']>;
  useCiscoTotalAmount?: InputMaybe<Scalars['Boolean']['input']>;
  userBillingCycle?: InputMaybe<Scalars['String']['input']>;
  userTerm?: InputMaybe<Scalars['Float']['input']>;
};

export type CiscoAnnuityLineTerm = {
  __typename?: 'CiscoAnnuityLineTerm';
  autoRenew?: Maybe<Scalars['Boolean']['output']>;
  billingCycle?: Maybe<Scalars['String']['output']>;
  line?: Maybe<Scalars['String']['output']>;
  partNumber?: Maybe<Scalars['String']['output']>;
  term?: Maybe<Scalars['Float']['output']>;
};

export type CiscoAnnuityLineTermInput = {
  autoRenew?: InputMaybe<Scalars['Boolean']['input']>;
  billingCycle?: InputMaybe<Scalars['String']['input']>;
  line?: InputMaybe<Scalars['String']['input']>;
  partNumber?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['Float']['input']>;
};

export type CiscoAnnuityPartNumber = {
  __typename?: 'CiscoAnnuityPartNumber';
  category?: Maybe<Scalars['String']['output']>;
  decimalPlaces?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  partNumber?: Maybe<Scalars['String']['output']>;
  useCiscoTotalAmount?: Maybe<Scalars['Boolean']['output']>;
};

export type CiscoAnnuityPartNumberInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  decimalPlaces?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  partNumber?: InputMaybe<Scalars['String']['input']>;
  useCiscoTotalAmount?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CiscoAnnuityPaymentSchedule = {
  __typename?: 'CiscoAnnuityPaymentSchedule';
  id?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  values?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
};

export type CiscoAnnuityQuoteResponse = {
  __typename?: 'CiscoAnnuityQuoteResponse';
  lines?: Maybe<Array<Maybe<CiscoAnnuityLine>>>;
  paymentSchedule?: Maybe<Array<Maybe<CiscoAnnuityPaymentSchedule>>>;
  quoteSummary?: Maybe<CiscoAnnuityQuoteSummary>;
  serviceFeeSchedule?: Maybe<Array<Maybe<CiscoAnnuityServiceFeeSchedule>>>;
};

export type CiscoAnnuityQuoteSummary = {
  __typename?: 'CiscoAnnuityQuoteSummary';
  cdwCostInitial?: Maybe<Scalars['Float']['output']>;
  cdwCostRenewal?: Maybe<Scalars['Float']['output']>;
  cdwRawCostInitial?: Maybe<Scalars['Float']['output']>;
  cdwRawCostRenewal?: Maybe<Scalars['Float']['output']>;
  commissionTierInitial?: Maybe<Scalars['Float']['output']>;
  commissionTierRenewal?: Maybe<Scalars['Float']['output']>;
  customerPriceInitial?: Maybe<Scalars['Float']['output']>;
  customerPriceRenewal?: Maybe<Scalars['Float']['output']>;
  gpInitial?: Maybe<Scalars['Float']['output']>;
  gpPercentInitial?: Maybe<Scalars['Float']['output']>;
  gpPercentPerCiscoInitial?: Maybe<Scalars['Float']['output']>;
  gpPercentPerCiscoRenewal?: Maybe<Scalars['Float']['output']>;
  gpPercentRenewal?: Maybe<Scalars['Float']['output']>;
  gpRenewal?: Maybe<Scalars['Float']['output']>;
};

export type CiscoAnnuityQuoteSummaryInput = {
  cdwCostInitial?: InputMaybe<Scalars['Float']['input']>;
  cdwCostRenewal?: InputMaybe<Scalars['Float']['input']>;
  cdwRawCostInitial?: InputMaybe<Scalars['Float']['input']>;
  cdwRawCostRenewal?: InputMaybe<Scalars['Float']['input']>;
  commissionTierInitial?: InputMaybe<Scalars['Float']['input']>;
  commissionTierRenewal?: InputMaybe<Scalars['Float']['input']>;
  customerPriceInitial?: InputMaybe<Scalars['Float']['input']>;
  customerPriceRenewal?: InputMaybe<Scalars['Float']['input']>;
  gpInitial?: InputMaybe<Scalars['Float']['input']>;
  gpPercentInitial?: InputMaybe<Scalars['Float']['input']>;
  gpPercentPerCiscoInitial?: InputMaybe<Scalars['Float']['input']>;
  gpPercentPerCiscoRenewal?: InputMaybe<Scalars['Float']['input']>;
  gpPercentRenewal?: InputMaybe<Scalars['Float']['input']>;
  gpRenewal?: InputMaybe<Scalars['Float']['input']>;
};

export type CiscoAnnuityServiceFeeSchedule = {
  __typename?: 'CiscoAnnuityServiceFeeSchedule';
  customerPrice?: Maybe<Scalars['Float']['output']>;
  customerPriceMonthly?: Maybe<Scalars['Float']['output']>;
  gp?: Maybe<Scalars['Float']['output']>;
  monthQuantity?: Maybe<Scalars['Float']['output']>;
  period?: Maybe<Scalars['String']['output']>;
};

export type CiscoAnnuityStatusHistory = {
  __typename?: 'CiscoAnnuityStatusHistory';
  comment?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DATE']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type CiscoApiResult = {
  __typename?: 'CiscoApiResult';
  code?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  po?: Maybe<Scalars['String']['output']>;
  webOrderId?: Maybe<Scalars['String']['output']>;
};

export type CiscoCcwToken = {
  __typename?: 'CiscoCcwToken';
  access_token?: Maybe<Scalars['String']['output']>;
  expires_in?: Maybe<Scalars['Int']['output']>;
  token_type?: Maybe<Scalars['String']['output']>;
};

export type CiscoEaTrueForward = {
  __typename?: 'CiscoEATrueForward';
  duration?: Maybe<Scalars['Int']['output']>;
  durationInMonths?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DATE']['output']>;
  nextTrueForward?: Maybe<Scalars['DATE']['output']>;
  remainingDuration?: Maybe<Scalars['Int']['output']>;
  startDate?: Maybe<Scalars['DATE']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subscriptionID?: Maybe<Scalars['String']['output']>;
  trueForwardCost?: Maybe<Scalars['String']['output']>;
};

export type CiscoOrder = {
  __typename?: 'CiscoOrder';
  billToCustomerAddressLine1?: Maybe<Scalars['String']['output']>;
  billToCustomerAddressLine2?: Maybe<Scalars['String']['output']>;
  billToCustomerCity?: Maybe<Scalars['String']['output']>;
  billToCustomerCountryCode?: Maybe<Scalars['String']['output']>;
  billToCustomerName?: Maybe<Scalars['String']['output']>;
  billToCustomerPostalCode?: Maybe<Scalars['String']['output']>;
  billToCustomerSubDivisionCode?: Maybe<Scalars['String']['output']>;
  createdByName?: Maybe<Scalars['String']['output']>;
  dealId?: Maybe<Scalars['String']['output']>;
  endCustomerAddressLine1?: Maybe<Scalars['String']['output']>;
  endCustomerAddressLine2?: Maybe<Scalars['String']['output']>;
  endCustomerCity?: Maybe<Scalars['String']['output']>;
  endCustomerContactEmail?: Maybe<Scalars['String']['output']>;
  endCustomerContactName?: Maybe<Scalars['String']['output']>;
  endCustomerContactPhone?: Maybe<Scalars['String']['output']>;
  endCustomerCountryCode?: Maybe<Scalars['String']['output']>;
  endCustomerName?: Maybe<Scalars['String']['output']>;
  endCustomerPostalCode?: Maybe<Scalars['String']['output']>;
  endCustomerSmartAccount?: Maybe<Scalars['String']['output']>;
  endCustomerSubDivisionCode?: Maybe<Scalars['String']['output']>;
  holdingSmartAccount?: Maybe<Scalars['String']['output']>;
  orderBookDate?: Maybe<Scalars['DATE']['output']>;
  orderCurrency?: Maybe<Scalars['String']['output']>;
  orderName?: Maybe<Scalars['String']['output']>;
  orderSubmittedDate?: Maybe<Scalars['String']['output']>;
  orderType?: Maybe<Scalars['String']['output']>;
  poNumber?: Maybe<Scalars['String']['output']>;
  priceList?: Maybe<Scalars['String']['output']>;
  priceListId?: Maybe<Scalars['String']['output']>;
  quoteReferenceId?: Maybe<Scalars['String']['output']>;
  salesOrderReferenceId?: Maybe<Scalars['String']['output']>;
  shipToCustomerAddressLine1?: Maybe<Scalars['String']['output']>;
  shipToCustomerAddressLine2?: Maybe<Scalars['String']['output']>;
  shipToCustomerCity?: Maybe<Scalars['String']['output']>;
  shipToCustomerContactEmail?: Maybe<Scalars['String']['output']>;
  shipToCustomerContactName?: Maybe<Scalars['String']['output']>;
  shipToCustomerContactPhone?: Maybe<Scalars['String']['output']>;
  shipToCustomerCountryCode?: Maybe<Scalars['String']['output']>;
  shipToCustomerName?: Maybe<Scalars['String']['output']>;
  shipToCustomerPostalCode?: Maybe<Scalars['String']['output']>;
  shipToCustomerSubDivisionCode?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  submittedByName?: Maybe<Scalars['String']['output']>;
  tAA?: Maybe<Scalars['String']['output']>;
  totalAmount?: Maybe<Scalars['Float']['output']>;
  usFederalGOVT?: Maybe<Scalars['String']['output']>;
  webOrderId?: Maybe<Scalars['String']['output']>;
};

export type CiscoOrderApiResult = {
  __typename?: 'CiscoOrderApiResult';
  code?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  po?: Maybe<Scalars['String']['output']>;
  webOrderId?: Maybe<Scalars['String']['output']>;
};

export type CiscoOrderBatchHistory = {
  __typename?: 'CiscoOrderBatchHistory';
  id?: Maybe<Scalars['ID']['output']>;
  lastFetched?: Maybe<Scalars['DATE']['output']>;
  recordsProccessed?: Maybe<Scalars['Int']['output']>;
  start?: Maybe<Scalars['DATE']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type CiscoOrderLine = {
  __typename?: 'CiscoOrderLine';
  autoRenewalTerm?: Maybe<Scalars['String']['output']>;
  billingModel?: Maybe<Scalars['String']['output']>;
  cost?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  estimatedStartDate?: Maybe<Scalars['DATE']['output']>;
  initialTerm?: Maybe<Scalars['String']['output']>;
  lineIdSet?: Maybe<Scalars['String']['output']>;
  lineNumber?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  partNumber?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  requestedStartDate?: Maybe<Scalars['DATE']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  typeCode?: Maybe<Scalars['String']['output']>;
  webOrderId?: Maybe<Scalars['String']['output']>;
};

export type CiscoOrderSearch = {
  __typename?: 'CiscoOrderSearch';
  assignSmartAccountURL?: Maybe<Scalars['String']['output']>;
  billToCustomerId?: Maybe<Scalars['Int']['output']>;
  customerId?: Maybe<Scalars['Int']['output']>;
  dealId?: Maybe<Scalars['String']['output']>;
  endCustomer?: Maybe<Scalars['String']['output']>;
  endCustomerSmartAccountDomain?: Maybe<Scalars['String']['output']>;
  endCustomerSmartAccountStatus?: Maybe<Scalars['String']['output']>;
  expectedShipDate?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['String']['output']>;
  orderCreatedBy?: Maybe<Scalars['String']['output']>;
  orderCreatedByDate?: Maybe<Scalars['String']['output']>;
  orderName?: Maybe<Scalars['String']['output']>;
  orderStatus?: Maybe<Scalars['String']['output']>;
  orderTotal?: Maybe<Scalars['Float']['output']>;
  programType?: Maybe<Scalars['String']['output']>;
  purchaseOrderNo?: Maybe<Scalars['String']['output']>;
  salesOrderNo?: Maybe<Scalars['Int']['output']>;
  shipToCountry?: Maybe<Scalars['String']['output']>;
  shipToCustName?: Maybe<Scalars['String']['output']>;
  smartSkuTypeFlagDisp?: Maybe<Scalars['String']['output']>;
  submitDate?: Maybe<Scalars['String']['output']>;
  webOrderNo?: Maybe<Scalars['String']['output']>;
};

export type CiscoOrdersWorkList = {
  __typename?: 'CiscoOrdersWorkList';
  webOrderId?: Maybe<Scalars['String']['output']>;
};

export type CiscoQuote = {
  __typename?: 'CiscoQuote';
  _id?: Maybe<Scalars['ID']['output']>;
  autoRenew?: Maybe<Scalars['Boolean']['output']>;
  billingCycle?: Maybe<Scalars['String']['output']>;
  change?: Maybe<Scalars['Boolean']['output']>;
  committedAudioMarkup?: Maybe<Scalars['Float']['output']>;
  dealId?: Maybe<Scalars['String']['output']>;
  disableAdders?: Maybe<Scalars['Boolean']['output']>;
  expires?: Maybe<Scalars['DATE']['output']>;
  hideCustomerViewTotal?: Maybe<Scalars['Boolean']['output']>;
  hideIncludedSkus?: Maybe<Scalars['Boolean']['output']>;
  hideMonthlyFeeBreakdown?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isEstimate?: Maybe<Scalars['Boolean']['output']>;
  lineTerms?: Maybe<Array<Maybe<CiscoAnnuityLineTerm>>>;
  name?: Maybe<Scalars['String']['output']>;
  newCofRequired?: Maybe<Scalars['Boolean']['output']>;
  quoteId?: Maybe<Scalars['String']['output']>;
  rawQuote?: Maybe<Scalars['String']['output']>;
  removeBidAdders?: Maybe<Scalars['Boolean']['output']>;
  start?: Maybe<Scalars['DATE']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subscriptionReferenceId?: Maybe<Scalars['String']['output']>;
  term?: Maybe<Scalars['Float']['output']>;
  userBillingCycle?: Maybe<Scalars['String']['output']>;
  userTerm?: Maybe<Scalars['Float']['output']>;
};

export type CiscoQuoteInput = {
  autoRenew?: InputMaybe<Scalars['Boolean']['input']>;
  billingCycle?: InputMaybe<Scalars['String']['input']>;
  committedAudioMarkup?: InputMaybe<Scalars['Float']['input']>;
  contractLength?: InputMaybe<Scalars['Float']['input']>;
  dealId?: InputMaybe<Scalars['String']['input']>;
  hideCustomerViewTotal?: InputMaybe<Scalars['Boolean']['input']>;
  hideIncludedSkus?: InputMaybe<Scalars['Boolean']['input']>;
  hideMonthlyFeeBreakdown?: InputMaybe<Scalars['Boolean']['input']>;
  lineTerms?: InputMaybe<Array<InputMaybe<CiscoAnnuityLineTermInput>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  quoteId?: InputMaybe<Scalars['String']['input']>;
  removeBidAdders?: InputMaybe<Scalars['Boolean']['input']>;
  term?: InputMaybe<Scalars['Float']['input']>;
};

export type CiscoSmartAccount = {
  __typename?: 'CiscoSmartAccount';
  name?: Maybe<Scalars['String']['output']>;
  virtualAccounts?: Maybe<Array<Maybe<CiscoVirtualAccount>>>;
};

export type CiscoSmartAccountInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  virtualAccounts?: InputMaybe<Array<InputMaybe<CiscoVirtualAccountInput>>>;
};

export type CiscoSubscription = {
  __typename?: 'CiscoSubscription';
  accountTypeCode?: Maybe<Scalars['String']['output']>;
  adjustedMrc?: Maybe<Scalars['String']['output']>;
  autoRenTerm?: Maybe<Scalars['String']['output']>;
  billDay?: Maybe<Scalars['String']['output']>;
  billToCustomerId?: Maybe<Scalars['String']['output']>;
  billToCustomerName?: Maybe<Scalars['String']['output']>;
  billingModel?: Maybe<Scalars['String']['output']>;
  bundleLine?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  daysToRenewal?: Maybe<Scalars['Int']['output']>;
  endCustomerId?: Maybe<Scalars['String']['output']>;
  endCustomerName?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DATE']['output']>;
  hasZuora?: Maybe<Scalars['Boolean']['output']>;
  hostedOffer?: Maybe<Scalars['String']['output']>;
  initialTCV?: Maybe<Scalars['String']['output']>;
  initialTerm?: Maybe<Scalars['String']['output']>;
  lastUpdateDate?: Maybe<Scalars['DATE']['output']>;
  nextTrueForwardDate?: Maybe<Scalars['DATE']['output']>;
  orderActivationDate?: Maybe<Scalars['DATE']['output']>;
  orderSubmissionDate?: Maybe<Scalars['DATE']['output']>;
  overConsumed?: Maybe<Scalars['String']['output']>;
  poNumber?: Maybe<Scalars['String']['output']>;
  prepayTerm?: Maybe<Scalars['String']['output']>;
  primaryBillingContactEmail?: Maybe<Scalars['String']['output']>;
  primaryBillingContactFirstName?: Maybe<Scalars['String']['output']>;
  primaryBillingContactId?: Maybe<Scalars['String']['output']>;
  primaryBillingContactLastName?: Maybe<Scalars['String']['output']>;
  primaryBillingContactPhone?: Maybe<Scalars['String']['output']>;
  primaryBusinessContactEmail?: Maybe<Scalars['String']['output']>;
  primaryBusinessContactFirstName?: Maybe<Scalars['String']['output']>;
  primaryBusinessContactId?: Maybe<Scalars['String']['output']>;
  primaryBusinessContactLastName?: Maybe<Scalars['String']['output']>;
  primaryBusinessContactPhone?: Maybe<Scalars['String']['output']>;
  remainingTerm?: Maybe<Scalars['String']['output']>;
  renewalDate?: Maybe<Scalars['DATE']['output']>;
  renewalTerm?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DATE']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  tfConsumptionQuantity?: Maybe<Scalars['Int']['output']>;
  webOrderId?: Maybe<Scalars['String']['output']>;
};

export type CiscoSubscriptionBatchHistory = {
  __typename?: 'CiscoSubscriptionBatchHistory';
  id?: Maybe<Scalars['ID']['output']>;
  lastFetched?: Maybe<Scalars['DATE']['output']>;
  recordsProccessed?: Maybe<Scalars['Int']['output']>;
  start?: Maybe<Scalars['DATE']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type CiscoSubscriptionConsumptionSku = {
  __typename?: 'CiscoSubscriptionConsumptionSku';
  c1ToDNAMigratedCount?: Maybe<Scalars['Int']['output']>;
  calculationMethod?: Maybe<Scalars['String']['output']>;
  commerceSku?: Maybe<Scalars['String']['output']>;
  commerceSkuDescription?: Maybe<Scalars['String']['output']>;
  custSuiteId?: Maybe<Scalars['String']['output']>;
  custSuiteName?: Maybe<Scalars['String']['output']>;
  deployment?: Maybe<Scalars['String']['output']>;
  eol?: Maybe<Scalars['Boolean']['output']>;
  healthMessage?: Maybe<Scalars['String']['output']>;
  licenseGenerated?: Maybe<Scalars['Int']['output']>;
  licenseMigrated?: Maybe<Scalars['Int']['output']>;
  preEAConsumption?: Maybe<Scalars['Int']['output']>;
  premierEntitlements?: Maybe<Scalars['Int']['output']>;
  purchasedEntitlements?: Maybe<Scalars['Int']['output']>;
  remainingEntitlement?: Maybe<Scalars['Int']['output']>;
  reportDate?: Maybe<Scalars['DATE']['output']>;
  softwareDownloads?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  totalConsumption?: Maybe<Scalars['Int']['output']>;
  totalEntitlement?: Maybe<Scalars['Int']['output']>;
};

export type CiscoSubscriptionDetailsWorkList = {
  __typename?: 'CiscoSubscriptionDetailsWorkList';
  subscriptionId?: Maybe<Scalars['String']['output']>;
};

export type CiscoSubscriptionHistory = {
  __typename?: 'CiscoSubscriptionHistory';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['DATE']['output']>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
  transactionType?: Maybe<Scalars['String']['output']>;
  webOrderId?: Maybe<Scalars['String']['output']>;
};

export type CiscoSubscriptionHistoryWorkList = {
  __typename?: 'CiscoSubscriptionHistoryWorkList';
  subscriptionId?: Maybe<Scalars['String']['output']>;
};

export type CiscoSubscriptionLine = {
  __typename?: 'CiscoSubscriptionLine';
  billingAmount?: Maybe<Scalars['Float']['output']>;
  chargeType?: Maybe<Scalars['String']['output']>;
  deliveryOption?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  extendedNetPrice?: Maybe<Scalars['Float']['output']>;
  hostedOffer?: Maybe<Scalars['String']['output']>;
  lineNumber?: Maybe<Scalars['String']['output']>;
  monthlySubscriptionCredit?: Maybe<Scalars['Float']['output']>;
  overConsumed?: Maybe<Scalars['String']['output']>;
  overConsumedQuantity?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  suite?: Maybe<Scalars['String']['output']>;
  tfConsumptionQuantity?: Maybe<Scalars['Int']['output']>;
  tfEntitlement?: Maybe<Scalars['String']['output']>;
  totalCredits?: Maybe<Scalars['Float']['output']>;
  totalDiscount?: Maybe<Scalars['Float']['output']>;
  typeOfQuantity?: Maybe<Scalars['String']['output']>;
  unitListPrice?: Maybe<Scalars['Float']['output']>;
  unitNetPrice?: Maybe<Scalars['Float']['output']>;
  usageType?: Maybe<Scalars['String']['output']>;
};

export type CiscoVirtualAccount = {
  __typename?: 'CiscoVirtualAccount';
  name?: Maybe<Scalars['String']['output']>;
};

export type CiscoVirtualAccountInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Collection = {
  __typename?: 'Collection';
  count?: Maybe<Scalars['Int']['output']>;
  lastModified?: Maybe<Scalars['DATE']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ContentRequest = {
  __typename?: 'ContentRequest';
  assignee?: Maybe<Scalars['JSON']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  issuetype?: Maybe<Scalars['JSON']['output']>;
  jiraIssue?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['JSON']['output']>;
  project?: Maybe<Scalars['JSON']['output']>;
  reporter?: Maybe<Scalars['JSON']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
};

export type ContentRequestInput = {
  assignee?: InputMaybe<Scalars['JSON']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  issuetype?: InputMaybe<Scalars['JSON']['input']>;
  jiraIssue?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['JSON']['input']>;
  project?: InputMaybe<Scalars['JSON']['input']>;
  reporter?: InputMaybe<Scalars['JSON']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
};

export type CustomService = {
  __typename?: 'CustomService';
  cost?: Maybe<Scalars['IntOrString']['output']>;
  divisorRate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['IntOrString']['output']>;
  margin?: Maybe<Scalars['IntOrString']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  pmPercent?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  providerName?: Maybe<Scalars['String']['output']>;
  site?: Maybe<Scalars['String']['output']>;
};

export type Customer = {
  __typename?: 'Customer';
  _id?: Maybe<Scalars['ID']['output']>;
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  area?: Maybe<Scalars['String']['output']>;
  ciscoSmartAccounts?: Maybe<Array<Maybe<CiscoSmartAccount>>>;
  city?: Maybe<Scalars['String']['output']>;
  companyCode?: Maybe<Scalars['IntOrString']['output']>;
  contactPhone?: Maybe<Scalars['IntOrString']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  customerAddressCode?: Maybe<Scalars['IntOrString']['output']>;
  customerCode?: Maybe<Scalars['IntOrString']['output']>;
  customerDescription?: Maybe<Scalars['String']['output']>;
  customerSeq?: Maybe<Scalars['IntOrString']['output']>;
  district?: Maybe<Scalars['String']['output']>;
  glCode?: Maybe<Scalars['IntOrString']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  legacyId?: Maybe<Scalars['String']['output']>;
  listName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  onedriveId?: Maybe<Scalars['String']['output']>;
  onedriveLink?: Maybe<Scalars['String']['output']>;
  primaryAMCoworkerEmail?: Maybe<Scalars['String']['output']>;
  primaryAMCoworkerName?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  secondaryAMCoworkerEmail?: Maybe<Scalars['String']['output']>;
  secondaryAMCoworkerName?: Maybe<Scalars['String']['output']>;
  sector?: Maybe<Scalars['String']['output']>;
  stateCode?: Maybe<Scalars['String']['output']>;
  t12Months?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['IntOrString']['output']>;
};

export type CustomerInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  ciscoSmartAccount?: InputMaybe<Array<InputMaybe<CiscoSmartAccountInput>>>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  customerCode?: InputMaybe<Scalars['IntOrString']['input']>;
  customerDescription?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  onedriveId?: InputMaybe<Scalars['String']['input']>;
  onedriveLink?: InputMaybe<Scalars['String']['input']>;
  stateCode?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['IntOrString']['input']>;
};

export enum CustomerType {
  Existing = 'Existing',
  New = 'New',
}

export type DataCollectionItem = {
  __typename?: 'DataCollectionItem';
  id?: Maybe<Scalars['Int']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type DataCollectionItemInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export enum DatabaseActions {
  Create = 'CREATE',
  Delete = 'DELETE',
  Update = 'UPDATE',
}

export type DefaultAddResponse = {
  __typename?: 'DefaultAddResponse';
  id: Scalars['ID']['output'];
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DefaultMutationResponse = {
  __typename?: 'DefaultMutationResponse';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DefaultMutationResponseWithId = {
  __typename?: 'DefaultMutationResponseWithID';
  id?: Maybe<Scalars['ID']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type EmailTemplates = {
  __typename?: 'EmailTemplates';
  body?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
};

export type EmailTemplatesInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type EnrollmentSummary = {
  __typename?: 'EnrollmentSummary';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rate?: Maybe<Scalars['IntOrString']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['IntOrString']['output']>;
};

export type EstimatorSowInput = {
  agiloftSentStatus?: InputMaybe<Scalars['String']['input']>;
  agiloftSowId?: InputMaybe<Scalars['String']['input']>;
  businessOutcome?: InputMaybe<Scalars['HTML']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  estimatorId?: InputMaybe<Scalars['ID']['input']>;
  executiveSummaryDrivers?: InputMaybe<Scalars['HTML']['input']>;
  executiveSummaryOverview?: InputMaybe<Scalars['HTML']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  modified?: InputMaybe<Scalars['String']['input']>;
  modifiedBy?: InputMaybe<Scalars['String']['input']>;
  outcomeBased?: InputMaybe<Scalars['Boolean']['input']>;
  outcomeProjectScopePhases?: InputMaybe<Scalars['HTML']['input']>;
  projectItemId?: InputMaybe<Scalars['ID']['input']>;
  taskGroup?: InputMaybe<Scalars['String']['input']>;
  technicalOutcome?: InputMaybe<Scalars['HTML']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type EstimatorTotals = {
  __typename?: 'EstimatorTotals';
  cdwCost?: Maybe<Scalars['Float']['output']>;
  enrollmentPmHours?: Maybe<Scalars['Float']['output']>;
  grossProfit?: Maybe<Scalars['Float']['output']>;
  isMinPmMet?: Maybe<Scalars['Boolean']['output']>;
  managedSericesOtc?: Maybe<Scalars['Float']['output']>;
  managedServices?: Maybe<Scalars['Float']['output']>;
  minPmHours?: Maybe<Scalars['Float']['output']>;
  onetimeService?: Maybe<Scalars['Float']['output']>;
  pmHours?: Maybe<Scalars['Float']['output']>;
  serviceEstimate?: Maybe<Scalars['Float']['output']>;
  taskHours?: Maybe<Scalars['Float']['output']>;
};

export type FbUser = {
  __typename?: 'FBUser';
  _id: Scalars['ID']['output'];
  admin?: Maybe<Scalars['Boolean']['output']>;
  aud?: Maybe<Scalars['String']['output']>;
  auth_time?: Maybe<Scalars['String']['output']>;
  contentAdmin?: Maybe<Scalars['Boolean']['output']>;
  department?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  email_verified?: Maybe<Scalars['Boolean']['output']>;
  employeeId?: Maybe<Scalars['String']['output']>;
  exp?: Maybe<Scalars['String']['output']>;
  iat?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  iss?: Maybe<Scalars['String']['output']>;
  managedServicesAdmin?: Maybe<Scalars['Boolean']['output']>;
  managerEmail?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  sub?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type FbUserMetaData = {
  __typename?: 'FBUserMetaData';
  creationTime?: Maybe<Scalars['String']['output']>;
  lastSignInTime?: Maybe<Scalars['String']['output']>;
};

export type FbUserMetaDataInput = {
  creationTime?: InputMaybe<Scalars['String']['input']>;
  lastSignInTime?: InputMaybe<Scalars['String']['input']>;
};

export type FixedFeeMilestone = {
  __typename?: 'FixedFeeMilestone';
  cost?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['IntOrString']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  percent?: Maybe<Scalars['Float']['output']>;
};

export type FixedFeeMilestoneInput = {
  cost?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  percent?: InputMaybe<Scalars['Float']['input']>;
};

export type GainsightSubscriptionsView = {
  __typename?: 'GainsightSubscriptionsView';
  Sub_AccountTypeCode?: Maybe<Scalars['String']['output']>;
  Sub_AdjustedMRC?: Maybe<Scalars['String']['output']>;
  Sub_AppInf_EA?: Maybe<Scalars['String']['output']>;
  Sub_AutoRenewTerm?: Maybe<Scalars['String']['output']>;
  Sub_BillDay?: Maybe<Scalars['String']['output']>;
  Sub_BillToCustomerID?: Maybe<Scalars['String']['output']>;
  Sub_BillToCustomerName?: Maybe<Scalars['String']['output']>;
  Sub_BillingContact_Email?: Maybe<Scalars['String']['output']>;
  Sub_BillingContact_FirstName?: Maybe<Scalars['String']['output']>;
  Sub_BillingContact_ID?: Maybe<Scalars['String']['output']>;
  Sub_BillingContact_LastName?: Maybe<Scalars['String']['output']>;
  Sub_BillingContact_Phone?: Maybe<Scalars['String']['output']>;
  Sub_BillingModel?: Maybe<Scalars['String']['output']>;
  Sub_BundleLine?: Maybe<Scalars['String']['output']>;
  Sub_BusinessContact_Email?: Maybe<Scalars['String']['output']>;
  Sub_BusinessContact_FirstName?: Maybe<Scalars['String']['output']>;
  Sub_BusinessContact_ID?: Maybe<Scalars['String']['output']>;
  Sub_BusinessContact_LastName?: Maybe<Scalars['String']['output']>;
  Sub_BusinessContact_Phone?: Maybe<Scalars['String']['output']>;
  Sub_CiscoProvisioningEmailRecieved?: Maybe<Scalars['String']['output']>;
  Sub_Cisco_TCV?: Maybe<Scalars['String']['output']>;
  Sub_Collab_EA?: Maybe<Scalars['String']['output']>;
  Sub_CurrencyCode?: Maybe<Scalars['String']['output']>;
  Sub_DaysToRenewal?: Maybe<Scalars['String']['output']>;
  Sub_EAType?: Maybe<Scalars['String']['output']>;
  Sub_EndCustomerContact_Email?: Maybe<Scalars['String']['output']>;
  Sub_EndCustomerContact_Name?: Maybe<Scalars['String']['output']>;
  Sub_EndCustomerContact_Phone?: Maybe<Scalars['String']['output']>;
  Sub_EndCustomerID?: Maybe<Scalars['String']['output']>;
  Sub_EndCustomerName?: Maybe<Scalars['String']['output']>;
  Sub_EndCustomerSmartAccount?: Maybe<Scalars['String']['output']>;
  Sub_EndCustomer_AddressLine1?: Maybe<Scalars['String']['output']>;
  Sub_EndCustomer_AddressLine2?: Maybe<Scalars['String']['output']>;
  Sub_EndCustomer_CityName?: Maybe<Scalars['String']['output']>;
  Sub_EndCustomer_CountryCode?: Maybe<Scalars['String']['output']>;
  Sub_EndCustomer_CountrySubDivisionCode?: Maybe<Scalars['String']['output']>;
  Sub_EndCustomer_PostalCode?: Maybe<Scalars['String']['output']>;
  Sub_EndDate?: Maybe<Scalars['String']['output']>;
  Sub_EstimatedStartDate?: Maybe<Scalars['String']['output']>;
  Sub_HoldingSmartAccount?: Maybe<Scalars['String']['output']>;
  Sub_HostedOffer?: Maybe<Scalars['String']['output']>;
  Sub_Initial_Cisco_TCV?: Maybe<Scalars['String']['output']>;
  Sub_Initial_DealID?: Maybe<Scalars['String']['output']>;
  Sub_Initial_OrderSubmissionDate?: Maybe<Scalars['String']['output']>;
  Sub_Initial_PONumber?: Maybe<Scalars['String']['output']>;
  Sub_Initial_Term?: Maybe<Scalars['String']['output']>;
  Sub_Initial_WebOrder_ID?: Maybe<Scalars['String']['output']>;
  Sub_ItemType?: Maybe<Scalars['String']['output']>;
  Sub_LastUpdateDate?: Maybe<Scalars['String']['output']>;
  Sub_Multi_EA?: Maybe<Scalars['String']['output']>;
  Sub_Networking_EA?: Maybe<Scalars['String']['output']>;
  Sub_NextTrueForwardDate?: Maybe<Scalars['String']['output']>;
  Sub_OrderActivationDate?: Maybe<Scalars['String']['output']>;
  Sub_OrderSubmissionDate?: Maybe<Scalars['String']['output']>;
  Sub_OverConsumed?: Maybe<Scalars['String']['output']>;
  Sub_PONumber?: Maybe<Scalars['String']['output']>;
  Sub_PrepayTerm?: Maybe<Scalars['String']['output']>;
  Sub_RemainingTerm?: Maybe<Scalars['String']['output']>;
  Sub_RenewalDate?: Maybe<Scalars['String']['output']>;
  Sub_RenewalTerm?: Maybe<Scalars['String']['output']>;
  Sub_RequestedStartDate?: Maybe<Scalars['String']['output']>;
  Sub_Services_EA?: Maybe<Scalars['String']['output']>;
  Sub_ShipToCustomerContact_Name?: Maybe<Scalars['String']['output']>;
  Sub_ShipToCustomer_AddressLine1?: Maybe<Scalars['String']['output']>;
  Sub_ShipToCustomer_AddressLine2?: Maybe<Scalars['String']['output']>;
  Sub_ShipToCustomer_CityName?: Maybe<Scalars['String']['output']>;
  Sub_ShipToCustomer_CountryCode?: Maybe<Scalars['String']['output']>;
  Sub_ShipToCustomer_CountrySubDivisionCode?: Maybe<
    Scalars['String']['output']
  >;
  Sub_ShipToCustomer_Email?: Maybe<Scalars['String']['output']>;
  Sub_ShipToCustomer_Name?: Maybe<Scalars['String']['output']>;
  Sub_ShipToCustomer_Phone?: Maybe<Scalars['String']['output']>;
  Sub_ShipToCustomer_PostalCode?: Maybe<Scalars['String']['output']>;
  Sub_StartDate?: Maybe<Scalars['String']['output']>;
  Sub_Sub_w_EA?: Maybe<Scalars['String']['output']>;
  Sub_SubscriptionID?: Maybe<Scalars['String']['output']>;
  Sub_Subscription_Status?: Maybe<Scalars['String']['output']>;
  Sub_TFConsumptionQty?: Maybe<Scalars['String']['output']>;
  Sub_WebOrder_ID?: Maybe<Scalars['String']['output']>;
  Sub_WebOrder_ID_List?: Maybe<Scalars['String']['output']>;
};

export type GetAdminConfigSettingsResponse = {
  __typename?: 'GetAdminConfigSettingsResponse';
  adminConfigSettings: Array<Maybe<AdminConfigSettings>>;
  count?: Maybe<Scalars['Int']['output']>;
};

export type GetAgiloftExpenseTypesResponse = {
  __typename?: 'GetAgiloftExpenseTypesResponse';
  agiloftExpenseTypes?: Maybe<Array<Maybe<AgiloftExpenseType>>>;
  count?: Maybe<Scalars['Int']['output']>;
};

export type GetAgiloftRolesResponse = {
  __typename?: 'GetAgiloftRolesResponse';
  count?: Maybe<Scalars['Int']['output']>;
  roles?: Maybe<Array<Maybe<AgiloftRole>>>;
};

export type GetAgiloftTravelNoticeTypesResponse = {
  __typename?: 'GetAgiloftTravelNoticeTypesResponse';
  agiloftTravelNoticeTypes?: Maybe<Array<Maybe<AgiloftTravelNoticeType>>>;
  count?: Maybe<Scalars['Int']['output']>;
};

export type GetCdwRates = {
  __typename?: 'GetCdwRates';
  agiloftRole?: Maybe<Scalars['String']['output']>;
  cost?: Maybe<Scalars['IntOrString']['output']>;
  createdAt?: Maybe<Scalars['DATE']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  practice?: Maybe<Scalars['String']['output']>;
  practiceName?: Maybe<Scalars['String']['output']>;
};

export type GetCdwRatesResponse = {
  __typename?: 'GetCdwRatesResponse';
  count?: Maybe<Scalars['Int']['output']>;
  rates?: Maybe<Array<Maybe<GetCdwRates>>>;
};

export type GetCdwRatesResponse_RatesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GetCiscoAnnuityPartNumbersResponse = {
  __typename?: 'GetCiscoAnnuityPartNumbersResponse';
  count?: Maybe<Scalars['Int']['output']>;
  partNumbers?: Maybe<Array<Maybe<CiscoAnnuityPartNumber>>>;
};

export type GetCiscoAnnuityPartNumbersResponse_PartNumbersArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type GetCiscoCcwEstimateByIdResponse = {
  __typename?: 'GetCiscoCcwEstimateByIdResponse';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type GetCiscoCcwQuoteByDealIdResponse = {
  __typename?: 'GetCiscoCcwQuoteByDealIdResponse';
  error?: Maybe<Scalars['String']['output']>;
  quotes?: Maybe<Array<Maybe<CiscoQuote>>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type GetCollectionsResponse = {
  __typename?: 'GetCollectionsResponse';
  collections?: Maybe<Array<Maybe<Collection>>>;
  count?: Maybe<Scalars['IntOrString']['output']>;
  lastModified?: Maybe<Scalars['IntOrString']['output']>;
};

export type GetCustomersByOpportunityResponse = {
  __typename?: 'GetCustomersByOpportunityResponse';
  count: Scalars['Int']['output'];
  customers?: Maybe<Array<Maybe<Customer>>>;
};

export type GetCustomersResponse = {
  __typename?: 'GetCustomersResponse';
  count: Scalars['Int']['output'];
  customers: Array<Maybe<Customer>>;
};

export type GetEmailTemplatesResponse = {
  __typename?: 'GetEmailTemplatesResponse';
  emailTemplates: Array<Maybe<EmailTemplates>>;
};

export type GetEstimateToPsaResponse = {
  __typename?: 'GetEstimateToPSAResponse';
  estimateProductList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  inputs?: Maybe<Array<Maybe<EstimateToPsaInputs>>>;
};

export type GetOpportunityResponse = {
  __typename?: 'GetOpportunityResponse';
  count?: Maybe<Scalars['Int']['output']>;
  opportunities: Array<Maybe<Opportunity>>;
};

export type GetPhasesResponse = {
  __typename?: 'GetPhasesResponse';
  count?: Maybe<Scalars['Int']['output']>;
  phases?: Maybe<Array<Maybe<Phase>>>;
};

export type GetPhasesResponse_PhasesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GetProjectItemEstimatorRatesResponse = {
  __typename?: 'GetProjectItemEstimatorRatesResponse';
  practices?: Maybe<Array<Maybe<Practice>>>;
  rates?: Maybe<Array<Maybe<RoleAndRate>>>;
};

export type GetProjectRatesResponse = {
  __typename?: 'GetProjectRatesResponse';
  practices?: Maybe<Array<Maybe<Practice>>>;
  rates?: Maybe<Array<Maybe<RoleAndRate>>>;
};

export type GetProjectUsersResponse = {
  __typename?: 'GetProjectUsersResponse';
  count?: Maybe<Scalars['Int']['output']>;
  users?: Maybe<Array<Maybe<ProjectUser>>>;
};

export type GetProjectsResponse = {
  __typename?: 'GetProjectsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  projects?: Maybe<Array<Maybe<Opportunity>>>;
};

export type GetProposalTemplatesResponse = {
  __typename?: 'GetProposalTemplatesResponse';
  count?: Maybe<Scalars['Int']['output']>;
  proposalTemplates?: Maybe<Array<Maybe<ProposalTemplate>>>;
};

export type GetRolesAndRatesResponse = {
  __typename?: 'GetRolesAndRatesResponse';
  count?: Maybe<Scalars['Int']['output']>;
  rolesAndRates?: Maybe<Array<Maybe<RoleAndRate>>>;
};

export type GetRolesResponse = {
  __typename?: 'GetRolesResponse';
  count?: Maybe<Scalars['Int']['output']>;
  roles?: Maybe<Array<Maybe<RoleAndRate>>>;
};

export type GetSalesHistoryResponse = {
  __typename?: 'GetSalesHistoryResponse';
  count?: Maybe<Scalars['Int']['output']>;
  salesHistory?: Maybe<Array<Maybe<SalesHistory>>>;
};

export type GetSalesHistoryResponse_SalesHistoryArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GetSalesRepsTrendingResponse = {
  __typename?: 'GetSalesRepsTrendingResponse';
  count: Scalars['Int']['output'];
  salesReps: Array<Maybe<SalesRepTrending>>;
};

export type GetSalesRepsTrendingResponse_SalesRepsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GetScopeGroupsResponse = {
  __typename?: 'GetScopeGroupsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  scopeGroups?: Maybe<Array<Maybe<ScopeGroup>>>;
};

export type GetScopeGroupsResponse_CountArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type GetScopeGroupsResponse_ScopeGroupsArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type GetScopeItemsResponse = {
  __typename?: 'GetScopeItemsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  scopeItems?: Maybe<Array<Maybe<ScopeItem>>>;
};

export type GetScopeItemsResponse_CountArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type GetScopeItemsResponse_ScopeItemsArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type GetServiceItemsResponse = {
  __typename?: 'GetServiceItemsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  serviceItems?: Maybe<Array<Maybe<ServiceItem>>>;
};

export type GetServiceItemsResponse_CountArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type GetServiceItemsResponse_ServiceItemsArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GetServiceSkillsResponse = {
  __typename?: 'GetServiceSkillsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  serviceSkills?: Maybe<Array<Maybe<ServiceSkill>>>;
};

export type GetServiceSkillsResponse_CountArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type GetServiceSkillsResponse_ServiceSkillsArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GetServiceSuppliersResponse = {
  __typename?: 'GetServiceSuppliersResponse';
  count?: Maybe<Scalars['Int']['output']>;
  serviceSuppliers?: Maybe<Array<Maybe<ServiceSupplier>>>;
};

export type GetServiceSuppliersResponse_CountArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type GetServiceSuppliersResponse_ServiceSuppliersArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GetSowSectionsResponse = {
  __typename?: 'GetSowSectionsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  sowSections?: Maybe<Array<Maybe<SowSection>>>;
};

export type GetSowSectionsResponse_CountArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type GetSowSectionsResponse_SowSectionsArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type GetTaskGroupSowResponse = {
  __typename?: 'GetTaskGroupSowResponse';
  rawSowContent?: Maybe<Scalars['String']['output']>;
  sow?: Maybe<Scalars['String']['output']>;
};

export type GetTaskGroupsResponse = {
  __typename?: 'GetTaskGroupsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  taskGroups?: Maybe<Array<Maybe<TaskGroup>>>;
};

export type GetTaskGroupsResponse_CountArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type GetTaskGroupsResponse_TaskGroupsArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type GetTasksResponse = {
  __typename?: 'GetTasksResponse';
  count?: Maybe<Scalars['Int']['output']>;
  tasks?: Maybe<Array<Maybe<Task>>>;
};

export type GetTasksResponse_CountArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type GetTasksResponse_TasksArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type GetTiersResponse = {
  __typename?: 'GetTiersResponse';
  count?: Maybe<Scalars['Int']['output']>;
  tiers?: Maybe<Array<Maybe<Tier>>>;
};

export type GetTiersResponse_CountArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type GetTiersResponse_TiersArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type GetTotalCustomersResponse = {
  __typename?: 'GetTotalCustomersResponse';
  count: Scalars['Int']['output'];
  customers: Array<Maybe<Customer>>;
  customersSinceLastMonthPercent: Scalars['Int']['output'];
};

export type GetTotalSalesResponse = {
  __typename?: 'GetTotalSalesResponse';
  totalSales?: Maybe<Scalars['Float']['output']>;
  totalSalesSinceLastMonthPercent?: Maybe<Scalars['Float']['output']>;
};

export type GetUsersResponse = {
  __typename?: 'GetUsersResponse';
  count?: Maybe<Scalars['Int']['output']>;
  users?: Maybe<Array<Maybe<FbUser>>>;
};

export type HandledMutationResponse = {
  __typename?: 'HandledMutationResponse';
  data?: Maybe<Scalars['ANY']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type InvestmentSummary = {
  __typename?: 'InvestmentSummary';
  hwsw?: Maybe<Scalars['Float']['output']>;
  managedServicesName?: Maybe<Scalars['String']['output']>;
  managedServicesTotal?: Maybe<Scalars['Float']['output']>;
  servicesTotal?: Maybe<Scalars['Float']['output']>;
  tAndETotal?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type ItemsProvidedToCustomers = {
  __typename?: 'ItemsProvidedToCustomers';
  description?: Maybe<Scalars['String']['output']>;
  format?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['IntOrString']['output']>;
  item?: Maybe<Scalars['String']['output']>;
  operator?: Maybe<Scalars['IntOrString']['output']>;
  taskId?: Maybe<Scalars['IntOrString']['output']>;
  value?: Maybe<Scalars['IntOrString']['output']>;
};

export type ItemsProvidedToCustomersInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  format?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['IntOrString']['input']>;
  item?: InputMaybe<Scalars['String']['input']>;
  operator?: InputMaybe<Scalars['IntOrString']['input']>;
  taskId?: InputMaybe<Scalars['IntOrString']['input']>;
  value?: InputMaybe<Scalars['IntOrString']['input']>;
};

export type Lead = {
  __typename?: 'Lead';
  additionalInformation?: Maybe<Scalars['String']['output']>;
  annuityTotal?: Maybe<Scalars['Float']['output']>;
  associatedProjects?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  customerDescription?: Maybe<Scalars['String']['output']>;
  hardwareTotal?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Scalars['String']['output']>;
  primarySa?: Maybe<Scalars['String']['output']>;
  primarySales?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['String']['output']>;
  secondarySa?: Maybe<Scalars['String']['output']>;
  secondarySales?: Maybe<Scalars['String']['output']>;
  serviceTotal?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type LeadInput = {
  customerDescription?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type LineSet = {
  __typename?: 'LineSet';
  contractType?: Maybe<Scalars['String']['output']>;
  createMilestone?: Maybe<Scalars['Boolean']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  estimateProduct?: Maybe<Scalars['String']['output']>;
  fixedPriceMarginPercent?: Maybe<Scalars['Float']['output']>;
  lineSetType?: Maybe<Scalars['String']['output']>;
  mileStone?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  netBillAmount?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  percentDiscount?: Maybe<Scalars['Float']['output']>;
  practice?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  servicesItem?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  subcontractorAccount?: Maybe<Scalars['String']['output']>;
  unitCost?: Maybe<Scalars['Float']['output']>;
  unitPrice?: Maybe<Scalars['Float']['output']>;
};

export type ManagedServicesRenewalResponse = {
  __typename?: 'ManagedServicesRenewalResponse';
  changeInGP?: Maybe<Scalars['Float']['output']>;
  changeInPrice?: Maybe<Scalars['Float']['output']>;
  changeReason?: Maybe<Scalars['String']['output']>;
  changeinPricePercent?: Maybe<Scalars['Float']['output']>;
  currentCost?: Maybe<Scalars['Float']['output']>;
  currentPrice?: Maybe<Scalars['Float']['output']>;
  currentPriceGP?: Maybe<Scalars['Float']['output']>;
  proposedGP?: Maybe<Scalars['Float']['output']>;
};

export type ManagedServicesSummaryResponse = {
  __typename?: 'ManagedServicesSummaryResponse';
  grossProfit?: Maybe<Scalars['Float']['output']>;
  grossProfitPercent?: Maybe<Scalars['Float']['output']>;
};

export type MansEnrollmentSummaryResponse = {
  __typename?: 'MansEnrollmentSummaryResponse';
  pmPercent?: Maybe<Scalars['IntOrString']['output']>;
  riskMitigation?: Maybe<Scalars['IntOrString']['output']>;
  roles?: Maybe<Array<Maybe<EnrollmentSummary>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addAgiloftExpenseType?: Maybe<DefaultAddResponse>;
  addAgiloftSowContent?: Maybe<DefaultMutationResponse>;
  addAgiloftTravelNoticeType?: Maybe<DefaultAddResponse>;
  addCdwRate?: Maybe<DefaultAddResponse>;
  addCiscoAnnuity?: Maybe<DefaultMutationResponseWithId>;
  addCiscoAnnuityComment?: Maybe<DefaultMutationResponse>;
  addCiscoAnnuityContact?: Maybe<DefaultMutationResponse>;
  addCiscoAnnuityLine?: Maybe<DefaultMutationResponse>;
  addCiscoAnnuityPartNumber?: Maybe<DefaultMutationResponseWithId>;
  addCiscoSmartAccount?: Maybe<DefaultMutationResponse>;
  addCustomTaskToSite?: Maybe<DefaultMutationResponse>;
  addCustomer?: Maybe<DefaultAddResponse>;
  addEstimatorSow?: Maybe<DefaultMutationResponseWithId>;
  addEstimatorTaskNote?: Maybe<DefaultMutationResponse>;
  addEstimatorTasksByTaskGroup?: Maybe<AddEstimatorTasksByTaskGroupResponse>;
  addPhase?: Maybe<DefaultAddResponse>;
  addPractice?: Maybe<DefaultAddResponse>;
  addProjectComment?: Maybe<DefaultMutationResponse>;
  addProjectItem?: Maybe<DefaultMutationResponseWithId>;
  addProjectItemEstimator?: Maybe<DefaultMutationResponseWithId>;
  addProjectItemQuote?: Maybe<DefaultMutationResponseWithId>;
  addProjectProposal?: Maybe<HandledMutationResponse>;
  addProjectSow?: Maybe<DefaultMutationResponseWithId>;
  addProjectUser?: Maybe<AddProjectUserResponse>;
  addProposalTemplate?: Maybe<HandledMutationResponse>;
  addQuoteLines?: Maybe<DefaultMutationResponse>;
  addRole?: Maybe<DefaultAddResponse>;
  addScopeGroup?: Maybe<DefaultMutationResponseWithId>;
  addScopeItem?: Maybe<DefaultMutationResponseWithId>;
  addScopeItemQuestion?: Maybe<DefaultMutationResponseWithId>;
  addScopingSession?: Maybe<DefaultMutationResponseWithId>;
  addSiteInfo?: Maybe<DefaultMutationResponse>;
  addSowSection?: Maybe<DefaultAddResponse>;
  addTask?: Maybe<DefaultAddResponse>;
  addTaskGroup?: Maybe<DefaultAddResponse>;
  addTier?: Maybe<DefaultAddResponse>;
  approveTaskGroupTestVersion?: Maybe<DefaultMutationResponse>;
  buildAgiloftFeeTable?: Maybe<AgiloftApiResponse>;
  calculateEstimatorTasksBySiteId?: Maybe<DefaultMutationResponse>;
  cancelEstimatorBidAssurance?: Maybe<DefaultMutationResponse>;
  clearAgiloftFeeTable?: Maybe<AgiloftApiResponse>;
  clearProjectSalesforceData?: Maybe<DefaultMutationResponse>;
  copyProject?: Maybe<DefaultMutationResponse>;
  copyProjectItemEstimator?: Maybe<DefaultMutationResponseWithId>;
  copySiteAndTasks?: Maybe<DefaultMutationResponse>;
  createJiraIssue?: Maybe<DefaultMutationResponseWithId>;
  createLead?: Maybe<DefaultMutationResponseWithId>;
  createProject?: Maybe<DefaultMutationResponseWithId>;
  createTestVersionBySowSection?: Maybe<DefaultMutationResponse>;
  createTestVersionByTask?: Maybe<DefaultMutationResponse>;
  createTestVersionByTaskGroup?: Maybe<DefaultMutationResponse>;
  deleteEmailTemplate?: Maybe<DefaultMutationResponse>;
  denyEstimatorBidAssurance?: Maybe<DefaultMutationResponse>;
  duplicateTaskGroup?: Maybe<DefaultMutationResponse>;
  escalateEstimatorBidAssurance?: Maybe<DefaultMutationResponse>;
  generateAgiloftDocuments?: Maybe<AgiloftApiResponse>;
  reloadCiscoAnnuityQuoteById?: Maybe<DefaultMutationResponse>;
  reloadProjectProposal?: Maybe<HandledMutationResponse>;
  removeAdminPractice?: Maybe<DefaultMutationResponse>;
  removeAgiloftExpenseType?: Maybe<DefaultMutationResponse>;
  removeAgiloftTravelNoticeType?: Maybe<DefaultMutationResponse>;
  removeCdwRate?: Maybe<DefaultMutationResponse>;
  removeCiscoAnnuityById?: Maybe<DefaultMutationResponse>;
  removeCiscoAnnuityCommentById?: Maybe<DefaultMutationResponse>;
  removeCiscoAnnuityContactById?: Maybe<DefaultMutationResponse>;
  removeCiscoAnnuityLineById?: Maybe<DefaultMutationResponse>;
  removeCiscoAnnuityPartNumber?: Maybe<DefaultMutationResponse>;
  removeCiscoAnnuityQuoteById?: Maybe<DefaultMutationResponse>;
  removeEstimatorBidAssuranceNote?: Maybe<DefaultMutationResponse>;
  removeEstimatorCustomTaskById?: Maybe<DefaultMutationResponse>;
  removeEstimatorSiteById?: Maybe<DefaultMutationResponse>;
  removeEstimatorTaskById?: Maybe<DefaultMutationResponse>;
  removeEstimatorTaskGroupFromSite?: Maybe<DefaultMutationResponse>;
  removeEstimatorTaskNote?: Maybe<DefaultMutationResponse>;
  removeOpportunity?: Maybe<DefaultMutationResponse>;
  removeProjectComment?: Maybe<DefaultMutationResponse>;
  removeProjectItemById?: Maybe<DefaultMutationResponse>;
  removeProjectItemEstimator?: Maybe<DefaultMutationResponse>;
  removeProjectItemQuote?: Maybe<DefaultMutationResponse>;
  removeProjectProposal?: Maybe<HandledMutationResponse>;
  removeProjectTeamMember?: Maybe<DefaultMutationResponse>;
  removeProposalTemplate?: Maybe<HandledMutationResponse>;
  removeQuoteLines?: Maybe<DefaultMutationResponse>;
  removeRole?: Maybe<DefaultMutationResponse>;
  removeScopeGroup?: Maybe<DefaultMutationResponse>;
  removeScopeItem?: Maybe<DefaultMutationResponse>;
  removeScopeItemQuestion?: Maybe<DefaultMutationResponse>;
  removeScopingSession?: Maybe<DefaultMutationResponse>;
  removeSowSection?: Maybe<DefaultMutationResponse>;
  removeTask?: Maybe<DefaultMutationResponse>;
  removeTaskGroup?: Maybe<DefaultMutationResponse>;
  removeTaskGroupTeamMember?: Maybe<DefaultMutationResponse>;
  removeTaskGroupTestVersion?: Maybe<DefaultMutationResponse>;
  removeTasks?: Maybe<DefaultMutationResponse>;
  removeTier?: Maybe<DefaultMutationResponse>;
  requestEstimatorBidAssurance?: Maybe<DefaultMutationResponse>;
  requestWordCofById?: Maybe<DefaultMutationResponse>;
  resetCiscoAnnuityCategories?: Maybe<DefaultMutationResponse>;
  resetEstimatorTaskMinGP?: Maybe<DefaultMutationResponse>;
  restoreToLatestGroupTaskVersion?: Maybe<DefaultMutationResponse>;
  saveCustomTasksToSite?: Maybe<DefaultMutationResponse>;
  saveEstimateToPsa?: Maybe<SaveEstimateToPsaResponse>;
  saveEstimatorBidAssuranceNote?: Maybe<DefaultMutationResponse>;
  setProjectItemEstimatorRates?: Maybe<DefaultMutationResponse>;
  setSiteInfo?: Maybe<DefaultMutationResponse>;
  syncCdwRates?: Maybe<DefaultMutationResponse>;
  syncServiceItems?: Maybe<DefaultMutationResponse>;
  syncServicePractices?: Maybe<DefaultMutationResponse>;
  syncServiceSkills?: Maybe<DefaultMutationResponse>;
  syncServiceSuppliers?: Maybe<DefaultMutationResponse>;
  updateAdminConfigSettings?: Maybe<DefaultMutationResponse>;
  updateAgiloftExpenseType?: Maybe<DefaultMutationResponse>;
  updateAgiloftFeeSummary?: Maybe<AgiloftApiResponse>;
  updateAgiloftTravelNoticeType?: Maybe<DefaultMutationResponse>;
  updateCdwRate?: Maybe<DefaultMutationResponse>;
  updateCiscoAnnuity?: Maybe<DefaultMutationResponse>;
  updateCiscoAnnuityCommentById?: Maybe<DefaultMutationResponse>;
  updateCiscoAnnuityContactById?: Maybe<DefaultMutationResponse>;
  updateCiscoAnnuityLine?: Maybe<CiscoAnnuityLine>;
  updateCiscoAnnuityLines?: Maybe<DefaultMutationResponse>;
  updateCiscoAnnuityPartNumber?: Maybe<DefaultMutationResponse>;
  updateCiscoAnnuityPartNumbers?: Maybe<DefaultMutationResponse>;
  updateCiscoAnnuityQuote?: Maybe<DefaultMutationResponse>;
  updateCustomer?: Maybe<DefaultMutationResponse>;
  updateEmailTemplates?: Maybe<DefaultMutationResponse>;
  updateEstimatorSiteCards?: Maybe<DefaultMutationResponse>;
  updateEstimatorSow?: Maybe<DefaultMutationResponse>;
  updateEstimatorTask?: Maybe<UpdatedTaskMutationResponse>;
  updateEstimatorTaskGP?: Maybe<DefaultMutationResponse>;
  updateEstimatorTaskNote?: Maybe<DefaultMutationResponse>;
  updateEstimatorTasks?: Maybe<DefaultMutationResponse>;
  updateIncludeInQuoteById?: Maybe<DefaultMutationResponse>;
  updateLead?: Maybe<UpdateLeadResponse>;
  updatePhase?: Maybe<DefaultMutationResponse>;
  updatePractice?: Maybe<DefaultMutationResponse>;
  updateProject?: Maybe<UpdateProjectResponse>;
  updateProjectComment?: Maybe<DefaultMutationResponse>;
  updateProjectItem?: Maybe<DefaultMutationResponse>;
  updateProjectItemEstimator?: Maybe<DefaultMutationResponse>;
  updateProjectItemEstimatorCalculation?: Maybe<DefaultMutationResponse>;
  updateProjectItemEstimatorVariables?: Maybe<DefaultMutationResponse>;
  updateProjectItemQuote?: Maybe<ProjectItemQuote>;
  updateProjectProposal?: Maybe<ProjectProposal>;
  updateProjectSalesforceData?: Maybe<DefaultMutationResponse>;
  updateProjectSow?: Maybe<DefaultMutationResponse>;
  updateProjectStatus?: Maybe<UpdateProjectResponse>;
  updateProjectTeamMember?: Maybe<DefaultMutationResponse>;
  updateProposalTemplate?: Maybe<HandledMutationResponse>;
  updateQuoteLines?: Maybe<DefaultMutationResponse>;
  updateRole?: Maybe<DefaultMutationResponse>;
  updateScopeGroup?: Maybe<DefaultMutationResponse>;
  updateScopeItem?: Maybe<DefaultMutationResponse>;
  updateScopeItemQuestion?: Maybe<DefaultMutationResponse>;
  updateScopingSession?: Maybe<DefaultMutationResponse>;
  updateSowSection?: Maybe<DefaultMutationResponse>;
  updateTask?: Maybe<DefaultMutationResponse>;
  updateTaskGroup?: Maybe<DefaultMutationResponse>;
  updateTasks?: Maybe<DefaultMutationResponse>;
  updateTier?: Maybe<DefaultMutationResponse>;
  updateUser?: Maybe<DefaultMutationResponse>;
  validateReadyForAgiloft?: Maybe<AgiloftApiResponse>;
};

export type Mutation_AddAgiloftExpenseTypeArgs = {
  params?: InputMaybe<AgiloftExpenseTypeInput>;
};

export type Mutation_AddAgiloftSowContentArgs = {
  params?: InputMaybe<AgiloftSowConnectionInput>;
};

export type Mutation_AddAgiloftTravelNoticeTypeArgs = {
  params?: InputMaybe<AgiloftTravelNoticeTypeInput>;
};

export type Mutation_AddCdwRateArgs = {
  params?: InputMaybe<CdwRateInput>;
};

export type Mutation_AddCiscoAnnuityArgs = {
  params: CiscoAnnuityInput;
};

export type Mutation_AddCiscoAnnuityCommentArgs = {
  ciscoAnnuityComment: CiscoAnnuityCommentInput;
  ciscoAnnuityId: Scalars['ID']['input'];
};

export type Mutation_AddCiscoAnnuityContactArgs = {
  ciscoAnnuityContact: CiscoAnnuityContactInput;
  ciscoAnnuityId: Scalars['String']['input'];
};

export type Mutation_AddCiscoAnnuityLineArgs = {
  params?: InputMaybe<CiscoAnnuityLineInput>;
};

export type Mutation_AddCiscoAnnuityPartNumberArgs = {
  params: CiscoAnnuityPartNumberInput;
};

export type Mutation_AddCiscoSmartAccountArgs = {
  ciscoSmartAccount?: InputMaybe<CiscoSmartAccountInput>;
  customerId?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation_AddCustomTaskToSiteArgs = {
  task: TaskInput;
};

export type Mutation_AddCustomerArgs = {
  params?: InputMaybe<CustomerInput>;
};

export type Mutation_AddEstimatorSowArgs = {
  params: EstimatorSowInput;
};

export type Mutation_AddEstimatorTaskNoteArgs = {
  note: TaskNoteInput;
  taskId: Scalars['ID']['input'];
};

export type Mutation_AddEstimatorTasksByTaskGroupArgs = {
  itemId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
  taskGroupId: Scalars['String']['input'];
};

export type Mutation_AddPracticeArgs = {
  params?: InputMaybe<AdminPracticeInput>;
};

export type Mutation_AddProjectCommentArgs = {
  comment: ProjectCommentInput;
  projectId: Scalars['ID']['input'];
};

export type Mutation_AddProjectItemArgs = {
  params: ProjectItemInput;
};

export type Mutation_AddProjectItemEstimatorArgs = {
  params?: InputMaybe<ProjectItemEstimatorsInput>;
};

export type Mutation_AddProjectItemQuoteArgs = {
  params: ProjectItemQuoteInput;
};

export type Mutation_AddProjectProposalArgs = {
  params: AddProjectProposalInput;
};

export type Mutation_AddProjectSowArgs = {
  params: ProjectSowInput;
};

export type Mutation_AddProjectUserArgs = {
  params: AddProjectUserInput;
};

export type Mutation_AddProposalTemplateArgs = {
  params?: InputMaybe<ProposalTemplateInput>;
};

export type Mutation_AddQuoteLinesArgs = {
  projectItemId: Scalars['ID']['input'];
  quoteLines?: InputMaybe<Array<InputMaybe<ProjectItemQuoteLineInput>>>;
};

export type Mutation_AddRoleArgs = {
  params?: InputMaybe<AdminRoleInput>;
};

export type Mutation_AddScopeGroupArgs = {
  params: ScopeGroupInput;
};

export type Mutation_AddScopeItemArgs = {
  params: ScopeItemInput;
};

export type Mutation_AddScopeItemQuestionArgs = {
  params: QuestionInput;
};

export type Mutation_AddScopingSessionArgs = {
  params: ScopingSessionInput;
};

export type Mutation_AddSiteInfoArgs = {
  projectItemId: Scalars['ID']['input'];
  siteInfo: SiteInfoInput;
};

export type Mutation_AddSowSectionArgs = {
  params?: InputMaybe<SowSectionInput>;
};

export type Mutation_AddTaskArgs = {
  params?: InputMaybe<TaskInput>;
};

export type Mutation_AddTaskGroupArgs = {
  params?: InputMaybe<AdminTaskGroupInput>;
};

export type Mutation_AddTierArgs = {
  params?: InputMaybe<AdminTierInput>;
};

export type Mutation_ApproveTaskGroupTestVersionArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_BuildAgiloftFeeTableArgs = {
  params?: InputMaybe<AgiloftSowConnectionInput>;
};

export type Mutation_CalculateEstimatorTasksBySiteIdArgs = {
  siteId: Scalars['ID']['input'];
};

export type Mutation_CancelEstimatorBidAssuranceArgs = {
  projectItemId: Scalars['String']['input'];
};

export type Mutation_ClearAgiloftFeeTableArgs = {
  params?: InputMaybe<AgiloftSowConnectionInput>;
};

export type Mutation_ClearProjectSalesforceDataArgs = {
  projectId: Scalars['String']['input'];
};

export type Mutation_CopyProjectArgs = {
  includeProjectItems: Scalars['Boolean']['input'];
  projectId: Scalars['ID']['input'];
};

export type Mutation_CopyProjectItemEstimatorArgs = {
  projectItemId: Scalars['ID']['input'];
};

export type Mutation_CopySiteAndTasksArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
  projectItemId: Scalars['ID']['input'];
  site: Scalars['String']['input'];
  siteName: Scalars['String']['input'];
};

export type Mutation_CreateJiraIssueArgs = {
  params: ContentRequestInput;
};

export type Mutation_CreateLeadArgs = {
  params: LeadInput;
};

export type Mutation_CreateProjectArgs = {
  params: ProjectInput;
};

export type Mutation_CreateTestVersionBySowSectionArgs = {
  sowSectionId: Scalars['ID']['input'];
};

export type Mutation_CreateTestVersionByTaskArgs = {
  taskId: Scalars['ID']['input'];
};

export type Mutation_CreateTestVersionByTaskGroupArgs = {
  taskGroupId: Scalars['ID']['input'];
};

export type Mutation_DeleteEmailTemplateArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation_DenyEstimatorBidAssuranceArgs = {
  projectItemId: Scalars['String']['input'];
};

export type Mutation_DuplicateTaskGroupArgs = {
  includeAllTasks?: InputMaybe<Scalars['Boolean']['input']>;
  taskGroup?: InputMaybe<AdminTaskGroupInput>;
};

export type Mutation_EscalateEstimatorBidAssuranceArgs = {
  projectItemId: Scalars['String']['input'];
};

export type Mutation_GenerateAgiloftDocumentsArgs = {
  params?: InputMaybe<AgiloftSowConnectionInput>;
};

export type Mutation_ReloadCiscoAnnuityQuoteByIdArgs = {
  ciscoAnnuityId: Scalars['ID']['input'];
  ciscoQuoteId: Scalars['ID']['input'];
};

export type Mutation_ReloadProjectProposalArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_RemoveAdminPracticeArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_RemoveAgiloftExpenseTypeArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation_RemoveAgiloftTravelNoticeTypeArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation_RemoveCdwRateArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation_RemoveCiscoAnnuityByIdArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_RemoveCiscoAnnuityCommentByIdArgs = {
  ciscoAnnuityCommentId: Scalars['ID']['input'];
  ciscoAnnuityId: Scalars['ID']['input'];
};

export type Mutation_RemoveCiscoAnnuityContactByIdArgs = {
  ciscoAnnuityContactId: Scalars['ID']['input'];
  ciscoAnnuityId: Scalars['ID']['input'];
};

export type Mutation_RemoveCiscoAnnuityLineByIdArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_RemoveCiscoAnnuityPartNumberArgs = {
  partNumber: Scalars['String']['input'];
};

export type Mutation_RemoveCiscoAnnuityQuoteByIdArgs = {
  ciscoAnnuityId: Scalars['ID']['input'];
  ciscoQuoteId: Scalars['ID']['input'];
};

export type Mutation_RemoveEstimatorBidAssuranceNoteArgs = {
  messageId: Scalars['String']['input'];
  projectItemId: Scalars['ID']['input'];
};

export type Mutation_RemoveEstimatorCustomTaskByIdArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  projectItemId: Scalars['ID']['input'];
  site?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation_RemoveEstimatorSiteByIdArgs = {
  projectItemId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

export type Mutation_RemoveEstimatorTaskByIdArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_RemoveEstimatorTaskGroupFromSiteArgs = {
  projectItemId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
  taskGroupId: Scalars['String']['input'];
};

export type Mutation_RemoveEstimatorTaskNoteArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_RemoveOpportunityArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_RemoveProjectCommentArgs = {
  commentId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type Mutation_RemoveProjectItemByIdArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_RemoveProjectItemEstimatorArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_RemoveProjectItemQuoteArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_RemoveProjectProposalArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_RemoveProjectTeamMemberArgs = {
  email: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};

export type Mutation_RemoveProposalTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_RemoveQuoteLinesArgs = {
  projectItemId: Scalars['ID']['input'];
  quoteLineIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Mutation_RemoveRoleArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation_RemoveScopeGroupArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_RemoveScopeItemArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_RemoveScopeItemQuestionArgs = {
  questionId: Scalars['ID']['input'];
  scopeItemId: Scalars['ID']['input'];
};

export type Mutation_RemoveScopingSessionArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_RemoveSowSectionArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_RemoveTaskArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation_RemoveTaskGroupArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation_RemoveTaskGroupTeamMemberArgs = {
  email: Scalars['String']['input'];
  taskGroupId: Scalars['ID']['input'];
};

export type Mutation_RemoveTaskGroupTestVersionArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_RemoveTasksArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Mutation_RemoveTierArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation_RequestEstimatorBidAssuranceArgs = {
  projectItemId: Scalars['String']['input'];
};

export type Mutation_RequestWordCofByIdArgs = {
  ciscoAnnuityId: Scalars['ID']['input'];
};

export type Mutation_ResetCiscoAnnuityCategoriesArgs = {
  ciscoAnnuityId: Scalars['String']['input'];
};

export type Mutation_ResetEstimatorTaskMinGpArgs = {
  projectItemId: Scalars['String']['input'];
};

export type Mutation_RestoreToLatestGroupTaskVersionArgs = {
  projectItemId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

export type Mutation_SaveCustomTasksToSiteArgs = {
  tasks?: InputMaybe<Array<InputMaybe<TaskInput>>>;
};

export type Mutation_SaveEstimateToPsaArgs = {
  projectItemId: Scalars['ID']['input'];
};

export type Mutation_SaveEstimatorBidAssuranceNoteArgs = {
  params: BidAssuranceNoteInput;
  projectItemId: Scalars['ID']['input'];
};

export type Mutation_SetProjectItemEstimatorRatesArgs = {
  practices?: InputMaybe<Array<InputMaybe<PracticeInput>>>;
  projectItemId: Scalars['String']['input'];
  rates?: InputMaybe<Array<InputMaybe<RoleAndRateInput>>>;
};

export type Mutation_SetSiteInfoArgs = {
  currentSite: Scalars['String']['input'];
  projectItemId: Scalars['ID']['input'];
  siteInfo: SiteInfoInput;
};

export type Mutation_UpdateAdminConfigSettingsArgs = {
  params?: InputMaybe<AdminConfigSettingsInput>;
};

export type Mutation_UpdateAgiloftExpenseTypeArgs = {
  params?: InputMaybe<AgiloftExpenseTypeInput>;
};

export type Mutation_UpdateAgiloftFeeSummaryArgs = {
  params?: InputMaybe<AgiloftSowConnectionInput>;
};

export type Mutation_UpdateAgiloftTravelNoticeTypeArgs = {
  params?: InputMaybe<AgiloftTravelNoticeTypeInput>;
};

export type Mutation_UpdateCdwRateArgs = {
  params?: InputMaybe<CdwRateInput>;
};

export type Mutation_UpdateCiscoAnnuityArgs = {
  params?: InputMaybe<CiscoAnnuityInput>;
};

export type Mutation_UpdateCiscoAnnuityCommentByIdArgs = {
  ciscoAnnuityComment: CiscoAnnuityCommentInput;
  ciscoAnnuityCommentId: Scalars['ID']['input'];
  ciscoAnnuityId: Scalars['ID']['input'];
};

export type Mutation_UpdateCiscoAnnuityContactByIdArgs = {
  ciscoAnnuityContact: CiscoAnnuityContactInput;
  ciscoAnnuityContactId: Scalars['String']['input'];
  ciscoAnnuityId: Scalars['String']['input'];
};

export type Mutation_UpdateCiscoAnnuityLineArgs = {
  params?: InputMaybe<CiscoAnnuityLineInput>;
};

export type Mutation_UpdateCiscoAnnuityLinesArgs = {
  params?: InputMaybe<Array<InputMaybe<CiscoAnnuityLineInput>>>;
};

export type Mutation_UpdateCiscoAnnuityPartNumberArgs = {
  params: CiscoAnnuityPartNumberInput;
};

export type Mutation_UpdateCiscoAnnuityPartNumbersArgs = {
  params?: InputMaybe<Array<InputMaybe<CiscoAnnuityPartNumberInput>>>;
};

export type Mutation_UpdateCiscoAnnuityQuoteArgs = {
  ciscoActiveQuote: Scalars['String']['input'];
  ciscoAnnuityId: Scalars['String']['input'];
  ciscoAnnuityQuote: CiscoQuoteInput;
};

export type Mutation_UpdateCustomerArgs = {
  params?: InputMaybe<CustomerInput>;
};

export type Mutation_UpdateEmailTemplatesArgs = {
  params?: InputMaybe<EmailTemplatesInput>;
};

export type Mutation_UpdateEstimatorSiteCardsArgs = {
  projectItemId: Scalars['ID']['input'];
  sites: Array<InputMaybe<SiteInput>>;
};

export type Mutation_UpdateEstimatorSowArgs = {
  params?: InputMaybe<EstimatorSowInput>;
};

export type Mutation_UpdateEstimatorTaskArgs = {
  estimatorAdminUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  params?: InputMaybe<TaskInput>;
};

export type Mutation_UpdateEstimatorTaskGpArgs = {
  params?: InputMaybe<RmsGpTaskUpdateInput>;
};

export type Mutation_UpdateEstimatorTaskNoteArgs = {
  note?: InputMaybe<TaskNoteInput>;
  taskId?: InputMaybe<Scalars['ID']['input']>;
};

export type Mutation_UpdateEstimatorTasksArgs = {
  params?: InputMaybe<Array<InputMaybe<TaskInput>>>;
};

export type Mutation_UpdateIncludeInQuoteByIdArgs = {
  category?: InputMaybe<Scalars['String']['input']>;
  includeInQuote: Scalars['Boolean']['input'];
  quoteId: Scalars['ID']['input'];
};

export type Mutation_UpdateLeadArgs = {
  params: UpdateLeadInput;
};

export type Mutation_UpdatePracticeArgs = {
  params?: InputMaybe<AdminPracticeInput>;
};

export type Mutation_UpdateProjectArgs = {
  params: UpdateProjectInput;
};

export type Mutation_UpdateProjectCommentArgs = {
  comment: ProjectCommentInput;
  commentId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type Mutation_UpdateProjectItemArgs = {
  params?: InputMaybe<ProjectItemInput>;
};

export type Mutation_UpdateProjectItemEstimatorArgs = {
  params?: InputMaybe<ProjectItemEstimatorsInput>;
};

export type Mutation_UpdateProjectItemEstimatorCalculationArgs = {
  params?: InputMaybe<ProjectItemEstimatorsInput>;
};

export type Mutation_UpdateProjectItemEstimatorVariablesArgs = {
  params?: InputMaybe<ProjectItemEstimatorsInput>;
};

export type Mutation_UpdateProjectItemQuoteArgs = {
  params: ProjectItemQuoteInput;
};

export type Mutation_UpdateProjectProposalArgs = {
  params?: InputMaybe<ProjectProposalInput>;
};

export type Mutation_UpdateProjectSalesforceDataArgs = {
  projectId: Scalars['String']['input'];
  salesforceData: SalesforceDataInput;
};

export type Mutation_UpdateProjectSowArgs = {
  params?: InputMaybe<ProjectSowInput>;
};

export type Mutation_UpdateProjectStatusArgs = {
  params: UpdateProjectStatusInput;
};

export type Mutation_UpdateProjectTeamMemberArgs = {
  projectId: Scalars['ID']['input'];
  projectTeam: ProjectTeamInput;
};

export type Mutation_UpdateProposalTemplateArgs = {
  params?: InputMaybe<ProposalTemplateInput>;
};

export type Mutation_UpdateQuoteLinesArgs = {
  projectItemId: Scalars['ID']['input'];
  updatedQuoteLines?: InputMaybe<Array<InputMaybe<ProjectItemQuoteLineInput>>>;
};

export type Mutation_UpdateRoleArgs = {
  params?: InputMaybe<AdminRoleInput>;
};

export type Mutation_UpdateScopeGroupArgs = {
  id: Scalars['ID']['input'];
  modifiedScopeGroup?: InputMaybe<ScopeGroupInput>;
};

export type Mutation_UpdateScopeItemArgs = {
  id: Scalars['ID']['input'];
  modifiedScopeItem?: InputMaybe<ScopeItemInput>;
};

export type Mutation_UpdateScopeItemQuestionArgs = {
  id: Scalars['ID']['input'];
  modifiedQuestion: QuestionInput;
};

export type Mutation_UpdateScopingSessionArgs = {
  id: Scalars['ID']['input'];
  modifiedScopingSession?: InputMaybe<ScopingSessionInput>;
};

export type Mutation_UpdateSowSectionArgs = {
  params?: InputMaybe<SowSectionInput>;
};

export type Mutation_UpdateTaskArgs = {
  params?: InputMaybe<TaskInput>;
};

export type Mutation_UpdateTaskGroupArgs = {
  params?: InputMaybe<AdminTaskGroupInput>;
};

export type Mutation_UpdateTasksArgs = {
  params?: InputMaybe<Array<InputMaybe<TaskInput>>>;
};

export type Mutation_UpdateTierArgs = {
  params?: InputMaybe<AdminTierInput>;
};

export type Mutation_UpdateUserArgs = {
  params: UserInput;
};

export type Mutation_ValidateReadyForAgiloftArgs = {
  params?: InputMaybe<AgiloftSowConnectionInput>;
};

export type Opportunity = {
  __typename?: 'Opportunity';
  _id?: Maybe<Scalars['ANY']['output']>;
  annuityTotal?: Maybe<Scalars['Int']['output']>;
  area?: Maybe<Scalars['String']['output']>;
  cdwCustomerAccountNumber?: Maybe<Scalars['String']['output']>;
  cewProvided?: Maybe<Scalars['Boolean']['output']>;
  closedOn?: Maybe<Scalars['DATE']['output']>;
  closedReason?: Maybe<Scalars['String']['output']>;
  closedStatus?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Array<Maybe<ProjectComment>>>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  customerArea?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  customerName?: Maybe<Scalars['String']['output']>;
  customerRegion?: Maybe<Scalars['String']['output']>;
  customerSector?: Maybe<Scalars['String']['output']>;
  customerSegment?: Maybe<Scalars['String']['output']>;
  demoProvided?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  estClose?: Maybe<Scalars['DATE']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastModified?: Maybe<Scalars['String']['output']>;
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  managedServiceTotal?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  onedriveId?: Maybe<Scalars['String']['output']>;
  onedriveLink?: Maybe<Scalars['String']['output']>;
  opportunity?: Maybe<Scalars['String']['output']>;
  opportunityTotal?: Maybe<Scalars['Float']['output']>;
  organization?: Maybe<Scalars['String']['output']>;
  probability?: Maybe<Scalars['Int']['output']>;
  productTotal?: Maybe<Scalars['Float']['output']>;
  projectOwner?: Maybe<Scalars['String']['output']>;
  projectOwnerDisplayName?: Maybe<Scalars['String']['output']>;
  projectTeam?: Maybe<Array<Maybe<ProjectTeam>>>;
  quoteTotal?: Maybe<Scalars['Int']['output']>;
  salesForceId?: Maybe<Scalars['String']['output']>;
  salesForceRegion?: Maybe<Scalars['String']['output']>;
  salesForceUrl?: Maybe<Scalars['String']['output']>;
  serviceLocation?: Maybe<Scalars['String']['output']>;
  serviceTotal?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<OpportunityStatusEnum>;
  subscriptionTotal?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export enum OpportunityStatusEnum {
  Archive = 'Archive',
  Close = 'Close',
  ClosedDidNotPurchased = 'ClosedDidNotPurchased',
  ClosedLost = 'ClosedLost',
  ClosedWon = 'ClosedWon',
  Delete = 'Delete',
  Discovery = 'Discovery',
  Propose = 'Propose',
  Solution = 'Solution',
}

export type OpportunityTable = {
  __typename?: 'OpportunityTable';
  _id?: Maybe<Scalars['ANY']['output']>;
  annuityTotal?: Maybe<Scalars['Float']['output']>;
  closedOn?: Maybe<Scalars['DATE']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  customerName?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  estClose?: Maybe<Scalars['DATE']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  managedServiceTotal?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  opportunityTotal?: Maybe<Scalars['Float']['output']>;
  organization?: Maybe<Scalars['String']['output']>;
  probability?: Maybe<Scalars['Int']['output']>;
  productTotal?: Maybe<Scalars['Float']['output']>;
  projectOwner?: Maybe<Scalars['String']['output']>;
  projectOwnerDisplayName?: Maybe<Scalars['String']['output']>;
  projectTeam?: Maybe<Array<Maybe<ProjectTeam>>>;
  quoteTotal?: Maybe<Scalars['Float']['output']>;
  salesRep?: Maybe<Scalars['String']['output']>;
  serviceTotal?: Maybe<Scalars['Float']['output']>;
  solutionArchitect?: Maybe<Scalars['String']['output']>;
  status?: Maybe<OpportunityStatusEnum>;
  subscriptionTotal?: Maybe<Scalars['Float']['output']>;
};

export type Organization = {
  __typename?: 'Organization';
  bidAssuranceEmail?: Maybe<Scalars['String']['output']>;
  departments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  sparkAllRooms?: Maybe<Scalars['String']['output']>;
  sparkWonRooms?: Maybe<Scalars['String']['output']>;
};

export type Phase = {
  __typename?: 'Phase';
  defaultPercent?: Maybe<Scalars['IntOrString']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lifecycle?: Maybe<Scalars['IntOrString']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['IntOrString']['output']>;
  practice?: Maybe<Scalars['IntOrString']['output']>;
};

export type PhaseBreakdown = {
  __typename?: 'PhaseBreakdown';
  placeholder?: Maybe<Scalars['IntOrString']['output']>;
};

export type Practice = {
  __typename?: 'Practice';
  bidAssurance?: Maybe<Scalars['ANY']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  /** should be a boolean but comes from ultron as 0 or 1 */
  lastModified?: Maybe<Scalars['DATE']['output']>;
  managedSvc?: Maybe<Scalars['ANY']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  peerReviewEmail?: Maybe<Scalars['String']['output']>;
  practiceId?: Maybe<Scalars['String']['output']>;
  practiceUsernames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  psm?: Maybe<Scalars['String']['output']>;
  salesForceId?: Maybe<Scalars['String']['output']>;
  serviceLocationId?: Maybe<Scalars['String']['output']>;
  web?: Maybe<Scalars['String']['output']>;
};

export type PracticeInput = {
  bidAssurance?: InputMaybe<Scalars['ANY']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  managedSvc?: InputMaybe<Scalars['ANY']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  peerReviewEmail?: InputMaybe<Scalars['String']['input']>;
  practiceId?: InputMaybe<Scalars['String']['input']>;
  practiceUsernames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  psm?: InputMaybe<Scalars['String']['input']>;
  salesForceId?: InputMaybe<Scalars['String']['input']>;
  serviceLocationId?: InputMaybe<Scalars['String']['input']>;
  web?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectComment = {
  __typename?: 'ProjectComment';
  _id?: Maybe<Scalars['ID']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DATE']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ProjectCommentInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectCustomTaskPhase = {
  __typename?: 'ProjectCustomTaskPhase';
  percent?: Maybe<Scalars['IntOrString']['output']>;
  phaseId?: Maybe<Scalars['String']['output']>;
  taskId?: Maybe<Scalars['String']['output']>;
};

export type ProjectEstimates = {
  __typename?: 'ProjectEstimates';
  cdwCost?: Maybe<Scalars['IntOrString']['output']>;
  managedServices?: Maybe<Scalars['IntOrString']['output']>;
  pmHours?: Maybe<Scalars['IntOrString']['output']>;
  projectItemId?: Maybe<Scalars['String']['output']>;
  serviceEstimate?: Maybe<Scalars['IntOrString']['output']>;
  sites?: Maybe<Array<Maybe<Site>>>;
  taskHours?: Maybe<Scalars['IntOrString']['output']>;
};

export type ProjectEstimatesInput = {
  cdwCost?: InputMaybe<Scalars['IntOrString']['input']>;
  pmHours?: InputMaybe<Scalars['IntOrString']['input']>;
  projectItemId?: InputMaybe<Scalars['String']['input']>;
  serviceEstimate?: InputMaybe<Scalars['IntOrString']['input']>;
  taskHours?: InputMaybe<Scalars['IntOrString']['input']>;
};

export type ProjectInput = {
  area?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  customerName?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  estClose?: InputMaybe<Scalars['DATE']['input']>;
  hours?: InputMaybe<Scalars['Int']['input']>;
  hwsw?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  primaryTech?: InputMaybe<Scalars['String']['input']>;
  probability?: InputMaybe<Scalars['Int']['input']>;
  projectOwner?: InputMaybe<Scalars['String']['input']>;
  salesForceId?: InputMaybe<Scalars['String']['input']>;
  salesForceUrl?: InputMaybe<Scalars['String']['input']>;
  salesRep?: InputMaybe<Scalars['String']['input']>;
  serviceLocation?: InputMaybe<Scalars['String']['input']>;
  services?: InputMaybe<Scalars['String']['input']>;
  solutionType?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<OpportunityStatusEnum>;
};

export type ProjectItem = {
  __typename?: 'ProjectItem';
  billingyCycle?: Maybe<Scalars['String']['output']>;
  ciscoEA?: Maybe<Scalars['Boolean']['output']>;
  cost?: Maybe<Scalars['Float']['output']>;
  costTotal?: Maybe<Scalars['Float']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['DATE']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  hwsw?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  includeInProjectTotal?: Maybe<Scalars['Boolean']['output']>;
  includeInProposal?: Maybe<Scalars['Boolean']['output']>;
  includeInSow?: Maybe<Scalars['Boolean']['output']>;
  managedServiceTotal?: Maybe<Scalars['Float']['output']>;
  mrrCost?: Maybe<Scalars['Float']['output']>;
  mrrPrice?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  numberOfPhoneSeatsAgents?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceTotal?: Maybe<Scalars['Float']['output']>;
  primaryBOMVendor?: Maybe<Scalars['String']['output']>;
  primaryTech?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  projectId?: Maybe<Scalars['ID']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  servicesTotal?: Maybe<Scalars['Float']['output']>;
  solution?: Maybe<Scalars['String']['output']>;
  solutionType?: Maybe<Scalars['String']['output']>;
  terms?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  vendor?: Maybe<Scalars['String']['output']>;
};

export type ProjectItemEstimators = {
  __typename?: 'ProjectItemEstimators';
  actualSellPrice?: Maybe<Scalars['Float']['output']>;
  agiloftExpenseClause?: Maybe<Scalars['String']['output']>;
  agiloftExpenseMiles?: Maybe<Scalars['Int']['output']>;
  agiloftExpenseType?: Maybe<Scalars['String']['output']>;
  agiloftFeeStructure?: Maybe<Scalars['String']['output']>;
  agiloftFundingAmount?: Maybe<Scalars['Float']['output']>;
  agiloftGenerateDocuments?: Maybe<Scalars['Boolean']['output']>;
  agiloftMultiFeeTables?: Maybe<Scalars['Boolean']['output']>;
  agiloftPmClause?: Maybe<Scalars['String']['output']>;
  agiloftPrintTemplateType?: Maybe<Scalars['String']['output']>;
  agiloftServiceFeeClause?: Maybe<Scalars['String']['output']>;
  agiloftSummaryTable?: Maybe<Scalars['Boolean']['output']>;
  agiloftTravelClause?: Maybe<Scalars['String']['output']>;
  agiloftTravelPenaltyAmount?: Maybe<Scalars['Float']['output']>;
  agiloftTravelType?: Maybe<Scalars['String']['output']>;
  agiloftUrl?: Maybe<Scalars['String']['output']>;
  bidAssuranceNotes?: Maybe<Array<Maybe<BidAssuranceNote>>>;
  bidAssuranceStatus?: Maybe<Scalars['String']['output']>;
  bidAssuranceStatusHistories?: Maybe<Array<Maybe<BidAssuranceStatusHistory>>>;
  billingType?: Maybe<Scalars['String']['output']>;
  calculatedSellPrice?: Maybe<Scalars['Float']['output']>;
  changeReason?: Maybe<Scalars['String']['output']>;
  currentCost?: Maybe<Scalars['Float']['output']>;
  currentPrice?: Maybe<Scalars['Float']['output']>;
  currentProposalId?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DATE']['output']>;
  fixedFeeMilestones?: Maybe<Array<Maybe<FixedFeeMilestone>>>;
  hours?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  includePartnerServicesInFixedFee?: Maybe<Scalars['Boolean']['output']>;
  includeTAndEFixedFee?: Maybe<Scalars['Boolean']['output']>;
  isManagedServicesRenewal?: Maybe<Scalars['Boolean']['output']>;
  managedServicesContractLength?: Maybe<Scalars['Int']['output']>;
  managedServicesCustomDeal?: Maybe<Scalars['Boolean']['output']>;
  managedServicesCustomerType?: Maybe<Scalars['String']['output']>;
  managedServicesDealType?: Maybe<Scalars['String']['output']>;
  managedServicesTransitionEstimatedDuration?: Maybe<Scalars['Int']['output']>;
  managedServicesTransitionFeeType?: Maybe<Scalars['String']['output']>;
  managedServicesTransitionStartDate?: Maybe<Scalars['DATE']['output']>;
  overtimePercent?: Maybe<Scalars['Int']['output']>;
  pmPercent?: Maybe<Scalars['Float']['output']>;
  pmRole?: Maybe<Scalars['String']['output']>;
  projectItemId?: Maybe<Scalars['String']['output']>;
  psaEstimateId?: Maybe<Scalars['String']['output']>;
  psaEstimateProduct?: Maybe<Scalars['String']['output']>;
  psaSyncCombined?: Maybe<Scalars['Boolean']['output']>;
  psaSyncHistories?: Maybe<Array<Maybe<PsaSyncHistory>>>;
  riskMitigation?: Maybe<Scalars['Int']['output']>;
  rmsProposal?: Maybe<RmsProposal>;
  salesForceId?: Maybe<Scalars['String']['output']>;
  salesForceRegion?: Maybe<Scalars['String']['output']>;
  salesForceUrl?: Maybe<Scalars['String']['output']>;
  sellingAsFixedFee?: Maybe<Scalars['Boolean']['output']>;
  sites?: Maybe<Array<Maybe<Site>>>;
  startDate?: Maybe<Scalars['DATE']['output']>;
  tAndETotal?: Maybe<Scalars['Float']['output']>;
};

export type ProjectItemEstimatorsInput = {
  actualSellPrice?: InputMaybe<Scalars['Float']['input']>;
  agiloftExpenseClause?: InputMaybe<Scalars['String']['input']>;
  agiloftExpenseMiles?: InputMaybe<Scalars['Int']['input']>;
  agiloftExpenseType?: InputMaybe<Scalars['String']['input']>;
  agiloftFeeStructure?: InputMaybe<Scalars['String']['input']>;
  agiloftFundingAmount?: InputMaybe<Scalars['Float']['input']>;
  agiloftGenerateDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  agiloftMultiFeeTables?: InputMaybe<Scalars['Boolean']['input']>;
  agiloftPmClause?: InputMaybe<Scalars['String']['input']>;
  agiloftPrintTemplateType?: InputMaybe<Scalars['String']['input']>;
  agiloftServiceFeeClause?: InputMaybe<Scalars['String']['input']>;
  agiloftSummaryTable?: InputMaybe<Scalars['Boolean']['input']>;
  agiloftTravelClause?: InputMaybe<Scalars['String']['input']>;
  agiloftTravelPenaltyAmount?: InputMaybe<Scalars['Float']['input']>;
  agiloftTravelType?: InputMaybe<Scalars['String']['input']>;
  bidAssuranceNotes?: InputMaybe<Array<InputMaybe<BidAssuranceNoteInput>>>;
  bidAssuranceStatus?: InputMaybe<Scalars['String']['input']>;
  bidAssuranceStatusHistories?: InputMaybe<
    Array<InputMaybe<BidAssuranceStatusHistoryInput>>
  >;
  billingType?: InputMaybe<Scalars['String']['input']>;
  changeReason?: InputMaybe<Scalars['String']['input']>;
  currentCost?: InputMaybe<Scalars['Float']['input']>;
  currentPrice?: InputMaybe<Scalars['Float']['input']>;
  currentProposalId?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DATE']['input']>;
  fixedFeeMilestones?: InputMaybe<Array<InputMaybe<FixedFeeMilestoneInput>>>;
  hours?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  includePartnerServicesInFixedFee?: InputMaybe<Scalars['Boolean']['input']>;
  includeTAndEFixedFee?: InputMaybe<Scalars['Boolean']['input']>;
  isManagedServicesRenewal?: InputMaybe<Scalars['Boolean']['input']>;
  managedServicesContractLength?: InputMaybe<Scalars['Int']['input']>;
  managedServicesCustomDeal?: InputMaybe<Scalars['Boolean']['input']>;
  managedServicesCustomerType?: InputMaybe<Scalars['String']['input']>;
  managedServicesDealType?: InputMaybe<Scalars['String']['input']>;
  managedServicesTransitionEstimatedDuration?: InputMaybe<
    Scalars['Int']['input']
  >;
  managedServicesTransitionFeeType?: InputMaybe<Scalars['String']['input']>;
  managedServicesTransitionStartDate?: InputMaybe<Scalars['DATE']['input']>;
  overtimePercent?: InputMaybe<Scalars['Int']['input']>;
  pmPercent?: InputMaybe<Scalars['Float']['input']>;
  pmRole?: InputMaybe<Scalars['String']['input']>;
  projectItemId?: InputMaybe<Scalars['String']['input']>;
  psaEstimateId?: InputMaybe<Scalars['String']['input']>;
  psaEstimateProduct?: InputMaybe<Scalars['String']['input']>;
  psaSyncCombined?: InputMaybe<Scalars['Boolean']['input']>;
  psaSyncHistories?: InputMaybe<Array<InputMaybe<PsaSyncHistoryInput>>>;
  riskMitigation?: InputMaybe<Scalars['Int']['input']>;
  rmsProposal?: InputMaybe<RmsProposalInput>;
  salesForceId?: InputMaybe<Scalars['String']['input']>;
  salesForceRegion?: InputMaybe<Scalars['String']['input']>;
  salesForceUrl?: InputMaybe<Scalars['String']['input']>;
  sites?: InputMaybe<Array<InputMaybe<SiteInput>>>;
  startDate?: InputMaybe<Scalars['DATE']['input']>;
  tAndETotal?: InputMaybe<Scalars['Float']['input']>;
};

export type ProjectItemInput = {
  billingyCycle?: InputMaybe<Scalars['String']['input']>;
  cost?: InputMaybe<Scalars['Float']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdOn?: InputMaybe<Scalars['DATE']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  includeInProjectTotal?: InputMaybe<Scalars['Boolean']['input']>;
  includeInProposal?: InputMaybe<Scalars['Boolean']['input']>;
  includeInSow?: InputMaybe<Scalars['Boolean']['input']>;
  mrrCost?: InputMaybe<Scalars['Float']['input']>;
  mrrPrice?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  solution?: InputMaybe<Scalars['String']['input']>;
  terms?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  vendor?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectItemQuote = {
  __typename?: 'ProjectItemQuote';
  globalQuoteHardwareSettings?: Maybe<ProjectItemQuoteSettings>;
  globalQuoteServiceSettings?: Maybe<ProjectItemQuoteSettings>;
  globalQuoteSettings?: Maybe<ProjectItemQuoteGlobalSettings>;
  globalQuoteSoftwareSettings?: Maybe<ProjectItemQuoteSettings>;
  globalQuoteSupportSettings?: Maybe<ProjectItemQuoteSettings>;
  id?: Maybe<Scalars['ID']['output']>;
  projectItemId?: Maybe<Scalars['String']['output']>;
  projectProductsId?: Maybe<Scalars['String']['output']>;
  quoteLines?: Maybe<Array<Maybe<ProjectItemQuoteLine>>>;
  systems?: Maybe<Array<Maybe<ProjectItemQuoteSystem>>>;
};

export type ProjectItemQuoteGlobalSettings = {
  __typename?: 'ProjectItemQuoteGlobalSettings';
  adderTaxRate?: Maybe<Scalars['String']['output']>;
  shippingTaxRate?: Maybe<Scalars['String']['output']>;
};

export type ProjectItemQuoteGlobalSettingsInput = {
  adderTaxRate?: InputMaybe<Scalars['String']['input']>;
  shippingTaxRate?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectItemQuoteInput = {
  globalQuoteHardwareSettings?: InputMaybe<ProjectItemQuoteSettingsInput>;
  globalQuoteServiceSettings?: InputMaybe<ProjectItemQuoteSettingsInput>;
  globalQuoteSettings?: InputMaybe<ProjectItemQuoteGlobalSettingsInput>;
  globalQuoteSoftwareSettings?: InputMaybe<ProjectItemQuoteSettingsInput>;
  globalQuoteSupportSettings?: InputMaybe<ProjectItemQuoteSettingsInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  projectItemId?: InputMaybe<Scalars['String']['input']>;
  projectProductsId?: InputMaybe<Scalars['String']['input']>;
  quoteLines?: InputMaybe<Array<InputMaybe<ProjectItemQuoteLineInput>>>;
  systems?: InputMaybe<Array<InputMaybe<ProjectItemQuoteSystemInput>>>;
};

export type ProjectItemQuoteLine = {
  __typename?: 'ProjectItemQuoteLine';
  billingModel?: Maybe<Scalars['String']['output']>;
  billingTerm?: Maybe<Scalars['String']['output']>;
  billingType?: Maybe<Scalars['Int']['output']>;
  credit?: Maybe<Scalars['String']['output']>;
  creditProvidedBy?: Maybe<Scalars['String']['output']>;
  customerCost?: Maybe<Scalars['String']['output']>;
  customerDiscount?: Maybe<Scalars['Int']['output']>;
  customerTotalCost?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['Int']['output']>;
  edc?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  includeHwSwCost?: Maybe<Scalars['Boolean']['output']>;
  includeProposal?: Maybe<Scalars['Boolean']['output']>;
  listUnitCost?: Maybe<Scalars['String']['output']>;
  margin?: Maybe<Scalars['String']['output']>;
  markup?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
  partNumber?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['ID']['output']>;
  projectId?: Maybe<Scalars['ID']['output']>;
  projectQuoteSystemId?: Maybe<Scalars['ID']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  selectIndex?: Maybe<Scalars['Int']['output']>;
  totalCost?: Maybe<Scalars['String']['output']>;
  totalGp?: Maybe<Scalars['String']['output']>;
  totalSalesGp?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unitCost?: Maybe<Scalars['String']['output']>;
  unitGp?: Maybe<Scalars['String']['output']>;
  unitSalesGp?: Maybe<Scalars['String']['output']>;
  userDescription?: Maybe<Scalars['String']['output']>;
  vendor?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type ProjectItemQuoteLineInput = {
  billingModel?: InputMaybe<Scalars['String']['input']>;
  billingTerm?: InputMaybe<Scalars['String']['input']>;
  billingType?: InputMaybe<Scalars['Int']['input']>;
  credit?: InputMaybe<Scalars['String']['input']>;
  creditProvidedBy?: InputMaybe<Scalars['String']['input']>;
  customerCost?: InputMaybe<Scalars['String']['input']>;
  customerDiscount?: InputMaybe<Scalars['Int']['input']>;
  customerTotalCost?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discount?: InputMaybe<Scalars['Int']['input']>;
  edc?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  includeHwSwCost?: InputMaybe<Scalars['Boolean']['input']>;
  includeProposal?: InputMaybe<Scalars['Boolean']['input']>;
  listUnitCost?: InputMaybe<Scalars['String']['input']>;
  margin?: InputMaybe<Scalars['String']['input']>;
  markup?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  partNumber?: InputMaybe<Scalars['String']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  projectQuoteSystemId?: InputMaybe<Scalars['ID']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  selectIndex?: InputMaybe<Scalars['Int']['input']>;
  totalCost?: InputMaybe<Scalars['String']['input']>;
  totalGp?: InputMaybe<Scalars['String']['input']>;
  totalSalesGp?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  unitCost?: InputMaybe<Scalars['String']['input']>;
  unitGp?: InputMaybe<Scalars['String']['input']>;
  unitSalesGp?: InputMaybe<Scalars['String']['input']>;
  userDescription?: InputMaybe<Scalars['String']['input']>;
  vendor?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectItemQuoteSettings = {
  __typename?: 'ProjectItemQuoteSettings';
  discount?: Maybe<Scalars['String']['output']>;
  margin?: Maybe<Scalars['String']['output']>;
  markup?: Maybe<Scalars['String']['output']>;
};

export type ProjectItemQuoteSettingsInput = {
  discount?: InputMaybe<Scalars['String']['input']>;
  margin?: InputMaybe<Scalars['String']['input']>;
  markup?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectItemQuoteSystem = {
  __typename?: 'ProjectItemQuoteSystem';
  cdwTotal?: Maybe<Scalars['String']['output']>;
  customerTotal?: Maybe<Scalars['String']['output']>;
  include?: Maybe<Scalars['Boolean']['output']>;
  includeHwSwCost?: Maybe<Scalars['Boolean']['output']>;
  includeProposal?: Maybe<Scalars['Boolean']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  sortByType?: Maybe<Scalars['Boolean']['output']>;
};

export type ProjectItemQuoteSystemInput = {
  cdwTotal?: InputMaybe<Scalars['String']['input']>;
  customerTotal?: InputMaybe<Scalars['String']['input']>;
  include?: InputMaybe<Scalars['Boolean']['input']>;
  includeHwSwCost?: InputMaybe<Scalars['Boolean']['input']>;
  includeProposal?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  sortByType?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProjectPracticeAndRoles = {
  __typename?: 'ProjectPracticeAndRoles';
  practices?: Maybe<Array<Maybe<Practice>>>;
  projectId?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<Maybe<Role>>>;
};

export type ProjectProposal = {
  __typename?: 'ProjectProposal';
  content?: Maybe<Scalars['String']['output']>;
  estimatorId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['IntOrString']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  previewContent?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  projectItemId?: Maybe<Scalars['String']['output']>;
  templateId?: Maybe<Scalars['String']['output']>;
};

export type ProjectProposalInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  estimatorId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['IntOrString']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  projectItemId?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectSow = {
  __typename?: 'ProjectSow';
  agiloftSentStatus?: Maybe<Scalars['String']['output']>;
  agiloftSowId?: Maybe<Scalars['String']['output']>;
  businessOutcome?: Maybe<Scalars['HTML']['output']>;
  businessOutcomeHelp?: Maybe<Scalars['HTML']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  estimatorId?: Maybe<Scalars['ID']['output']>;
  executiveSummaryDrivers?: Maybe<Scalars['HTML']['output']>;
  executiveSummaryDriversHelp?: Maybe<Scalars['HTML']['output']>;
  executiveSummaryOverview?: Maybe<Scalars['HTML']['output']>;
  executiveSummaryOverviewHelp?: Maybe<Scalars['HTML']['output']>;
  id?: Maybe<Scalars['IntOrString']['output']>;
  modified?: Maybe<Scalars['String']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  outcomeBased?: Maybe<Scalars['Boolean']['output']>;
  outcomeIncluded?: Maybe<Scalars['Boolean']['output']>;
  outcomeProjectScopePhases?: Maybe<Scalars['HTML']['output']>;
  outcomeProjectScopePhasesHelp?: Maybe<Scalars['HTML']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  projectItemId?: Maybe<Scalars['ID']['output']>;
  taskGroup?: Maybe<Scalars['String']['output']>;
  technicalOutcome?: Maybe<Scalars['HTML']['output']>;
  technicalOutcomeHelp?: Maybe<Scalars['HTML']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['IntOrString']['output']>;
};

export type ProjectSowInput = {
  agiloftSentStatus?: InputMaybe<Scalars['String']['input']>;
  agiloftSowId?: InputMaybe<Scalars['String']['input']>;
  businessOutcome?: InputMaybe<Scalars['HTML']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  executiveSummaryDrivers?: InputMaybe<Scalars['HTML']['input']>;
  executiveSummaryOverview?: InputMaybe<Scalars['HTML']['input']>;
  id?: InputMaybe<Scalars['IntOrString']['input']>;
  modified?: InputMaybe<Scalars['String']['input']>;
  modifiedBy?: InputMaybe<Scalars['String']['input']>;
  outcomeBased?: InputMaybe<Scalars['Boolean']['input']>;
  outcomeProjectScopePhases?: InputMaybe<Scalars['HTML']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  taskGroup?: InputMaybe<Scalars['String']['input']>;
  technicalOutcome?: InputMaybe<Scalars['HTML']['input']>;
  version?: InputMaybe<Scalars['IntOrString']['input']>;
};

export type ProjectStatusHistory = {
  __typename?: 'ProjectStatusHistory';
  customerDescription?: Maybe<Scalars['String']['output']>;
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedByUserId?: Maybe<Scalars['String']['output']>;
  lastModifiedByUsername?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['ID']['output']>;
  projectName?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['IntOrString']['output']>;
};

export type ProjectTeam = {
  __typename?: 'ProjectTeam';
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ProjectTeamInput = {
  email: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type ProjectUser = {
  __typename?: 'ProjectUser';
  createdAt?: Maybe<Scalars['String']['output']>;
  customClaims?: Maybe<Scalars['String']['output']>;
  disabled?: Maybe<Scalars['Boolean']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailVerified?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  metadata?: Maybe<FbUserMetaData>;
  passwordHash?: Maybe<Scalars['String']['output']>;
  passwordSalt?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  photoURL?: Maybe<Scalars['String']['output']>;
  projectId: Scalars['ID']['output'];
  role?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  tokensValidAfterTime?: Maybe<Scalars['String']['output']>;
  uid: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type ProposalTemplate = {
  __typename?: 'ProposalTemplate';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  dataCollection?: Maybe<Array<Maybe<DataCollectionItem>>>;
  id?: Maybe<Scalars['IntOrString']['output']>;
  isDataCollectionIncluded?: Maybe<Scalars['Boolean']['output']>;
  isSystem?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  proposalTemplateId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type ProposalTemplateInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  dataCollection?: InputMaybe<Array<InputMaybe<DataCollectionItemInput>>>;
  id?: InputMaybe<Scalars['IntOrString']['input']>;
  isDataCollectionIncluded?: InputMaybe<Scalars['Boolean']['input']>;
  isSystem?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PsaSyncHistory = {
  __typename?: 'PsaSyncHistory';
  error?: Maybe<Scalars['String']['output']>;
  isSynced?: Maybe<Scalars['Boolean']['output']>;
  psaEstimateId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DATE']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type PsaSyncHistoryInput = {
  error?: InputMaybe<Scalars['String']['input']>;
  isSynced?: InputMaybe<Scalars['Boolean']['input']>;
  psaEstimateId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DATE']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
  __typename?: 'Query';
  getActiveSitesAndTasksByProjectItemId?: Maybe<
    Array<Maybe<ActiveSiteWithTasks>>
  >;
  getAdminConfigSettings?: Maybe<GetAdminConfigSettingsResponse>;
  getAgiloftExpenseTypes?: Maybe<GetAgiloftExpenseTypesResponse>;
  getAgiloftRoles?: Maybe<GetAgiloftRolesResponse>;
  getAgiloftSOWById?: Maybe<AgiloftSow>;
  getAgiloftTravelNoticeTypes?: Maybe<GetAgiloftTravelNoticeTypesResponse>;
  getAgiloftTypeOptionsByProjectItemId?: Maybe<TaskGroupsPrintOptions>;
  getCdwRateById?: Maybe<CdwRate>;
  getCdwRates?: Maybe<GetCdwRatesResponse>;
  getCdwRolesAndRatesByPracticeIds?: Maybe<GetRolesAndRatesResponse>;
  getCiscoAnnuities?: Maybe<Array<Maybe<CiscoAnnuity>>>;
  getCiscoAnnuityAdminQuoteByProjectItemId?: Maybe<
    Array<Maybe<CiscoAnnuityLine>>
  >;
  getCiscoAnnuityById?: Maybe<CiscoAnnuity>;
  getCiscoAnnuityByProjectItemId?: Maybe<CiscoAnnuity>;
  getCiscoAnnuityCategories?: Maybe<Array<Maybe<CiscoAnnuityCategory>>>;
  getCiscoAnnuityComments?: Maybe<Array<Maybe<CiscoAnnuityComment>>>;
  getCiscoAnnuityLineById?: Maybe<CiscoAnnuityLine>;
  getCiscoAnnuityLines?: Maybe<Array<Maybe<CiscoAnnuityLine>>>;
  getCiscoAnnuityLinesByProjectItemId?: Maybe<Array<Maybe<CiscoAnnuityLine>>>;
  getCiscoAnnuityPartNumbers?: Maybe<GetCiscoAnnuityPartNumbersResponse>;
  getCiscoAnnuityQuoteByProjectItemId?: Maybe<CiscoAnnuityQuoteResponse>;
  getCiscoCcwEstimateById?: Maybe<GetCiscoCcwEstimateByIdResponse>;
  getCiscoCcwQuotesByDealId?: Maybe<GetCiscoCcwQuoteByDealIdResponse>;
  getCiscoQuoteById?: Maybe<Array<Maybe<CiscoAnnuityLine>>>;
  getCiscoQuotesByDealId?: Maybe<Array<Maybe<CiscoQuote>>>;
  getCiscoSmartAccountsByCustomerId?: Maybe<Array<Maybe<CiscoSmartAccount>>>;
  getCiscoSubscriptionLinesBySubscriptionId?: Maybe<
    Array<Maybe<CiscoSubscriptionLine>>
  >;
  getCollections?: Maybe<GetCollectionsResponse>;
  getCustomServicesByProjectId?: Maybe<Array<Maybe<CustomService>>>;
  getCustomer?: Maybe<Customer>;
  getCustomers?: Maybe<GetCustomersResponse>;
  getCustomersByOpportunities?: Maybe<GetCustomersByOpportunityResponse>;
  getDistinctSolutions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  getDistinctTiers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  getDistinctVendors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  getEmailTemplates?: Maybe<GetEmailTemplatesResponse>;
  getEstimatorBidAssuranceNotes?: Maybe<Array<Maybe<BidAssuranceNote>>>;
  getEstimatorBidAssuranceStatusHistory?: Maybe<
    Array<Maybe<BidAssuranceStatusHistory>>
  >;
  getEstimatorCustomTasksBySiteId?: Maybe<Array<Maybe<Task>>>;
  getEstimatorHasBidAssuranceUrl?: Maybe<Scalars['Boolean']['output']>;
  getEstimatorIncludesManagedServices?: Maybe<Scalars['Boolean']['output']>;
  getEstimatorIncludesSummaryTasks?: Maybe<Scalars['Boolean']['output']>;
  getEstimatorProposalTemplates?: Maybe<GetProposalTemplatesResponse>;
  getEstimatorSitesByProjectItemId?: Maybe<Array<Maybe<Site>>>;
  getEstimatorTaskById?: Maybe<Task>;
  getEstimatorTaskGroupScope?: Maybe<GetTaskGroupSowResponse>;
  getEstimatorTaskGroupsByProjectItemId?: Maybe<Array<Maybe<SowTaskGroup>>>;
  getEstimatorTaskNotesByTaskId?: Maybe<Array<Maybe<TaskNote>>>;
  getEstimatorTasksBySiteId?: Maybe<Array<Maybe<Task>>>;
  getEstimatorTotalsByItemId?: Maybe<EstimatorTotals>;
  getInvestmentSummaryByEstimatorId?: Maybe<InvestmentSummary>;
  getInvestmentSummaryByProjectId?: Maybe<InvestmentSummary>;
  getLatestVersionTaskGroup?: Maybe<Scalars['Boolean']['output']>;
  getLeads?: Maybe<Array<Maybe<Lead>>>;
  getManagedServicesRenewalByEstimatorId?: Maybe<ManagedServicesRenewalResponse>;
  getManagedServicesSummaryByEstimatorId?: Maybe<ManagedServicesSummaryResponse>;
  getMansEnrollmentSummaryByEstimatorId?: Maybe<MansEnrollmentSummaryResponse>;
  getNextTaskId?: Maybe<Scalars['String']['output']>;
  getOpportunities?: Maybe<GetOpportunityResponse>;
  getOrganizations?: Maybe<Array<Maybe<Organization>>>;
  getPhaseBreakdownByProjectId?: Maybe<Array<Maybe<PhaseBreakdown>>>;
  getPracticeById?: Maybe<AdminPractice>;
  getPractices?: Maybe<GetPracticesResponse>;
  getProject?: Maybe<Opportunity>;
  getProjectCommentsByProjectId?: Maybe<Array<Maybe<ProjectComment>>>;
  getProjectItem?: Maybe<ProjectItem>;
  getProjectItemEstimator?: Maybe<ProjectItemEstimators>;
  getProjectItemEstimatorByItemId?: Maybe<ProjectItemEstimators>;
  getProjectItemEstimatorByItemIds?: Maybe<Array<Maybe<ProjectItemEstimators>>>;
  getProjectItemEstimatorRates?: Maybe<GetProjectItemEstimatorRatesResponse>;
  getProjectItemQuoteById?: Maybe<ProjectItemQuote>;
  getProjectItemQuotesByProjectItemId?: Maybe<ProjectItemQuote>;
  getProjectItems?: Maybe<Array<Maybe<ProjectItem>>>;
  getProjectProposalById?: Maybe<ProjectProposal>;
  getProjectProposalsByEstimatorId?: Maybe<Array<Maybe<ProjectProposal>>>;
  getProjectProposalsByProjectId?: Maybe<Array<Maybe<ProjectProposal>>>;
  getProjectSowById?: Maybe<ProjectSow>;
  getProjectStatus?: Maybe<Array<Maybe<ProjectStatusHistory>>>;
  getProjectTaskGroupScope?: Maybe<GetTaskGroupSowResponse>;
  getProjectUsersByProjectId?: Maybe<GetProjectUsersResponse>;
  getProjects?: Maybe<GetProjectsResponse>;
  getProposalTemplateById?: Maybe<ProposalTemplate>;
  getProposalTemplates?: Maybe<GetProposalTemplatesResponse>;
  getPsaBreakdownByProjectId?: Maybe<GetEstimateToPsaResponse>;
  getRmsGPAnalysisByItemId?: Maybe<RmsGpAnalysis>;
  getRmsOneTimeCostTemplateByItemId?: Maybe<RmsOneTimeCost>;
  getRmsProposalByItemId?: Maybe<RmsProposal>;
  getRoleById?: Maybe<AdminRole>;
  getRoles?: Maybe<GetRolesResponse>;
  getSalesHistory?: Maybe<GetSalesHistoryResponse>;
  getSalesRepsTrending?: Maybe<GetSalesRepsTrendingResponse>;
  getScopeGroupById?: Maybe<ScopeGroup>;
  getScopeGroups?: Maybe<GetScopeGroupsResponse>;
  getScopeItemById?: Maybe<ScopeItem>;
  getScopeItems?: Maybe<GetScopeItemsResponse>;
  getScopeItemsByGroup?: Maybe<Array<Maybe<ScopeItem>>>;
  getScopingSessionById?: Maybe<ScopingSession>;
  getScopingSessionsByProjectItemId?: Maybe<Array<Maybe<ScopingSession>>>;
  getServiceCategories?: Maybe<GetServiceCategoriesResponse>;
  getServiceItems?: Maybe<GetServiceItemsResponse>;
  getServiceLocations?: Maybe<GetServiceLocationsResponse>;
  getServiceLocationsRoles?: Maybe<GetServiceLocationsRolesResponse>;
  getServicePractices?: Maybe<GetServicePracticesResponse>;
  getServiceSkills?: Maybe<GetServiceSkillsResponse>;
  getServiceSummaryByEstimatorId?: Maybe<ServiceSummaryResponse>;
  getServiceSummaryByProjectId?: Maybe<ServiceSummaryResponse>;
  getServiceSuppliers?: Maybe<GetServiceSuppliersResponse>;
  getSiteBreakdownByProjectId?: Maybe<Array<Maybe<SiteBreakdown>>>;
  getSitesWithTasksByProjectItemId?: Maybe<Array<Maybe<SiteWithTasks>>>;
  getSkillSummaryByEstimatorId?: Maybe<SkillSummary>;
  getSowByEstimatorId?: Maybe<ProjectSow>;
  getSowByProjectId?: Maybe<ProjectSow>;
  getSowByProjectItemId?: Maybe<ProjectSow>;
  getSowSectionById?: Maybe<SowSection>;
  getSowSectionByIdAndVersion?: Maybe<SowSection>;
  getSowSectionVersionList?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  getSowSections?: Maybe<GetSowSectionsResponse>;
  getSowSectionsById?: Maybe<SowSection>;
  getSprintSummaryByEstimatorId?: Maybe<SprintSummary>;
  getStandardRmsProposalTemplateByItemId?: Maybe<RmsProposalPricing>;
  getSystemProposalById?: Maybe<ProjectProposal>;
  getTaskById?: Maybe<Task>;
  getTaskByIdAndVersion?: Maybe<Task>;
  getTaskDependenciesById?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  getTaskGroupById?: Maybe<AdminTaskGroup>;
  getTaskGroupByIdAndVersion?: Maybe<AdminTaskGroup>;
  getTaskGroupDependenciesById?: Maybe<Scalars['JSON']['output']>;
  getTaskGroupVersionList?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  getTaskGroups?: Maybe<GetTaskGroupsResponse>;
  getTaskVersionList?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  getTasks?: Maybe<GetTasksResponse>;
  getTierById?: Maybe<Tier>;
  getTierRmsProposalTemplateByItemId?: Maybe<RmsProposalPricingTier>;
  getTiers?: Maybe<GetTiersResponse>;
  getTotalCustomers?: Maybe<GetTotalCustomersResponse>;
  getTotalSales?: Maybe<GetTotalSalesResponse>;
  getTravelExpenseSummaryByEstimatorId?: Maybe<TravelExpenseSummaryResponse>;
  getUser?: Maybe<User>;
  getUserIsCloudFulfillment?: Maybe<Scalars['Boolean']['output']>;
  getUsers?: Maybe<GetUsersResponse>;
  getZuoraSubscription?: Maybe<ZuoraSubscription>;
  me?: Maybe<User>;
  searchCustomers?: Maybe<SearchCustomersResponse>;
  searchServiceItems?: Maybe<SearchServiceItemsResponse>;
  searchServiceSkills?: Maybe<SearchServiceSkillsResponse>;
  searchServiceSuppliers?: Maybe<SearchServiceSuppliersResponse>;
  searchSowSections?: Maybe<SearchSowSectionsResponse>;
  searchTaskGroups?: Maybe<SearchTaskGroupsResponse>;
  searchUsers?: Maybe<SearchUsersResponse>;
  you?: Maybe<AdminUser>;
};

export type Query_GetActiveSitesAndTasksByProjectItemIdArgs = {
  projectItemId: Scalars['String']['input'];
};

export type Query_GetAdminConfigSettingsArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type Query_GetAgiloftExpenseTypesArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type Query_GetAgiloftSowByIdArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetAgiloftTravelNoticeTypesArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type Query_GetAgiloftTypeOptionsByProjectItemIdArgs = {
  projectItemId: Scalars['String']['input'];
};

export type Query_GetCdwRateByIdArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetCdwRatesArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type Query_GetCdwRolesAndRatesByPracticeIdsArgs = {
  practiceIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type Query_GetCiscoAnnuitiesArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type Query_GetCiscoAnnuityAdminQuoteByProjectItemIdArgs = {
  projectItemId: Scalars['ID']['input'];
};

export type Query_GetCiscoAnnuityByIdArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetCiscoAnnuityByProjectItemIdArgs = {
  projectItemId: Scalars['ID']['input'];
};

export type Query_GetCiscoAnnuityCategoriesArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type Query_GetCiscoAnnuityCommentsArgs = {
  projectItemId: Scalars['ID']['input'];
};

export type Query_GetCiscoAnnuityLineByIdArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetCiscoAnnuityLinesArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type Query_GetCiscoAnnuityLinesByProjectItemIdArgs = {
  projectItemId: Scalars['ID']['input'];
};

export type Query_GetCiscoAnnuityPartNumbersArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type Query_GetCiscoAnnuityQuoteByProjectItemIdArgs = {
  projectItemId: Scalars['ID']['input'];
};

export type Query_GetCiscoCcwEstimateByIdArgs = {
  ciscoAnnuityId: Scalars['String']['input'];
  ciscoEstimateId: Scalars['String']['input'];
};

export type Query_GetCiscoCcwQuotesByDealIdArgs = {
  ciscoAnnuityId: Scalars['String']['input'];
  ciscoDealId: Scalars['String']['input'];
};

export type Query_GetCiscoQuoteByIdArgs = {
  quoteId?: InputMaybe<Scalars['String']['input']>;
};

export type Query_GetCiscoQuotesByDealIdArgs = {
  dealId?: InputMaybe<Scalars['String']['input']>;
};

export type Query_GetCiscoSmartAccountsByCustomerIdArgs = {
  customerId?: InputMaybe<Scalars['String']['input']>;
};

export type Query_GetCiscoSubscriptionLinesBySubscriptionIdArgs = {
  subscriptionId: Scalars['String']['input'];
};

export type Query_GetCustomServicesByProjectIdArgs = {
  projectId: Scalars['ID']['input'];
};

export type Query_GetCustomerArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetCustomersArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type Query_GetCustomersByOpportunitiesArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type Query_GetEmailTemplatesArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type Query_GetEstimatorBidAssuranceNotesArgs = {
  projectItemId: Scalars['String']['input'];
};

export type Query_GetEstimatorBidAssuranceStatusHistoryArgs = {
  projectItemId: Scalars['String']['input'];
};

export type Query_GetEstimatorCustomTasksBySiteIdArgs = {
  siteId: Scalars['ID']['input'];
};

export type Query_GetEstimatorHasBidAssuranceUrlArgs = {
  projectItemId: Scalars['String']['input'];
};

export type Query_GetEstimatorIncludesManagedServicesArgs = {
  projectItemId: Scalars['ID']['input'];
};

export type Query_GetEstimatorIncludesSummaryTasksArgs = {
  projectItemId: Scalars['ID']['input'];
};

export type Query_GetEstimatorProposalTemplatesArgs = {
  projectItemId?: InputMaybe<Scalars['String']['input']>;
};

export type Query_GetEstimatorSitesByProjectItemIdArgs = {
  projectItemId: Scalars['ID']['input'];
};

export type Query_GetEstimatorTaskByIdArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetEstimatorTaskGroupScopeArgs = {
  projectId: Scalars['ID']['input'];
  projectItemId: Scalars['ID']['input'];
  taskGroupId: Scalars['ID']['input'];
};

export type Query_GetEstimatorTaskGroupsByProjectItemIdArgs = {
  projectItemId: Scalars['ID']['input'];
};

export type Query_GetEstimatorTaskNotesByTaskIdArgs = {
  taskId: Scalars['ID']['input'];
};

export type Query_GetEstimatorTasksBySiteIdArgs = {
  firstRequest?: InputMaybe<Scalars['Boolean']['input']>;
  siteId: Scalars['ID']['input'];
  taskId?: InputMaybe<Scalars['ID']['input']>;
};

export type Query_GetEstimatorTotalsByItemIdArgs = {
  itemId: Scalars['ID']['input'];
};

export type Query_GetInvestmentSummaryByEstimatorIdArgs = {
  projectItemId: Scalars['ID']['input'];
};

export type Query_GetInvestmentSummaryByProjectIdArgs = {
  projectId: Scalars['ID']['input'];
};

export type Query_GetLatestVersionTaskGroupArgs = {
  projectItemId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

export type Query_GetLeadsArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type Query_GetManagedServicesRenewalByEstimatorIdArgs = {
  projectItemId: Scalars['ID']['input'];
};

export type Query_GetManagedServicesSummaryByEstimatorIdArgs = {
  projectItemId: Scalars['ID']['input'];
};

export type Query_GetMansEnrollmentSummaryByEstimatorIdArgs = {
  projectItemId: Scalars['ID']['input'];
};

export type Query_GetNextTaskIdArgs = {
  taskGroupId: Scalars['String']['input'];
};

export type Query_GetOpportunitiesArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type Query_GetPhaseBreakdownByProjectIdArgs = {
  projectId: Scalars['ID']['input'];
};

export type Query_GetPracticeByIdArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetPracticesArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type Query_GetProjectArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetProjectCommentsByProjectIdArgs = {
  projectId: Scalars['ID']['input'];
};

export type Query_GetProjectItemArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetProjectItemEstimatorArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetProjectItemEstimatorByItemIdArgs = {
  projectItemId: Scalars['String']['input'];
};

export type Query_GetProjectItemEstimatorByItemIdsArgs = {
  projectItemIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Query_GetProjectItemEstimatorRatesArgs = {
  projectItemId: Scalars['String']['input'];
};

export type Query_GetProjectItemQuoteByIdArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetProjectItemQuotesByProjectItemIdArgs = {
  projectItemId: Scalars['ID']['input'];
};

export type Query_GetProjectItemsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['ID']['input'];
};

export type Query_GetProjectProposalByIdArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetProjectProposalsByEstimatorIdArgs = {
  estimatorId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type Query_GetProjectProposalsByProjectIdArgs = {
  projectId: Scalars['ID']['input'];
};

export type Query_GetProjectSowByIdArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetProjectStatusArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetProjectTaskGroupScopeArgs = {
  projectId: Scalars['ID']['input'];
};

export type Query_GetProjectUsersByProjectIdArgs = {
  projectId: Scalars['ID']['input'];
};

export type Query_GetProposalTemplateByIdArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetProposalTemplatesArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type Query_GetPsaBreakdownByProjectIdArgs = {
  projectId: Scalars['ID']['input'];
};

export type Query_GetRmsGpAnalysisByItemIdArgs = {
  projectItemId: Scalars['String']['input'];
};

export type Query_GetRmsOneTimeCostTemplateByItemIdArgs = {
  projectItemId: Scalars['String']['input'];
};

export type Query_GetRmsProposalByItemIdArgs = {
  projectItemId: Scalars['String']['input'];
};

export type Query_GetRoleByIdArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetRolesArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type Query_GetScopeGroupByIdArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetScopeItemByIdArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetScopeItemsByGroupArgs = {
  scopeGroupId: Scalars['ID']['input'];
};

export type Query_GetScopingSessionByIdArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetScopingSessionsByProjectItemIdArgs = {
  projectItemId: Scalars['ID']['input'];
};

export type Query_GetServiceItemsArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type Query_GetServiceLocationsArgs = {
  sort?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type Query_GetServicePracticesArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type Query_GetServiceSkillsArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type Query_GetServiceSummaryByEstimatorIdArgs = {
  projectItemId: Scalars['ID']['input'];
};

export type Query_GetServiceSummaryByProjectIdArgs = {
  projectId: Scalars['ID']['input'];
};

export type Query_GetServiceSuppliersArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type Query_GetSiteBreakdownByProjectIdArgs = {
  projectId: Scalars['ID']['input'];
};

export type Query_GetSitesWithTasksByProjectItemIdArgs = {
  projectItemId: Scalars['String']['input'];
  siteId?: InputMaybe<Scalars['String']['input']>;
};

export type Query_GetSkillSummaryByEstimatorIdArgs = {
  projectItemId: Scalars['ID']['input'];
};

export type Query_GetSowByEstimatorIdArgs = {
  estimatorId: Scalars['ID']['input'];
};

export type Query_GetSowByProjectIdArgs = {
  projectId: Scalars['ID']['input'];
};

export type Query_GetSowByProjectItemIdArgs = {
  projectItemId: Scalars['ID']['input'];
};

export type Query_GetSowSectionByIdArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetSowSectionByIdAndVersionArgs = {
  id: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
};

export type Query_GetSowSectionVersionListArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetSowSectionsByIdArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetSprintSummaryByEstimatorIdArgs = {
  projectItemId: Scalars['ID']['input'];
};

export type Query_GetStandardRmsProposalTemplateByItemIdArgs = {
  projectItemId: Scalars['String']['input'];
};

export type Query_GetSystemProposalByIdArgs = {
  projectId: Scalars['ID']['input'];
  projectItemId: Scalars['ID']['input'];
  proposalId: Scalars['ID']['input'];
};

export type Query_GetTaskByIdArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetTaskByIdAndVersionArgs = {
  id: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
};

export type Query_GetTaskDependenciesByIdArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetTaskGroupByIdArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetTaskGroupByIdAndVersionArgs = {
  id: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
};

export type Query_GetTaskGroupDependenciesByIdArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetTaskGroupVersionListArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetTaskVersionListArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetTierByIdArgs = {
  id: Scalars['ID']['input'];
};

export type Query_GetTierRmsProposalTemplateByItemIdArgs = {
  projectItemId: Scalars['String']['input'];
};

export type Query_GetTravelExpenseSummaryByEstimatorIdArgs = {
  projectItemId: Scalars['ID']['input'];
};

export type Query_GetUsersArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type Query_GetZuoraSubscriptionArgs = {
  subscriptionId: Scalars['String']['input'];
};

export type Query_SearchServiceItemsArgs = {
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type Query_SearchServiceSkillsArgs = {
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type Query_SearchServiceSuppliersArgs = {
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type Question = {
  __typename?: 'Question';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['DATE']['output']>;
  id: Scalars['ID']['output'];
  list?: Maybe<Array<Maybe<QuestionOption>>>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  scopeItemId: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type QuestionInput = {
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdOn?: InputMaybe<Scalars['DATE']['input']>;
  list?: InputMaybe<Array<InputMaybe<QuestionOptionInput>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  scopeItemId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionOption = {
  __typename?: 'QuestionOption';
  name?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type QuestionOptionInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type RmsProposal = {
  __typename?: 'RMSProposal';
  _id?: Maybe<Scalars['ID']['output']>;
  altEmail?: Maybe<Scalars['String']['output']>;
  altName?: Maybe<Scalars['String']['output']>;
  altOrg?: Maybe<Scalars['String']['output']>;
  altPhone?: Maybe<Scalars['String']['output']>;
  altTitle?: Maybe<Scalars['String']['output']>;
  bronze?: Maybe<Scalars['Boolean']['output']>;
  gold?: Maybe<Scalars['Boolean']['output']>;
  includeMonthlySubtotals?: Maybe<Scalars['Boolean']['output']>;
  includeMonthlyTotal?: Maybe<Scalars['Boolean']['output']>;
  isTierTemplate?: Maybe<Scalars['Boolean']['output']>;
  priceOverride?: Maybe<Scalars['Float']['output']>;
  pricingNotes?: Maybe<Scalars['HTML']['output']>;
  primaryEmail?: Maybe<Scalars['String']['output']>;
  primaryName?: Maybe<Scalars['String']['output']>;
  primaryOrg?: Maybe<Scalars['String']['output']>;
  primaryPhone?: Maybe<Scalars['String']['output']>;
  primaryTitle?: Maybe<Scalars['String']['output']>;
  showOnboardingVerbage?: Maybe<Scalars['Boolean']['output']>;
  showReadinessVerbage?: Maybe<Scalars['Boolean']['output']>;
  silver?: Maybe<Scalars['Boolean']['output']>;
  term?: Maybe<Scalars['Int']['output']>;
};

export type RmsProposalInput = {
  altEmail?: InputMaybe<Scalars['String']['input']>;
  altName?: InputMaybe<Scalars['String']['input']>;
  altOrg?: InputMaybe<Scalars['String']['input']>;
  altPhone?: InputMaybe<Scalars['String']['input']>;
  altTitle?: InputMaybe<Scalars['String']['input']>;
  bronze?: InputMaybe<Scalars['Boolean']['input']>;
  gold?: InputMaybe<Scalars['Boolean']['input']>;
  includeMonthlySubtotals?: InputMaybe<Scalars['Boolean']['input']>;
  includeMonthlyTotal?: InputMaybe<Scalars['Boolean']['input']>;
  isTierTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  priceOverride?: InputMaybe<Scalars['Float']['input']>;
  pricingNotes?: InputMaybe<Scalars['HTML']['input']>;
  primaryEmail?: InputMaybe<Scalars['String']['input']>;
  primaryName?: InputMaybe<Scalars['String']['input']>;
  primaryOrg?: InputMaybe<Scalars['String']['input']>;
  primaryPhone?: InputMaybe<Scalars['String']['input']>;
  primaryTitle?: InputMaybe<Scalars['String']['input']>;
  showOnboardingVerbage?: InputMaybe<Scalars['Boolean']['input']>;
  showReadinessVerbage?: InputMaybe<Scalars['Boolean']['input']>;
  silver?: InputMaybe<Scalars['Boolean']['input']>;
  term?: InputMaybe<Scalars['Int']['input']>;
};

export type RmsGpAnalysis = {
  __typename?: 'RmsGPAnalysis';
  rmsItems?: Maybe<Array<Maybe<RmsGpAnalysisTableSites>>>;
  totalMonthlyCharges?: Maybe<Scalars['Float']['output']>;
};

export type RmsGpAnalysisTableData = {
  __typename?: 'RmsGPAnalysisTableData';
  category?: Maybe<Scalars['String']['output']>;
  lineItems?: Maybe<Array<Maybe<RmsGpAnalysisTableDataLineItems>>>;
  totalSellPrice?: Maybe<Scalars['Float']['output']>;
};

export type RmsGpAnalysisTableDataLineItems = {
  __typename?: 'RmsGPAnalysisTableDataLineItems';
  grossProfit?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  minGrossProfit?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  qty?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  taskId?: Maybe<Scalars['String']['output']>;
  totalCost?: Maybe<Scalars['Float']['output']>;
  totalGP?: Maybe<Scalars['Float']['output']>;
  totalPrice?: Maybe<Scalars['Float']['output']>;
  unitCost?: Maybe<Scalars['Float']['output']>;
  unitGP?: Maybe<Scalars['Float']['output']>;
  unitGPPercent?: Maybe<Scalars['Float']['output']>;
  unitPrice?: Maybe<Scalars['Float']['output']>;
};

export type RmsGpAnalysisTableSites = {
  __typename?: 'RmsGPAnalysisTableSites';
  categories?: Maybe<Array<Maybe<RmsGpAnalysisTableData>>>;
  siteId?: Maybe<Scalars['String']['output']>;
  siteName?: Maybe<Scalars['String']['output']>;
  totalSellPrice?: Maybe<Scalars['Float']['output']>;
};

export type RmsGpTaskUpdate = {
  __typename?: 'RmsGPTaskUpdate';
  id?: Maybe<Scalars['String']['output']>;
  minGrossProfit?: Maybe<Scalars['Float']['output']>;
  minGrossProfitPercent?: Maybe<Scalars['Float']['output']>;
};

export type RmsGpTaskUpdateInput = {
  cost?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  minGrossProfit?: InputMaybe<Scalars['Float']['input']>;
  minGrossProfitPercent?: InputMaybe<Scalars['Float']['input']>;
  siteId?: InputMaybe<Scalars['String']['input']>;
};

export type RmsOneTimeCost = {
  __typename?: 'RmsOneTimeCost';
  rmsOneTimeCostItems?: Maybe<Array<Maybe<RmsProposalTemplateStandardSites>>>;
  totalMonthlyCharges?: Maybe<Scalars['Float']['output']>;
};

export type RmsProposalPricing = {
  __typename?: 'RmsProposalPricing';
  rmsProposalItems?: Maybe<Array<Maybe<RmsProposalTemplateStandardSites>>>;
  totalMonthlyCharges?: Maybe<Scalars['Float']['output']>;
};

export type RmsProposalPricingTier = {
  __typename?: 'RmsProposalPricingTier';
  bronzeTotal?: Maybe<Scalars['Float']['output']>;
  goldTotal?: Maybe<Scalars['Float']['output']>;
  rmsProposalItems?: Maybe<Array<Maybe<RmsProposalTemplateTierSites>>>;
  silverTotal?: Maybe<Scalars['Float']['output']>;
  totalMonthlyCharges?: Maybe<Scalars['Float']['output']>;
};

export type RmsProposalTemplateStandardLines = {
  __typename?: 'RmsProposalTemplateStandardLines';
  category?: Maybe<Scalars['String']['output']>;
  lineItems?: Maybe<Array<Maybe<Task>>>;
  totalSellPrice?: Maybe<Scalars['Float']['output']>;
};

export type RmsProposalTemplateStandardSites = {
  __typename?: 'RmsProposalTemplateStandardSites';
  categories?: Maybe<Array<Maybe<RmsProposalTemplateStandardLines>>>;
  siteId?: Maybe<Scalars['String']['output']>;
  siteName?: Maybe<Scalars['String']['output']>;
  totalSellPrice?: Maybe<Scalars['Float']['output']>;
};

export type RmsProposalTemplateTier = {
  __typename?: 'RmsProposalTemplateTier';
  category?: Maybe<Scalars['String']['output']>;
  lineItems?: Maybe<Array<Maybe<RmsProposalTemplateTierLines>>>;
  totalSellPrice?: Maybe<Scalars['Float']['output']>;
};

export type RmsProposalTemplateTierLines = {
  __typename?: 'RmsProposalTemplateTierLines';
  tasks?: Maybe<Array<Maybe<Task>>>;
  tier?: Maybe<Scalars['String']['output']>;
  totalSellPrice?: Maybe<Scalars['Float']['output']>;
};

export type RmsProposalTemplateTierSites = {
  __typename?: 'RmsProposalTemplateTierSites';
  categories?: Maybe<Array<Maybe<RmsProposalTemplateTier>>>;
  siteId?: Maybe<Scalars['String']['output']>;
  siteName?: Maybe<Scalars['String']['output']>;
  totalSellPrice?: Maybe<Scalars['Float']['output']>;
};

export type Role = {
  __typename?: 'Role';
  agiloftRole?: Maybe<Scalars['String']['output']>;
  /** should be a boolean but comes from ultron as 0 or 1 */
  cdwRateId?: Maybe<Scalars['String']['output']>;
  coOwners?: Maybe<Array<Maybe<RoleTeam>>>;
  createdAt?: Maybe<Scalars['DATE']['output']>;
  id?: Maybe<Scalars['IntOrString']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Array<Maybe<RoleTeam>>>;
  practice?: Maybe<Scalars['String']['output']>;
  projectManager?: Maybe<Scalars['ANY']['output']>;
  rate?: Maybe<Scalars['String']['output']>;
  testers?: Maybe<Array<Maybe<RoleTeam>>>;
};

export type RoleAndRate = {
  __typename?: 'RoleAndRate';
  agiloftRole?: Maybe<Scalars['String']['output']>;
  cdwRateId?: Maybe<Scalars['String']['output']>;
  cdwRateName?: Maybe<Scalars['String']['output']>;
  cost?: Maybe<Scalars['IntOrString']['output']>;
  createdAt?: Maybe<Scalars['DATE']['output']>;
  id?: Maybe<Scalars['IntOrString']['output']>;
  locationId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  otCost?: Maybe<Scalars['IntOrString']['output']>;
  practice?: Maybe<Scalars['String']['output']>;
  practiceName?: Maybe<Scalars['String']['output']>;
  projectManager?: Maybe<Scalars['Boolean']['output']>;
  rate?: Maybe<Scalars['IntOrString']['output']>;
  roleId?: Maybe<Scalars['String']['output']>;
};

export type RoleAndRateInput = {
  agiloftRole?: InputMaybe<Scalars['String']['input']>;
  cdwRateId?: InputMaybe<Scalars['String']['input']>;
  cost?: InputMaybe<Scalars['IntOrString']['input']>;
  id?: InputMaybe<Scalars['IntOrString']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  otCost?: InputMaybe<Scalars['IntOrString']['input']>;
  practice?: InputMaybe<Scalars['String']['input']>;
  projectManager?: InputMaybe<Scalars['Boolean']['input']>;
  rate?: InputMaybe<Scalars['IntOrString']['input']>;
  roleId?: InputMaybe<Scalars['String']['input']>;
};

export type RoleHistory = {
  __typename?: 'RoleHistory';
  action?: Maybe<DatabaseActions>;
  agiloftRole?: Maybe<Scalars['String']['output']>;
  /** should be a boolean but comes from ultron as 0 or 1 */
  cdwRateId?: Maybe<Scalars['String']['output']>;
  coOwners?: Maybe<Array<Maybe<RoleTeam>>>;
  createdAt?: Maybe<Scalars['DATE']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Array<Maybe<RoleTeam>>>;
  practice?: Maybe<Scalars['String']['output']>;
  projectManager?: Maybe<Scalars['ANY']['output']>;
  rate?: Maybe<Scalars['String']['output']>;
  roleId?: Maybe<Scalars['IntOrString']['output']>;
  testers?: Maybe<Array<Maybe<RoleTeam>>>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type RoleRequest = {
  __typename?: 'RoleRequest';
  bill_Rate_Override?: Maybe<Scalars['Float']['output']>;
  cost_Rate_Override?: Maybe<Scalars['Float']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  estimateLineName?: Maybe<Scalars['String']['output']>;
  estimateProduct?: Maybe<Scalars['String']['output']>;
  estimateProductId?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  externalResource?: Maybe<Scalars['Boolean']['output']>;
  hours?: Maybe<Scalars['Float']['output']>;
  practice?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  role_Nickname?: Maybe<Scalars['String']['output']>;
  servicesItem?: Maybe<Scalars['String']['output']>;
  skills?: Maybe<Array<Maybe<SfdcSkillRequest>>>;
  startDate?: Maybe<Scalars['String']['output']>;
};

export type RoleTeam = {
  __typename?: 'RoleTeam';
  email?: Maybe<Scalars['String']['output']>;
};

export type RoleTeamInput = {
  email?: InputMaybe<Scalars['String']['input']>;
};

export type SfdcEstimate = {
  __typename?: 'SFDCEstimate';
  Name?: Maybe<Scalars['String']['output']>;
  Project_Type__c?: Maybe<Scalars['String']['output']>;
  action__c?: Maybe<Scalars['String']['output']>;
  ffscpq__Approval_Status__c?: Maybe<Scalars['String']['output']>;
  ffscpq__Billing_Type__c?: Maybe<Scalars['String']['output']>;
  ffscpq__End_Date__c?: Maybe<Scalars['String']['output']>;
  ffscpq__Is_Primary__c?: Maybe<Scalars['Boolean']['output']>;
  ffscpq__Is_Template__c?: Maybe<Scalars['String']['output']>;
  ffscpq__Opportunity__c?: Maybe<Scalars['String']['output']>;
  ffscpq__Region__c?: Maybe<Scalars['String']['output']>;
  ffscpq__Stage_Name__c?: Maybe<Scalars['String']['output']>;
  ffscpq__Start_Date__c?: Maybe<Scalars['String']['output']>;
  ffscpq__Total_Hours__c?: Maybe<Scalars['String']['output']>;
  source__c?: Maybe<Scalars['String']['output']>;
};

export type SfdcEstimateProduct = {
  __typename?: 'SFDCEstimateProduct';
  Contingency_amount?: Maybe<Scalars['Float']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  contingency_percentage?: Maybe<Scalars['Float']['output']>;
  contractType?: Maybe<Scalars['String']['output']>;
  external_id?: Maybe<Scalars['String']['output']>;
  proServOrManaged?: Maybe<Scalars['String']['output']>;
  travel_bill_rate?: Maybe<Scalars['Float']['output']>;
  travel_hours?: Maybe<Scalars['Float']['output']>;
};

export type SfdcOpportunity = {
  __typename?: 'SFDCOpportunity';
  AccountId?: Maybe<Scalars['String']['output']>;
  Account_Business_Segment__c?: Maybe<Scalars['String']['output']>;
  Account_Owner_Title__c?: Maybe<Scalars['String']['output']>;
  Amount?: Maybe<Scalars['Float']['output']>;
  Amount_Override__c?: Maybe<Scalars['Float']['output']>;
  Amount__c?: Maybe<Scalars['Float']['output']>;
  Assigned_to__c?: Maybe<Scalars['String']['output']>;
  At_Risk_Remaining_Amount__c?: Maybe<Scalars['Float']['output']>;
  At_Risk_Status__c?: Maybe<Scalars['String']['output']>;
  At_Risk_Threshold_Amount__c?: Maybe<Scalars['Float']['output']>;
  Billing_Frequency__c?: Maybe<Scalars['String']['output']>;
  Budget_Confirmed__c?: Maybe<Scalars['Boolean']['output']>;
  Business_Segment__c?: Maybe<Scalars['String']['output']>;
  CDWCreatedByUser__c?: Maybe<Scalars['String']['output']>;
  CDWLastModifiedUser__c?: Maybe<Scalars['String']['output']>;
  CDW_Order_Number__c?: Maybe<Scalars['String']['output']>;
  CDW_Purchase_Order__c?: Maybe<Scalars['String']['output']>;
  CampaignId?: Maybe<Scalars['String']['output']>;
  Cancellable__c?: Maybe<Scalars['String']['output']>;
  Cancelled_Reason__c?: Maybe<Scalars['String']['output']>;
  Case_Count__c?: Maybe<Scalars['Int']['output']>;
  CloseDate?: Maybe<Scalars['String']['output']>;
  Company_Code__c?: Maybe<Scalars['String']['output']>;
  ContactId?: Maybe<Scalars['String']['output']>;
  ContractId?: Maybe<Scalars['String']['output']>;
  CreatedById?: Maybe<Scalars['String']['output']>;
  CreatedDate?: Maybe<Scalars['String']['output']>;
  Credit_Warning_Flag__c?: Maybe<Scalars['String']['output']>;
  CurrencyIsoCode?: Maybe<Scalars['String']['output']>;
  Customer_Number__c?: Maybe<Scalars['String']['output']>;
  DB_Competitor__c?: Maybe<Scalars['String']['output']>;
  Department__c?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Discovery_Completed__c?: Maybe<Scalars['Boolean']['output']>;
  Do_you_need_ITS_support__c?: Maybe<Scalars['String']['output']>;
  End_Date_of_Contract__c?: Maybe<Scalars['String']['output']>;
  ExpectedRevenue?: Maybe<Scalars['Float']['output']>;
  Expiration_Date__c?: Maybe<Scalars['String']['output']>;
  Fiscal?: Maybe<Scalars['String']['output']>;
  FiscalQuarter?: Maybe<Scalars['Int']['output']>;
  FiscalYear?: Maybe<Scalars['Int']['output']>;
  ForecastCategory?: Maybe<Scalars['String']['output']>;
  ForecastCategoryName?: Maybe<Scalars['String']['output']>;
  Forecast_Category__c?: Maybe<Scalars['String']['output']>;
  GLCode__c?: Maybe<Scalars['String']['output']>;
  GL_Department_Code__c?: Maybe<Scalars['String']['output']>;
  Gross_Profit_Margin__c?: Maybe<Scalars['Float']['output']>;
  Gross_Profit__c?: Maybe<Scalars['Float']['output']>;
  Hardware_Part_Number__c?: Maybe<Scalars['String']['output']>;
  HasOpenActivity?: Maybe<Scalars['Boolean']['output']>;
  HasOpportunityLineItem?: Maybe<Scalars['Boolean']['output']>;
  HasOverdueTask?: Maybe<Scalars['Boolean']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  IsClosed?: Maybe<Scalars['Boolean']['output']>;
  IsDeleted?: Maybe<Scalars['Boolean']['output']>;
  IsExcludedFromTerritory2Filter?: Maybe<Scalars['Boolean']['output']>;
  IsPrivate?: Maybe<Scalars['Boolean']['output']>;
  IsSplit?: Maybe<Scalars['Boolean']['output']>;
  IsWon?: Maybe<Scalars['Boolean']['output']>;
  Is_ITS_Case_Created__c?: Maybe<Scalars['Boolean']['output']>;
  Item_Count__c?: Maybe<Scalars['Int']['output']>;
  LastActivityDate?: Maybe<Scalars['String']['output']>;
  LastAmountChangedHistoryId?: Maybe<Scalars['String']['output']>;
  LastCloseDateChangedHistoryId?: Maybe<Scalars['String']['output']>;
  LastModifiedById?: Maybe<Scalars['String']['output']>;
  LastModifiedDate?: Maybe<Scalars['String']['output']>;
  LastReferencedDate?: Maybe<Scalars['String']['output']>;
  LastStageChangeDate?: Maybe<Scalars['String']['output']>;
  LastViewedDate?: Maybe<Scalars['String']['output']>;
  LeadSource?: Maybe<Scalars['String']['output']>;
  Level__c?: Maybe<Scalars['String']['output']>;
  Loss_Reason__c?: Maybe<Scalars['String']['output']>;
  Multiple_Billing_Agreement__c?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  Net_Profit_Margin__c?: Maybe<Scalars['Int']['output']>;
  Net_Profit__c?: Maybe<Scalars['Float']['output']>;
  NextStep?: Maybe<Scalars['String']['output']>;
  Number_of_Billings__c?: Maybe<Scalars['Int']['output']>;
  Number_of_Licenses__c?: Maybe<Scalars['Int']['output']>;
  Operations_Contact__c?: Maybe<Scalars['String']['output']>;
  Operations_Status__c?: Maybe<Scalars['String']['output']>;
  Opportunity_Number__c?: Maybe<Scalars['String']['output']>;
  Opportunity_Owner_Title__c?: Maybe<Scalars['String']['output']>;
  Opportunity_Type__c?: Maybe<Scalars['String']['output']>;
  OwnerId?: Maybe<Scalars['String']['output']>;
  PSA_ID__c?: Maybe<Scalars['String']['output']>;
  Parent_Opportunity__c?: Maybe<Scalars['String']['output']>;
  Pricebook2Id?: Maybe<Scalars['String']['output']>;
  Probability?: Maybe<Scalars['Int']['output']>;
  Project_Manager__c?: Maybe<Scalars['String']['output']>;
  PushCount?: Maybe<Scalars['Int']['output']>;
  ROI_Analysis_Completed__c?: Maybe<Scalars['Boolean']['output']>;
  Region__c?: Maybe<Scalars['String']['output']>;
  SBQQ__AmendedContract__c?: Maybe<Scalars['String']['output']>;
  SBQQ__Contracted__c?: Maybe<Scalars['Boolean']['output']>;
  SBQQ__CreateContractedPrices__c?: Maybe<Scalars['Boolean']['output']>;
  SBQQ__OrderGroupID__c?: Maybe<Scalars['String']['output']>;
  SBQQ__Ordered__c?: Maybe<Scalars['Boolean']['output']>;
  SBQQ__PrimaryQuote__c?: Maybe<Scalars['String']['output']>;
  SBQQ__QuotePricebookId__c?: Maybe<Scalars['String']['output']>;
  SBQQ__Renewal__c?: Maybe<Scalars['Boolean']['output']>;
  SBQQ__RenewedContract__c?: Maybe<Scalars['String']['output']>;
  Serial_Number__c?: Maybe<Scalars['String']['output']>;
  Service_Partner_Integrator__c?: Maybe<Scalars['String']['output']>;
  Services_Manager__c?: Maybe<Scalars['String']['output']>;
  Ship_Date__c?: Maybe<Scalars['String']['output']>;
  Sirius_Opportunity__c?: Maybe<Scalars['Boolean']['output']>;
  StageName?: Maybe<Scalars['String']['output']>;
  SyncedQuoteId?: Maybe<Scalars['String']['output']>;
  SystemModstamp?: Maybe<Scalars['String']['output']>;
  Team__c?: Maybe<Scalars['String']['output']>;
  Territory2Id?: Maybe<Scalars['String']['output']>;
  TotalOpportunityQuantity?: Maybe<Scalars['Int']['output']>;
  Transaction_Type__c?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
  When_an_Opportunity_is_Likely_to_Win__c?: Maybe<Scalars['String']['output']>;
  pse__Forecast_Curve__c?: Maybe<Scalars['String']['output']>;
  pse__Group__c?: Maybe<Scalars['String']['output']>;
  pse__IsParentOpportunityServices__c?: Maybe<Scalars['Boolean']['output']>;
  pse__Is_Change_Request__c?: Maybe<Scalars['Boolean']['output']>;
  pse__Is_Services_Opportunity__c?: Maybe<Scalars['Boolean']['output']>;
  pse__Parent_Opportunity__c?: Maybe<Scalars['String']['output']>;
  pse__Practice__c?: Maybe<Scalars['String']['output']>;
  pse__PreBilledAmount__c?: Maybe<Scalars['Float']['output']>;
  pse__Pre_Billed_Amount__c?: Maybe<Scalars['Float']['output']>;
  pse__Primary_Project__c?: Maybe<Scalars['String']['output']>;
  pse__Rate_Card_Discount_Limit__c?: Maybe<Scalars['Float']['output']>;
  pse__Rate_Card_Percentage_Discount__c?: Maybe<Scalars['Float']['output']>;
  pse__Rate_Card_Set__c?: Maybe<Scalars['String']['output']>;
  pse__Region__c?: Maybe<Scalars['String']['output']>;
  pse__Services_Attached_From_Products__c?: Maybe<Scalars['Int']['output']>;
  pse__Services_Attached_Percent_From_Products__c?: Maybe<
    Scalars['Int']['output']
  >;
  sbaa__ApprovalStatus__c?: Maybe<Scalars['String']['output']>;
  sbaa__ApprovalStep__c?: Maybe<Scalars['String']['output']>;
  sbaa__Approver__c?: Maybe<Scalars['String']['output']>;
  sbaa__StepApproved__c?: Maybe<Scalars['Boolean']['output']>;
  sbaa__SubmittedDate__c?: Maybe<Scalars['String']['output']>;
  sbaa__SubmittedUser__c?: Maybe<Scalars['String']['output']>;
};

export type SfdcSkillRequest = {
  __typename?: 'SFDCSkillRequest';
  estimateRoleSkillRequestName?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  minimumRating?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type SalesHistory = {
  __typename?: 'SalesHistory';
  customerCode?: Maybe<Scalars['String']['output']>;
  customerSeq?: Maybe<Scalars['String']['output']>;
  t12SalesDollarAmount?: Maybe<Scalars['IntOrString']['output']>;
  t24SalesDollarAmount?: Maybe<Scalars['IntOrString']['output']>;
  t36SalesDollarAmount?: Maybe<Scalars['IntOrString']['output']>;
  t48SalesDollarAmount?: Maybe<Scalars['IntOrString']['output']>;
  t60SalesDollarAmount?: Maybe<Scalars['IntOrString']['output']>;
  technologyCategoryDescription?: Maybe<Scalars['String']['output']>;
  technologySubCategoryDescription?: Maybe<Scalars['String']['output']>;
};

export type SalesRepTrending = {
  __typename?: 'SalesRepTrending';
  id?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type SalesforceDataInput = {
  salesForceId: Scalars['String']['input'];
  salesForceUrl: Scalars['String']['input'];
};

export type SaveEstimateToPsaResponse = {
  __typename?: 'SaveEstimateToPsaResponse';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ScopeGroup = {
  __typename?: 'ScopeGroup';
  createdAt?: Maybe<Scalars['DATE']['output']>;
  id: Scalars['ID']['output'];
  legacyId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  practiceId?: Maybe<Scalars['String']['output']>;
};

export type ScopeGroupInput = {
  legacyId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  practiceId?: InputMaybe<Scalars['String']['input']>;
};

export type ScopeItem = {
  __typename?: 'ScopeItem';
  category?: Maybe<Scalars['String']['output']>;
  collateral?: Maybe<Array<Maybe<TrainingOrCollateral>>>;
  createdAt?: Maybe<Scalars['DATE']['output']>;
  id: Scalars['ID']['output'];
  legacyId?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  parentItemDataId?: Maybe<Scalars['String']['output']>;
  parentItemDataValue?: Maybe<Scalars['String']['output']>;
  parentItemId?: Maybe<Scalars['String']['output']>;
  practiceId?: Maybe<Scalars['String']['output']>;
  questions?: Maybe<Array<Maybe<Question>>>;
  scopeGroupId?: Maybe<Scalars['ID']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  trainings?: Maybe<Array<Maybe<TrainingOrCollateral>>>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type ScopeItemInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  collateral?: InputMaybe<Array<InputMaybe<TrainingOrCollateralInput>>>;
  legacyId?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  parentItemDataId?: InputMaybe<Scalars['String']['input']>;
  parentItemDataValue?: InputMaybe<Scalars['String']['input']>;
  parentItemId?: InputMaybe<Scalars['String']['input']>;
  practiceId?: InputMaybe<Scalars['String']['input']>;
  questions?: InputMaybe<Array<InputMaybe<QuestionInput>>>;
  scopeGroupId?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  trainings?: InputMaybe<Array<InputMaybe<TrainingOrCollateralInput>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type ScopingSession = {
  __typename?: 'ScopingSession';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['DATE']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  projectItemId: Scalars['String']['output'];
  section?: Maybe<Array<Maybe<ScopingSessionSection>>>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  updatedOn?: Maybe<Scalars['DATE']['output']>;
};

export type ScopingSessionInput = {
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdOn?: InputMaybe<Scalars['DATE']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  projectItemId: Scalars['String']['input'];
  section?: InputMaybe<Array<InputMaybe<ScopingSessionSectionInput>>>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  updatedOn?: InputMaybe<Scalars['DATE']['input']>;
};

export type ScopingSessionSection = {
  __typename?: 'ScopingSessionSection';
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  questions?: Maybe<Array<Maybe<SessionQuestion>>>;
};

export type ScopingSessionSectionInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  questions?: InputMaybe<Array<InputMaybe<SessionQuestionInput>>>;
};

export type SearchCustomersResponse = {
  __typename?: 'SearchCustomersResponse';
  customers?: Maybe<Array<Maybe<Customer>>>;
};

export type SearchCustomersResponse_CustomersArgs = {
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type SearchServiceItemsResponse = {
  __typename?: 'SearchServiceItemsResponse';
  serviceItems?: Maybe<Array<Maybe<ServiceItem>>>;
};

export type SearchServiceItemsResponse_ServiceItemsArgs = {
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type SearchServiceSkillsResponse = {
  __typename?: 'SearchServiceSkillsResponse';
  serviceSkills?: Maybe<Array<Maybe<ServiceSkill>>>;
};

export type SearchServiceSkillsResponse_ServiceSkillsArgs = {
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type SearchServiceSuppliersResponse = {
  __typename?: 'SearchServiceSuppliersResponse';
  serviceSuppliers?: Maybe<Array<Maybe<ServiceSupplier>>>;
};

export type SearchServiceSuppliersResponse_ServiceSuppliersArgs = {
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type SearchSowSectionsResponse = {
  __typename?: 'SearchSowSectionsResponse';
  sowSections?: Maybe<Array<Maybe<SowSection>>>;
};

export type SearchSowSectionsResponse_SowSectionsArgs = {
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type SearchTaskGroupsResponse = {
  __typename?: 'SearchTaskGroupsResponse';
  taskGroups?: Maybe<Array<Maybe<TaskGroup>>>;
};

export type SearchTaskGroupsResponse_TaskGroupsArgs = {
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type SearchUsersResponse = {
  __typename?: 'SearchUsersResponse';
  users?: Maybe<Array<Maybe<FbUser>>>;
};

export type SearchUsersResponse_UsersArgs = {
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type ServiceCategory = {
  __typename?: 'ServiceCategory';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ServiceItem = {
  __typename?: 'ServiceItem';
  className?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  practice?: Maybe<Scalars['String']['output']>;
  serviceItemNumber?: Maybe<Scalars['String']['output']>;
};

export type ServiceLocation = {
  __typename?: 'ServiceLocation';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ServiceLocationRole = {
  __typename?: 'ServiceLocationRole';
  id?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['String']['output']>;
  rate?: Maybe<Scalars['String']['output']>;
  roleId?: Maybe<Scalars['String']['output']>;
};

export type ServicePractice = {
  __typename?: 'ServicePractice';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  relatedPractices?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ServiceSkill = {
  __typename?: 'ServiceSkill';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pseExternalId?: Maybe<Scalars['String']['output']>;
};

export type ServiceSummary = {
  __typename?: 'ServiceSummary';
  costTotal?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rate?: Maybe<Scalars['IntOrString']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['IntOrString']['output']>;
};

export type ServiceSummaryResponse = {
  __typename?: 'ServiceSummaryResponse';
  pmPercent?: Maybe<Scalars['IntOrString']['output']>;
  riskMitigation?: Maybe<Scalars['IntOrString']['output']>;
  roles?: Maybe<Array<Maybe<ServiceSummary>>>;
};

export type ServiceSupplier = {
  __typename?: 'ServiceSupplier';
  Id?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  SupplierStatus__c?: Maybe<Scalars['String']['output']>;
  Supplier_ID__c?: Maybe<Scalars['String']['output']>;
};

export type SessionQuestion = {
  __typename?: 'SessionQuestion';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['DATE']['output']>;
  scopeQuestionId: Scalars['ID']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type SessionQuestionInput = {
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdOn?: InputMaybe<Scalars['DATE']['input']>;
  scopeQuestionId: Scalars['ID']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type SfdcAccount = {
  __typename?: 'SfdcAccount';
  AccountNumber?: Maybe<Scalars['String']['output']>;
  AnnualRevenue?: Maybe<Scalars['Float']['output']>;
  CurrencyIsoCode?: Maybe<Scalars['String']['output']>;
  CustomerID__c?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  Industry?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  NumberOfEmployees?: Maybe<Scalars['Int']['output']>;
  ParentId?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
};

export type SfdcRegion = {
  __typename?: 'SfdcRegion';
  Id?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
};

export type Site = {
  __typename?: 'Site';
  _id?: Maybe<Scalars['ID']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  expanded?: Maybe<Scalars['Boolean']['output']>;
  floor?: Maybe<Scalars['IntOrString']['output']>;
  hideZeroQuantity?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

export type SiteBreakdown = {
  __typename?: 'SiteBreakdown';
  pmHours?: Maybe<Scalars['IntOrString']['output']>;
  servicesTotal?: Maybe<Scalars['Float']['output']>;
  site?: Maybe<Scalars['String']['output']>;
  taskHours?: Maybe<Scalars['IntOrString']['output']>;
  thirdPartyServices?: Maybe<Scalars['IntOrString']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  totalHours?: Maybe<Scalars['Float']['output']>;
};

export type SiteInfoInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  expanded?: InputMaybe<Scalars['Boolean']['input']>;
  floor?: InputMaybe<Scalars['String']['input']>;
  hideZeroQuantity?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type SiteInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  floor?: InputMaybe<Scalars['IntOrString']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type SiteTask = {
  __typename?: 'SiteTask';
  category?: Maybe<Scalars['String']['output']>;
  dropDownIndex?: Maybe<Scalars['IntOrString']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  listNames?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['IntOrString']['output']>;
  siteId?: Maybe<Scalars['String']['output']>;
  taskGroupId?: Maybe<Scalars['String']['output']>;
  taskId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type SiteWithTasks = {
  __typename?: 'SiteWithTasks';
  siteId?: Maybe<Scalars['String']['output']>;
  siteName?: Maybe<Scalars['String']['output']>;
  tasks?: Maybe<Array<Maybe<SiteTask>>>;
};

export type SkillSummary = {
  __typename?: 'SkillSummary';
  skillSummaryRows?: Maybe<Array<Maybe<SkillSummaryRow>>>;
};

export type SkillSummaryRow = {
  __typename?: 'SkillSummaryRow';
  skill?: Maybe<Scalars['String']['output']>;
  totalHours?: Maybe<Scalars['Float']['output']>;
};

export type SowSection = {
  __typename?: 'SowSection';
  assumptions?: Maybe<Scalars['String']['output']>;
  assumptionsGlobal?: Maybe<Scalars['Boolean']['output']>;
  coOwners?: Maybe<Array<Maybe<SowSectionTeam>>>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  customerResponsibilities?: Maybe<Scalars['String']['output']>;
  customerResponsibilitiesGlobal?: Maybe<Scalars['Boolean']['output']>;
  help?: Maybe<Scalars['String']['output']>;
  helpGlobal?: Maybe<Scalars['IntOrString']['output']>;
  highLevel?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  inTestingSowSectionId?: Maybe<Scalars['String']['output']>;
  lastReviewedBy?: Maybe<Scalars['String']['output']>;
  lastReviewedOn?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  originalSowSectionId?: Maybe<Scalars['String']['output']>;
  outOfScope?: Maybe<Scalars['String']['output']>;
  outOfScopeGlobal?: Maybe<Scalars['Boolean']['output']>;
  outcomeUnit?: Maybe<Scalars['String']['output']>;
  outcomeUnitDescription?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Array<Maybe<SowSectionTeam>>>;
  scope?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  testers?: Maybe<Array<Maybe<SowSectionTeam>>>;
  title?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['IntOrString']['output']>;
};

export type SowSectionInput = {
  assumptions?: InputMaybe<Scalars['String']['input']>;
  assumptionsGlobal?: InputMaybe<Scalars['Boolean']['input']>;
  coOwners?: InputMaybe<Array<InputMaybe<SowSectionTeamInput>>>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdOn?: InputMaybe<Scalars['String']['input']>;
  customerResponsibilities?: InputMaybe<Scalars['String']['input']>;
  customerResponsibilitiesGlobal?: InputMaybe<Scalars['Boolean']['input']>;
  help?: InputMaybe<Scalars['String']['input']>;
  helpGlobal?: InputMaybe<Scalars['IntOrString']['input']>;
  highLevel?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inTestingSowSectionId?: InputMaybe<Scalars['String']['input']>;
  lastReviewedBy?: InputMaybe<Scalars['String']['input']>;
  lastReviewedOn?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  originalSowSectionId?: InputMaybe<Scalars['String']['input']>;
  outOfScope?: InputMaybe<Scalars['String']['input']>;
  outOfScopeGlobal?: InputMaybe<Scalars['Boolean']['input']>;
  outcomeUnit?: InputMaybe<Scalars['String']['input']>;
  outcomeUnitDescription?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<Array<InputMaybe<SowSectionTeamInput>>>;
  scope?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  testers?: InputMaybe<Array<InputMaybe<SowSectionTeamInput>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['IntOrString']['input']>;
};

export type SowSectionTeam = {
  __typename?: 'SowSectionTeam';
  email?: Maybe<Scalars['String']['output']>;
};

export type SowSectionTeamInput = {
  email?: InputMaybe<Scalars['String']['input']>;
};

export type SowTaskGroup = {
  __typename?: 'SowTaskGroup';
  agiloftBlendedScopeType?: Maybe<Scalars['String']['output']>;
  agiloftClauses?: Maybe<Scalars['String']['output']>;
  agiloftExhibits?: Maybe<Scalars['String']['output']>;
  agiloftManagedScopeType?: Maybe<Scalars['String']['output']>;
  agiloftRecurringServicesFees?: Maybe<Scalars['String']['output']>;
  agiloftScopeType?: Maybe<Scalars['String']['output']>;
  agiloftServiceCategory?: Maybe<Scalars['String']['output']>;
  businessOutcome?: Maybe<Scalars['HTML']['output']>;
  businessOutcomeHelp?: Maybe<Scalars['HTML']['output']>;
  businessOutcomeIncluded?: Maybe<Scalars['Boolean']['output']>;
  executiveSummaryDrivers?: Maybe<Scalars['HTML']['output']>;
  executiveSummaryDriversHelp?: Maybe<Scalars['HTML']['output']>;
  executiveSummaryDriversIncluded?: Maybe<Scalars['Boolean']['output']>;
  executiveSummaryOverview?: Maybe<Scalars['HTML']['output']>;
  executiveSummaryOverviewHelp?: Maybe<Scalars['HTML']['output']>;
  executiveSummaryOverviewIncluded?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['IntOrString']['output']>;
  outcomeIncluded?: Maybe<Scalars['Boolean']['output']>;
  outcomeProjectScope?: Maybe<Scalars['HTML']['output']>;
  outcomeProjectScopeIncluded?: Maybe<Scalars['Boolean']['output']>;
  outcomeProjectScopePhases?: Maybe<Scalars['HTML']['output']>;
  outcomeProjectScopePhasesHelp?: Maybe<Scalars['HTML']['output']>;
  outcomeProjectScopePhasesIncluded?: Maybe<Scalars['Boolean']['output']>;
  outcomeSowFooter?: Maybe<Scalars['HTML']['output']>;
  outcomeSowHeader?: Maybe<Scalars['HTML']['output']>;
  outcomeSummary?: Maybe<Scalars['HTML']['output']>;
  practice?: Maybe<Scalars['String']['output']>;
  proposalContent?: Maybe<Scalars['HTML']['output']>;
  sowScopeFooter?: Maybe<Scalars['HTML']['output']>;
  sowScopeHeader?: Maybe<Scalars['HTML']['output']>;
  technicalOutcome?: Maybe<Scalars['HTML']['output']>;
  technicalOutcomeHelp?: Maybe<Scalars['HTML']['output']>;
  technicalOutcomeIncluded?: Maybe<Scalars['Boolean']['output']>;
  version?: Maybe<Scalars['IntOrString']['output']>;
};

export type SprintSummary = {
  __typename?: 'SprintSummary';
  sprintSummaryRows?: Maybe<Array<Maybe<SprintSummaryRow>>>;
};

export type SprintSummaryRow = {
  __typename?: 'SprintSummaryRow';
  sprint?: Maybe<Scalars['String']['output']>;
  totalHours?: Maybe<Scalars['Float']['output']>;
  totalSellPrice?: Maybe<Scalars['Float']['output']>;
};

export type Task = {
  __typename?: 'Task';
  _id?: Maybe<Scalars['ID']['output']>;
  allowAdditionalGPMargin?: Maybe<Scalars['ANY']['output']>;
  allowAdminEdit?: Maybe<Scalars['Boolean']['output']>;
  allowBlankText?: Maybe<Scalars['Boolean']['output']>;
  allowMarkup?: Maybe<Scalars['ANY']['output']>;
  allowModifyPrimaryRoleRate?: Maybe<Scalars['Boolean']['output']>;
  allowModifySecondaryRoleRate?: Maybe<Scalars['Boolean']['output']>;
  allowModifyTertiaryRoleRate?: Maybe<Scalars['Boolean']['output']>;
  allowTotalHoursAdjustment?: Maybe<Scalars['Boolean']['output']>;
  alwaysShowOnProposal?: Maybe<Scalars['Boolean']['output']>;
  catalogId?: Maybe<Scalars['String']['output']>;
  catalogName?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  cost?: Maybe<Scalars['IntOrString']['output']>;
  costFormula?: Maybe<Scalars['ANY']['output']>;
  costType?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DATE']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  currentDisableCost?: Maybe<Scalars['IntOrString']['output']>;
  currentDisableHours?: Maybe<Scalars['IntOrString']['output']>;
  currentDisableQuantity?: Maybe<Scalars['IntOrString']['output']>;
  currentDisableText?: Maybe<Scalars['Boolean']['output']>;
  currentDropDownIndex?: Maybe<Scalars['IntOrString']['output']>;
  currentGrossProfit?: Maybe<Scalars['IntOrString']['output']>;
  currentHide?: Maybe<Scalars['Boolean']['output']>;
  custom?: Maybe<Scalars['Boolean']['output']>;
  customServiceCost?: Maybe<Scalars['Float']['output']>;
  customServiceDivisorRate?: Maybe<Scalars['Float']['output']>;
  customServiceSellPrice?: Maybe<Scalars['Float']['output']>;
  customServiceSupplierId?: Maybe<Scalars['String']['output']>;
  defaultCost?: Maybe<Scalars['IntOrString']['output']>;
  defaultGrossProfit?: Maybe<Scalars['IntOrString']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  disableCost?: Maybe<Scalars['Boolean']['output']>;
  disableCostFormula?: Maybe<Scalars['String']['output']>;
  disableHours?: Maybe<Scalars['Boolean']['output']>;
  disableHoursFormula?: Maybe<Scalars['IntOrString']['output']>;
  disableQuantity?: Maybe<Scalars['IntOrString']['output']>;
  disableQuantityFormula?: Maybe<Scalars['IntOrString']['output']>;
  dropDownIndex?: Maybe<Scalars['IntOrString']['output']>;
  dropDownIndexFormula?: Maybe<Scalars['IntOrString']['output']>;
  edc?: Maybe<Scalars['String']['output']>;
  enrollmentCost?: Maybe<Scalars['Float']['output']>;
  enrollmentCostFormula?: Maybe<Scalars['String']['output']>;
  enrollmentHours?: Maybe<Scalars['Float']['output']>;
  enrollmentHoursFormula?: Maybe<Scalars['IntOrString']['output']>;
  enrollmentRate?: Maybe<Scalars['Float']['output']>;
  enrollmentRole?: Maybe<Scalars['String']['output']>;
  enrollmentTotalHours?: Maybe<Scalars['Float']['output']>;
  estimatorLocation?: Maybe<Scalars['String']['output']>;
  exclude?: Maybe<Scalars['Boolean']['output']>;
  excludeToggle?: Maybe<Scalars['ANY']['output']>;
  fixedFeePrice?: Maybe<Scalars['IntOrString']['output']>;
  fixedFeeTotalHours?: Maybe<Scalars['IntOrString']['output']>;
  goalSeekError?: Maybe<Scalars['String']['output']>;
  grossProfit?: Maybe<Scalars['IntOrString']['output']>;
  hide?: Maybe<Scalars['IntOrString']['output']>;
  hideHours?: Maybe<Scalars['Boolean']['output']>;
  hideShowFormula?: Maybe<Scalars['IntOrString']['output']>;
  hours?: Maybe<Scalars['IntOrString']['output']>;
  hoursFormula?: Maybe<Scalars['ANY']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imported?: Maybe<Scalars['Boolean']['output']>;
  inTestingTaskId?: Maybe<Scalars['String']['output']>;
  instructionsText?: Maybe<Scalars['String']['output']>;
  isExpense?: Maybe<Scalars['Boolean']['output']>;
  isManagedServicesCostCalculation?: Maybe<Scalars['Boolean']['output']>;
  isProjectManagement?: Maybe<Scalars['Boolean']['output']>;
  isTravelTime?: Maybe<Scalars['Boolean']['output']>;
  lastReviewedBy?: Maybe<Scalars['String']['output']>;
  lastReviewedOn?: Maybe<Scalars['IntOrString']['output']>;
  latestVersion?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<TaskList>>>;
  listNames?: Maybe<Scalars['String']['output']>;
  listValues?: Maybe<Scalars['String']['output']>;
  managedServices?: Maybe<Scalars['Boolean']['output']>;
  managedServicesArcRrcFormula?: Maybe<Scalars['String']['output']>;
  managedServicesFteFormula?: Maybe<Scalars['String']['output']>;
  managedServicesTotalArcRrc?: Maybe<Scalars['Float']['output']>;
  managedServicesTotalFte?: Maybe<Scalars['Float']['output']>;
  managedServicesTotalTransition?: Maybe<Scalars['Float']['output']>;
  managedServicesTransitionFormula?: Maybe<Scalars['String']['output']>;
  margin?: Maybe<Scalars['Float']['output']>;
  minGrossProfit?: Maybe<Scalars['IntOrString']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  noValue?: Maybe<Scalars['Float']['output']>;
  noteCount?: Maybe<Scalars['Int']['output']>;
  notes?: Maybe<Array<Maybe<TaskNote>>>;
  order?: Maybe<Scalars['Int']['output']>;
  originalTaskId?: Maybe<Scalars['String']['output']>;
  overtime?: Maybe<Scalars['Boolean']['output']>;
  phases?: Maybe<Array<Maybe<TaskPhase>>>;
  pmPercent?: Maybe<Scalars['Float']['output']>;
  practice?: Maybe<Scalars['String']['output']>;
  practiceId?: Maybe<Scalars['String']['output']>;
  previousOrder?: Maybe<Scalars['Int']['output']>;
  primaryPercent?: Maybe<Scalars['IntOrString']['output']>;
  primaryRole?: Maybe<Scalars['String']['output']>;
  primaryRoleId?: Maybe<Scalars['String']['output']>;
  primaryRoleName?: Maybe<Scalars['String']['output']>;
  primaryRoleRate?: Maybe<Scalars['Float']['output']>;
  primaryRoleRateOverride?: Maybe<Scalars['Float']['output']>;
  primaryRoleRateOverrideFormula?: Maybe<Scalars['String']['output']>;
  primaryTotalHours?: Maybe<Scalars['Float']['output']>;
  productType?: Maybe<Scalars['String']['output']>;
  proposalDescription?: Maybe<Scalars['String']['output']>;
  proposalNotes?: Maybe<Scalars['String']['output']>;
  proposalShowEmpty?: Maybe<Scalars['ANY']['output']>;
  quantity?: Maybe<Scalars['IntOrString']['output']>;
  quantityFormula?: Maybe<Scalars['ANY']['output']>;
  quantityMax?: Maybe<Scalars['IntOrString']['output']>;
  quantityMin?: Maybe<Scalars['IntOrString']['output']>;
  rate?: Maybe<Scalars['IntOrString']['output']>;
  renderIndex?: Maybe<Scalars['Int']['output']>;
  replacedNamedExpression?: Maybe<Scalars['Boolean']['output']>;
  secondaryPercent?: Maybe<Scalars['IntOrString']['output']>;
  secondaryRole?: Maybe<Scalars['IntOrString']['output']>;
  secondaryRoleId?: Maybe<Scalars['IntOrString']['output']>;
  secondaryRoleName?: Maybe<Scalars['String']['output']>;
  secondaryRoleRate?: Maybe<Scalars['Float']['output']>;
  secondaryRoleRateOverride?: Maybe<Scalars['Float']['output']>;
  secondaryRoleRateOverrideFormula?: Maybe<Scalars['String']['output']>;
  secondaryTotalHours?: Maybe<Scalars['Float']['output']>;
  sellPrice?: Maybe<Scalars['Float']['output']>;
  sellPriceFormula?: Maybe<Scalars['String']['output']>;
  serviceClassName?: Maybe<Scalars['String']['output']>;
  serviceItem?: Maybe<Scalars['String']['output']>;
  serviceItemFormula?: Maybe<Scalars['String']['output']>;
  serviceItemId?: Maybe<Scalars['String']['output']>;
  serviceItemName?: Maybe<Scalars['String']['output']>;
  siteId?: Maybe<Scalars['String']['output']>;
  siteQuantity?: Maybe<Scalars['Int']['output']>;
  skill?: Maybe<Scalars['String']['output']>;
  skillFormula?: Maybe<Scalars['String']['output']>;
  skillId?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  sowSection?: Maybe<Scalars['IntOrString']['output']>;
  sowSectionId?: Maybe<Scalars['IntOrString']['output']>;
  sowSections?: Maybe<SowSection>;
  sprint?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subType?: Maybe<Scalars['IntOrString']['output']>;
  taskGroupId?: Maybe<Scalars['IntOrString']['output']>;
  taskGroupName?: Maybe<Scalars['String']['output']>;
  taskGroups?: Maybe<Array<Maybe<Scalars['IntOrString']['output']>>>;
  taskId?: Maybe<Scalars['String']['output']>;
  taskType?: Maybe<Scalars['String']['output']>;
  technologyName?: Maybe<Scalars['String']['output']>;
  tertiaryPercent?: Maybe<Scalars['IntOrString']['output']>;
  tertiaryRole?: Maybe<Scalars['IntOrString']['output']>;
  tertiaryRoleId?: Maybe<Scalars['IntOrString']['output']>;
  tertiaryRoleName?: Maybe<Scalars['String']['output']>;
  tertiaryRoleRate?: Maybe<Scalars['Float']['output']>;
  tertiaryRoleRateOverride?: Maybe<Scalars['Float']['output']>;
  tertiaryRoleRateOverrideFormula?: Maybe<Scalars['String']['output']>;
  tertiaryTotalHours?: Maybe<Scalars['Float']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  textDisabled?: Maybe<Scalars['Boolean']['output']>;
  textDisabledFormula?: Maybe<Scalars['String']['output']>;
  textFormula?: Maybe<Scalars['String']['output']>;
  textType?: Maybe<Scalars['String']['output']>;
  tier?: Maybe<Scalars['String']['output']>;
  tierId?: Maybe<Scalars['IntOrString']['output']>;
  tiers?: Maybe<Array<Maybe<Scalars['IntOrString']['output']>>>;
  totalCost?: Maybe<Scalars['Float']['output']>;
  totalHours?: Maybe<Scalars['Float']['output']>;
  totalHoursAdjustment?: Maybe<Scalars['Float']['output']>;
  totalHoursNoRounding?: Maybe<Scalars['Boolean']['output']>;
  totalSellPrice?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  units?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DATE']['output']>;
  vendor?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
  volumePricing?: Maybe<Array<Maybe<VolumePricing>>>;
  volumePricingEnabled?: Maybe<Scalars['Boolean']['output']>;
  yesValue?: Maybe<Scalars['Float']['output']>;
};

export type TaskGroup = {
  __typename?: 'TaskGroup';
  agiloftBlendedScopeType?: Maybe<Scalars['String']['output']>;
  agiloftClauses?: Maybe<Scalars['String']['output']>;
  agiloftDefaultPrintTemplate?: Maybe<Scalars['String']['output']>;
  agiloftExhibits?: Maybe<Scalars['String']['output']>;
  agiloftManagedScopeType?: Maybe<Scalars['String']['output']>;
  agiloftOutcomeScopeType?: Maybe<Scalars['String']['output']>;
  agiloftPrintTemplateTypeOptions?: Maybe<
    Array<Maybe<Scalars['String']['output']>>
  >;
  agiloftRecurringServicesFees?: Maybe<Scalars['String']['output']>;
  agiloftScopeType?: Maybe<Scalars['String']['output']>;
  agiloftServiceCategory?: Maybe<Scalars['String']['output']>;
  bidAssuranceGroup?: Maybe<Scalars['String']['output']>;
  bidAssuranceUrl?: Maybe<Scalars['String']['output']>;
  businessOutcome?: Maybe<Scalars['HTML']['output']>;
  businessOutcomeHelp?: Maybe<Scalars['HTML']['output']>;
  businessOutcomeIncluded?: Maybe<Scalars['Boolean']['output']>;
  coOwners?: Maybe<Array<Maybe<TaskGroupTeam>>>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  dynamicSiteTableContent?: Maybe<Scalars['HTML']['output']>;
  dynamicSiteTableIncluded?: Maybe<Scalars['Boolean']['output']>;
  executiveSummaryDrivers?: Maybe<Scalars['HTML']['output']>;
  executiveSummaryDriversHelp?: Maybe<Scalars['HTML']['output']>;
  executiveSummaryDriversIncluded?: Maybe<Scalars['Boolean']['output']>;
  executiveSummaryOverview?: Maybe<Scalars['HTML']['output']>;
  executiveSummaryOverviewHelp?: Maybe<Scalars['HTML']['output']>;
  executiveSummaryOverviewIncluded?: Maybe<Scalars['Boolean']['output']>;
  fixedFee?: Maybe<Scalars['IntOrString']['output']>;
  fixedFeeSellPrice?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  inTestingTaskGroupId?: Maybe<Scalars['String']['output']>;
  itemsProvidedToCustomers?: Maybe<Array<Maybe<ItemsProvidedToCustomers>>>;
  lastReviewedBy?: Maybe<Scalars['String']['output']>;
  lastReviewedOn?: Maybe<Scalars['IntOrString']['output']>;
  minPmHours?: Maybe<Scalars['Float']['output']>;
  minPmPercent?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['IntOrString']['output']>;
  originalTaskGroupId?: Maybe<Scalars['String']['output']>;
  /** blob */
  outcomeIncluded?: Maybe<Scalars['Boolean']['output']>;
  outcomeProjectScope?: Maybe<Scalars['HTML']['output']>;
  outcomeProjectScopeIncluded?: Maybe<Scalars['Boolean']['output']>;
  outcomeProjectScopePhases?: Maybe<Scalars['HTML']['output']>;
  outcomeProjectScopePhasesHelp?: Maybe<Scalars['HTML']['output']>;
  outcomeProjectScopePhasesIncluded?: Maybe<Scalars['Boolean']['output']>;
  outcomeSowFooter?: Maybe<Scalars['HTML']['output']>;
  outcomeSowHeader?: Maybe<Scalars['HTML']['output']>;
  outcomeSummary?: Maybe<Scalars['HTML']['output']>;
  owner?: Maybe<Array<Maybe<TaskGroupTeam>>>;
  pmPercent?: Maybe<Scalars['Float']['output']>;
  pmRole?: Maybe<Scalars['String']['output']>;
  practice?: Maybe<Scalars['String']['output']>;
  proposalContent?: Maybe<Scalars['HTML']['output']>;
  proposalTemplateIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  published?: Maybe<Scalars['IntOrString']['output']>;
  riskPercent?: Maybe<Scalars['IntOrString']['output']>;
  setPm?: Maybe<Scalars['Boolean']['output']>;
  sowScopeFooter?: Maybe<Scalars['HTML']['output']>;
  /** blob */
  sowScopeHeader?: Maybe<Scalars['HTML']['output']>;
  sowSections?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status?: Maybe<Scalars['String']['output']>;
  taskGroupCategories?: Maybe<Array<Maybe<Category>>>;
  taskGroupTeam?: Maybe<Array<Maybe<TaskGroupTeam>>>;
  technicalOutcome?: Maybe<Scalars['HTML']['output']>;
  technicalOutcomeHelp?: Maybe<Scalars['HTML']['output']>;
  technicalOutcomeIncluded?: Maybe<Scalars['Boolean']['output']>;
  testers?: Maybe<Array<Maybe<TaskGroupTeam>>>;
  version?: Maybe<Scalars['IntOrString']['output']>;
};

export type TaskGroupTeam = {
  __typename?: 'TaskGroupTeam';
  email?: Maybe<Scalars['String']['output']>;
};

export type TaskGroupTeamInput = {
  email: Scalars['String']['input'];
};

export type TaskGroupsPrintOptions = {
  __typename?: 'TaskGroupsPrintOptions';
  agiloftDefaultTemplates?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  agiloftPrintTemplateTypeOptions?: Maybe<
    Array<Maybe<Scalars['String']['output']>>
  >;
};

export type TaskHasGroup = {
  __typename?: 'TaskHasGroup';
  groupId?: Maybe<Scalars['String']['output']>;
  taskId?: Maybe<Scalars['String']['output']>;
};

export type TaskInput = {
  allowAdditionalGPMargin?: InputMaybe<Scalars['ANY']['input']>;
  allowBlankText?: InputMaybe<Scalars['Boolean']['input']>;
  allowMarkup?: InputMaybe<Scalars['ANY']['input']>;
  allowModifyPrimaryRoleRate?: InputMaybe<Scalars['Boolean']['input']>;
  allowModifySecondaryRoleRate?: InputMaybe<Scalars['Boolean']['input']>;
  allowModifyTertiaryRoleRate?: InputMaybe<Scalars['Boolean']['input']>;
  allowTotalHoursAdjustment?: InputMaybe<Scalars['Boolean']['input']>;
  alwaysShowOnProposal?: InputMaybe<Scalars['Boolean']['input']>;
  catalogId?: InputMaybe<Scalars['String']['input']>;
  catalogName?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  cost?: InputMaybe<Scalars['IntOrString']['input']>;
  costFormula?: InputMaybe<Scalars['ANY']['input']>;
  costType?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DATE']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  custom?: InputMaybe<Scalars['Boolean']['input']>;
  customServiceCost?: InputMaybe<Scalars['Float']['input']>;
  customServiceDivisorRate?: InputMaybe<Scalars['Float']['input']>;
  customServiceSellPrice?: InputMaybe<Scalars['Float']['input']>;
  defaultCost?: InputMaybe<Scalars['IntOrString']['input']>;
  defaultGrossProfit?: InputMaybe<Scalars['IntOrString']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  disableCost?: InputMaybe<Scalars['Boolean']['input']>;
  disableCostFormula?: InputMaybe<Scalars['String']['input']>;
  disableHours?: InputMaybe<Scalars['Boolean']['input']>;
  disableHoursFormula?: InputMaybe<Scalars['IntOrString']['input']>;
  disableQuantity?: InputMaybe<Scalars['IntOrString']['input']>;
  disableQuantityFormula?: InputMaybe<Scalars['IntOrString']['input']>;
  dropDownIndex?: InputMaybe<Scalars['IntOrString']['input']>;
  dropDownIndexFormula?: InputMaybe<Scalars['IntOrString']['input']>;
  edc?: InputMaybe<Scalars['String']['input']>;
  enrollmentCost?: InputMaybe<Scalars['Float']['input']>;
  enrollmentCostFormula?: InputMaybe<Scalars['String']['input']>;
  enrollmentHours?: InputMaybe<Scalars['Float']['input']>;
  enrollmentHoursFormula?: InputMaybe<Scalars['IntOrString']['input']>;
  enrollmentRole?: InputMaybe<Scalars['String']['input']>;
  estimatorLocation?: InputMaybe<Scalars['String']['input']>;
  exclude?: InputMaybe<Scalars['Boolean']['input']>;
  excludeToggle?: InputMaybe<Scalars['ANY']['input']>;
  fixedFeePrice?: InputMaybe<Scalars['IntOrString']['input']>;
  fixedFeeTotalHours?: InputMaybe<Scalars['IntOrString']['input']>;
  grossProfit?: InputMaybe<Scalars['Float']['input']>;
  hide?: InputMaybe<Scalars['IntOrString']['input']>;
  hideHours?: InputMaybe<Scalars['Boolean']['input']>;
  hideShowFormula?: InputMaybe<Scalars['IntOrString']['input']>;
  hours?: InputMaybe<Scalars['IntOrString']['input']>;
  hoursFormula?: InputMaybe<Scalars['ANY']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imported?: InputMaybe<Scalars['Boolean']['input']>;
  inTestingTaskId?: InputMaybe<Scalars['String']['input']>;
  instructionsText?: InputMaybe<Scalars['String']['input']>;
  isExpense?: InputMaybe<Scalars['Boolean']['input']>;
  isManagedServicesCostCalculation?: InputMaybe<Scalars['Boolean']['input']>;
  isProjectManagement?: InputMaybe<Scalars['Boolean']['input']>;
  isTravelTime?: InputMaybe<Scalars['Boolean']['input']>;
  lastReviewedBy?: InputMaybe<Scalars['String']['input']>;
  lastReviewedOn?: InputMaybe<Scalars['IntOrString']['input']>;
  list?: InputMaybe<Array<InputMaybe<TaskListInput>>>;
  listNames?: InputMaybe<Scalars['String']['input']>;
  listValues?: InputMaybe<Scalars['String']['input']>;
  managedServices?: InputMaybe<Scalars['Boolean']['input']>;
  managedServicesArcRrcFormula?: InputMaybe<Scalars['String']['input']>;
  managedServicesFteFormula?: InputMaybe<Scalars['String']['input']>;
  managedServicesTransitionFormula?: InputMaybe<Scalars['String']['input']>;
  margin?: InputMaybe<Scalars['Float']['input']>;
  minGrossProfit?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  noValue?: InputMaybe<Scalars['Float']['input']>;
  order?: InputMaybe<Scalars['IntOrString']['input']>;
  originalTaskId?: InputMaybe<Scalars['String']['input']>;
  overtime?: InputMaybe<Scalars['Boolean']['input']>;
  phases?: InputMaybe<Array<InputMaybe<TaskPhaseInput>>>;
  pmPercent?: InputMaybe<Scalars['Float']['input']>;
  practice?: InputMaybe<Scalars['String']['input']>;
  practiceId?: InputMaybe<Scalars['String']['input']>;
  primaryPercent?: InputMaybe<Scalars['IntOrString']['input']>;
  primaryRole?: InputMaybe<Scalars['String']['input']>;
  primaryRoleId?: InputMaybe<Scalars['String']['input']>;
  primaryRoleName?: InputMaybe<Scalars['String']['input']>;
  primaryRoleRateOverride?: InputMaybe<Scalars['Float']['input']>;
  primaryRoleRateOverrideFormula?: InputMaybe<Scalars['String']['input']>;
  productType?: InputMaybe<Scalars['String']['input']>;
  projectItemId?: InputMaybe<Scalars['String']['input']>;
  proposalDescription?: InputMaybe<Scalars['String']['input']>;
  proposalNotes?: InputMaybe<Scalars['String']['input']>;
  proposalShowEmpty?: InputMaybe<Scalars['ANY']['input']>;
  quantity?: InputMaybe<Scalars['IntOrString']['input']>;
  quantityFormula?: InputMaybe<Scalars['ANY']['input']>;
  quantityMax?: InputMaybe<Scalars['IntOrString']['input']>;
  quantityMin?: InputMaybe<Scalars['IntOrString']['input']>;
  rate?: InputMaybe<Scalars['IntOrString']['input']>;
  renderIndex?: InputMaybe<Scalars['Int']['input']>;
  replacedNamedExpression?: InputMaybe<Scalars['Boolean']['input']>;
  secondaryPercent?: InputMaybe<Scalars['IntOrString']['input']>;
  secondaryRole?: InputMaybe<Scalars['IntOrString']['input']>;
  secondaryRoleId?: InputMaybe<Scalars['IntOrString']['input']>;
  secondaryRoleName?: InputMaybe<Scalars['String']['input']>;
  secondaryRoleRateOverride?: InputMaybe<Scalars['Float']['input']>;
  secondaryRoleRateOverrideFormula?: InputMaybe<Scalars['String']['input']>;
  sellPrice?: InputMaybe<Scalars['Float']['input']>;
  sellPriceFormula?: InputMaybe<Scalars['String']['input']>;
  serviceItem?: InputMaybe<Scalars['String']['input']>;
  serviceItemFormula?: InputMaybe<Scalars['String']['input']>;
  serviceItemId?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['String']['input']>;
  skill?: InputMaybe<Scalars['String']['input']>;
  skillFormula?: InputMaybe<Scalars['String']['input']>;
  skillId?: InputMaybe<Scalars['String']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  sowSection?: InputMaybe<Scalars['IntOrString']['input']>;
  sowSectionId?: InputMaybe<Scalars['IntOrString']['input']>;
  sowSections?: InputMaybe<SowSectionInput>;
  sprint?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  subType?: InputMaybe<Scalars['IntOrString']['input']>;
  taskGroupId?: InputMaybe<Scalars['IntOrString']['input']>;
  taskGroups?: InputMaybe<Array<InputMaybe<Scalars['IntOrString']['input']>>>;
  taskId?: InputMaybe<Scalars['String']['input']>;
  technologyName?: InputMaybe<Scalars['String']['input']>;
  tertiaryPercent?: InputMaybe<Scalars['IntOrString']['input']>;
  tertiaryRole?: InputMaybe<Scalars['IntOrString']['input']>;
  tertiaryRoleId?: InputMaybe<Scalars['IntOrString']['input']>;
  tertiaryRoleName?: InputMaybe<Scalars['String']['input']>;
  tertiaryRoleRateOverride?: InputMaybe<Scalars['Float']['input']>;
  tertiaryRoleRateOverrideFormula?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  textDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  textDisabledFormula?: InputMaybe<Scalars['String']['input']>;
  textFormula?: InputMaybe<Scalars['String']['input']>;
  textType?: InputMaybe<Scalars['String']['input']>;
  tier?: InputMaybe<Scalars['String']['input']>;
  tierId?: InputMaybe<Scalars['IntOrString']['input']>;
  totalHours?: InputMaybe<Scalars['IntOrString']['input']>;
  totalHoursAdjustment?: InputMaybe<Scalars['Float']['input']>;
  totalHoursNoRounding?: InputMaybe<Scalars['Boolean']['input']>;
  totalSellPrice?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  units?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DATE']['input']>;
  vendor?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
  volumePricing?: InputMaybe<Array<InputMaybe<VolumePricingInput>>>;
  volumePricingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  yesValue?: InputMaybe<Scalars['Float']['input']>;
};

export type TaskList = {
  __typename?: 'TaskList';
  names?: Maybe<Scalars['String']['output']>;
  selectedIndex?: Maybe<Scalars['Int']['output']>;
  values?: Maybe<Scalars['IntOrString']['output']>;
};

export type TaskListInput = {
  names?: InputMaybe<Scalars['String']['input']>;
  selectedIndex?: InputMaybe<Scalars['Int']['input']>;
  values?: InputMaybe<Scalars['IntOrString']['input']>;
};

export type TaskNote = {
  __typename?: 'TaskNote';
  createdAt?: Maybe<Scalars['IntOrString']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  noteType?: Maybe<Scalars['String']['output']>;
  projectItemId?: Maybe<Scalars['String']['output']>;
  siteId?: Maybe<Scalars['String']['output']>;
  taskId?: Maybe<Scalars['String']['output']>;
};

export type TaskNoteInput = {
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  noteType?: InputMaybe<Scalars['String']['input']>;
  projectItemId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
  taskId?: InputMaybe<Scalars['String']['input']>;
};

export type TaskOrder = {
  __typename?: 'TaskOrder';
  order?: Maybe<Scalars['IntOrString']['output']>;
  taskGroup?: Maybe<Scalars['String']['output']>;
  taskId?: Maybe<Scalars['String']['output']>;
};

export type TaskPhase = {
  __typename?: 'TaskPhase';
  name?: Maybe<Scalars['String']['output']>;
  taskPercent?: Maybe<Scalars['IntOrString']['output']>;
};

export type TaskPhaseInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  taskPercent?: InputMaybe<Scalars['IntOrString']['input']>;
};

export type Tier = {
  __typename?: 'Tier';
  createdAt?: Maybe<Scalars['DATE']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type TrainingOrCollateral = {
  __typename?: 'TrainingOrCollateral';
  description?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type TrainingOrCollateralInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type TravelExpenseSummaryResponse = {
  __typename?: 'TravelExpenseSummaryResponse';
  isExpenseTotal?: Maybe<Scalars['Float']['output']>;
  travelExpenseTotal?: Maybe<Scalars['Float']['output']>;
  travelTimeRole?: Maybe<Array<Maybe<ServiceSummary>>>;
  travelTimeTotal?: Maybe<Array<Maybe<ServiceSummary>>>;
};

export type UpdateLeadInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>;
  associatedProjects?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<Scalars['String']['input']>;
  primarySa?: InputMaybe<Scalars['String']['input']>;
  primarySales?: InputMaybe<Scalars['String']['input']>;
  secondarySa?: InputMaybe<Scalars['String']['input']>;
  secondarySales?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLeadResponse = {
  __typename?: 'UpdateLeadResponse';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateProjectInput = {
  area?: InputMaybe<Scalars['String']['input']>;
  cdwCustomerAccountNumber?: InputMaybe<Scalars['String']['input']>;
  cewProvided?: InputMaybe<Scalars['Boolean']['input']>;
  closedOn?: InputMaybe<Scalars['DATE']['input']>;
  closedReason?: InputMaybe<Scalars['String']['input']>;
  closedStatus?: InputMaybe<Scalars['String']['input']>;
  customerArea?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  customerName?: InputMaybe<Scalars['String']['input']>;
  customerRegion?: InputMaybe<Scalars['String']['input']>;
  customerSector?: InputMaybe<Scalars['String']['input']>;
  customerSegment?: InputMaybe<Scalars['String']['input']>;
  demoProvided?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  estClose?: InputMaybe<Scalars['DATE']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  probability?: InputMaybe<Scalars['Int']['input']>;
  projectOwner?: InputMaybe<Scalars['String']['input']>;
  projectTeam?: InputMaybe<Array<InputMaybe<ProjectTeamInput>>>;
  salesForceId?: InputMaybe<Scalars['String']['input']>;
  salesForceUrl?: InputMaybe<Scalars['String']['input']>;
  serviceLocation?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<OpportunityStatusEnum>;
};

export type UpdateProjectResponse = {
  __typename?: 'UpdateProjectResponse';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateProjectStatusInput = {
  closedReason: Scalars['String']['input'];
  closedStatus: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  status: OpportunityStatusEnum;
};

export type UpdatedTaskMutationResponse = {
  __typename?: 'UpdatedTaskMutationResponse';
  success?: Maybe<Scalars['Boolean']['output']>;
  updatedTask?: Maybe<Task>;
};

export type User = {
  __typename?: 'User';
  admin?: Maybe<Scalars['Boolean']['output']>;
  aud?: Maybe<Scalars['String']['output']>;
  auth_time?: Maybe<Scalars['String']['output']>;
  ciscoCcwToken?: Maybe<UserCiscoCcwToken>;
  contentAdmin?: Maybe<Scalars['Boolean']['output']>;
  department?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  email_verified?: Maybe<Scalars['Boolean']['output']>;
  employeeId?: Maybe<Scalars['String']['output']>;
  exp?: Maybe<Scalars['String']['output']>;
  iat?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  iss?: Maybe<Scalars['String']['output']>;
  managedServicesAdmin?: Maybe<Scalars['Boolean']['output']>;
  managerEmail?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  sub?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type UserCiscoCcwToken = {
  __typename?: 'UserCiscoCcwToken';
  expires?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type UserInput = {
  department?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type VolumePricing = {
  __typename?: 'VolumePricing';
  cost?: Maybe<Scalars['Float']['output']>;
  maxQuantity?: Maybe<Scalars['Float']['output']>;
  minQuantity?: Maybe<Scalars['Float']['output']>;
};

export type VolumePricingInput = {
  cost?: InputMaybe<Scalars['Float']['input']>;
  maxQuantity?: InputMaybe<Scalars['Float']['input']>;
  minQuantity?: InputMaybe<Scalars['Float']['input']>;
};

export type ZuoraRateChange = {
  __typename?: 'ZuoraRateChange';
  adjustedSimCost?: Maybe<Scalars['Float']['output']>;
  amendedByOrderOn?: Maybe<Scalars['DATE']['output']>;
  applyDiscountTo?: Maybe<Scalars['String']['output']>;
  billingDay?: Maybe<Scalars['String']['output']>;
  billingPeriod?: Maybe<Scalars['String']['output']>;
  billingPeriodAlignment?: Maybe<Scalars['String']['output']>;
  billingTiming?: Maybe<Scalars['String']['output']>;
  chargeModelConfiguration?: Maybe<Scalars['String']['output']>;
  chargedThroughDate?: Maybe<Scalars['DATE']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discountClass?: Maybe<Scalars['String']['output']>;
  discountLevel?: Maybe<Scalars['String']['output']>;
  dmrc?: Maybe<Scalars['Float']['output']>;
  done?: Maybe<Scalars['Boolean']['output']>;
  dtcv?: Maybe<Scalars['Float']['output']>;
  effectiveEndDate?: Maybe<Scalars['DATE']['output']>;
  effectiveStartDate?: Maybe<Scalars['DATE']['output']>;
  endDateCondition?: Maybe<Scalars['String']['output']>;
  entitlementId?: Maybe<Scalars['String']['output']>;
  externalProductID?: Maybe<Scalars['String']['output']>;
  includedUnits?: Maybe<Scalars['String']['output']>;
  inputArgumentId?: Maybe<Scalars['String']['output']>;
  invoiceLineComments?: Maybe<Scalars['String']['output']>;
  invoiceLineNumber?: Maybe<Scalars['String']['output']>;
  isStackedDiscount?: Maybe<Scalars['Boolean']['output']>;
  listPriceBase?: Maybe<Scalars['String']['output']>;
  marginPercentage?: Maybe<Scalars['Float']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
  msrp?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  numberOfPeriods?: Maybe<Scalars['Int']['output']>;
  orderTypeService?: Maybe<Scalars['String']['output']>;
  originalChargeId?: Maybe<Scalars['String']['output']>;
  originalOrderDate?: Maybe<Scalars['DATE']['output']>;
  overageCalculationOption?: Maybe<Scalars['String']['output']>;
  overagePrice?: Maybe<Scalars['Float']['output']>;
  overageUnusedUnitsCreditOption?: Maybe<Scalars['String']['output']>;
  partnerProvider?: Maybe<Scalars['String']['output']>;
  priceChangeOption?: Maybe<Scalars['String']['output']>;
  priceIncreasePercentage?: Maybe<Scalars['Float']['output']>;
  pricingTypeSVAR?: Maybe<Scalars['String']['output']>;
  processedThroughDate?: Maybe<Scalars['DATE']['output']>;
  productRatePlanChargeId?: Maybe<Scalars['String']['output']>;
  productRatePlanChargeNumber?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  ratePlanChargeUsageIdentifier?: Maybe<Scalars['String']['output']>;
  ratingGroup?: Maybe<Scalars['String']['output']>;
  segment?: Maybe<Scalars['Int']['output']>;
  serialNumber?: Maybe<Scalars['String']['output']>;
  serviceID?: Maybe<Scalars['String']['output']>;
  shipFromLocation?: Maybe<Scalars['String']['output']>;
  shipToReference?: Maybe<Scalars['String']['output']>;
  shipToSource?: Maybe<Scalars['String']['output']>;
  smoothingModel?: Maybe<Scalars['String']['output']>;
  specificBillingPeriod?: Maybe<Scalars['String']['output']>;
  specificEndDate?: Maybe<Scalars['DATE']['output']>;
  specificListPriceBase?: Maybe<Scalars['String']['output']>;
  subhubProductOrderId?: Maybe<Scalars['String']['output']>;
  tcv?: Maybe<Scalars['Float']['output']>;
  tiers?: Maybe<Array<Maybe<ZuoraTier>>>;
  triggerDate?: Maybe<Scalars['DATE']['output']>;
  triggerEvent?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unitCost?: Maybe<Scalars['String']['output']>;
  unusedUnitsCreditRates?: Maybe<Scalars['String']['output']>;
  uom?: Maybe<Scalars['String']['output']>;
  upToPeriods?: Maybe<Scalars['String']['output']>;
  upToPeriodsType?: Maybe<Scalars['String']['output']>;
  usageRecordRatingOption?: Maybe<Scalars['String']['output']>;
};

export type ZuoraRatePlan = {
  __typename?: 'ZuoraRatePlan';
  externallyManagedPlanId?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  productName?: Maybe<Scalars['String']['output']>;
  productRatePlanId?: Maybe<Scalars['String']['output']>;
  productRatePlanNumber?: Maybe<Scalars['String']['output']>;
  productSku?: Maybe<Scalars['String']['output']>;
  ratePlanCharges?: Maybe<Array<Maybe<ZuoraRateChange>>>;
  ratePlanName?: Maybe<Scalars['String']['output']>;
  ratePlanUsageIdentifier?: Maybe<Scalars['String']['output']>;
  subscriptionProductFeatures?: Maybe<
    Array<Maybe<Scalars['String']['output']>>
  >;
  subscriptionRatePlanNumber?: Maybe<Scalars['String']['output']>;
};

export type ZuoraSubscription = {
  __typename?: 'ZuoraSubscription';
  accountId?: Maybe<Scalars['String']['output']>;
  accountName?: Maybe<Scalars['String']['output']>;
  accountNumber?: Maybe<Scalars['String']['output']>;
  autoRenew?: Maybe<Scalars['Boolean']['output']>;
  billToContact?: Maybe<Scalars['String']['output']>;
  billToEmailAddress?: Maybe<Scalars['String']['output']>;
  billToEmailAddress2?: Maybe<Scalars['String']['output']>;
  billToEmailSource?: Maybe<Scalars['String']['output']>;
  billToMedia?: Maybe<Scalars['String']['output']>;
  billToReference?: Maybe<Scalars['String']['output']>;
  billToSource?: Maybe<Scalars['String']['output']>;
  cancelReason?: Maybe<Scalars['String']['output']>;
  commissionable?: Maybe<Scalars['String']['output']>;
  contactReference?: Maybe<Scalars['String']['output']>;
  contactSource?: Maybe<Scalars['String']['output']>;
  contractEffectiveDate?: Maybe<Scalars['DATE']['output']>;
  contractedMrr?: Maybe<Scalars['Float']['output']>;
  cpqBundleJsonId?: Maybe<Scalars['String']['output']>;
  currentTerm?: Maybe<Scalars['Int']['output']>;
  currentTermPeriodType?: Maybe<Scalars['String']['output']>;
  customerAcceptanceDate?: Maybe<Scalars['DATE']['output']>;
  customerPO?: Maybe<Scalars['String']['output']>;
  daasType?: Maybe<Scalars['String']['output']>;
  department?: Maybe<Scalars['String']['output']>;
  engagementId?: Maybe<Scalars['String']['output']>;
  initialTerm?: Maybe<Scalars['Int']['output']>;
  initialTermPeriodType?: Maybe<Scalars['String']['output']>;
  invoiceComments?: Maybe<Scalars['String']['output']>;
  invoiceOwnerAccountId?: Maybe<Scalars['String']['output']>;
  invoiceOwnerAccountName?: Maybe<Scalars['String']['output']>;
  invoiceOwnerAccountNumber?: Maybe<Scalars['String']['output']>;
  invoiceSeparately?: Maybe<Scalars['Boolean']['output']>;
  invoiceTemplate?: Maybe<Scalars['String']['output']>;
  invoiceTemplateId?: Maybe<Scalars['String']['output']>;
  invoiceTemplateName?: Maybe<Scalars['String']['output']>;
  isLatestVersion?: Maybe<Scalars['String']['output']>;
  lastBookingDate?: Maybe<Scalars['DATE']['output']>;
  marginPercentage?: Maybe<Scalars['String']['output']>;
  minimumContractValue?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  opportunityCloseDate?: Maybe<Scalars['String']['output']>;
  opportunityName?: Maybe<Scalars['String']['output']>;
  orderTypeService?: Maybe<Scalars['String']['output']>;
  paymentTerm?: Maybe<Scalars['String']['output']>;
  paymentTermCode?: Maybe<Scalars['String']['output']>;
  paymentType?: Maybe<Scalars['String']['output']>;
  productHubLocation?: Maybe<Scalars['String']['output']>;
  quoteBusinessType?: Maybe<Scalars['String']['output']>;
  quoteNumber?: Maybe<Scalars['String']['output']>;
  quoteType?: Maybe<Scalars['String']['output']>;
  ratePlans?: Maybe<Array<Maybe<ZuoraRatePlan>>>;
  renewalSetting?: Maybe<Scalars['String']['output']>;
  renewalTerm?: Maybe<Scalars['Int']['output']>;
  renewalTermPeriodType?: Maybe<Scalars['String']['output']>;
  salesPerson?: Maybe<Scalars['String']['output']>;
  sequenceSetId?: Maybe<Scalars['String']['output']>;
  sequenceSetName?: Maybe<Scalars['String']['output']>;
  serviceActivationDate?: Maybe<Scalars['DATE']['output']>;
  serviceType?: Maybe<Scalars['String']['output']>;
  shipFromLocation?: Maybe<Scalars['String']['output']>;
  shipToReference?: Maybe<Scalars['String']['output']>;
  shipToSource?: Maybe<Scalars['String']['output']>;
  soldToContact?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subHubSubscriptionId?: Maybe<Scalars['String']['output']>;
  subscriptionEndDate?: Maybe<Scalars['DATE']['output']>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  subscriptionNumber?: Maybe<Scalars['String']['output']>;
  subscriptionStartDate?: Maybe<Scalars['DATE']['output']>;
  subscriptionUsageIdentifier?: Maybe<Scalars['String']['output']>;
  svarContractNumber?: Maybe<Scalars['String']['output']>;
  termEndDate?: Maybe<Scalars['DATE']['output']>;
  termStartDate?: Maybe<Scalars['DATE']['output']>;
  termType?: Maybe<Scalars['String']['output']>;
  totalContractedValue?: Maybe<Scalars['Float']['output']>;
  vendor?: Maybe<Scalars['String']['output']>;
  vendorId?: Maybe<Scalars['String']['output']>;
  vendorSubscriptionId?: Maybe<Scalars['String']['output']>;
  zuoraId?: Maybe<Scalars['String']['output']>;
};

export type ZuoraTier = {
  __typename?: 'ZuoraTier';
  endingUnit?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceFormat?: Maybe<Scalars['String']['output']>;
  startingUnit?: Maybe<Scalars['Float']['output']>;
  tier?: Maybe<Scalars['Int']['output']>;
};

export type AddProjectUserInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  customClaims?: InputMaybe<Scalars['String']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  metadata?: InputMaybe<FbUserMetaDataInput>;
  passwordHash?: InputMaybe<Scalars['String']['input']>;
  passwordSalt?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  photoURL?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['ID']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
  tenantId?: InputMaybe<Scalars['String']['input']>;
  tokensValidAfterTime?: InputMaybe<Scalars['String']['input']>;
  uid: Scalars['ID']['input'];
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type EstimateToPsaInputs = {
  __typename?: 'estimateToPSAInputs';
  wrapperestimate?: Maybe<WrapperEstimateToPsaInputs>;
};

export type GetPracticesResponse = {
  __typename?: 'getPracticesResponse';
  count?: Maybe<Scalars['Int']['output']>;
  practices?: Maybe<Array<Maybe<Practice>>>;
};

export type GetPracticesResponse_CountArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type GetPracticesResponse_PracticesArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GetServiceCategoriesResponse = {
  __typename?: 'getServiceCategoriesResponse';
  count?: Maybe<Scalars['Int']['output']>;
  serviceCategories: Array<Maybe<ServiceCategory>>;
};

export type GetServiceLocationsResponse = {
  __typename?: 'getServiceLocationsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  locations: Array<Maybe<ServiceLocation>>;
};

export type GetServiceLocationsResponse_LocationsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GetServiceLocationsRolesResponse = {
  __typename?: 'getServiceLocationsRolesResponse';
  count?: Maybe<Scalars['Int']['output']>;
  roles: Array<Maybe<ServiceLocationRole>>;
};

export type GetServiceLocationsRolesResponse_RolesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GetServicePracticesResponse = {
  __typename?: 'getServicePracticesResponse';
  count?: Maybe<Scalars['Int']['output']>;
  servicePractices?: Maybe<Array<Maybe<ServicePractice>>>;
};

export type GetServicePracticesResponse_CountArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
};

export type GetServicePracticesResponse_ServicePracticesArgs = {
  filters?: InputMaybe<Scalars['MongoFilter']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type WrapperEstimateToPsaInputs = {
  __typename?: 'wrapperEstimateToPSAInputs';
  estimate?: Maybe<SfdcEstimate>;
  estimateLines?: Maybe<Array<Maybe<LineSet>>>;
  estimateProducts?: Maybe<Array<Maybe<SfdcEstimateProduct>>>;
  estimateRoleRequests?: Maybe<Array<Maybe<RoleRequest>>>;
};

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ReferenceResolver<TResult, TReference, TContext> = (
  reference: TReference,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

type ScalarCheck<T, S> = S extends true ? T : NullableCheck<T, S>;
type NullableCheck<T, S> = Maybe<T> extends T
  ? Maybe<ListCheck<NonNullable<T>, S>>
  : ListCheck<T, S>;
type ListCheck<T, S> = T extends (infer U)[]
  ? NullableCheck<U, S>[]
  : GraphQLRecursivePick<T, S>;
export type GraphQLRecursivePick<T, S> = {
  [K in keyof T & keyof S]: ScalarCheck<T[K], S[K]>;
};

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> {
  subscribe: SubscriptionSubscribeFn<
    { [key in TKey]: TResult },
    TParent,
    TContext,
    TArgs
  >;
  resolve?: SubscriptionResolveFn<
    TResult,
    { [key in TKey]: TResult },
    TContext,
    TArgs
  >;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {}
> =
  | ((
      ...args: any[]
    ) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<
  TResult = {},
  TParent = {},
  TContext = {},
  TArgs = {}
> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  ANY: ResolverTypeWrapper<Partial<Scalars['ANY']['output']>>;
  ActiveSiteTask: ResolverTypeWrapper<Partial<ActiveSiteTask>>;
  String: ResolverTypeWrapper<Partial<Scalars['String']['output']>>;
  Float: ResolverTypeWrapper<Partial<Scalars['Float']['output']>>;
  Int: ResolverTypeWrapper<Partial<Scalars['Int']['output']>>;
  Boolean: ResolverTypeWrapper<Partial<Scalars['Boolean']['output']>>;
  ActiveSiteWithTasks: ResolverTypeWrapper<Partial<ActiveSiteWithTasks>>;
  AddEstimatorTasksByTaskGroupResponse: ResolverTypeWrapper<
    Partial<AddEstimatorTasksByTaskGroupResponse>
  >;
  ID: ResolverTypeWrapper<Partial<Scalars['ID']['output']>>;
  AddProjectProposalInput: ResolverTypeWrapper<
    Partial<AddProjectProposalInput>
  >;
  AddProjectUserResponse: ResolverTypeWrapper<Partial<AddProjectUserResponse>>;
  AdminConfigSettings: ResolverTypeWrapper<Partial<AdminConfigSettings>>;
  AdminConfigSettingsInput: ResolverTypeWrapper<
    Partial<AdminConfigSettingsInput>
  >;
  AdminPractice: ResolverTypeWrapper<Partial<AdminPractice>>;
  AdminPracticeInput: ResolverTypeWrapper<Partial<AdminPracticeInput>>;
  AdminRole: ResolverTypeWrapper<Partial<AdminRole>>;
  AdminRoleInput: ResolverTypeWrapper<Partial<AdminRoleInput>>;
  AdminTaskGroup: ResolverTypeWrapper<Partial<AdminTaskGroup>>;
  AdminTaskGroupInput: ResolverTypeWrapper<Partial<AdminTaskGroupInput>>;
  AdminTierInput: ResolverTypeWrapper<Partial<AdminTierInput>>;
  AdminUser: ResolverTypeWrapper<Partial<AdminUser>>;
  AgiloftAPILog: ResolverTypeWrapper<Partial<AgiloftApiLog>>;
  AgiloftApiResponse: ResolverTypeWrapper<Partial<AgiloftApiResponse>>;
  AgiloftExpenseType: ResolverTypeWrapper<Partial<AgiloftExpenseType>>;
  AgiloftExpenseTypeInput: ResolverTypeWrapper<
    Partial<AgiloftExpenseTypeInput>
  >;
  AgiloftRole: ResolverTypeWrapper<Partial<AgiloftRole>>;
  AgiloftSOW: ResolverTypeWrapper<Partial<AgiloftSow>>;
  AgiloftSowConnectionInput: ResolverTypeWrapper<
    Partial<AgiloftSowConnectionInput>
  >;
  AgiloftToken: ResolverTypeWrapper<Partial<AgiloftToken>>;
  AgiloftTravelNoticeType: ResolverTypeWrapper<
    Partial<AgiloftTravelNoticeType>
  >;
  AgiloftTravelNoticeTypeInput: ResolverTypeWrapper<
    Partial<AgiloftTravelNoticeTypeInput>
  >;
  BidAssuranceNote: ResolverTypeWrapper<Partial<BidAssuranceNote>>;
  BidAssuranceNoteInput: ResolverTypeWrapper<Partial<BidAssuranceNoteInput>>;
  BidAssuranceStatusHistory: ResolverTypeWrapper<
    Partial<BidAssuranceStatusHistory>
  >;
  BidAssuranceStatusHistoryInput: ResolverTypeWrapper<
    Partial<BidAssuranceStatusHistoryInput>
  >;
  BidAssuranceStatusType: ResolverTypeWrapper<Partial<BidAssuranceStatusType>>;
  Category: ResolverTypeWrapper<Partial<Category>>;
  CategoryInput: ResolverTypeWrapper<Partial<CategoryInput>>;
  CdwOpenOrdersWorkList: ResolverTypeWrapper<Partial<CdwOpenOrdersWorkList>>;
  CdwRate: ResolverTypeWrapper<Partial<CdwRate>>;
  CdwRateInput: ResolverTypeWrapper<Partial<CdwRateInput>>;
  CiscoAnnuity: ResolverTypeWrapper<Partial<CiscoAnnuity>>;
  CiscoAnnuityCategory: ResolverTypeWrapper<Partial<CiscoAnnuityCategory>>;
  CiscoAnnuityCategoryInput: ResolverTypeWrapper<
    Partial<CiscoAnnuityCategoryInput>
  >;
  CiscoAnnuityComment: ResolverTypeWrapper<Partial<CiscoAnnuityComment>>;
  CiscoAnnuityCommentInput: ResolverTypeWrapper<
    Partial<CiscoAnnuityCommentInput>
  >;
  CiscoAnnuityContact: ResolverTypeWrapper<Partial<CiscoAnnuityContact>>;
  CiscoAnnuityContactInput: ResolverTypeWrapper<
    Partial<CiscoAnnuityContactInput>
  >;
  CiscoAnnuityContactType: ResolverTypeWrapper<
    Partial<CiscoAnnuityContactType>
  >;
  CiscoAnnuityInput: ResolverTypeWrapper<Partial<CiscoAnnuityInput>>;
  CiscoAnnuityLine: ResolverTypeWrapper<Partial<CiscoAnnuityLine>>;
  CiscoAnnuityLineInput: ResolverTypeWrapper<Partial<CiscoAnnuityLineInput>>;
  CiscoAnnuityLineTerm: ResolverTypeWrapper<Partial<CiscoAnnuityLineTerm>>;
  CiscoAnnuityLineTermInput: ResolverTypeWrapper<
    Partial<CiscoAnnuityLineTermInput>
  >;
  CiscoAnnuityPartNumber: ResolverTypeWrapper<Partial<CiscoAnnuityPartNumber>>;
  CiscoAnnuityPartNumberInput: ResolverTypeWrapper<
    Partial<CiscoAnnuityPartNumberInput>
  >;
  CiscoAnnuityPaymentSchedule: ResolverTypeWrapper<
    Partial<CiscoAnnuityPaymentSchedule>
  >;
  CiscoAnnuityQuoteResponse: ResolverTypeWrapper<
    Partial<CiscoAnnuityQuoteResponse>
  >;
  CiscoAnnuityQuoteSummary: ResolverTypeWrapper<
    Partial<CiscoAnnuityQuoteSummary>
  >;
  CiscoAnnuityQuoteSummaryInput: ResolverTypeWrapper<
    Partial<CiscoAnnuityQuoteSummaryInput>
  >;
  CiscoAnnuityServiceFeeSchedule: ResolverTypeWrapper<
    Partial<CiscoAnnuityServiceFeeSchedule>
  >;
  CiscoAnnuityStatusHistory: ResolverTypeWrapper<
    Partial<CiscoAnnuityStatusHistory>
  >;
  CiscoApiResult: ResolverTypeWrapper<Partial<CiscoApiResult>>;
  CiscoCcwToken: ResolverTypeWrapper<Partial<CiscoCcwToken>>;
  CiscoEATrueForward: ResolverTypeWrapper<Partial<CiscoEaTrueForward>>;
  CiscoOrder: ResolverTypeWrapper<Partial<CiscoOrder>>;
  CiscoOrderApiResult: ResolverTypeWrapper<Partial<CiscoOrderApiResult>>;
  CiscoOrderBatchHistory: ResolverTypeWrapper<Partial<CiscoOrderBatchHistory>>;
  CiscoOrderLine: ResolverTypeWrapper<Partial<CiscoOrderLine>>;
  CiscoOrderSearch: ResolverTypeWrapper<Partial<CiscoOrderSearch>>;
  CiscoOrdersWorkList: ResolverTypeWrapper<Partial<CiscoOrdersWorkList>>;
  CiscoQuote: ResolverTypeWrapper<Partial<CiscoQuote>>;
  CiscoQuoteInput: ResolverTypeWrapper<Partial<CiscoQuoteInput>>;
  CiscoSmartAccount: ResolverTypeWrapper<Partial<CiscoSmartAccount>>;
  CiscoSmartAccountInput: ResolverTypeWrapper<Partial<CiscoSmartAccountInput>>;
  CiscoSubscription: ResolverTypeWrapper<Partial<CiscoSubscription>>;
  CiscoSubscriptionBatchHistory: ResolverTypeWrapper<
    Partial<CiscoSubscriptionBatchHistory>
  >;
  CiscoSubscriptionConsumptionSku: ResolverTypeWrapper<
    Partial<CiscoSubscriptionConsumptionSku>
  >;
  CiscoSubscriptionDetailsWorkList: ResolverTypeWrapper<
    Partial<CiscoSubscriptionDetailsWorkList>
  >;
  CiscoSubscriptionHistory: ResolverTypeWrapper<
    Partial<CiscoSubscriptionHistory>
  >;
  CiscoSubscriptionHistoryWorkList: ResolverTypeWrapper<
    Partial<CiscoSubscriptionHistoryWorkList>
  >;
  CiscoSubscriptionLine: ResolverTypeWrapper<Partial<CiscoSubscriptionLine>>;
  CiscoVirtualAccount: ResolverTypeWrapper<Partial<CiscoVirtualAccount>>;
  CiscoVirtualAccountInput: ResolverTypeWrapper<
    Partial<CiscoVirtualAccountInput>
  >;
  Collection: ResolverTypeWrapper<Partial<Collection>>;
  ContentRequest: ResolverTypeWrapper<Partial<ContentRequest>>;
  ContentRequestInput: ResolverTypeWrapper<Partial<ContentRequestInput>>;
  CustomService: ResolverTypeWrapper<Partial<CustomService>>;
  Customer: ResolverTypeWrapper<Partial<Customer>>;
  CustomerInput: ResolverTypeWrapper<Partial<CustomerInput>>;
  CustomerType: ResolverTypeWrapper<Partial<CustomerType>>;
  DATE: ResolverTypeWrapper<Partial<Scalars['DATE']['output']>>;
  DataCollectionItem: ResolverTypeWrapper<Partial<DataCollectionItem>>;
  DataCollectionItemInput: ResolverTypeWrapper<
    Partial<DataCollectionItemInput>
  >;
  DatabaseActions: ResolverTypeWrapper<Partial<DatabaseActions>>;
  DefaultAddResponse: ResolverTypeWrapper<Partial<DefaultAddResponse>>;
  DefaultMutationResponse: ResolverTypeWrapper<
    Partial<DefaultMutationResponse>
  >;
  DefaultMutationResponseWithID: ResolverTypeWrapper<
    Partial<DefaultMutationResponseWithId>
  >;
  EmailTemplates: ResolverTypeWrapper<Partial<EmailTemplates>>;
  EmailTemplatesInput: ResolverTypeWrapper<Partial<EmailTemplatesInput>>;
  EnrollmentSummary: ResolverTypeWrapper<Partial<EnrollmentSummary>>;
  EstimatorSowInput: ResolverTypeWrapper<Partial<EstimatorSowInput>>;
  EstimatorTotals: ResolverTypeWrapper<Partial<EstimatorTotals>>;
  FBUser: ResolverTypeWrapper<Partial<FbUser>>;
  FBUserMetaData: ResolverTypeWrapper<Partial<FbUserMetaData>>;
  FBUserMetaDataInput: ResolverTypeWrapper<Partial<FbUserMetaDataInput>>;
  FixedFeeMilestone: ResolverTypeWrapper<Partial<FixedFeeMilestone>>;
  FixedFeeMilestoneInput: ResolverTypeWrapper<Partial<FixedFeeMilestoneInput>>;
  GainsightSubscriptionsView: ResolverTypeWrapper<
    Partial<GainsightSubscriptionsView>
  >;
  GetAdminConfigSettingsResponse: ResolverTypeWrapper<
    Partial<GetAdminConfigSettingsResponse>
  >;
  GetAgiloftExpenseTypesResponse: ResolverTypeWrapper<
    Partial<GetAgiloftExpenseTypesResponse>
  >;
  GetAgiloftRolesResponse: ResolverTypeWrapper<
    Partial<GetAgiloftRolesResponse>
  >;
  GetAgiloftTravelNoticeTypesResponse: ResolverTypeWrapper<
    Partial<GetAgiloftTravelNoticeTypesResponse>
  >;
  GetCdwRates: ResolverTypeWrapper<Partial<GetCdwRates>>;
  GetCdwRatesResponse: ResolverTypeWrapper<Partial<GetCdwRatesResponse>>;
  GetCiscoAnnuityPartNumbersResponse: ResolverTypeWrapper<
    Partial<GetCiscoAnnuityPartNumbersResponse>
  >;
  GetCiscoCcwEstimateByIdResponse: ResolverTypeWrapper<
    Partial<GetCiscoCcwEstimateByIdResponse>
  >;
  GetCiscoCcwQuoteByDealIdResponse: ResolverTypeWrapper<
    Partial<GetCiscoCcwQuoteByDealIdResponse>
  >;
  GetCollectionsResponse: ResolverTypeWrapper<Partial<GetCollectionsResponse>>;
  GetCustomersByOpportunityResponse: ResolverTypeWrapper<
    Partial<GetCustomersByOpportunityResponse>
  >;
  GetCustomersResponse: ResolverTypeWrapper<Partial<GetCustomersResponse>>;
  GetEmailTemplatesResponse: ResolverTypeWrapper<
    Partial<GetEmailTemplatesResponse>
  >;
  GetEstimateToPSAResponse: ResolverTypeWrapper<
    Partial<GetEstimateToPsaResponse>
  >;
  GetOpportunityResponse: ResolverTypeWrapper<Partial<GetOpportunityResponse>>;
  GetPhasesResponse: ResolverTypeWrapper<Partial<GetPhasesResponse>>;
  GetProjectItemEstimatorRatesResponse: ResolverTypeWrapper<
    Partial<GetProjectItemEstimatorRatesResponse>
  >;
  GetProjectRatesResponse: ResolverTypeWrapper<
    Partial<GetProjectRatesResponse>
  >;
  GetProjectUsersResponse: ResolverTypeWrapper<
    Partial<GetProjectUsersResponse>
  >;
  GetProjectsResponse: ResolverTypeWrapper<Partial<GetProjectsResponse>>;
  GetProposalTemplatesResponse: ResolverTypeWrapper<
    Partial<GetProposalTemplatesResponse>
  >;
  GetRolesAndRatesResponse: ResolverTypeWrapper<
    Partial<GetRolesAndRatesResponse>
  >;
  GetRolesResponse: ResolverTypeWrapper<Partial<GetRolesResponse>>;
  GetSalesHistoryResponse: ResolverTypeWrapper<
    Partial<GetSalesHistoryResponse>
  >;
  GetSalesRepsTrendingResponse: ResolverTypeWrapper<
    Partial<GetSalesRepsTrendingResponse>
  >;
  GetScopeGroupsResponse: ResolverTypeWrapper<Partial<GetScopeGroupsResponse>>;
  GetScopeItemsResponse: ResolverTypeWrapper<Partial<GetScopeItemsResponse>>;
  GetServiceItemsResponse: ResolverTypeWrapper<
    Partial<GetServiceItemsResponse>
  >;
  GetServiceSkillsResponse: ResolverTypeWrapper<
    Partial<GetServiceSkillsResponse>
  >;
  GetServiceSuppliersResponse: ResolverTypeWrapper<
    Partial<GetServiceSuppliersResponse>
  >;
  GetSowSectionsResponse: ResolverTypeWrapper<Partial<GetSowSectionsResponse>>;
  GetTaskGroupSowResponse: ResolverTypeWrapper<
    Partial<GetTaskGroupSowResponse>
  >;
  GetTaskGroupsResponse: ResolverTypeWrapper<Partial<GetTaskGroupsResponse>>;
  GetTasksResponse: ResolverTypeWrapper<Partial<GetTasksResponse>>;
  GetTiersResponse: ResolverTypeWrapper<Partial<GetTiersResponse>>;
  GetTotalCustomersResponse: ResolverTypeWrapper<
    Partial<GetTotalCustomersResponse>
  >;
  GetTotalSalesResponse: ResolverTypeWrapper<Partial<GetTotalSalesResponse>>;
  GetUsersResponse: ResolverTypeWrapper<Partial<GetUsersResponse>>;
  HTML: ResolverTypeWrapper<Partial<Scalars['HTML']['output']>>;
  HandledMutationResponse: ResolverTypeWrapper<
    Partial<HandledMutationResponse>
  >;
  IntOrString: ResolverTypeWrapper<Partial<Scalars['IntOrString']['output']>>;
  InvestmentSummary: ResolverTypeWrapper<Partial<InvestmentSummary>>;
  ItemsProvidedToCustomers: ResolverTypeWrapper<
    Partial<ItemsProvidedToCustomers>
  >;
  ItemsProvidedToCustomersInput: ResolverTypeWrapper<
    Partial<ItemsProvidedToCustomersInput>
  >;
  JSON: ResolverTypeWrapper<Partial<Scalars['JSON']['output']>>;
  Lead: ResolverTypeWrapper<Partial<Lead>>;
  LeadInput: ResolverTypeWrapper<Partial<LeadInput>>;
  LineSet: ResolverTypeWrapper<Partial<LineSet>>;
  ManagedServicesRenewalResponse: ResolverTypeWrapper<
    Partial<ManagedServicesRenewalResponse>
  >;
  ManagedServicesSummaryResponse: ResolverTypeWrapper<
    Partial<ManagedServicesSummaryResponse>
  >;
  MansEnrollmentSummaryResponse: ResolverTypeWrapper<
    Partial<MansEnrollmentSummaryResponse>
  >;
  MongoFilter: ResolverTypeWrapper<Partial<Scalars['MongoFilter']['output']>>;
  Mutation: ResolverTypeWrapper<{}>;
  Opportunity: ResolverTypeWrapper<Partial<Opportunity>>;
  OpportunityStatusEnum: ResolverTypeWrapper<Partial<OpportunityStatusEnum>>;
  OpportunityTable: ResolverTypeWrapper<Partial<OpportunityTable>>;
  Organization: ResolverTypeWrapper<Partial<Organization>>;
  Phase: ResolverTypeWrapper<Partial<Phase>>;
  PhaseBreakdown: ResolverTypeWrapper<Partial<PhaseBreakdown>>;
  Practice: ResolverTypeWrapper<Partial<Practice>>;
  PracticeInput: ResolverTypeWrapper<Partial<PracticeInput>>;
  ProjectComment: ResolverTypeWrapper<Partial<ProjectComment>>;
  ProjectCommentInput: ResolverTypeWrapper<Partial<ProjectCommentInput>>;
  ProjectCustomTaskPhase: ResolverTypeWrapper<Partial<ProjectCustomTaskPhase>>;
  ProjectEstimates: ResolverTypeWrapper<Partial<ProjectEstimates>>;
  ProjectEstimatesInput: ResolverTypeWrapper<Partial<ProjectEstimatesInput>>;
  ProjectInput: ResolverTypeWrapper<Partial<ProjectInput>>;
  ProjectItem: ResolverTypeWrapper<Partial<ProjectItem>>;
  ProjectItemEstimators: ResolverTypeWrapper<Partial<ProjectItemEstimators>>;
  ProjectItemEstimatorsInput: ResolverTypeWrapper<
    Partial<ProjectItemEstimatorsInput>
  >;
  ProjectItemInput: ResolverTypeWrapper<Partial<ProjectItemInput>>;
  ProjectItemQuote: ResolverTypeWrapper<Partial<ProjectItemQuote>>;
  ProjectItemQuoteGlobalSettings: ResolverTypeWrapper<
    Partial<ProjectItemQuoteGlobalSettings>
  >;
  ProjectItemQuoteGlobalSettingsInput: ResolverTypeWrapper<
    Partial<ProjectItemQuoteGlobalSettingsInput>
  >;
  ProjectItemQuoteInput: ResolverTypeWrapper<Partial<ProjectItemQuoteInput>>;
  ProjectItemQuoteLine: ResolverTypeWrapper<Partial<ProjectItemQuoteLine>>;
  ProjectItemQuoteLineInput: ResolverTypeWrapper<
    Partial<ProjectItemQuoteLineInput>
  >;
  ProjectItemQuoteSettings: ResolverTypeWrapper<
    Partial<ProjectItemQuoteSettings>
  >;
  ProjectItemQuoteSettingsInput: ResolverTypeWrapper<
    Partial<ProjectItemQuoteSettingsInput>
  >;
  ProjectItemQuoteSystem: ResolverTypeWrapper<Partial<ProjectItemQuoteSystem>>;
  ProjectItemQuoteSystemInput: ResolverTypeWrapper<
    Partial<ProjectItemQuoteSystemInput>
  >;
  ProjectPracticeAndRoles: ResolverTypeWrapper<
    Partial<ProjectPracticeAndRoles>
  >;
  ProjectProposal: ResolverTypeWrapper<Partial<ProjectProposal>>;
  ProjectProposalInput: ResolverTypeWrapper<Partial<ProjectProposalInput>>;
  ProjectSow: ResolverTypeWrapper<Partial<ProjectSow>>;
  ProjectSowInput: ResolverTypeWrapper<Partial<ProjectSowInput>>;
  ProjectStatusHistory: ResolverTypeWrapper<Partial<ProjectStatusHistory>>;
  ProjectTeam: ResolverTypeWrapper<Partial<ProjectTeam>>;
  ProjectTeamInput: ResolverTypeWrapper<Partial<ProjectTeamInput>>;
  ProjectUser: ResolverTypeWrapper<Partial<ProjectUser>>;
  ProposalTemplate: ResolverTypeWrapper<Partial<ProposalTemplate>>;
  ProposalTemplateInput: ResolverTypeWrapper<Partial<ProposalTemplateInput>>;
  PsaSyncHistory: ResolverTypeWrapper<Partial<PsaSyncHistory>>;
  PsaSyncHistoryInput: ResolverTypeWrapper<Partial<PsaSyncHistoryInput>>;
  Query: ResolverTypeWrapper<{}>;
  Question: ResolverTypeWrapper<Partial<Question>>;
  QuestionInput: ResolverTypeWrapper<Partial<QuestionInput>>;
  QuestionOption: ResolverTypeWrapper<Partial<QuestionOption>>;
  QuestionOptionInput: ResolverTypeWrapper<Partial<QuestionOptionInput>>;
  RMSProposal: ResolverTypeWrapper<Partial<RmsProposal>>;
  RMSProposalInput: ResolverTypeWrapper<Partial<RmsProposalInput>>;
  RmsGPAnalysis: ResolverTypeWrapper<Partial<RmsGpAnalysis>>;
  RmsGPAnalysisTableData: ResolverTypeWrapper<Partial<RmsGpAnalysisTableData>>;
  RmsGPAnalysisTableDataLineItems: ResolverTypeWrapper<
    Partial<RmsGpAnalysisTableDataLineItems>
  >;
  RmsGPAnalysisTableSites: ResolverTypeWrapper<
    Partial<RmsGpAnalysisTableSites>
  >;
  RmsGPTaskUpdate: ResolverTypeWrapper<Partial<RmsGpTaskUpdate>>;
  RmsGPTaskUpdateInput: ResolverTypeWrapper<Partial<RmsGpTaskUpdateInput>>;
  RmsOneTimeCost: ResolverTypeWrapper<Partial<RmsOneTimeCost>>;
  RmsProposalPricing: ResolverTypeWrapper<Partial<RmsProposalPricing>>;
  RmsProposalPricingTier: ResolverTypeWrapper<Partial<RmsProposalPricingTier>>;
  RmsProposalTemplateStandardLines: ResolverTypeWrapper<
    Partial<RmsProposalTemplateStandardLines>
  >;
  RmsProposalTemplateStandardSites: ResolverTypeWrapper<
    Partial<RmsProposalTemplateStandardSites>
  >;
  RmsProposalTemplateTier: ResolverTypeWrapper<
    Partial<RmsProposalTemplateTier>
  >;
  RmsProposalTemplateTierLines: ResolverTypeWrapper<
    Partial<RmsProposalTemplateTierLines>
  >;
  RmsProposalTemplateTierSites: ResolverTypeWrapper<
    Partial<RmsProposalTemplateTierSites>
  >;
  Role: ResolverTypeWrapper<Partial<Role>>;
  RoleAndRate: ResolverTypeWrapper<Partial<RoleAndRate>>;
  RoleAndRateInput: ResolverTypeWrapper<Partial<RoleAndRateInput>>;
  RoleHistory: ResolverTypeWrapper<Partial<RoleHistory>>;
  RoleRequest: ResolverTypeWrapper<Partial<RoleRequest>>;
  RoleTeam: ResolverTypeWrapper<Partial<RoleTeam>>;
  RoleTeamInput: ResolverTypeWrapper<Partial<RoleTeamInput>>;
  SFDCEstimate: ResolverTypeWrapper<Partial<SfdcEstimate>>;
  SFDCEstimateProduct: ResolverTypeWrapper<Partial<SfdcEstimateProduct>>;
  SFDCOpportunity: ResolverTypeWrapper<Partial<SfdcOpportunity>>;
  SFDCSkillRequest: ResolverTypeWrapper<Partial<SfdcSkillRequest>>;
  SalesHistory: ResolverTypeWrapper<Partial<SalesHistory>>;
  SalesRepTrending: ResolverTypeWrapper<Partial<SalesRepTrending>>;
  SalesforceDataInput: ResolverTypeWrapper<Partial<SalesforceDataInput>>;
  SaveEstimateToPsaResponse: ResolverTypeWrapper<
    Partial<SaveEstimateToPsaResponse>
  >;
  ScopeGroup: ResolverTypeWrapper<Partial<ScopeGroup>>;
  ScopeGroupInput: ResolverTypeWrapper<Partial<ScopeGroupInput>>;
  ScopeItem: ResolverTypeWrapper<Partial<ScopeItem>>;
  ScopeItemInput: ResolverTypeWrapper<Partial<ScopeItemInput>>;
  ScopingSession: ResolverTypeWrapper<Partial<ScopingSession>>;
  ScopingSessionInput: ResolverTypeWrapper<Partial<ScopingSessionInput>>;
  ScopingSessionSection: ResolverTypeWrapper<Partial<ScopingSessionSection>>;
  ScopingSessionSectionInput: ResolverTypeWrapper<
    Partial<ScopingSessionSectionInput>
  >;
  SearchCustomersResponse: ResolverTypeWrapper<
    Partial<SearchCustomersResponse>
  >;
  SearchServiceItemsResponse: ResolverTypeWrapper<
    Partial<SearchServiceItemsResponse>
  >;
  SearchServiceSkillsResponse: ResolverTypeWrapper<
    Partial<SearchServiceSkillsResponse>
  >;
  SearchServiceSuppliersResponse: ResolverTypeWrapper<
    Partial<SearchServiceSuppliersResponse>
  >;
  SearchSowSectionsResponse: ResolverTypeWrapper<
    Partial<SearchSowSectionsResponse>
  >;
  SearchTaskGroupsResponse: ResolverTypeWrapper<
    Partial<SearchTaskGroupsResponse>
  >;
  SearchUsersResponse: ResolverTypeWrapper<Partial<SearchUsersResponse>>;
  ServiceCategory: ResolverTypeWrapper<Partial<ServiceCategory>>;
  ServiceItem: ResolverTypeWrapper<Partial<ServiceItem>>;
  ServiceLocation: ResolverTypeWrapper<Partial<ServiceLocation>>;
  ServiceLocationRole: ResolverTypeWrapper<Partial<ServiceLocationRole>>;
  ServicePractice: ResolverTypeWrapper<Partial<ServicePractice>>;
  ServiceSkill: ResolverTypeWrapper<Partial<ServiceSkill>>;
  ServiceSummary: ResolverTypeWrapper<Partial<ServiceSummary>>;
  ServiceSummaryResponse: ResolverTypeWrapper<Partial<ServiceSummaryResponse>>;
  ServiceSupplier: ResolverTypeWrapper<Partial<ServiceSupplier>>;
  SessionQuestion: ResolverTypeWrapper<Partial<SessionQuestion>>;
  SessionQuestionInput: ResolverTypeWrapper<Partial<SessionQuestionInput>>;
  SfdcAccount: ResolverTypeWrapper<Partial<SfdcAccount>>;
  SfdcRegion: ResolverTypeWrapper<Partial<SfdcRegion>>;
  Site: ResolverTypeWrapper<Partial<Site>>;
  SiteBreakdown: ResolverTypeWrapper<Partial<SiteBreakdown>>;
  SiteInfoInput: ResolverTypeWrapper<Partial<SiteInfoInput>>;
  SiteInput: ResolverTypeWrapper<Partial<SiteInput>>;
  SiteTask: ResolverTypeWrapper<Partial<SiteTask>>;
  SiteWithTasks: ResolverTypeWrapper<Partial<SiteWithTasks>>;
  SkillSummary: ResolverTypeWrapper<Partial<SkillSummary>>;
  SkillSummaryRow: ResolverTypeWrapper<Partial<SkillSummaryRow>>;
  SowSection: ResolverTypeWrapper<Partial<SowSection>>;
  SowSectionInput: ResolverTypeWrapper<Partial<SowSectionInput>>;
  SowSectionTeam: ResolverTypeWrapper<Partial<SowSectionTeam>>;
  SowSectionTeamInput: ResolverTypeWrapper<Partial<SowSectionTeamInput>>;
  SowTaskGroup: ResolverTypeWrapper<Partial<SowTaskGroup>>;
  SprintSummary: ResolverTypeWrapper<Partial<SprintSummary>>;
  SprintSummaryRow: ResolverTypeWrapper<Partial<SprintSummaryRow>>;
  StringOrArray: ResolverTypeWrapper<
    Partial<Scalars['StringOrArray']['output']>
  >;
  Task: ResolverTypeWrapper<Partial<Task>>;
  TaskGroup: ResolverTypeWrapper<Partial<TaskGroup>>;
  TaskGroupTeam: ResolverTypeWrapper<Partial<TaskGroupTeam>>;
  TaskGroupTeamInput: ResolverTypeWrapper<Partial<TaskGroupTeamInput>>;
  TaskGroupsPrintOptions: ResolverTypeWrapper<Partial<TaskGroupsPrintOptions>>;
  TaskHasGroup: ResolverTypeWrapper<Partial<TaskHasGroup>>;
  TaskInput: ResolverTypeWrapper<Partial<TaskInput>>;
  TaskList: ResolverTypeWrapper<Partial<TaskList>>;
  TaskListInput: ResolverTypeWrapper<Partial<TaskListInput>>;
  TaskNote: ResolverTypeWrapper<Partial<TaskNote>>;
  TaskNoteInput: ResolverTypeWrapper<Partial<TaskNoteInput>>;
  TaskOrder: ResolverTypeWrapper<Partial<TaskOrder>>;
  TaskPhase: ResolverTypeWrapper<Partial<TaskPhase>>;
  TaskPhaseInput: ResolverTypeWrapper<Partial<TaskPhaseInput>>;
  Tier: ResolverTypeWrapper<Partial<Tier>>;
  TrainingOrCollateral: ResolverTypeWrapper<Partial<TrainingOrCollateral>>;
  TrainingOrCollateralInput: ResolverTypeWrapper<
    Partial<TrainingOrCollateralInput>
  >;
  TravelExpenseSummaryResponse: ResolverTypeWrapper<
    Partial<TravelExpenseSummaryResponse>
  >;
  UpdateLeadInput: ResolverTypeWrapper<Partial<UpdateLeadInput>>;
  UpdateLeadResponse: ResolverTypeWrapper<Partial<UpdateLeadResponse>>;
  UpdateProjectInput: ResolverTypeWrapper<Partial<UpdateProjectInput>>;
  UpdateProjectResponse: ResolverTypeWrapper<Partial<UpdateProjectResponse>>;
  UpdateProjectStatusInput: ResolverTypeWrapper<
    Partial<UpdateProjectStatusInput>
  >;
  UpdatedTaskMutationResponse: ResolverTypeWrapper<
    Partial<UpdatedTaskMutationResponse>
  >;
  User: ResolverTypeWrapper<Partial<User>>;
  UserCiscoCcwToken: ResolverTypeWrapper<Partial<UserCiscoCcwToken>>;
  UserInput: ResolverTypeWrapper<Partial<UserInput>>;
  VolumePricing: ResolverTypeWrapper<Partial<VolumePricing>>;
  VolumePricingInput: ResolverTypeWrapper<Partial<VolumePricingInput>>;
  ZuoraRateChange: ResolverTypeWrapper<Partial<ZuoraRateChange>>;
  ZuoraRatePlan: ResolverTypeWrapper<Partial<ZuoraRatePlan>>;
  ZuoraSubscription: ResolverTypeWrapper<Partial<ZuoraSubscription>>;
  ZuoraTier: ResolverTypeWrapper<Partial<ZuoraTier>>;
  addProjectUserInput: ResolverTypeWrapper<Partial<AddProjectUserInput>>;
  estimateToPSAInputs: ResolverTypeWrapper<Partial<EstimateToPsaInputs>>;
  getPracticesResponse: ResolverTypeWrapper<Partial<GetPracticesResponse>>;
  getServiceCategoriesResponse: ResolverTypeWrapper<
    Partial<GetServiceCategoriesResponse>
  >;
  getServiceLocationsResponse: ResolverTypeWrapper<
    Partial<GetServiceLocationsResponse>
  >;
  getServiceLocationsRolesResponse: ResolverTypeWrapper<
    Partial<GetServiceLocationsRolesResponse>
  >;
  getServicePracticesResponse: ResolverTypeWrapper<
    Partial<GetServicePracticesResponse>
  >;
  wrapperEstimateToPSAInputs: ResolverTypeWrapper<
    Partial<WrapperEstimateToPsaInputs>
  >;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  ANY: Partial<Scalars['ANY']['output']>;
  ActiveSiteTask: Partial<ActiveSiteTask>;
  String: Partial<Scalars['String']['output']>;
  Float: Partial<Scalars['Float']['output']>;
  Int: Partial<Scalars['Int']['output']>;
  Boolean: Partial<Scalars['Boolean']['output']>;
  ActiveSiteWithTasks: Partial<ActiveSiteWithTasks>;
  AddEstimatorTasksByTaskGroupResponse: Partial<AddEstimatorTasksByTaskGroupResponse>;
  ID: Partial<Scalars['ID']['output']>;
  AddProjectProposalInput: Partial<AddProjectProposalInput>;
  AddProjectUserResponse: Partial<AddProjectUserResponse>;
  AdminConfigSettings: Partial<AdminConfigSettings>;
  AdminConfigSettingsInput: Partial<AdminConfigSettingsInput>;
  AdminPractice: Partial<AdminPractice>;
  AdminPracticeInput: Partial<AdminPracticeInput>;
  AdminRole: Partial<AdminRole>;
  AdminRoleInput: Partial<AdminRoleInput>;
  AdminTaskGroup: Partial<AdminTaskGroup>;
  AdminTaskGroupInput: Partial<AdminTaskGroupInput>;
  AdminTierInput: Partial<AdminTierInput>;
  AdminUser: Partial<AdminUser>;
  AgiloftAPILog: Partial<AgiloftApiLog>;
  AgiloftApiResponse: Partial<AgiloftApiResponse>;
  AgiloftExpenseType: Partial<AgiloftExpenseType>;
  AgiloftExpenseTypeInput: Partial<AgiloftExpenseTypeInput>;
  AgiloftRole: Partial<AgiloftRole>;
  AgiloftSOW: Partial<AgiloftSow>;
  AgiloftSowConnectionInput: Partial<AgiloftSowConnectionInput>;
  AgiloftToken: Partial<AgiloftToken>;
  AgiloftTravelNoticeType: Partial<AgiloftTravelNoticeType>;
  AgiloftTravelNoticeTypeInput: Partial<AgiloftTravelNoticeTypeInput>;
  BidAssuranceNote: Partial<BidAssuranceNote>;
  BidAssuranceNoteInput: Partial<BidAssuranceNoteInput>;
  BidAssuranceStatusHistory: Partial<BidAssuranceStatusHistory>;
  BidAssuranceStatusHistoryInput: Partial<BidAssuranceStatusHistoryInput>;
  Category: Partial<Category>;
  CategoryInput: Partial<CategoryInput>;
  CdwOpenOrdersWorkList: Partial<CdwOpenOrdersWorkList>;
  CdwRate: Partial<CdwRate>;
  CdwRateInput: Partial<CdwRateInput>;
  CiscoAnnuity: Partial<CiscoAnnuity>;
  CiscoAnnuityCategory: Partial<CiscoAnnuityCategory>;
  CiscoAnnuityCategoryInput: Partial<CiscoAnnuityCategoryInput>;
  CiscoAnnuityComment: Partial<CiscoAnnuityComment>;
  CiscoAnnuityCommentInput: Partial<CiscoAnnuityCommentInput>;
  CiscoAnnuityContact: Partial<CiscoAnnuityContact>;
  CiscoAnnuityContactInput: Partial<CiscoAnnuityContactInput>;
  CiscoAnnuityInput: Partial<CiscoAnnuityInput>;
  CiscoAnnuityLine: Partial<CiscoAnnuityLine>;
  CiscoAnnuityLineInput: Partial<CiscoAnnuityLineInput>;
  CiscoAnnuityLineTerm: Partial<CiscoAnnuityLineTerm>;
  CiscoAnnuityLineTermInput: Partial<CiscoAnnuityLineTermInput>;
  CiscoAnnuityPartNumber: Partial<CiscoAnnuityPartNumber>;
  CiscoAnnuityPartNumberInput: Partial<CiscoAnnuityPartNumberInput>;
  CiscoAnnuityPaymentSchedule: Partial<CiscoAnnuityPaymentSchedule>;
  CiscoAnnuityQuoteResponse: Partial<CiscoAnnuityQuoteResponse>;
  CiscoAnnuityQuoteSummary: Partial<CiscoAnnuityQuoteSummary>;
  CiscoAnnuityQuoteSummaryInput: Partial<CiscoAnnuityQuoteSummaryInput>;
  CiscoAnnuityServiceFeeSchedule: Partial<CiscoAnnuityServiceFeeSchedule>;
  CiscoAnnuityStatusHistory: Partial<CiscoAnnuityStatusHistory>;
  CiscoApiResult: Partial<CiscoApiResult>;
  CiscoCcwToken: Partial<CiscoCcwToken>;
  CiscoEATrueForward: Partial<CiscoEaTrueForward>;
  CiscoOrder: Partial<CiscoOrder>;
  CiscoOrderApiResult: Partial<CiscoOrderApiResult>;
  CiscoOrderBatchHistory: Partial<CiscoOrderBatchHistory>;
  CiscoOrderLine: Partial<CiscoOrderLine>;
  CiscoOrderSearch: Partial<CiscoOrderSearch>;
  CiscoOrdersWorkList: Partial<CiscoOrdersWorkList>;
  CiscoQuote: Partial<CiscoQuote>;
  CiscoQuoteInput: Partial<CiscoQuoteInput>;
  CiscoSmartAccount: Partial<CiscoSmartAccount>;
  CiscoSmartAccountInput: Partial<CiscoSmartAccountInput>;
  CiscoSubscription: Partial<CiscoSubscription>;
  CiscoSubscriptionBatchHistory: Partial<CiscoSubscriptionBatchHistory>;
  CiscoSubscriptionConsumptionSku: Partial<CiscoSubscriptionConsumptionSku>;
  CiscoSubscriptionDetailsWorkList: Partial<CiscoSubscriptionDetailsWorkList>;
  CiscoSubscriptionHistory: Partial<CiscoSubscriptionHistory>;
  CiscoSubscriptionHistoryWorkList: Partial<CiscoSubscriptionHistoryWorkList>;
  CiscoSubscriptionLine: Partial<CiscoSubscriptionLine>;
  CiscoVirtualAccount: Partial<CiscoVirtualAccount>;
  CiscoVirtualAccountInput: Partial<CiscoVirtualAccountInput>;
  Collection: Partial<Collection>;
  ContentRequest: Partial<ContentRequest>;
  ContentRequestInput: Partial<ContentRequestInput>;
  CustomService: Partial<CustomService>;
  Customer: Partial<Customer>;
  CustomerInput: Partial<CustomerInput>;
  DATE: Partial<Scalars['DATE']['output']>;
  DataCollectionItem: Partial<DataCollectionItem>;
  DataCollectionItemInput: Partial<DataCollectionItemInput>;
  DefaultAddResponse: Partial<DefaultAddResponse>;
  DefaultMutationResponse: Partial<DefaultMutationResponse>;
  DefaultMutationResponseWithID: Partial<DefaultMutationResponseWithId>;
  EmailTemplates: Partial<EmailTemplates>;
  EmailTemplatesInput: Partial<EmailTemplatesInput>;
  EnrollmentSummary: Partial<EnrollmentSummary>;
  EstimatorSowInput: Partial<EstimatorSowInput>;
  EstimatorTotals: Partial<EstimatorTotals>;
  FBUser: Partial<FbUser>;
  FBUserMetaData: Partial<FbUserMetaData>;
  FBUserMetaDataInput: Partial<FbUserMetaDataInput>;
  FixedFeeMilestone: Partial<FixedFeeMilestone>;
  FixedFeeMilestoneInput: Partial<FixedFeeMilestoneInput>;
  GainsightSubscriptionsView: Partial<GainsightSubscriptionsView>;
  GetAdminConfigSettingsResponse: Partial<GetAdminConfigSettingsResponse>;
  GetAgiloftExpenseTypesResponse: Partial<GetAgiloftExpenseTypesResponse>;
  GetAgiloftRolesResponse: Partial<GetAgiloftRolesResponse>;
  GetAgiloftTravelNoticeTypesResponse: Partial<GetAgiloftTravelNoticeTypesResponse>;
  GetCdwRates: Partial<GetCdwRates>;
  GetCdwRatesResponse: Partial<GetCdwRatesResponse>;
  GetCiscoAnnuityPartNumbersResponse: Partial<GetCiscoAnnuityPartNumbersResponse>;
  GetCiscoCcwEstimateByIdResponse: Partial<GetCiscoCcwEstimateByIdResponse>;
  GetCiscoCcwQuoteByDealIdResponse: Partial<GetCiscoCcwQuoteByDealIdResponse>;
  GetCollectionsResponse: Partial<GetCollectionsResponse>;
  GetCustomersByOpportunityResponse: Partial<GetCustomersByOpportunityResponse>;
  GetCustomersResponse: Partial<GetCustomersResponse>;
  GetEmailTemplatesResponse: Partial<GetEmailTemplatesResponse>;
  GetEstimateToPSAResponse: Partial<GetEstimateToPsaResponse>;
  GetOpportunityResponse: Partial<GetOpportunityResponse>;
  GetPhasesResponse: Partial<GetPhasesResponse>;
  GetProjectItemEstimatorRatesResponse: Partial<GetProjectItemEstimatorRatesResponse>;
  GetProjectRatesResponse: Partial<GetProjectRatesResponse>;
  GetProjectUsersResponse: Partial<GetProjectUsersResponse>;
  GetProjectsResponse: Partial<GetProjectsResponse>;
  GetProposalTemplatesResponse: Partial<GetProposalTemplatesResponse>;
  GetRolesAndRatesResponse: Partial<GetRolesAndRatesResponse>;
  GetRolesResponse: Partial<GetRolesResponse>;
  GetSalesHistoryResponse: Partial<GetSalesHistoryResponse>;
  GetSalesRepsTrendingResponse: Partial<GetSalesRepsTrendingResponse>;
  GetScopeGroupsResponse: Partial<GetScopeGroupsResponse>;
  GetScopeItemsResponse: Partial<GetScopeItemsResponse>;
  GetServiceItemsResponse: Partial<GetServiceItemsResponse>;
  GetServiceSkillsResponse: Partial<GetServiceSkillsResponse>;
  GetServiceSuppliersResponse: Partial<GetServiceSuppliersResponse>;
  GetSowSectionsResponse: Partial<GetSowSectionsResponse>;
  GetTaskGroupSowResponse: Partial<GetTaskGroupSowResponse>;
  GetTaskGroupsResponse: Partial<GetTaskGroupsResponse>;
  GetTasksResponse: Partial<GetTasksResponse>;
  GetTiersResponse: Partial<GetTiersResponse>;
  GetTotalCustomersResponse: Partial<GetTotalCustomersResponse>;
  GetTotalSalesResponse: Partial<GetTotalSalesResponse>;
  GetUsersResponse: Partial<GetUsersResponse>;
  HTML: Partial<Scalars['HTML']['output']>;
  HandledMutationResponse: Partial<HandledMutationResponse>;
  IntOrString: Partial<Scalars['IntOrString']['output']>;
  InvestmentSummary: Partial<InvestmentSummary>;
  ItemsProvidedToCustomers: Partial<ItemsProvidedToCustomers>;
  ItemsProvidedToCustomersInput: Partial<ItemsProvidedToCustomersInput>;
  JSON: Partial<Scalars['JSON']['output']>;
  Lead: Partial<Lead>;
  LeadInput: Partial<LeadInput>;
  LineSet: Partial<LineSet>;
  ManagedServicesRenewalResponse: Partial<ManagedServicesRenewalResponse>;
  ManagedServicesSummaryResponse: Partial<ManagedServicesSummaryResponse>;
  MansEnrollmentSummaryResponse: Partial<MansEnrollmentSummaryResponse>;
  MongoFilter: Partial<Scalars['MongoFilter']['output']>;
  Mutation: {};
  Opportunity: Partial<Opportunity>;
  OpportunityTable: Partial<OpportunityTable>;
  Organization: Partial<Organization>;
  Phase: Partial<Phase>;
  PhaseBreakdown: Partial<PhaseBreakdown>;
  Practice: Partial<Practice>;
  PracticeInput: Partial<PracticeInput>;
  ProjectComment: Partial<ProjectComment>;
  ProjectCommentInput: Partial<ProjectCommentInput>;
  ProjectCustomTaskPhase: Partial<ProjectCustomTaskPhase>;
  ProjectEstimates: Partial<ProjectEstimates>;
  ProjectEstimatesInput: Partial<ProjectEstimatesInput>;
  ProjectInput: Partial<ProjectInput>;
  ProjectItem: Partial<ProjectItem>;
  ProjectItemEstimators: Partial<ProjectItemEstimators>;
  ProjectItemEstimatorsInput: Partial<ProjectItemEstimatorsInput>;
  ProjectItemInput: Partial<ProjectItemInput>;
  ProjectItemQuote: Partial<ProjectItemQuote>;
  ProjectItemQuoteGlobalSettings: Partial<ProjectItemQuoteGlobalSettings>;
  ProjectItemQuoteGlobalSettingsInput: Partial<ProjectItemQuoteGlobalSettingsInput>;
  ProjectItemQuoteInput: Partial<ProjectItemQuoteInput>;
  ProjectItemQuoteLine: Partial<ProjectItemQuoteLine>;
  ProjectItemQuoteLineInput: Partial<ProjectItemQuoteLineInput>;
  ProjectItemQuoteSettings: Partial<ProjectItemQuoteSettings>;
  ProjectItemQuoteSettingsInput: Partial<ProjectItemQuoteSettingsInput>;
  ProjectItemQuoteSystem: Partial<ProjectItemQuoteSystem>;
  ProjectItemQuoteSystemInput: Partial<ProjectItemQuoteSystemInput>;
  ProjectPracticeAndRoles: Partial<ProjectPracticeAndRoles>;
  ProjectProposal: Partial<ProjectProposal>;
  ProjectProposalInput: Partial<ProjectProposalInput>;
  ProjectSow: Partial<ProjectSow>;
  ProjectSowInput: Partial<ProjectSowInput>;
  ProjectStatusHistory: Partial<ProjectStatusHistory>;
  ProjectTeam: Partial<ProjectTeam>;
  ProjectTeamInput: Partial<ProjectTeamInput>;
  ProjectUser: Partial<ProjectUser>;
  ProposalTemplate: Partial<ProposalTemplate>;
  ProposalTemplateInput: Partial<ProposalTemplateInput>;
  PsaSyncHistory: Partial<PsaSyncHistory>;
  PsaSyncHistoryInput: Partial<PsaSyncHistoryInput>;
  Query: {};
  Question: Partial<Question>;
  QuestionInput: Partial<QuestionInput>;
  QuestionOption: Partial<QuestionOption>;
  QuestionOptionInput: Partial<QuestionOptionInput>;
  RMSProposal: Partial<RmsProposal>;
  RMSProposalInput: Partial<RmsProposalInput>;
  RmsGPAnalysis: Partial<RmsGpAnalysis>;
  RmsGPAnalysisTableData: Partial<RmsGpAnalysisTableData>;
  RmsGPAnalysisTableDataLineItems: Partial<RmsGpAnalysisTableDataLineItems>;
  RmsGPAnalysisTableSites: Partial<RmsGpAnalysisTableSites>;
  RmsGPTaskUpdate: Partial<RmsGpTaskUpdate>;
  RmsGPTaskUpdateInput: Partial<RmsGpTaskUpdateInput>;
  RmsOneTimeCost: Partial<RmsOneTimeCost>;
  RmsProposalPricing: Partial<RmsProposalPricing>;
  RmsProposalPricingTier: Partial<RmsProposalPricingTier>;
  RmsProposalTemplateStandardLines: Partial<RmsProposalTemplateStandardLines>;
  RmsProposalTemplateStandardSites: Partial<RmsProposalTemplateStandardSites>;
  RmsProposalTemplateTier: Partial<RmsProposalTemplateTier>;
  RmsProposalTemplateTierLines: Partial<RmsProposalTemplateTierLines>;
  RmsProposalTemplateTierSites: Partial<RmsProposalTemplateTierSites>;
  Role: Partial<Role>;
  RoleAndRate: Partial<RoleAndRate>;
  RoleAndRateInput: Partial<RoleAndRateInput>;
  RoleHistory: Partial<RoleHistory>;
  RoleRequest: Partial<RoleRequest>;
  RoleTeam: Partial<RoleTeam>;
  RoleTeamInput: Partial<RoleTeamInput>;
  SFDCEstimate: Partial<SfdcEstimate>;
  SFDCEstimateProduct: Partial<SfdcEstimateProduct>;
  SFDCOpportunity: Partial<SfdcOpportunity>;
  SFDCSkillRequest: Partial<SfdcSkillRequest>;
  SalesHistory: Partial<SalesHistory>;
  SalesRepTrending: Partial<SalesRepTrending>;
  SalesforceDataInput: Partial<SalesforceDataInput>;
  SaveEstimateToPsaResponse: Partial<SaveEstimateToPsaResponse>;
  ScopeGroup: Partial<ScopeGroup>;
  ScopeGroupInput: Partial<ScopeGroupInput>;
  ScopeItem: Partial<ScopeItem>;
  ScopeItemInput: Partial<ScopeItemInput>;
  ScopingSession: Partial<ScopingSession>;
  ScopingSessionInput: Partial<ScopingSessionInput>;
  ScopingSessionSection: Partial<ScopingSessionSection>;
  ScopingSessionSectionInput: Partial<ScopingSessionSectionInput>;
  SearchCustomersResponse: Partial<SearchCustomersResponse>;
  SearchServiceItemsResponse: Partial<SearchServiceItemsResponse>;
  SearchServiceSkillsResponse: Partial<SearchServiceSkillsResponse>;
  SearchServiceSuppliersResponse: Partial<SearchServiceSuppliersResponse>;
  SearchSowSectionsResponse: Partial<SearchSowSectionsResponse>;
  SearchTaskGroupsResponse: Partial<SearchTaskGroupsResponse>;
  SearchUsersResponse: Partial<SearchUsersResponse>;
  ServiceCategory: Partial<ServiceCategory>;
  ServiceItem: Partial<ServiceItem>;
  ServiceLocation: Partial<ServiceLocation>;
  ServiceLocationRole: Partial<ServiceLocationRole>;
  ServicePractice: Partial<ServicePractice>;
  ServiceSkill: Partial<ServiceSkill>;
  ServiceSummary: Partial<ServiceSummary>;
  ServiceSummaryResponse: Partial<ServiceSummaryResponse>;
  ServiceSupplier: Partial<ServiceSupplier>;
  SessionQuestion: Partial<SessionQuestion>;
  SessionQuestionInput: Partial<SessionQuestionInput>;
  SfdcAccount: Partial<SfdcAccount>;
  SfdcRegion: Partial<SfdcRegion>;
  Site: Partial<Site>;
  SiteBreakdown: Partial<SiteBreakdown>;
  SiteInfoInput: Partial<SiteInfoInput>;
  SiteInput: Partial<SiteInput>;
  SiteTask: Partial<SiteTask>;
  SiteWithTasks: Partial<SiteWithTasks>;
  SkillSummary: Partial<SkillSummary>;
  SkillSummaryRow: Partial<SkillSummaryRow>;
  SowSection: Partial<SowSection>;
  SowSectionInput: Partial<SowSectionInput>;
  SowSectionTeam: Partial<SowSectionTeam>;
  SowSectionTeamInput: Partial<SowSectionTeamInput>;
  SowTaskGroup: Partial<SowTaskGroup>;
  SprintSummary: Partial<SprintSummary>;
  SprintSummaryRow: Partial<SprintSummaryRow>;
  StringOrArray: Partial<Scalars['StringOrArray']['output']>;
  Task: Partial<Task>;
  TaskGroup: Partial<TaskGroup>;
  TaskGroupTeam: Partial<TaskGroupTeam>;
  TaskGroupTeamInput: Partial<TaskGroupTeamInput>;
  TaskGroupsPrintOptions: Partial<TaskGroupsPrintOptions>;
  TaskHasGroup: Partial<TaskHasGroup>;
  TaskInput: Partial<TaskInput>;
  TaskList: Partial<TaskList>;
  TaskListInput: Partial<TaskListInput>;
  TaskNote: Partial<TaskNote>;
  TaskNoteInput: Partial<TaskNoteInput>;
  TaskOrder: Partial<TaskOrder>;
  TaskPhase: Partial<TaskPhase>;
  TaskPhaseInput: Partial<TaskPhaseInput>;
  Tier: Partial<Tier>;
  TrainingOrCollateral: Partial<TrainingOrCollateral>;
  TrainingOrCollateralInput: Partial<TrainingOrCollateralInput>;
  TravelExpenseSummaryResponse: Partial<TravelExpenseSummaryResponse>;
  UpdateLeadInput: Partial<UpdateLeadInput>;
  UpdateLeadResponse: Partial<UpdateLeadResponse>;
  UpdateProjectInput: Partial<UpdateProjectInput>;
  UpdateProjectResponse: Partial<UpdateProjectResponse>;
  UpdateProjectStatusInput: Partial<UpdateProjectStatusInput>;
  UpdatedTaskMutationResponse: Partial<UpdatedTaskMutationResponse>;
  User: Partial<User>;
  UserCiscoCcwToken: Partial<UserCiscoCcwToken>;
  UserInput: Partial<UserInput>;
  VolumePricing: Partial<VolumePricing>;
  VolumePricingInput: Partial<VolumePricingInput>;
  ZuoraRateChange: Partial<ZuoraRateChange>;
  ZuoraRatePlan: Partial<ZuoraRatePlan>;
  ZuoraSubscription: Partial<ZuoraSubscription>;
  ZuoraTier: Partial<ZuoraTier>;
  addProjectUserInput: Partial<AddProjectUserInput>;
  estimateToPSAInputs: Partial<EstimateToPsaInputs>;
  getPracticesResponse: Partial<GetPracticesResponse>;
  getServiceCategoriesResponse: Partial<GetServiceCategoriesResponse>;
  getServiceLocationsResponse: Partial<GetServiceLocationsResponse>;
  getServiceLocationsRolesResponse: Partial<GetServiceLocationsRolesResponse>;
  getServicePracticesResponse: Partial<GetServicePracticesResponse>;
  wrapperEstimateToPSAInputs: Partial<WrapperEstimateToPsaInputs>;
}>;

export interface AnyScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['ANY'], any> {
  name: 'ANY';
}

export type ActiveSiteTaskResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActiveSiteTask'] = ResolversParentTypes['ActiveSiteTask']
> = ResolversObject<{
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  noteCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  overtime?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  primaryRole?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  quantity?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  secondaryRole?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  siteId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taskId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalHours?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ActiveSiteWithTasksResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActiveSiteWithTasks'] = ResolversParentTypes['ActiveSiteWithTasks']
> = ResolversObject<{
  siteId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  siteName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  siteTotalHours?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  tasks?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ActiveSiteTask']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddEstimatorTasksByTaskGroupResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddEstimatorTasksByTaskGroupResponse'] = ResolversParentTypes['AddEstimatorTasksByTaskGroupResponse']
> = ResolversObject<{
  hasBillingTypeChanged?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddProjectUserResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddProjectUserResponse'] = ResolversParentTypes['AddProjectUserResponse']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AdminConfigSettingsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdminConfigSettings'] = ResolversParentTypes['AdminConfigSettings']
> = ResolversObject<{
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<
    Maybe<ResolversTypes['StringOrArray']>,
    ParentType,
    ContextType
  >;
  valueType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AdminPracticeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdminPractice'] = ResolversParentTypes['AdminPractice']
> = ResolversObject<{
  bidAssurance?: Resolver<
    Maybe<ResolversTypes['ANY']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  managedSvc?: Resolver<Maybe<ResolversTypes['ANY']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  peerReviewEmail?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  practiceId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  practiceUsernames?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  psm?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  salesForceId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  serviceLocationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  web?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AdminRoleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdminRole'] = ResolversParentTypes['AdminRole']
> = ResolversObject<{
  agiloftRole?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  cdwRateId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  coOwners?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RoleTeam']>>>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['IntOrString']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  owner?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RoleTeam']>>>,
    ParentType,
    ContextType
  >;
  practice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  projectManager?: Resolver<
    Maybe<ResolversTypes['ANY']>,
    ParentType,
    ContextType
  >;
  rate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  testers?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RoleTeam']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AdminTaskGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdminTaskGroup'] = ResolversParentTypes['AdminTaskGroup']
> = ResolversObject<{
  agiloftBlendedScopeType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftClauses?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftDefaultPrintTemplate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftExhibits?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftManagedScopeType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftOutcomeScopeType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftPrintTemplateTypeOptions?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  agiloftRecurringServicesFees?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftScopeType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftServiceCategory?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  bidAssuranceGroup?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  bidAssuranceUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  businessOutcome?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  businessOutcomeHelp?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  businessOutcomeIncluded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  coOwners?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TaskGroupTeam']>>>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  executiveSummaryDrivers?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  executiveSummaryDriversHelp?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  executiveSummaryDriversIncluded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  executiveSummaryOverview?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  executiveSummaryOverviewHelp?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  executiveSummaryOverviewIncluded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  fixedFee?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  inTestingTaskGroupId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  itemsProvidedToCustomers?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ItemsProvidedToCustomers']>>>,
    ParentType,
    ContextType
  >;
  lastReviewedBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lastReviewedOn?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  minPmHours?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  originalTaskGroupId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  outcomeIncluded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  outcomeProjectScope?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  outcomeProjectScopeIncluded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  outcomeProjectScopePhases?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  outcomeProjectScopePhasesHelp?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  outcomeProjectScopePhasesIncluded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  outcomeSowFooter?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  outcomeSowHeader?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  outcomeSummary?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  owner?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TaskGroupTeam']>>>,
    ParentType,
    ContextType
  >;
  pmPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pmRole?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  practice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  proposalContent?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  proposalTemplateIds?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  published?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  riskPercent?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  setPm?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sowScopeFooter?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  sowScopeHeader?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  sowSections?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taskGroupCategories?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Category']>>>,
    ParentType,
    ContextType
  >;
  taskGroupTeam?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TaskGroupTeam']>>>,
    ParentType,
    ContextType
  >;
  technicalOutcome?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  technicalOutcomeHelp?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  technicalOutcomeIncluded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  testers?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TaskGroupTeam']>>>,
    ParentType,
    ContextType
  >;
  version?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AdminUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdminUser'] = ResolversParentTypes['AdminUser']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  something?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AgiloftApiLogResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgiloftAPILog'] = ResolversParentTypes['AgiloftAPILog']
> = ResolversObject<{
  agiloftSOWId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  api?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  payload?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AgiloftApiResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgiloftApiResponse'] = ResolversParentTypes['AgiloftApiResponse']
> = ResolversObject<{
  buildContinue?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  buildIndex?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AgiloftExpenseTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgiloftExpenseType'] = ResolversParentTypes['AgiloftExpenseType']
> = ResolversObject<{
  agiloftDefaultExpenseClause?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  expenseClauses?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  expenseTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  managedServicesExpenseClause?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  showExpenseMiles?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AgiloftRoleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgiloftRole'] = ResolversParentTypes['AgiloftRole']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AgiloftSowResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgiloftSOW'] = ResolversParentTypes['AgiloftSOW']
> = ResolversObject<{
  custom_225653673?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  customerLegalName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  projectName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  project_scope_clause_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  solutionArchitect?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AgiloftTokenResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgiloftToken'] = ResolversParentTypes['AgiloftToken']
> = ResolversObject<{
  access_token?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  expires_in?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  token_type?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AgiloftTravelNoticeTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgiloftTravelNoticeType'] = ResolversParentTypes['AgiloftTravelNoticeType']
> = ResolversObject<{
  agiloftDefaultTravelNoticeClause?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  showTravelNoticePenaltyAmount?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  travelNoticeClauses?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  travelNoticeTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BidAssuranceNoteResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BidAssuranceNote'] = ResolversParentTypes['BidAssuranceNote']
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  createdBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BidAssuranceStatusHistoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BidAssuranceStatusHistory'] = ResolversParentTypes['BidAssuranceStatusHistory']
> = ResolversObject<{
  status?: Resolver<
    Maybe<ResolversTypes['BidAssuranceStatusType']>,
    ParentType,
    ContextType
  >;
  statusReason?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  taskGroupId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Category'] = ResolversParentTypes['Category']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CdwOpenOrdersWorkListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CdwOpenOrdersWorkList'] = ResolversParentTypes['CdwOpenOrdersWorkList']
> = ResolversObject<{
  poCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CdwRateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CdwRate'] = ResolversParentTypes['CdwRate']
> = ResolversObject<{
  agiloftRole?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  practice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  practiceName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoAnnuityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoAnnuity'] = ResolversParentTypes['CiscoAnnuity']
> = ResolversObject<{
  _id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  activeCiscoQuote?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  actualStartDate?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  cdwBillingId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  changeType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  ciscoCapital?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  ciscoQuotes?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CiscoQuote']>>>,
    ParentType,
    ContextType
  >;
  ciscoSmartAccount?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  ciscoSubscriptionId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  ciscoVirtualAccount?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  cofCreatedAt?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  cofCreatedBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  cofDeniedReason?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  cofRequestedAt?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  cofRequestedBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  cofSignedAt?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  cofSignedBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  comments?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CiscoAnnuityComment']>>>,
    ParentType,
    ContextType
  >;
  contacts?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CiscoAnnuityContact']>>>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  createdBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  customerAdminSameAsBilling?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  customerBillingAccount?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  customerPo?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  customerPurchaseOrder?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  dealId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  lastModifiedAt?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  lastModifiedBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  orderDeniedReason?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  orderPlacedAt?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  orderPlacedBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  previousStatus?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  processVersion?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  projectItemId?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >;
  quotedAt?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  quotedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requestWordCopy?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  smartAccount?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  stateContract?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  statusHistory?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CiscoAnnuityStatusHistory']>>>,
    ParentType,
    ContextType
  >;
  subscriptionNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  virtualAccount?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  webOrderId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoAnnuityCategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoAnnuityCategory'] = ResolversParentTypes['CiscoAnnuityCategory']
> = ResolversObject<{
  allowCdwDiscountEdit?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  allowCustomerDiscountEdit?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoAnnuityCommentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoAnnuityComment'] = ResolversParentTypes['CiscoAnnuityComment']
> = ResolversObject<{
  _id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  createdBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoAnnuityContactResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoAnnuityContact'] = ResolversParentTypes['CiscoAnnuityContact']
> = ResolversObject<{
  _id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<
    Maybe<ResolversTypes['CiscoAnnuityContactType']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoAnnuityLineResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoAnnuityLine'] = ResolversParentTypes['CiscoAnnuityLine']
> = ResolversObject<{
  _id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  annuityAudio?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  annuityAutoRenewTerm?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  annuityBillingCycle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  annuityTerm?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  audioTier?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  autoRenew?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  billingAmountChangeType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  billingAmountNetChange?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  billingCycle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cdwDiscount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cdwPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cdwTotalPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  chargeType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  contractAmountChangeType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  contractAmountNetChange?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  creditAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  creditJson?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  creditType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  currentContractAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  customerDiscount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  customerPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  customerTermTotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  customerTotalPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  decimalPlaces?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  discount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  discountJson?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  extendedNetPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  extentionDatetime0?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  extentionDatetime1?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  extentionNumber0?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  extentionNumber1?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  extentionValue0?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  extentionValue1?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  extentionValue2?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  extentionValue3?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  extentionValue4?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  extentionValue5?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  gp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  includeInQuote?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  isDiscount?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  isRateTable?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  leadTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  line?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineChangeType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lineId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  newBillingAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  newContractAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  noGPImpact?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  offerDetailsJson?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  oldQuantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  originalUnitListPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  parent?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  partNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  pricingTerm?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  quantityChange?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  quantityChangeType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  quotableNonOrderableProduct?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  quoteId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rateTableLink?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  rateTableName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  serviceDuration?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  start?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  term?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeBoundCreditAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  timeBoundCreditDuration?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  timeBoundCreditFreeToCustomer?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  timeBoundCreditFrom?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  timeBoundCreditKeep?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  timeBoundCreditName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  timeBoundCreditType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  topLevel?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  totalAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalListPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  unitListPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  unitNetPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  unitOfMeasure?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  useCiscoTotalAmount?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  userBillingCycle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  userTerm?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoAnnuityLineTermResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoAnnuityLineTerm'] = ResolversParentTypes['CiscoAnnuityLineTerm']
> = ResolversObject<{
  autoRenew?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  billingCycle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  line?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  term?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoAnnuityPartNumberResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoAnnuityPartNumber'] = ResolversParentTypes['CiscoAnnuityPartNumber']
> = ResolversObject<{
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  decimalPlaces?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  useCiscoTotalAmount?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoAnnuityPaymentScheduleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoAnnuityPaymentSchedule'] = ResolversParentTypes['CiscoAnnuityPaymentSchedule']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  values?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Float']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoAnnuityQuoteResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoAnnuityQuoteResponse'] = ResolversParentTypes['CiscoAnnuityQuoteResponse']
> = ResolversObject<{
  lines?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CiscoAnnuityLine']>>>,
    ParentType,
    ContextType
  >;
  paymentSchedule?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CiscoAnnuityPaymentSchedule']>>>,
    ParentType,
    ContextType
  >;
  quoteSummary?: Resolver<
    Maybe<ResolversTypes['CiscoAnnuityQuoteSummary']>,
    ParentType,
    ContextType
  >;
  serviceFeeSchedule?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CiscoAnnuityServiceFeeSchedule']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoAnnuityQuoteSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoAnnuityQuoteSummary'] = ResolversParentTypes['CiscoAnnuityQuoteSummary']
> = ResolversObject<{
  cdwCostInitial?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cdwCostRenewal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cdwRawCostInitial?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cdwRawCostRenewal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  commissionTierInitial?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  commissionTierRenewal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  customerPriceInitial?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  customerPriceRenewal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  gpInitial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gpPercentInitial?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  gpPercentPerCiscoInitial?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  gpPercentPerCiscoRenewal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  gpPercentRenewal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  gpRenewal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoAnnuityServiceFeeScheduleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoAnnuityServiceFeeSchedule'] = ResolversParentTypes['CiscoAnnuityServiceFeeSchedule']
> = ResolversObject<{
  customerPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  customerPriceMonthly?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  gp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  monthQuantity?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  period?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoAnnuityStatusHistoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoAnnuityStatusHistory'] = ResolversParentTypes['CiscoAnnuityStatusHistory']
> = ResolversObject<{
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoApiResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoApiResult'] = ResolversParentTypes['CiscoApiResult']
> = ResolversObject<{
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  po?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  webOrderId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoCcwTokenResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoCcwToken'] = ResolversParentTypes['CiscoCcwToken']
> = ResolversObject<{
  access_token?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  expires_in?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  token_type?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoEaTrueForwardResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoEATrueForward'] = ResolversParentTypes['CiscoEATrueForward']
> = ResolversObject<{
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  durationInMonths?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  endDate?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  nextTrueForward?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  remainingDuration?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  startDate?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subscriptionID?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  trueForwardCost?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoOrderResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoOrder'] = ResolversParentTypes['CiscoOrder']
> = ResolversObject<{
  billToCustomerAddressLine1?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  billToCustomerAddressLine2?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  billToCustomerCity?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  billToCustomerCountryCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  billToCustomerName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  billToCustomerPostalCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  billToCustomerSubDivisionCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdByName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  dealId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endCustomerAddressLine1?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  endCustomerAddressLine2?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  endCustomerCity?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  endCustomerContactEmail?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  endCustomerContactName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  endCustomerContactPhone?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  endCustomerCountryCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  endCustomerName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  endCustomerPostalCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  endCustomerSmartAccount?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  endCustomerSubDivisionCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  holdingSmartAccount?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  orderBookDate?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  orderCurrency?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  orderName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  orderSubmittedDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  orderType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  poNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priceList?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  priceListId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  quoteReferenceId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  salesOrderReferenceId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  shipToCustomerAddressLine1?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  shipToCustomerAddressLine2?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  shipToCustomerCity?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  shipToCustomerContactEmail?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  shipToCustomerContactName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  shipToCustomerContactPhone?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  shipToCustomerCountryCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  shipToCustomerName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  shipToCustomerPostalCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  shipToCustomerSubDivisionCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  submittedByName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tAA?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  usFederalGOVT?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  webOrderId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoOrderApiResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoOrderApiResult'] = ResolversParentTypes['CiscoOrderApiResult']
> = ResolversObject<{
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  po?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  webOrderId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoOrderBatchHistoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoOrderBatchHistory'] = ResolversParentTypes['CiscoOrderBatchHistory']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  lastFetched?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  recordsProccessed?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  start?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoOrderLineResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoOrderLine'] = ResolversParentTypes['CiscoOrderLine']
> = ResolversObject<{
  autoRenewalTerm?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  billingModel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  estimatedStartDate?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  initialTerm?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lineIdSet?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lineNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  orderId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  requestedStartDate?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subscriptionId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  typeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  webOrderId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoOrderSearchResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoOrderSearch'] = ResolversParentTypes['CiscoOrderSearch']
> = ResolversObject<{
  assignSmartAccountURL?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  billToCustomerId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  customerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  dealId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endCustomer?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  endCustomerSmartAccountDomain?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  endCustomerSmartAccountStatus?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  expectedShipDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lastModifiedDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  orderCreatedBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  orderCreatedByDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  orderName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  orderStatus?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  orderTotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  programType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  purchaseOrderNo?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  salesOrderNo?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  shipToCountry?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  shipToCustName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  smartSkuTypeFlagDisp?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  submitDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  webOrderNo?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoOrdersWorkListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoOrdersWorkList'] = ResolversParentTypes['CiscoOrdersWorkList']
> = ResolversObject<{
  webOrderId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoQuoteResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoQuote'] = ResolversParentTypes['CiscoQuote']
> = ResolversObject<{
  _id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  autoRenew?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  billingCycle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  change?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  committedAudioMarkup?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  dealId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  disableAdders?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  expires?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  hideCustomerViewTotal?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  hideIncludedSkus?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  hideMonthlyFeeBreakdown?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  isEstimate?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  lineTerms?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CiscoAnnuityLineTerm']>>>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  newCofRequired?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  quoteId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rawQuote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  removeBidAdders?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  start?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subscriptionReferenceId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  term?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  userBillingCycle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  userTerm?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoSmartAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoSmartAccount'] = ResolversParentTypes['CiscoSmartAccount']
> = ResolversObject<{
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  virtualAccounts?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CiscoVirtualAccount']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoSubscriptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoSubscription'] = ResolversParentTypes['CiscoSubscription']
> = ResolversObject<{
  accountTypeCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  adjustedMrc?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  autoRenTerm?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  billDay?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billToCustomerId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  billToCustomerName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  billingModel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  bundleLine?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  currencyCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  daysToRenewal?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  endCustomerId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  endCustomerName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  endDate?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  hasZuora?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  hostedOffer?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  initialTCV?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  initialTerm?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lastUpdateDate?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  nextTrueForwardDate?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  orderActivationDate?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  orderSubmissionDate?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  overConsumed?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  poNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  prepayTerm?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryBillingContactEmail?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryBillingContactFirstName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryBillingContactId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryBillingContactLastName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryBillingContactPhone?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryBusinessContactEmail?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryBusinessContactFirstName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryBusinessContactId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryBusinessContactLastName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryBusinessContactPhone?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  remainingTerm?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  renewalDate?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  renewalTerm?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  startDate?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subscriptionId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tfConsumptionQuantity?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  webOrderId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoSubscriptionBatchHistoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoSubscriptionBatchHistory'] = ResolversParentTypes['CiscoSubscriptionBatchHistory']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  lastFetched?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  recordsProccessed?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  start?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoSubscriptionConsumptionSkuResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoSubscriptionConsumptionSku'] = ResolversParentTypes['CiscoSubscriptionConsumptionSku']
> = ResolversObject<{
  c1ToDNAMigratedCount?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  calculationMethod?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  commerceSku?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  commerceSkuDescription?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  custSuiteId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  custSuiteName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  deployment?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  eol?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  healthMessage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  licenseGenerated?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  licenseMigrated?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  preEAConsumption?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  premierEntitlements?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  purchasedEntitlements?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  remainingEntitlement?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  reportDate?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  softwareDownloads?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subscriptionId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  totalConsumption?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  totalEntitlement?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoSubscriptionDetailsWorkListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoSubscriptionDetailsWorkList'] = ResolversParentTypes['CiscoSubscriptionDetailsWorkList']
> = ResolversObject<{
  subscriptionId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoSubscriptionHistoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoSubscriptionHistory'] = ResolversParentTypes['CiscoSubscriptionHistory']
> = ResolversObject<{
  createdBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdDate?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  subscriptionId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  transactionId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  transactionType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  webOrderId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoSubscriptionHistoryWorkListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoSubscriptionHistoryWorkList'] = ResolversParentTypes['CiscoSubscriptionHistoryWorkList']
> = ResolversObject<{
  subscriptionId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoSubscriptionLineResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoSubscriptionLine'] = ResolversParentTypes['CiscoSubscriptionLine']
> = ResolversObject<{
  billingAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  chargeType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  deliveryOption?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  extendedNetPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  hostedOffer?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lineNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  monthlySubscriptionCredit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  overConsumed?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  overConsumedQuantity?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subscriptionId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  suite?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tfConsumptionQuantity?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  tfEntitlement?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  totalCredits?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalDiscount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  typeOfQuantity?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  unitListPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  unitNetPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  usageType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CiscoVirtualAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CiscoVirtualAccount'] = ResolversParentTypes['CiscoVirtualAccount']
> = ResolversObject<{
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CollectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Collection'] = ResolversParentTypes['Collection']
> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lastModified?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContentRequestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContentRequest'] = ResolversParentTypes['ContentRequest']
> = ResolversObject<{
  assignee?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  issuetype?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  jiraIssue?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  priority?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  project?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  reporter?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CustomServiceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomService'] = ResolversParentTypes['CustomService']
> = ResolversObject<{
  cost?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  divisorRate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['IntOrString']>, ParentType, ContextType>;
  margin?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pmPercent?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  providerName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  site?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CustomerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Customer'] = ResolversParentTypes['Customer']
> = ResolversObject<{
  _id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  addressLine1?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  addressLine2?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  area?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ciscoSmartAccounts?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CiscoSmartAccount']>>>,
    ParentType,
    ContextType
  >;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  companyCode?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  contactPhone?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  countryCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  customerAddressCode?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  customerCode?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  customerDescription?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  customerSeq?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  district?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  glCode?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  legacyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  listName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  onedriveId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  onedriveLink?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryAMCoworkerEmail?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryAMCoworkerName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryAMCoworkerEmail?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryAMCoworkerName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  sector?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stateCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  t12Months?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  zipCode?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface DateScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['DATE'], any> {
  name: 'DATE';
}

export type DataCollectionItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DataCollectionItem'] = ResolversParentTypes['DataCollectionItem']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DefaultAddResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DefaultAddResponse'] = ResolversParentTypes['DefaultAddResponse']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DefaultMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DefaultMutationResponse'] = ResolversParentTypes['DefaultMutationResponse']
> = ResolversObject<{
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DefaultMutationResponseWithIdResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DefaultMutationResponseWithID'] = ResolversParentTypes['DefaultMutationResponseWithID']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EmailTemplatesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailTemplates'] = ResolversParentTypes['EmailTemplates']
> = ResolversObject<{
  body?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EnrollmentSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EnrollmentSummary'] = ResolversParentTypes['EnrollmentSummary']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rate?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  total?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unit?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EstimatorTotalsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EstimatorTotals'] = ResolversParentTypes['EstimatorTotals']
> = ResolversObject<{
  cdwCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  enrollmentPmHours?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  grossProfit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  isMinPmMet?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  managedSericesOtc?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  managedServices?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minPmHours?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  onetimeService?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  pmHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serviceEstimate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  taskHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FbUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FBUser'] = ResolversParentTypes['FBUser']
> = ResolversObject<{
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  admin?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  aud?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  auth_time?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  contentAdmin?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  department?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  displayName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email_verified?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  employeeId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  exp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iat?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  iss?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  managedServicesAdmin?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  managerEmail?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organization?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  picture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sub?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FbUserMetaDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FBUserMetaData'] = ResolversParentTypes['FBUserMetaData']
> = ResolversObject<{
  creationTime?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lastSignInTime?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FixedFeeMilestoneResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FixedFeeMilestone'] = ResolversParentTypes['FixedFeeMilestone']
> = ResolversObject<{
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['IntOrString']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GainsightSubscriptionsViewResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GainsightSubscriptionsView'] = ResolversParentTypes['GainsightSubscriptionsView']
> = ResolversObject<{
  Sub_AccountTypeCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_AdjustedMRC?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_AppInf_EA?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_AutoRenewTerm?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_BillDay?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_BillToCustomerID?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_BillToCustomerName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_BillingContact_Email?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_BillingContact_FirstName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_BillingContact_ID?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_BillingContact_LastName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_BillingContact_Phone?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_BillingModel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_BundleLine?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_BusinessContact_Email?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_BusinessContact_FirstName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_BusinessContact_ID?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_BusinessContact_LastName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_BusinessContact_Phone?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_CiscoProvisioningEmailRecieved?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_Cisco_TCV?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_Collab_EA?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_CurrencyCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_DaysToRenewal?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_EAType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_EndCustomerContact_Email?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_EndCustomerContact_Name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_EndCustomerContact_Phone?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_EndCustomerID?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_EndCustomerName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_EndCustomerSmartAccount?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_EndCustomer_AddressLine1?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_EndCustomer_AddressLine2?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_EndCustomer_CityName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_EndCustomer_CountryCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_EndCustomer_CountrySubDivisionCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_EndCustomer_PostalCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_EndDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_EstimatedStartDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_HoldingSmartAccount?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_HostedOffer?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_Initial_Cisco_TCV?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_Initial_DealID?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_Initial_OrderSubmissionDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_Initial_PONumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_Initial_Term?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_Initial_WebOrder_ID?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_ItemType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_LastUpdateDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_Multi_EA?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_Networking_EA?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_NextTrueForwardDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_OrderActivationDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_OrderSubmissionDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_OverConsumed?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_PONumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_PrepayTerm?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_RemainingTerm?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_RenewalDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_RenewalTerm?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_RequestedStartDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_Services_EA?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_ShipToCustomerContact_Name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_ShipToCustomer_AddressLine1?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_ShipToCustomer_AddressLine2?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_ShipToCustomer_CityName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_ShipToCustomer_CountryCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_ShipToCustomer_CountrySubDivisionCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_ShipToCustomer_Email?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_ShipToCustomer_Name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_ShipToCustomer_Phone?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_ShipToCustomer_PostalCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_StartDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_Sub_w_EA?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_SubscriptionID?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_Subscription_Status?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_TFConsumptionQty?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_WebOrder_ID?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sub_WebOrder_ID_List?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetAdminConfigSettingsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetAdminConfigSettingsResponse'] = ResolversParentTypes['GetAdminConfigSettingsResponse']
> = ResolversObject<{
  adminConfigSettings?: Resolver<
    Array<Maybe<ResolversTypes['AdminConfigSettings']>>,
    ParentType,
    ContextType
  >;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetAgiloftExpenseTypesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetAgiloftExpenseTypesResponse'] = ResolversParentTypes['GetAgiloftExpenseTypesResponse']
> = ResolversObject<{
  agiloftExpenseTypes?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['AgiloftExpenseType']>>>,
    ParentType,
    ContextType
  >;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetAgiloftRolesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetAgiloftRolesResponse'] = ResolversParentTypes['GetAgiloftRolesResponse']
> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  roles?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['AgiloftRole']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetAgiloftTravelNoticeTypesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetAgiloftTravelNoticeTypesResponse'] = ResolversParentTypes['GetAgiloftTravelNoticeTypesResponse']
> = ResolversObject<{
  agiloftTravelNoticeTypes?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['AgiloftTravelNoticeType']>>>,
    ParentType,
    ContextType
  >;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetCdwRatesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetCdwRates'] = ResolversParentTypes['GetCdwRates']
> = ResolversObject<{
  agiloftRole?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  cost?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  practice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  practiceName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetCdwRatesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetCdwRatesResponse'] = ResolversParentTypes['GetCdwRatesResponse']
> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rates?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['GetCdwRates']>>>,
    ParentType,
    ContextType,
    Partial<GetCdwRatesResponse_RatesArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetCiscoAnnuityPartNumbersResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetCiscoAnnuityPartNumbersResponse'] = ResolversParentTypes['GetCiscoAnnuityPartNumbersResponse']
> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  partNumbers?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CiscoAnnuityPartNumber']>>>,
    ParentType,
    ContextType,
    Partial<GetCiscoAnnuityPartNumbersResponse_PartNumbersArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetCiscoCcwEstimateByIdResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetCiscoCcwEstimateByIdResponse'] = ResolversParentTypes['GetCiscoCcwEstimateByIdResponse']
> = ResolversObject<{
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetCiscoCcwQuoteByDealIdResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetCiscoCcwQuoteByDealIdResponse'] = ResolversParentTypes['GetCiscoCcwQuoteByDealIdResponse']
> = ResolversObject<{
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quotes?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CiscoQuote']>>>,
    ParentType,
    ContextType
  >;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetCollectionsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetCollectionsResponse'] = ResolversParentTypes['GetCollectionsResponse']
> = ResolversObject<{
  collections?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Collection']>>>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  lastModified?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetCustomersByOpportunityResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetCustomersByOpportunityResponse'] = ResolversParentTypes['GetCustomersByOpportunityResponse']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  customers?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Customer']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetCustomersResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetCustomersResponse'] = ResolversParentTypes['GetCustomersResponse']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  customers?: Resolver<
    Array<Maybe<ResolversTypes['Customer']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetEmailTemplatesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetEmailTemplatesResponse'] = ResolversParentTypes['GetEmailTemplatesResponse']
> = ResolversObject<{
  emailTemplates?: Resolver<
    Array<Maybe<ResolversTypes['EmailTemplates']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetEstimateToPsaResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetEstimateToPSAResponse'] = ResolversParentTypes['GetEstimateToPSAResponse']
> = ResolversObject<{
  estimateProductList?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  inputs?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['estimateToPSAInputs']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetOpportunityResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetOpportunityResponse'] = ResolversParentTypes['GetOpportunityResponse']
> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  opportunities?: Resolver<
    Array<Maybe<ResolversTypes['Opportunity']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetPhasesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetPhasesResponse'] = ResolversParentTypes['GetPhasesResponse']
> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  phases?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Phase']>>>,
    ParentType,
    ContextType,
    Partial<GetPhasesResponse_PhasesArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetProjectItemEstimatorRatesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetProjectItemEstimatorRatesResponse'] = ResolversParentTypes['GetProjectItemEstimatorRatesResponse']
> = ResolversObject<{
  practices?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Practice']>>>,
    ParentType,
    ContextType
  >;
  rates?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RoleAndRate']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetProjectRatesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetProjectRatesResponse'] = ResolversParentTypes['GetProjectRatesResponse']
> = ResolversObject<{
  practices?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Practice']>>>,
    ParentType,
    ContextType
  >;
  rates?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RoleAndRate']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetProjectUsersResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetProjectUsersResponse'] = ResolversParentTypes['GetProjectUsersResponse']
> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  users?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ProjectUser']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetProjectsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetProjectsResponse'] = ResolversParentTypes['GetProjectsResponse']
> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  projects?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Opportunity']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetProposalTemplatesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetProposalTemplatesResponse'] = ResolversParentTypes['GetProposalTemplatesResponse']
> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  proposalTemplates?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ProposalTemplate']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetRolesAndRatesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetRolesAndRatesResponse'] = ResolversParentTypes['GetRolesAndRatesResponse']
> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rolesAndRates?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RoleAndRate']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetRolesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetRolesResponse'] = ResolversParentTypes['GetRolesResponse']
> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  roles?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RoleAndRate']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetSalesHistoryResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetSalesHistoryResponse'] = ResolversParentTypes['GetSalesHistoryResponse']
> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  salesHistory?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['SalesHistory']>>>,
    ParentType,
    ContextType,
    Partial<GetSalesHistoryResponse_SalesHistoryArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetSalesRepsTrendingResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetSalesRepsTrendingResponse'] = ResolversParentTypes['GetSalesRepsTrendingResponse']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  salesReps?: Resolver<
    Array<Maybe<ResolversTypes['SalesRepTrending']>>,
    ParentType,
    ContextType,
    Partial<GetSalesRepsTrendingResponse_SalesRepsArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetScopeGroupsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetScopeGroupsResponse'] = ResolversParentTypes['GetScopeGroupsResponse']
> = ResolversObject<{
  count?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType,
    Partial<GetScopeGroupsResponse_CountArgs>
  >;
  scopeGroups?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ScopeGroup']>>>,
    ParentType,
    ContextType,
    Partial<GetScopeGroupsResponse_ScopeGroupsArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetScopeItemsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetScopeItemsResponse'] = ResolversParentTypes['GetScopeItemsResponse']
> = ResolversObject<{
  count?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType,
    Partial<GetScopeItemsResponse_CountArgs>
  >;
  scopeItems?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ScopeItem']>>>,
    ParentType,
    ContextType,
    Partial<GetScopeItemsResponse_ScopeItemsArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetServiceItemsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetServiceItemsResponse'] = ResolversParentTypes['GetServiceItemsResponse']
> = ResolversObject<{
  count?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType,
    Partial<GetServiceItemsResponse_CountArgs>
  >;
  serviceItems?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ServiceItem']>>>,
    ParentType,
    ContextType,
    Partial<GetServiceItemsResponse_ServiceItemsArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetServiceSkillsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetServiceSkillsResponse'] = ResolversParentTypes['GetServiceSkillsResponse']
> = ResolversObject<{
  count?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType,
    Partial<GetServiceSkillsResponse_CountArgs>
  >;
  serviceSkills?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ServiceSkill']>>>,
    ParentType,
    ContextType,
    Partial<GetServiceSkillsResponse_ServiceSkillsArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetServiceSuppliersResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetServiceSuppliersResponse'] = ResolversParentTypes['GetServiceSuppliersResponse']
> = ResolversObject<{
  count?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType,
    Partial<GetServiceSuppliersResponse_CountArgs>
  >;
  serviceSuppliers?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ServiceSupplier']>>>,
    ParentType,
    ContextType,
    Partial<GetServiceSuppliersResponse_ServiceSuppliersArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetSowSectionsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetSowSectionsResponse'] = ResolversParentTypes['GetSowSectionsResponse']
> = ResolversObject<{
  count?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType,
    Partial<GetSowSectionsResponse_CountArgs>
  >;
  sowSections?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['SowSection']>>>,
    ParentType,
    ContextType,
    Partial<GetSowSectionsResponse_SowSectionsArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetTaskGroupSowResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetTaskGroupSowResponse'] = ResolversParentTypes['GetTaskGroupSowResponse']
> = ResolversObject<{
  rawSowContent?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  sow?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetTaskGroupsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetTaskGroupsResponse'] = ResolversParentTypes['GetTaskGroupsResponse']
> = ResolversObject<{
  count?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType,
    Partial<GetTaskGroupsResponse_CountArgs>
  >;
  taskGroups?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TaskGroup']>>>,
    ParentType,
    ContextType,
    Partial<GetTaskGroupsResponse_TaskGroupsArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetTasksResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetTasksResponse'] = ResolversParentTypes['GetTasksResponse']
> = ResolversObject<{
  count?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType,
    Partial<GetTasksResponse_CountArgs>
  >;
  tasks?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Task']>>>,
    ParentType,
    ContextType,
    Partial<GetTasksResponse_TasksArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetTiersResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetTiersResponse'] = ResolversParentTypes['GetTiersResponse']
> = ResolversObject<{
  count?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType,
    Partial<GetTiersResponse_CountArgs>
  >;
  tiers?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Tier']>>>,
    ParentType,
    ContextType,
    Partial<GetTiersResponse_TiersArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetTotalCustomersResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetTotalCustomersResponse'] = ResolversParentTypes['GetTotalCustomersResponse']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  customers?: Resolver<
    Array<Maybe<ResolversTypes['Customer']>>,
    ParentType,
    ContextType
  >;
  customersSinceLastMonthPercent?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetTotalSalesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetTotalSalesResponse'] = ResolversParentTypes['GetTotalSalesResponse']
> = ResolversObject<{
  totalSales?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalSalesSinceLastMonthPercent?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetUsersResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetUsersResponse'] = ResolversParentTypes['GetUsersResponse']
> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  users?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['FBUser']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface HtmlScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['HTML'], any> {
  name: 'HTML';
}

export type HandledMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HandledMutationResponse'] = ResolversParentTypes['HandledMutationResponse']
> = ResolversObject<{
  data?: Resolver<Maybe<ResolversTypes['ANY']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface IntOrStringScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['IntOrString'], any> {
  name: 'IntOrString';
}

export type InvestmentSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvestmentSummary'] = ResolversParentTypes['InvestmentSummary']
> = ResolversObject<{
  hwsw?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  managedServicesName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  managedServicesTotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  servicesTotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  tAndETotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  total?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ItemsProvidedToCustomersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ItemsProvidedToCustomers'] = ResolversParentTypes['ItemsProvidedToCustomers']
> = ResolversObject<{
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  format?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['IntOrString']>, ParentType, ContextType>;
  item?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  operator?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  taskId?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  value?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface JsonScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type LeadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Lead'] = ResolversParentTypes['Lead']
> = ResolversObject<{
  additionalInformation?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  annuityTotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  associatedProjects?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  customerDescription?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  hardwareTotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primarySa?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primarySales?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  priority?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondarySa?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondarySales?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  serviceTotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LineSetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LineSet'] = ResolversParentTypes['LineSet']
> = ResolversObject<{
  contractType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createMilestone?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  currencyCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  estimateProduct?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fixedPriceMarginPercent?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  lineSetType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  mileStone?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  netBillAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percentDiscount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  practice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  servicesItem?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  startDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  subcontractorAccount?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  unitCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  unitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ManagedServicesRenewalResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ManagedServicesRenewalResponse'] = ResolversParentTypes['ManagedServicesRenewalResponse']
> = ResolversObject<{
  changeInGP?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  changeInPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  changeReason?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  changeinPricePercent?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currentCost?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currentPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currentPriceGP?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  proposedGP?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ManagedServicesSummaryResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ManagedServicesSummaryResponse'] = ResolversParentTypes['ManagedServicesSummaryResponse']
> = ResolversObject<{
  grossProfit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  grossProfitPercent?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MansEnrollmentSummaryResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MansEnrollmentSummaryResponse'] = ResolversParentTypes['MansEnrollmentSummaryResponse']
> = ResolversObject<{
  pmPercent?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  riskMitigation?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  roles?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['EnrollmentSummary']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface MongoFilterScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['MongoFilter'], any> {
  name: 'MongoFilter';
}

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']
> = ResolversObject<{
  addAgiloftExpenseType?: Resolver<
    Maybe<ResolversTypes['DefaultAddResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_AddAgiloftExpenseTypeArgs>
  >;
  addAgiloftSowContent?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_AddAgiloftSowContentArgs>
  >;
  addAgiloftTravelNoticeType?: Resolver<
    Maybe<ResolversTypes['DefaultAddResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_AddAgiloftTravelNoticeTypeArgs>
  >;
  addCdwRate?: Resolver<
    Maybe<ResolversTypes['DefaultAddResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_AddCdwRateArgs>
  >;
  addCiscoAnnuity?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponseWithID']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_AddCiscoAnnuityArgs, 'params'>
  >;
  addCiscoAnnuityComment?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_AddCiscoAnnuityCommentArgs,
      'ciscoAnnuityComment' | 'ciscoAnnuityId'
    >
  >;
  addCiscoAnnuityContact?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_AddCiscoAnnuityContactArgs,
      'ciscoAnnuityContact' | 'ciscoAnnuityId'
    >
  >;
  addCiscoAnnuityLine?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_AddCiscoAnnuityLineArgs>
  >;
  addCiscoAnnuityPartNumber?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponseWithID']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_AddCiscoAnnuityPartNumberArgs, 'params'>
  >;
  addCiscoSmartAccount?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_AddCiscoSmartAccountArgs>
  >;
  addCustomTaskToSite?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_AddCustomTaskToSiteArgs, 'task'>
  >;
  addCustomer?: Resolver<
    Maybe<ResolversTypes['DefaultAddResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_AddCustomerArgs>
  >;
  addEstimatorSow?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponseWithID']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_AddEstimatorSowArgs, 'params'>
  >;
  addEstimatorTaskNote?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_AddEstimatorTaskNoteArgs, 'note' | 'taskId'>
  >;
  addEstimatorTasksByTaskGroup?: Resolver<
    Maybe<ResolversTypes['AddEstimatorTasksByTaskGroupResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_AddEstimatorTasksByTaskGroupArgs,
      'itemId' | 'siteId' | 'taskGroupId'
    >
  >;
  addPhase?: Resolver<
    Maybe<ResolversTypes['DefaultAddResponse']>,
    ParentType,
    ContextType
  >;
  addPractice?: Resolver<
    Maybe<ResolversTypes['DefaultAddResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_AddPracticeArgs>
  >;
  addProjectComment?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_AddProjectCommentArgs, 'comment' | 'projectId'>
  >;
  addProjectItem?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponseWithID']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_AddProjectItemArgs, 'params'>
  >;
  addProjectItemEstimator?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponseWithID']>,
    ParentType,
    ContextType,
    Partial<Mutation_AddProjectItemEstimatorArgs>
  >;
  addProjectItemQuote?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponseWithID']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_AddProjectItemQuoteArgs, 'params'>
  >;
  addProjectProposal?: Resolver<
    Maybe<ResolversTypes['HandledMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_AddProjectProposalArgs, 'params'>
  >;
  addProjectSow?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponseWithID']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_AddProjectSowArgs, 'params'>
  >;
  addProjectUser?: Resolver<
    Maybe<ResolversTypes['AddProjectUserResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_AddProjectUserArgs, 'params'>
  >;
  addProposalTemplate?: Resolver<
    Maybe<ResolversTypes['HandledMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_AddProposalTemplateArgs>
  >;
  addQuoteLines?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_AddQuoteLinesArgs, 'projectItemId'>
  >;
  addRole?: Resolver<
    Maybe<ResolversTypes['DefaultAddResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_AddRoleArgs>
  >;
  addScopeGroup?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponseWithID']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_AddScopeGroupArgs, 'params'>
  >;
  addScopeItem?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponseWithID']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_AddScopeItemArgs, 'params'>
  >;
  addScopeItemQuestion?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponseWithID']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_AddScopeItemQuestionArgs, 'params'>
  >;
  addScopingSession?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponseWithID']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_AddScopingSessionArgs, 'params'>
  >;
  addSiteInfo?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_AddSiteInfoArgs, 'projectItemId' | 'siteInfo'>
  >;
  addSowSection?: Resolver<
    Maybe<ResolversTypes['DefaultAddResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_AddSowSectionArgs>
  >;
  addTask?: Resolver<
    Maybe<ResolversTypes['DefaultAddResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_AddTaskArgs>
  >;
  addTaskGroup?: Resolver<
    Maybe<ResolversTypes['DefaultAddResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_AddTaskGroupArgs>
  >;
  addTier?: Resolver<
    Maybe<ResolversTypes['DefaultAddResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_AddTierArgs>
  >;
  approveTaskGroupTestVersion?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_ApproveTaskGroupTestVersionArgs, 'id'>
  >;
  buildAgiloftFeeTable?: Resolver<
    Maybe<ResolversTypes['AgiloftApiResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_BuildAgiloftFeeTableArgs>
  >;
  calculateEstimatorTasksBySiteId?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_CalculateEstimatorTasksBySiteIdArgs, 'siteId'>
  >;
  cancelEstimatorBidAssurance?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_CancelEstimatorBidAssuranceArgs, 'projectItemId'>
  >;
  clearAgiloftFeeTable?: Resolver<
    Maybe<ResolversTypes['AgiloftApiResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_ClearAgiloftFeeTableArgs>
  >;
  clearProjectSalesforceData?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_ClearProjectSalesforceDataArgs, 'projectId'>
  >;
  copyProject?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_CopyProjectArgs, 'includeProjectItems' | 'projectId'>
  >;
  copyProjectItemEstimator?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponseWithID']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_CopyProjectItemEstimatorArgs, 'projectItemId'>
  >;
  copySiteAndTasks?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_CopySiteAndTasksArgs,
      'projectItemId' | 'site' | 'siteName'
    >
  >;
  createJiraIssue?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponseWithID']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_CreateJiraIssueArgs, 'params'>
  >;
  createLead?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponseWithID']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_CreateLeadArgs, 'params'>
  >;
  createProject?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponseWithID']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_CreateProjectArgs, 'params'>
  >;
  createTestVersionBySowSection?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_CreateTestVersionBySowSectionArgs, 'sowSectionId'>
  >;
  createTestVersionByTask?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_CreateTestVersionByTaskArgs, 'taskId'>
  >;
  createTestVersionByTaskGroup?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_CreateTestVersionByTaskGroupArgs, 'taskGroupId'>
  >;
  deleteEmailTemplate?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_DeleteEmailTemplateArgs>
  >;
  denyEstimatorBidAssurance?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_DenyEstimatorBidAssuranceArgs, 'projectItemId'>
  >;
  duplicateTaskGroup?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_DuplicateTaskGroupArgs>
  >;
  escalateEstimatorBidAssurance?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_EscalateEstimatorBidAssuranceArgs, 'projectItemId'>
  >;
  generateAgiloftDocuments?: Resolver<
    Maybe<ResolversTypes['AgiloftApiResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_GenerateAgiloftDocumentsArgs>
  >;
  reloadCiscoAnnuityQuoteById?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_ReloadCiscoAnnuityQuoteByIdArgs,
      'ciscoAnnuityId' | 'ciscoQuoteId'
    >
  >;
  reloadProjectProposal?: Resolver<
    Maybe<ResolversTypes['HandledMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_ReloadProjectProposalArgs, 'id'>
  >;
  removeAdminPractice?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RemoveAdminPracticeArgs, 'id'>
  >;
  removeAgiloftExpenseType?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_RemoveAgiloftExpenseTypeArgs>
  >;
  removeAgiloftTravelNoticeType?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_RemoveAgiloftTravelNoticeTypeArgs>
  >;
  removeCdwRate?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_RemoveCdwRateArgs>
  >;
  removeCiscoAnnuityById?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RemoveCiscoAnnuityByIdArgs, 'id'>
  >;
  removeCiscoAnnuityCommentById?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RemoveCiscoAnnuityCommentByIdArgs,
      'ciscoAnnuityCommentId' | 'ciscoAnnuityId'
    >
  >;
  removeCiscoAnnuityContactById?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RemoveCiscoAnnuityContactByIdArgs,
      'ciscoAnnuityContactId' | 'ciscoAnnuityId'
    >
  >;
  removeCiscoAnnuityLineById?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RemoveCiscoAnnuityLineByIdArgs, 'id'>
  >;
  removeCiscoAnnuityPartNumber?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RemoveCiscoAnnuityPartNumberArgs, 'partNumber'>
  >;
  removeCiscoAnnuityQuoteById?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RemoveCiscoAnnuityQuoteByIdArgs,
      'ciscoAnnuityId' | 'ciscoQuoteId'
    >
  >;
  removeEstimatorBidAssuranceNote?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RemoveEstimatorBidAssuranceNoteArgs,
      'messageId' | 'projectItemId'
    >
  >;
  removeEstimatorCustomTaskById?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RemoveEstimatorCustomTaskByIdArgs, 'projectItemId'>
  >;
  removeEstimatorSiteById?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RemoveEstimatorSiteByIdArgs,
      'projectItemId' | 'siteId'
    >
  >;
  removeEstimatorTaskById?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RemoveEstimatorTaskByIdArgs, 'id'>
  >;
  removeEstimatorTaskGroupFromSite?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RemoveEstimatorTaskGroupFromSiteArgs,
      'projectItemId' | 'siteId' | 'taskGroupId'
    >
  >;
  removeEstimatorTaskNote?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RemoveEstimatorTaskNoteArgs, 'id'>
  >;
  removeOpportunity?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RemoveOpportunityArgs, 'id'>
  >;
  removeProjectComment?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RemoveProjectCommentArgs, 'commentId' | 'projectId'>
  >;
  removeProjectItemById?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RemoveProjectItemByIdArgs, 'id'>
  >;
  removeProjectItemEstimator?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RemoveProjectItemEstimatorArgs, 'id'>
  >;
  removeProjectItemQuote?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RemoveProjectItemQuoteArgs, 'id'>
  >;
  removeProjectProposal?: Resolver<
    Maybe<ResolversTypes['HandledMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RemoveProjectProposalArgs, 'id'>
  >;
  removeProjectTeamMember?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RemoveProjectTeamMemberArgs, 'email' | 'projectId'>
  >;
  removeProposalTemplate?: Resolver<
    Maybe<ResolversTypes['HandledMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RemoveProposalTemplateArgs, 'id'>
  >;
  removeQuoteLines?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RemoveQuoteLinesArgs, 'projectItemId'>
  >;
  removeRole?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_RemoveRoleArgs>
  >;
  removeScopeGroup?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RemoveScopeGroupArgs, 'id'>
  >;
  removeScopeItem?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RemoveScopeItemArgs, 'id'>
  >;
  removeScopeItemQuestion?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RemoveScopeItemQuestionArgs,
      'questionId' | 'scopeItemId'
    >
  >;
  removeScopingSession?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RemoveScopingSessionArgs, 'id'>
  >;
  removeSowSection?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RemoveSowSectionArgs, 'id'>
  >;
  removeTask?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_RemoveTaskArgs>
  >;
  removeTaskGroup?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_RemoveTaskGroupArgs>
  >;
  removeTaskGroupTeamMember?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RemoveTaskGroupTeamMemberArgs,
      'email' | 'taskGroupId'
    >
  >;
  removeTaskGroupTestVersion?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RemoveTaskGroupTestVersionArgs, 'id'>
  >;
  removeTasks?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_RemoveTasksArgs>
  >;
  removeTier?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_RemoveTierArgs>
  >;
  requestEstimatorBidAssurance?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RequestEstimatorBidAssuranceArgs, 'projectItemId'>
  >;
  requestWordCofById?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RequestWordCofByIdArgs, 'ciscoAnnuityId'>
  >;
  resetCiscoAnnuityCategories?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_ResetCiscoAnnuityCategoriesArgs, 'ciscoAnnuityId'>
  >;
  resetEstimatorTaskMinGP?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_ResetEstimatorTaskMinGpArgs, 'projectItemId'>
  >;
  restoreToLatestGroupTaskVersion?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RestoreToLatestGroupTaskVersionArgs,
      'projectItemId' | 'siteId'
    >
  >;
  saveCustomTasksToSite?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_SaveCustomTasksToSiteArgs>
  >;
  saveEstimateToPsa?: Resolver<
    Maybe<ResolversTypes['SaveEstimateToPsaResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_SaveEstimateToPsaArgs, 'projectItemId'>
  >;
  saveEstimatorBidAssuranceNote?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_SaveEstimatorBidAssuranceNoteArgs,
      'params' | 'projectItemId'
    >
  >;
  setProjectItemEstimatorRates?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_SetProjectItemEstimatorRatesArgs, 'projectItemId'>
  >;
  setSiteInfo?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_SetSiteInfoArgs,
      'currentSite' | 'projectItemId' | 'siteInfo'
    >
  >;
  syncCdwRates?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType
  >;
  syncServiceItems?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType
  >;
  syncServicePractices?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType
  >;
  syncServiceSkills?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType
  >;
  syncServiceSuppliers?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType
  >;
  updateAdminConfigSettings?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateAdminConfigSettingsArgs>
  >;
  updateAgiloftExpenseType?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateAgiloftExpenseTypeArgs>
  >;
  updateAgiloftFeeSummary?: Resolver<
    Maybe<ResolversTypes['AgiloftApiResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateAgiloftFeeSummaryArgs>
  >;
  updateAgiloftTravelNoticeType?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateAgiloftTravelNoticeTypeArgs>
  >;
  updateCdwRate?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateCdwRateArgs>
  >;
  updateCiscoAnnuity?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateCiscoAnnuityArgs>
  >;
  updateCiscoAnnuityCommentById?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_UpdateCiscoAnnuityCommentByIdArgs,
      'ciscoAnnuityComment' | 'ciscoAnnuityCommentId' | 'ciscoAnnuityId'
    >
  >;
  updateCiscoAnnuityContactById?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_UpdateCiscoAnnuityContactByIdArgs,
      'ciscoAnnuityContact' | 'ciscoAnnuityContactId' | 'ciscoAnnuityId'
    >
  >;
  updateCiscoAnnuityLine?: Resolver<
    Maybe<ResolversTypes['CiscoAnnuityLine']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateCiscoAnnuityLineArgs>
  >;
  updateCiscoAnnuityLines?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateCiscoAnnuityLinesArgs>
  >;
  updateCiscoAnnuityPartNumber?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_UpdateCiscoAnnuityPartNumberArgs, 'params'>
  >;
  updateCiscoAnnuityPartNumbers?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateCiscoAnnuityPartNumbersArgs>
  >;
  updateCiscoAnnuityQuote?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_UpdateCiscoAnnuityQuoteArgs,
      'ciscoActiveQuote' | 'ciscoAnnuityId' | 'ciscoAnnuityQuote'
    >
  >;
  updateCustomer?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateCustomerArgs>
  >;
  updateEmailTemplates?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateEmailTemplatesArgs>
  >;
  updateEstimatorSiteCards?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_UpdateEstimatorSiteCardsArgs,
      'projectItemId' | 'sites'
    >
  >;
  updateEstimatorSow?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateEstimatorSowArgs>
  >;
  updateEstimatorTask?: Resolver<
    Maybe<ResolversTypes['UpdatedTaskMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateEstimatorTaskArgs>
  >;
  updateEstimatorTaskGP?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateEstimatorTaskGpArgs>
  >;
  updateEstimatorTaskNote?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateEstimatorTaskNoteArgs>
  >;
  updateEstimatorTasks?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateEstimatorTasksArgs>
  >;
  updateIncludeInQuoteById?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_UpdateIncludeInQuoteByIdArgs,
      'includeInQuote' | 'quoteId'
    >
  >;
  updateLead?: Resolver<
    Maybe<ResolversTypes['UpdateLeadResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_UpdateLeadArgs, 'params'>
  >;
  updatePhase?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType
  >;
  updatePractice?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdatePracticeArgs>
  >;
  updateProject?: Resolver<
    Maybe<ResolversTypes['UpdateProjectResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_UpdateProjectArgs, 'params'>
  >;
  updateProjectComment?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_UpdateProjectCommentArgs,
      'comment' | 'commentId' | 'projectId'
    >
  >;
  updateProjectItem?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateProjectItemArgs>
  >;
  updateProjectItemEstimator?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateProjectItemEstimatorArgs>
  >;
  updateProjectItemEstimatorCalculation?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateProjectItemEstimatorCalculationArgs>
  >;
  updateProjectItemEstimatorVariables?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateProjectItemEstimatorVariablesArgs>
  >;
  updateProjectItemQuote?: Resolver<
    Maybe<ResolversTypes['ProjectItemQuote']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_UpdateProjectItemQuoteArgs, 'params'>
  >;
  updateProjectProposal?: Resolver<
    Maybe<ResolversTypes['ProjectProposal']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateProjectProposalArgs>
  >;
  updateProjectSalesforceData?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_UpdateProjectSalesforceDataArgs,
      'projectId' | 'salesforceData'
    >
  >;
  updateProjectSow?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateProjectSowArgs>
  >;
  updateProjectStatus?: Resolver<
    Maybe<ResolversTypes['UpdateProjectResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_UpdateProjectStatusArgs, 'params'>
  >;
  updateProjectTeamMember?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_UpdateProjectTeamMemberArgs,
      'projectId' | 'projectTeam'
    >
  >;
  updateProposalTemplate?: Resolver<
    Maybe<ResolversTypes['HandledMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateProposalTemplateArgs>
  >;
  updateQuoteLines?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_UpdateQuoteLinesArgs, 'projectItemId'>
  >;
  updateRole?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateRoleArgs>
  >;
  updateScopeGroup?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_UpdateScopeGroupArgs, 'id'>
  >;
  updateScopeItem?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_UpdateScopeItemArgs, 'id'>
  >;
  updateScopeItemQuestion?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_UpdateScopeItemQuestionArgs,
      'id' | 'modifiedQuestion'
    >
  >;
  updateScopingSession?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_UpdateScopingSessionArgs, 'id'>
  >;
  updateSowSection?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateSowSectionArgs>
  >;
  updateTask?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateTaskArgs>
  >;
  updateTaskGroup?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateTaskGroupArgs>
  >;
  updateTasks?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateTasksArgs>
  >;
  updateTier?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateTierArgs>
  >;
  updateUser?: Resolver<
    Maybe<ResolversTypes['DefaultMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_UpdateUserArgs, 'params'>
  >;
  validateReadyForAgiloft?: Resolver<
    Maybe<ResolversTypes['AgiloftApiResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_ValidateReadyForAgiloftArgs>
  >;
}>;

export type OpportunityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Opportunity'] = ResolversParentTypes['Opportunity']
> = ResolversObject<{
  _id?: Resolver<Maybe<ResolversTypes['ANY']>, ParentType, ContextType>;
  annuityTotal?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  area?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cdwCustomerAccountNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  cewProvided?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  closedOn?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  closedReason?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  closedStatus?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  comments?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ProjectComment']>>>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  customerArea?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  customerId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  customerName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  customerRegion?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  customerSector?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  customerSegment?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  demoProvided?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  estClose?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  lastModified?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lastModifiedBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  managedServiceTotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  onedriveId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  onedriveLink?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  opportunity?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  opportunityTotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  organization?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  probability?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  productTotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  projectOwner?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  projectOwnerDisplayName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  projectTeam?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ProjectTeam']>>>,
    ParentType,
    ContextType
  >;
  quoteTotal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  salesForceId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  salesForceRegion?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  salesForceUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  serviceLocation?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  serviceTotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<
    Maybe<ResolversTypes['OpportunityStatusEnum']>,
    ParentType,
    ContextType
  >;
  subscriptionTotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OpportunityTableResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OpportunityTable'] = ResolversParentTypes['OpportunityTable']
> = ResolversObject<{
  _id?: Resolver<Maybe<ResolversTypes['ANY']>, ParentType, ContextType>;
  annuityTotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  closedOn?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  customerId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  customerName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  estClose?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  managedServiceTotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opportunityTotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  organization?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  probability?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  productTotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  projectOwner?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  projectOwnerDisplayName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  projectTeam?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ProjectTeam']>>>,
    ParentType,
    ContextType
  >;
  quoteTotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  salesRep?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serviceTotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  solutionArchitect?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<
    Maybe<ResolversTypes['OpportunityStatusEnum']>,
    ParentType,
    ContextType
  >;
  subscriptionTotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrganizationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Organization'] = ResolversParentTypes['Organization']
> = ResolversObject<{
  bidAssuranceEmail?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  departments?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sparkAllRooms?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  sparkWonRooms?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PhaseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Phase'] = ResolversParentTypes['Phase']
> = ResolversObject<{
  defaultPercent?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lifecycle?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  practice?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PhaseBreakdownResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PhaseBreakdown'] = ResolversParentTypes['PhaseBreakdown']
> = ResolversObject<{
  placeholder?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PracticeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Practice'] = ResolversParentTypes['Practice']
> = ResolversObject<{
  bidAssurance?: Resolver<
    Maybe<ResolversTypes['ANY']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastModified?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  managedSvc?: Resolver<Maybe<ResolversTypes['ANY']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  peerReviewEmail?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  practiceId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  practiceUsernames?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  psm?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  salesForceId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  serviceLocationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  web?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProjectCommentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProjectComment'] = ResolversParentTypes['ProjectComment']
> = ResolversObject<{
  _id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  createdBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProjectCustomTaskPhaseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProjectCustomTaskPhase'] = ResolversParentTypes['ProjectCustomTaskPhase']
> = ResolversObject<{
  percent?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  phaseId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taskId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProjectEstimatesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProjectEstimates'] = ResolversParentTypes['ProjectEstimates']
> = ResolversObject<{
  cdwCost?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  managedServices?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  pmHours?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  projectItemId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  serviceEstimate?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  sites?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Site']>>>,
    ParentType,
    ContextType
  >;
  taskHours?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProjectItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProjectItem'] = ResolversParentTypes['ProjectItem']
> = ResolversObject<{
  billingyCycle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  ciscoEA?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  createdBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdOn?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  hwsw?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  includeInProjectTotal?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  includeInProposal?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  includeInSow?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  managedServiceTotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  mrrCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  mrrPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  numberOfPhoneSeatsAgents?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priceTotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryBOMVendor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryTech?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  product?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  servicesTotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  solution?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  solutionType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  terms?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vendor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProjectItemEstimatorsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProjectItemEstimators'] = ResolversParentTypes['ProjectItemEstimators']
> = ResolversObject<{
  actualSellPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  agiloftExpenseClause?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftExpenseMiles?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  agiloftExpenseType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftFeeStructure?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftFundingAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  agiloftGenerateDocuments?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  agiloftMultiFeeTables?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  agiloftPmClause?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftPrintTemplateType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftServiceFeeClause?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftSummaryTable?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  agiloftTravelClause?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftTravelPenaltyAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  agiloftTravelType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  bidAssuranceNotes?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['BidAssuranceNote']>>>,
    ParentType,
    ContextType
  >;
  bidAssuranceStatus?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  bidAssuranceStatusHistories?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['BidAssuranceStatusHistory']>>>,
    ParentType,
    ContextType
  >;
  billingType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  calculatedSellPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  changeReason?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  currentCost?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currentPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currentProposalId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  endDate?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  fixedFeeMilestones?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['FixedFeeMilestone']>>>,
    ParentType,
    ContextType
  >;
  hours?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  includePartnerServicesInFixedFee?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  includeTAndEFixedFee?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  isManagedServicesRenewal?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  managedServicesContractLength?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  managedServicesCustomDeal?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  managedServicesCustomerType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  managedServicesDealType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  managedServicesTransitionEstimatedDuration?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  managedServicesTransitionFeeType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  managedServicesTransitionStartDate?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  overtimePercent?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  pmPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pmRole?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  projectItemId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  psaEstimateId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  psaEstimateProduct?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  psaSyncCombined?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  psaSyncHistories?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['PsaSyncHistory']>>>,
    ParentType,
    ContextType
  >;
  riskMitigation?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  rmsProposal?: Resolver<
    Maybe<ResolversTypes['RMSProposal']>,
    ParentType,
    ContextType
  >;
  salesForceId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  salesForceRegion?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  salesForceUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  sellingAsFixedFee?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  sites?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Site']>>>,
    ParentType,
    ContextType
  >;
  startDate?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  tAndETotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProjectItemQuoteResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProjectItemQuote'] = ResolversParentTypes['ProjectItemQuote']
> = ResolversObject<{
  globalQuoteHardwareSettings?: Resolver<
    Maybe<ResolversTypes['ProjectItemQuoteSettings']>,
    ParentType,
    ContextType
  >;
  globalQuoteServiceSettings?: Resolver<
    Maybe<ResolversTypes['ProjectItemQuoteSettings']>,
    ParentType,
    ContextType
  >;
  globalQuoteSettings?: Resolver<
    Maybe<ResolversTypes['ProjectItemQuoteGlobalSettings']>,
    ParentType,
    ContextType
  >;
  globalQuoteSoftwareSettings?: Resolver<
    Maybe<ResolversTypes['ProjectItemQuoteSettings']>,
    ParentType,
    ContextType
  >;
  globalQuoteSupportSettings?: Resolver<
    Maybe<ResolversTypes['ProjectItemQuoteSettings']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  projectItemId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  projectProductsId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  quoteLines?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ProjectItemQuoteLine']>>>,
    ParentType,
    ContextType
  >;
  systems?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ProjectItemQuoteSystem']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProjectItemQuoteGlobalSettingsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProjectItemQuoteGlobalSettings'] = ResolversParentTypes['ProjectItemQuoteGlobalSettings']
> = ResolversObject<{
  adderTaxRate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  shippingTaxRate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProjectItemQuoteLineResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProjectItemQuoteLine'] = ResolversParentTypes['ProjectItemQuoteLine']
> = ResolversObject<{
  billingModel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  billingTerm?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  billingType?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  credit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  creditProvidedBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  customerCost?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  customerDiscount?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  customerTotalCost?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  discount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  edc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  includeHwSwCost?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  includeProposal?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  listUnitCost?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  margin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  markup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  parentId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  partNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  productId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  projectId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  projectQuoteSystemId?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  selectIndex?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalCost?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  totalGp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalSalesGp?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unitCost?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unitGp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unitSalesGp?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  userDescription?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  vendor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProjectItemQuoteSettingsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProjectItemQuoteSettings'] = ResolversParentTypes['ProjectItemQuoteSettings']
> = ResolversObject<{
  discount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  margin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  markup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProjectItemQuoteSystemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProjectItemQuoteSystem'] = ResolversParentTypes['ProjectItemQuoteSystem']
> = ResolversObject<{
  cdwTotal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerTotal?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  include?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  includeHwSwCost?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  includeProposal?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sortByType?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProjectPracticeAndRolesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProjectPracticeAndRoles'] = ResolversParentTypes['ProjectPracticeAndRoles']
> = ResolversObject<{
  practices?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Practice']>>>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  roles?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Role']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProjectProposalResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProjectProposal'] = ResolversParentTypes['ProjectProposal']
> = ResolversObject<{
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  estimatorId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['IntOrString']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  previewContent?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  projectItemId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  templateId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProjectSowResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProjectSow'] = ResolversParentTypes['ProjectSow']
> = ResolversObject<{
  agiloftSentStatus?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftSowId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  businessOutcome?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  businessOutcomeHelp?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  estimatorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  executiveSummaryDrivers?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  executiveSummaryDriversHelp?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  executiveSummaryOverview?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  executiveSummaryOverviewHelp?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['IntOrString']>, ParentType, ContextType>;
  modified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  outcomeBased?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  outcomeIncluded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  outcomeProjectScopePhases?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  outcomeProjectScopePhasesHelp?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  projectItemId?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >;
  taskGroup?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  technicalOutcome?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  technicalOutcomeHelp?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  version?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProjectStatusHistoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProjectStatusHistory'] = ResolversParentTypes['ProjectStatusHistory']
> = ResolversObject<{
  customerDescription?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lastModifiedBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lastModifiedByUserId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lastModifiedByUsername?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  projectName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timestamp?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProjectTeamResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProjectTeam'] = ResolversParentTypes['ProjectTeam']
> = ResolversObject<{
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProjectUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProjectUser'] = ResolversParentTypes['ProjectUser']
> = ResolversObject<{
  createdAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  customClaims?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  disabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  displayName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emailVerified?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  metadata?: Resolver<
    Maybe<ResolversTypes['FBUserMetaData']>,
    ParentType,
    ContextType
  >;
  passwordHash?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  passwordSalt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  phoneNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  photoURL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tenantId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tokensValidAfterTime?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  uid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProposalTemplateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProposalTemplate'] = ResolversParentTypes['ProposalTemplate']
> = ResolversObject<{
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  dataCollection?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['DataCollectionItem']>>>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['IntOrString']>, ParentType, ContextType>;
  isDataCollectionIncluded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  isSystem?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  proposalTemplateId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PsaSyncHistoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PsaSyncHistory'] = ResolversParentTypes['PsaSyncHistory']
> = ResolversObject<{
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isSynced?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  psaEstimateId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']
> = ResolversObject<{
  getActiveSitesAndTasksByProjectItemId?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ActiveSiteWithTasks']>>>,
    ParentType,
    ContextType,
    RequireFields<
      Query_GetActiveSitesAndTasksByProjectItemIdArgs,
      'projectItemId'
    >
  >;
  getAdminConfigSettings?: Resolver<
    Maybe<ResolversTypes['GetAdminConfigSettingsResponse']>,
    ParentType,
    ContextType,
    Partial<Query_GetAdminConfigSettingsArgs>
  >;
  getAgiloftExpenseTypes?: Resolver<
    Maybe<ResolversTypes['GetAgiloftExpenseTypesResponse']>,
    ParentType,
    ContextType,
    Partial<Query_GetAgiloftExpenseTypesArgs>
  >;
  getAgiloftRoles?: Resolver<
    Maybe<ResolversTypes['GetAgiloftRolesResponse']>,
    ParentType,
    ContextType
  >;
  getAgiloftSOWById?: Resolver<
    Maybe<ResolversTypes['AgiloftSOW']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetAgiloftSowByIdArgs, 'id'>
  >;
  getAgiloftTravelNoticeTypes?: Resolver<
    Maybe<ResolversTypes['GetAgiloftTravelNoticeTypesResponse']>,
    ParentType,
    ContextType,
    Partial<Query_GetAgiloftTravelNoticeTypesArgs>
  >;
  getAgiloftTypeOptionsByProjectItemId?: Resolver<
    Maybe<ResolversTypes['TaskGroupsPrintOptions']>,
    ParentType,
    ContextType,
    RequireFields<
      Query_GetAgiloftTypeOptionsByProjectItemIdArgs,
      'projectItemId'
    >
  >;
  getCdwRateById?: Resolver<
    Maybe<ResolversTypes['CdwRate']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetCdwRateByIdArgs, 'id'>
  >;
  getCdwRates?: Resolver<
    Maybe<ResolversTypes['GetCdwRatesResponse']>,
    ParentType,
    ContextType,
    Partial<Query_GetCdwRatesArgs>
  >;
  getCdwRolesAndRatesByPracticeIds?: Resolver<
    Maybe<ResolversTypes['GetRolesAndRatesResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetCdwRolesAndRatesByPracticeIdsArgs, 'practiceIds'>
  >;
  getCiscoAnnuities?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CiscoAnnuity']>>>,
    ParentType,
    ContextType,
    Partial<Query_GetCiscoAnnuitiesArgs>
  >;
  getCiscoAnnuityAdminQuoteByProjectItemId?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CiscoAnnuityLine']>>>,
    ParentType,
    ContextType,
    RequireFields<
      Query_GetCiscoAnnuityAdminQuoteByProjectItemIdArgs,
      'projectItemId'
    >
  >;
  getCiscoAnnuityById?: Resolver<
    Maybe<ResolversTypes['CiscoAnnuity']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetCiscoAnnuityByIdArgs, 'id'>
  >;
  getCiscoAnnuityByProjectItemId?: Resolver<
    Maybe<ResolversTypes['CiscoAnnuity']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetCiscoAnnuityByProjectItemIdArgs, 'projectItemId'>
  >;
  getCiscoAnnuityCategories?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CiscoAnnuityCategory']>>>,
    ParentType,
    ContextType,
    Partial<Query_GetCiscoAnnuityCategoriesArgs>
  >;
  getCiscoAnnuityComments?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CiscoAnnuityComment']>>>,
    ParentType,
    ContextType,
    RequireFields<Query_GetCiscoAnnuityCommentsArgs, 'projectItemId'>
  >;
  getCiscoAnnuityLineById?: Resolver<
    Maybe<ResolversTypes['CiscoAnnuityLine']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetCiscoAnnuityLineByIdArgs, 'id'>
  >;
  getCiscoAnnuityLines?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CiscoAnnuityLine']>>>,
    ParentType,
    ContextType,
    Partial<Query_GetCiscoAnnuityLinesArgs>
  >;
  getCiscoAnnuityLinesByProjectItemId?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CiscoAnnuityLine']>>>,
    ParentType,
    ContextType,
    RequireFields<
      Query_GetCiscoAnnuityLinesByProjectItemIdArgs,
      'projectItemId'
    >
  >;
  getCiscoAnnuityPartNumbers?: Resolver<
    Maybe<ResolversTypes['GetCiscoAnnuityPartNumbersResponse']>,
    ParentType,
    ContextType,
    Partial<Query_GetCiscoAnnuityPartNumbersArgs>
  >;
  getCiscoAnnuityQuoteByProjectItemId?: Resolver<
    Maybe<ResolversTypes['CiscoAnnuityQuoteResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Query_GetCiscoAnnuityQuoteByProjectItemIdArgs,
      'projectItemId'
    >
  >;
  getCiscoCcwEstimateById?: Resolver<
    Maybe<ResolversTypes['GetCiscoCcwEstimateByIdResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Query_GetCiscoCcwEstimateByIdArgs,
      'ciscoAnnuityId' | 'ciscoEstimateId'
    >
  >;
  getCiscoCcwQuotesByDealId?: Resolver<
    Maybe<ResolversTypes['GetCiscoCcwQuoteByDealIdResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Query_GetCiscoCcwQuotesByDealIdArgs,
      'ciscoAnnuityId' | 'ciscoDealId'
    >
  >;
  getCiscoQuoteById?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CiscoAnnuityLine']>>>,
    ParentType,
    ContextType,
    Partial<Query_GetCiscoQuoteByIdArgs>
  >;
  getCiscoQuotesByDealId?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CiscoQuote']>>>,
    ParentType,
    ContextType,
    Partial<Query_GetCiscoQuotesByDealIdArgs>
  >;
  getCiscoSmartAccountsByCustomerId?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CiscoSmartAccount']>>>,
    ParentType,
    ContextType,
    Partial<Query_GetCiscoSmartAccountsByCustomerIdArgs>
  >;
  getCiscoSubscriptionLinesBySubscriptionId?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CiscoSubscriptionLine']>>>,
    ParentType,
    ContextType,
    RequireFields<
      Query_GetCiscoSubscriptionLinesBySubscriptionIdArgs,
      'subscriptionId'
    >
  >;
  getCollections?: Resolver<
    Maybe<ResolversTypes['GetCollectionsResponse']>,
    ParentType,
    ContextType
  >;
  getCustomServicesByProjectId?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CustomService']>>>,
    ParentType,
    ContextType,
    RequireFields<Query_GetCustomServicesByProjectIdArgs, 'projectId'>
  >;
  getCustomer?: Resolver<
    Maybe<ResolversTypes['Customer']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetCustomerArgs, 'id'>
  >;
  getCustomers?: Resolver<
    Maybe<ResolversTypes['GetCustomersResponse']>,
    ParentType,
    ContextType,
    Partial<Query_GetCustomersArgs>
  >;
  getCustomersByOpportunities?: Resolver<
    Maybe<ResolversTypes['GetCustomersByOpportunityResponse']>,
    ParentType,
    ContextType,
    Partial<Query_GetCustomersByOpportunitiesArgs>
  >;
  getDistinctSolutions?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  getDistinctTiers?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  getDistinctVendors?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  getEmailTemplates?: Resolver<
    Maybe<ResolversTypes['GetEmailTemplatesResponse']>,
    ParentType,
    ContextType,
    Partial<Query_GetEmailTemplatesArgs>
  >;
  getEstimatorBidAssuranceNotes?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['BidAssuranceNote']>>>,
    ParentType,
    ContextType,
    RequireFields<Query_GetEstimatorBidAssuranceNotesArgs, 'projectItemId'>
  >;
  getEstimatorBidAssuranceStatusHistory?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['BidAssuranceStatusHistory']>>>,
    ParentType,
    ContextType,
    RequireFields<
      Query_GetEstimatorBidAssuranceStatusHistoryArgs,
      'projectItemId'
    >
  >;
  getEstimatorCustomTasksBySiteId?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Task']>>>,
    ParentType,
    ContextType,
    RequireFields<Query_GetEstimatorCustomTasksBySiteIdArgs, 'siteId'>
  >;
  getEstimatorHasBidAssuranceUrl?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetEstimatorHasBidAssuranceUrlArgs, 'projectItemId'>
  >;
  getEstimatorIncludesManagedServices?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<
      Query_GetEstimatorIncludesManagedServicesArgs,
      'projectItemId'
    >
  >;
  getEstimatorIncludesSummaryTasks?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetEstimatorIncludesSummaryTasksArgs, 'projectItemId'>
  >;
  getEstimatorProposalTemplates?: Resolver<
    Maybe<ResolversTypes['GetProposalTemplatesResponse']>,
    ParentType,
    ContextType,
    Partial<Query_GetEstimatorProposalTemplatesArgs>
  >;
  getEstimatorSitesByProjectItemId?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Site']>>>,
    ParentType,
    ContextType,
    RequireFields<Query_GetEstimatorSitesByProjectItemIdArgs, 'projectItemId'>
  >;
  getEstimatorTaskById?: Resolver<
    Maybe<ResolversTypes['Task']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetEstimatorTaskByIdArgs, 'id'>
  >;
  getEstimatorTaskGroupScope?: Resolver<
    Maybe<ResolversTypes['GetTaskGroupSowResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Query_GetEstimatorTaskGroupScopeArgs,
      'projectId' | 'projectItemId' | 'taskGroupId'
    >
  >;
  getEstimatorTaskGroupsByProjectItemId?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['SowTaskGroup']>>>,
    ParentType,
    ContextType,
    RequireFields<
      Query_GetEstimatorTaskGroupsByProjectItemIdArgs,
      'projectItemId'
    >
  >;
  getEstimatorTaskNotesByTaskId?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TaskNote']>>>,
    ParentType,
    ContextType,
    RequireFields<Query_GetEstimatorTaskNotesByTaskIdArgs, 'taskId'>
  >;
  getEstimatorTasksBySiteId?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Task']>>>,
    ParentType,
    ContextType,
    RequireFields<Query_GetEstimatorTasksBySiteIdArgs, 'siteId'>
  >;
  getEstimatorTotalsByItemId?: Resolver<
    Maybe<ResolversTypes['EstimatorTotals']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetEstimatorTotalsByItemIdArgs, 'itemId'>
  >;
  getInvestmentSummaryByEstimatorId?: Resolver<
    Maybe<ResolversTypes['InvestmentSummary']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetInvestmentSummaryByEstimatorIdArgs, 'projectItemId'>
  >;
  getInvestmentSummaryByProjectId?: Resolver<
    Maybe<ResolversTypes['InvestmentSummary']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetInvestmentSummaryByProjectIdArgs, 'projectId'>
  >;
  getLatestVersionTaskGroup?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<
      Query_GetLatestVersionTaskGroupArgs,
      'projectItemId' | 'siteId'
    >
  >;
  getLeads?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Lead']>>>,
    ParentType,
    ContextType,
    Partial<Query_GetLeadsArgs>
  >;
  getManagedServicesRenewalByEstimatorId?: Resolver<
    Maybe<ResolversTypes['ManagedServicesRenewalResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Query_GetManagedServicesRenewalByEstimatorIdArgs,
      'projectItemId'
    >
  >;
  getManagedServicesSummaryByEstimatorId?: Resolver<
    Maybe<ResolversTypes['ManagedServicesSummaryResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Query_GetManagedServicesSummaryByEstimatorIdArgs,
      'projectItemId'
    >
  >;
  getMansEnrollmentSummaryByEstimatorId?: Resolver<
    Maybe<ResolversTypes['MansEnrollmentSummaryResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Query_GetMansEnrollmentSummaryByEstimatorIdArgs,
      'projectItemId'
    >
  >;
  getNextTaskId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetNextTaskIdArgs, 'taskGroupId'>
  >;
  getOpportunities?: Resolver<
    Maybe<ResolversTypes['GetOpportunityResponse']>,
    ParentType,
    ContextType,
    Partial<Query_GetOpportunitiesArgs>
  >;
  getOrganizations?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Organization']>>>,
    ParentType,
    ContextType
  >;
  getPhaseBreakdownByProjectId?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['PhaseBreakdown']>>>,
    ParentType,
    ContextType,
    RequireFields<Query_GetPhaseBreakdownByProjectIdArgs, 'projectId'>
  >;
  getPracticeById?: Resolver<
    Maybe<ResolversTypes['AdminPractice']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetPracticeByIdArgs, 'id'>
  >;
  getPractices?: Resolver<
    Maybe<ResolversTypes['getPracticesResponse']>,
    ParentType,
    ContextType,
    Partial<Query_GetPracticesArgs>
  >;
  getProject?: Resolver<
    Maybe<ResolversTypes['Opportunity']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetProjectArgs, 'id'>
  >;
  getProjectCommentsByProjectId?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ProjectComment']>>>,
    ParentType,
    ContextType,
    RequireFields<Query_GetProjectCommentsByProjectIdArgs, 'projectId'>
  >;
  getProjectItem?: Resolver<
    Maybe<ResolversTypes['ProjectItem']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetProjectItemArgs, 'id'>
  >;
  getProjectItemEstimator?: Resolver<
    Maybe<ResolversTypes['ProjectItemEstimators']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetProjectItemEstimatorArgs, 'id'>
  >;
  getProjectItemEstimatorByItemId?: Resolver<
    Maybe<ResolversTypes['ProjectItemEstimators']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetProjectItemEstimatorByItemIdArgs, 'projectItemId'>
  >;
  getProjectItemEstimatorByItemIds?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ProjectItemEstimators']>>>,
    ParentType,
    ContextType,
    Partial<Query_GetProjectItemEstimatorByItemIdsArgs>
  >;
  getProjectItemEstimatorRates?: Resolver<
    Maybe<ResolversTypes['GetProjectItemEstimatorRatesResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetProjectItemEstimatorRatesArgs, 'projectItemId'>
  >;
  getProjectItemQuoteById?: Resolver<
    Maybe<ResolversTypes['ProjectItemQuote']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetProjectItemQuoteByIdArgs, 'id'>
  >;
  getProjectItemQuotesByProjectItemId?: Resolver<
    Maybe<ResolversTypes['ProjectItemQuote']>,
    ParentType,
    ContextType,
    RequireFields<
      Query_GetProjectItemQuotesByProjectItemIdArgs,
      'projectItemId'
    >
  >;
  getProjectItems?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ProjectItem']>>>,
    ParentType,
    ContextType,
    RequireFields<Query_GetProjectItemsArgs, 'projectId'>
  >;
  getProjectProposalById?: Resolver<
    Maybe<ResolversTypes['ProjectProposal']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetProjectProposalByIdArgs, 'id'>
  >;
  getProjectProposalsByEstimatorId?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ProjectProposal']>>>,
    ParentType,
    ContextType,
    RequireFields<
      Query_GetProjectProposalsByEstimatorIdArgs,
      'estimatorId' | 'projectId'
    >
  >;
  getProjectProposalsByProjectId?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ProjectProposal']>>>,
    ParentType,
    ContextType,
    RequireFields<Query_GetProjectProposalsByProjectIdArgs, 'projectId'>
  >;
  getProjectSowById?: Resolver<
    Maybe<ResolversTypes['ProjectSow']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetProjectSowByIdArgs, 'id'>
  >;
  getProjectStatus?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ProjectStatusHistory']>>>,
    ParentType,
    ContextType,
    RequireFields<Query_GetProjectStatusArgs, 'id'>
  >;
  getProjectTaskGroupScope?: Resolver<
    Maybe<ResolversTypes['GetTaskGroupSowResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetProjectTaskGroupScopeArgs, 'projectId'>
  >;
  getProjectUsersByProjectId?: Resolver<
    Maybe<ResolversTypes['GetProjectUsersResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetProjectUsersByProjectIdArgs, 'projectId'>
  >;
  getProjects?: Resolver<
    Maybe<ResolversTypes['GetProjectsResponse']>,
    ParentType,
    ContextType
  >;
  getProposalTemplateById?: Resolver<
    Maybe<ResolversTypes['ProposalTemplate']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetProposalTemplateByIdArgs, 'id'>
  >;
  getProposalTemplates?: Resolver<
    Maybe<ResolversTypes['GetProposalTemplatesResponse']>,
    ParentType,
    ContextType,
    Partial<Query_GetProposalTemplatesArgs>
  >;
  getPsaBreakdownByProjectId?: Resolver<
    Maybe<ResolversTypes['GetEstimateToPSAResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetPsaBreakdownByProjectIdArgs, 'projectId'>
  >;
  getRmsGPAnalysisByItemId?: Resolver<
    Maybe<ResolversTypes['RmsGPAnalysis']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetRmsGpAnalysisByItemIdArgs, 'projectItemId'>
  >;
  getRmsOneTimeCostTemplateByItemId?: Resolver<
    Maybe<ResolversTypes['RmsOneTimeCost']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetRmsOneTimeCostTemplateByItemIdArgs, 'projectItemId'>
  >;
  getRmsProposalByItemId?: Resolver<
    Maybe<ResolversTypes['RMSProposal']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetRmsProposalByItemIdArgs, 'projectItemId'>
  >;
  getRoleById?: Resolver<
    Maybe<ResolversTypes['AdminRole']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetRoleByIdArgs, 'id'>
  >;
  getRoles?: Resolver<
    Maybe<ResolversTypes['GetRolesResponse']>,
    ParentType,
    ContextType,
    Partial<Query_GetRolesArgs>
  >;
  getSalesHistory?: Resolver<
    Maybe<ResolversTypes['GetSalesHistoryResponse']>,
    ParentType,
    ContextType
  >;
  getSalesRepsTrending?: Resolver<
    Maybe<ResolversTypes['GetSalesRepsTrendingResponse']>,
    ParentType,
    ContextType
  >;
  getScopeGroupById?: Resolver<
    Maybe<ResolversTypes['ScopeGroup']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetScopeGroupByIdArgs, 'id'>
  >;
  getScopeGroups?: Resolver<
    Maybe<ResolversTypes['GetScopeGroupsResponse']>,
    ParentType,
    ContextType
  >;
  getScopeItemById?: Resolver<
    Maybe<ResolversTypes['ScopeItem']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetScopeItemByIdArgs, 'id'>
  >;
  getScopeItems?: Resolver<
    Maybe<ResolversTypes['GetScopeItemsResponse']>,
    ParentType,
    ContextType
  >;
  getScopeItemsByGroup?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ScopeItem']>>>,
    ParentType,
    ContextType,
    RequireFields<Query_GetScopeItemsByGroupArgs, 'scopeGroupId'>
  >;
  getScopingSessionById?: Resolver<
    Maybe<ResolversTypes['ScopingSession']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetScopingSessionByIdArgs, 'id'>
  >;
  getScopingSessionsByProjectItemId?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ScopingSession']>>>,
    ParentType,
    ContextType,
    RequireFields<Query_GetScopingSessionsByProjectItemIdArgs, 'projectItemId'>
  >;
  getServiceCategories?: Resolver<
    Maybe<ResolversTypes['getServiceCategoriesResponse']>,
    ParentType,
    ContextType
  >;
  getServiceItems?: Resolver<
    Maybe<ResolversTypes['GetServiceItemsResponse']>,
    ParentType,
    ContextType,
    Partial<Query_GetServiceItemsArgs>
  >;
  getServiceLocations?: Resolver<
    Maybe<ResolversTypes['getServiceLocationsResponse']>,
    ParentType,
    ContextType,
    Partial<Query_GetServiceLocationsArgs>
  >;
  getServiceLocationsRoles?: Resolver<
    Maybe<ResolversTypes['getServiceLocationsRolesResponse']>,
    ParentType,
    ContextType
  >;
  getServicePractices?: Resolver<
    Maybe<ResolversTypes['getServicePracticesResponse']>,
    ParentType,
    ContextType,
    Partial<Query_GetServicePracticesArgs>
  >;
  getServiceSkills?: Resolver<
    Maybe<ResolversTypes['GetServiceSkillsResponse']>,
    ParentType,
    ContextType,
    Partial<Query_GetServiceSkillsArgs>
  >;
  getServiceSummaryByEstimatorId?: Resolver<
    Maybe<ResolversTypes['ServiceSummaryResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetServiceSummaryByEstimatorIdArgs, 'projectItemId'>
  >;
  getServiceSummaryByProjectId?: Resolver<
    Maybe<ResolversTypes['ServiceSummaryResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetServiceSummaryByProjectIdArgs, 'projectId'>
  >;
  getServiceSuppliers?: Resolver<
    Maybe<ResolversTypes['GetServiceSuppliersResponse']>,
    ParentType,
    ContextType,
    Partial<Query_GetServiceSuppliersArgs>
  >;
  getSiteBreakdownByProjectId?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['SiteBreakdown']>>>,
    ParentType,
    ContextType,
    RequireFields<Query_GetSiteBreakdownByProjectIdArgs, 'projectId'>
  >;
  getSitesWithTasksByProjectItemId?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['SiteWithTasks']>>>,
    ParentType,
    ContextType,
    RequireFields<Query_GetSitesWithTasksByProjectItemIdArgs, 'projectItemId'>
  >;
  getSkillSummaryByEstimatorId?: Resolver<
    Maybe<ResolversTypes['SkillSummary']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetSkillSummaryByEstimatorIdArgs, 'projectItemId'>
  >;
  getSowByEstimatorId?: Resolver<
    Maybe<ResolversTypes['ProjectSow']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetSowByEstimatorIdArgs, 'estimatorId'>
  >;
  getSowByProjectId?: Resolver<
    Maybe<ResolversTypes['ProjectSow']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetSowByProjectIdArgs, 'projectId'>
  >;
  getSowByProjectItemId?: Resolver<
    Maybe<ResolversTypes['ProjectSow']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetSowByProjectItemIdArgs, 'projectItemId'>
  >;
  getSowSectionById?: Resolver<
    Maybe<ResolversTypes['SowSection']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetSowSectionByIdArgs, 'id'>
  >;
  getSowSectionByIdAndVersion?: Resolver<
    Maybe<ResolversTypes['SowSection']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetSowSectionByIdAndVersionArgs, 'id' | 'version'>
  >;
  getSowSectionVersionList?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Int']>>>,
    ParentType,
    ContextType,
    RequireFields<Query_GetSowSectionVersionListArgs, 'id'>
  >;
  getSowSections?: Resolver<
    Maybe<ResolversTypes['GetSowSectionsResponse']>,
    ParentType,
    ContextType
  >;
  getSowSectionsById?: Resolver<
    Maybe<ResolversTypes['SowSection']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetSowSectionsByIdArgs, 'id'>
  >;
  getSprintSummaryByEstimatorId?: Resolver<
    Maybe<ResolversTypes['SprintSummary']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetSprintSummaryByEstimatorIdArgs, 'projectItemId'>
  >;
  getStandardRmsProposalTemplateByItemId?: Resolver<
    Maybe<ResolversTypes['RmsProposalPricing']>,
    ParentType,
    ContextType,
    RequireFields<
      Query_GetStandardRmsProposalTemplateByItemIdArgs,
      'projectItemId'
    >
  >;
  getSystemProposalById?: Resolver<
    Maybe<ResolversTypes['ProjectProposal']>,
    ParentType,
    ContextType,
    RequireFields<
      Query_GetSystemProposalByIdArgs,
      'projectId' | 'projectItemId' | 'proposalId'
    >
  >;
  getTaskById?: Resolver<
    Maybe<ResolversTypes['Task']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetTaskByIdArgs, 'id'>
  >;
  getTaskByIdAndVersion?: Resolver<
    Maybe<ResolversTypes['Task']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetTaskByIdAndVersionArgs, 'id' | 'version'>
  >;
  getTaskDependenciesById?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType,
    RequireFields<Query_GetTaskDependenciesByIdArgs, 'id'>
  >;
  getTaskGroupById?: Resolver<
    Maybe<ResolversTypes['AdminTaskGroup']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetTaskGroupByIdArgs, 'id'>
  >;
  getTaskGroupByIdAndVersion?: Resolver<
    Maybe<ResolversTypes['AdminTaskGroup']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetTaskGroupByIdAndVersionArgs, 'id' | 'version'>
  >;
  getTaskGroupDependenciesById?: Resolver<
    Maybe<ResolversTypes['JSON']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetTaskGroupDependenciesByIdArgs, 'id'>
  >;
  getTaskGroupVersionList?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Int']>>>,
    ParentType,
    ContextType,
    RequireFields<Query_GetTaskGroupVersionListArgs, 'id'>
  >;
  getTaskGroups?: Resolver<
    Maybe<ResolversTypes['GetTaskGroupsResponse']>,
    ParentType,
    ContextType
  >;
  getTaskVersionList?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Int']>>>,
    ParentType,
    ContextType,
    RequireFields<Query_GetTaskVersionListArgs, 'id'>
  >;
  getTasks?: Resolver<
    Maybe<ResolversTypes['GetTasksResponse']>,
    ParentType,
    ContextType
  >;
  getTierById?: Resolver<
    Maybe<ResolversTypes['Tier']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetTierByIdArgs, 'id'>
  >;
  getTierRmsProposalTemplateByItemId?: Resolver<
    Maybe<ResolversTypes['RmsProposalPricingTier']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetTierRmsProposalTemplateByItemIdArgs, 'projectItemId'>
  >;
  getTiers?: Resolver<
    Maybe<ResolversTypes['GetTiersResponse']>,
    ParentType,
    ContextType
  >;
  getTotalCustomers?: Resolver<
    Maybe<ResolversTypes['GetTotalCustomersResponse']>,
    ParentType,
    ContextType
  >;
  getTotalSales?: Resolver<
    Maybe<ResolversTypes['GetTotalSalesResponse']>,
    ParentType,
    ContextType
  >;
  getTravelExpenseSummaryByEstimatorId?: Resolver<
    Maybe<ResolversTypes['TravelExpenseSummaryResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      Query_GetTravelExpenseSummaryByEstimatorIdArgs,
      'projectItemId'
    >
  >;
  getUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  getUserIsCloudFulfillment?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  getUsers?: Resolver<
    Maybe<ResolversTypes['GetUsersResponse']>,
    ParentType,
    ContextType,
    Partial<Query_GetUsersArgs>
  >;
  getZuoraSubscription?: Resolver<
    Maybe<ResolversTypes['ZuoraSubscription']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetZuoraSubscriptionArgs, 'subscriptionId'>
  >;
  me?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  searchCustomers?: Resolver<
    Maybe<ResolversTypes['SearchCustomersResponse']>,
    ParentType,
    ContextType
  >;
  searchServiceItems?: Resolver<
    Maybe<ResolversTypes['SearchServiceItemsResponse']>,
    ParentType,
    ContextType,
    Partial<Query_SearchServiceItemsArgs>
  >;
  searchServiceSkills?: Resolver<
    Maybe<ResolversTypes['SearchServiceSkillsResponse']>,
    ParentType,
    ContextType,
    Partial<Query_SearchServiceSkillsArgs>
  >;
  searchServiceSuppliers?: Resolver<
    Maybe<ResolversTypes['SearchServiceSuppliersResponse']>,
    ParentType,
    ContextType,
    Partial<Query_SearchServiceSuppliersArgs>
  >;
  searchSowSections?: Resolver<
    Maybe<ResolversTypes['SearchSowSectionsResponse']>,
    ParentType,
    ContextType
  >;
  searchTaskGroups?: Resolver<
    Maybe<ResolversTypes['SearchTaskGroupsResponse']>,
    ParentType,
    ContextType
  >;
  searchUsers?: Resolver<
    Maybe<ResolversTypes['SearchUsersResponse']>,
    ParentType,
    ContextType
  >;
  you?: Resolver<Maybe<ResolversTypes['AdminUser']>, ParentType, ContextType>;
}>;

export type QuestionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Question'] = ResolversParentTypes['Question']
> = ResolversObject<{
  createdBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdOn?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  list?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['QuestionOption']>>>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  scopeItemId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuestionOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuestionOption'] = ResolversParentTypes['QuestionOption']
> = ResolversObject<{
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RmsProposalResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RMSProposal'] = ResolversParentTypes['RMSProposal']
> = ResolversObject<{
  _id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  altEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  altName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  altOrg?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  altPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  altTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bronze?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  gold?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  includeMonthlySubtotals?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  includeMonthlyTotal?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  isTierTemplate?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  priceOverride?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  pricingNotes?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  primaryEmail?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryOrg?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryPhone?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  showOnboardingVerbage?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  showReadinessVerbage?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  silver?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  term?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RmsGpAnalysisResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RmsGPAnalysis'] = ResolversParentTypes['RmsGPAnalysis']
> = ResolversObject<{
  rmsItems?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RmsGPAnalysisTableSites']>>>,
    ParentType,
    ContextType
  >;
  totalMonthlyCharges?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RmsGpAnalysisTableDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RmsGPAnalysisTableData'] = ResolversParentTypes['RmsGPAnalysisTableData']
> = ResolversObject<{
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineItems?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RmsGPAnalysisTableDataLineItems']>>>,
    ParentType,
    ContextType
  >;
  totalSellPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RmsGpAnalysisTableDataLineItemsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RmsGPAnalysisTableDataLineItems'] = ResolversParentTypes['RmsGPAnalysisTableDataLineItems']
> = ResolversObject<{
  grossProfit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minGrossProfit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  qty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taskId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalGP?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  unitCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  unitGP?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  unitGPPercent?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  unitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RmsGpAnalysisTableSitesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RmsGPAnalysisTableSites'] = ResolversParentTypes['RmsGPAnalysisTableSites']
> = ResolversObject<{
  categories?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RmsGPAnalysisTableData']>>>,
    ParentType,
    ContextType
  >;
  siteId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  siteName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalSellPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RmsGpTaskUpdateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RmsGPTaskUpdate'] = ResolversParentTypes['RmsGPTaskUpdate']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minGrossProfit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minGrossProfitPercent?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RmsOneTimeCostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RmsOneTimeCost'] = ResolversParentTypes['RmsOneTimeCost']
> = ResolversObject<{
  rmsOneTimeCostItems?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RmsProposalTemplateStandardSites']>>>,
    ParentType,
    ContextType
  >;
  totalMonthlyCharges?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RmsProposalPricingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RmsProposalPricing'] = ResolversParentTypes['RmsProposalPricing']
> = ResolversObject<{
  rmsProposalItems?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RmsProposalTemplateStandardSites']>>>,
    ParentType,
    ContextType
  >;
  totalMonthlyCharges?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RmsProposalPricingTierResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RmsProposalPricingTier'] = ResolversParentTypes['RmsProposalPricingTier']
> = ResolversObject<{
  bronzeTotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  goldTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rmsProposalItems?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RmsProposalTemplateTierSites']>>>,
    ParentType,
    ContextType
  >;
  silverTotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalMonthlyCharges?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RmsProposalTemplateStandardLinesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RmsProposalTemplateStandardLines'] = ResolversParentTypes['RmsProposalTemplateStandardLines']
> = ResolversObject<{
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineItems?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Task']>>>,
    ParentType,
    ContextType
  >;
  totalSellPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RmsProposalTemplateStandardSitesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RmsProposalTemplateStandardSites'] = ResolversParentTypes['RmsProposalTemplateStandardSites']
> = ResolversObject<{
  categories?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RmsProposalTemplateStandardLines']>>>,
    ParentType,
    ContextType
  >;
  siteId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  siteName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalSellPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RmsProposalTemplateTierResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RmsProposalTemplateTier'] = ResolversParentTypes['RmsProposalTemplateTier']
> = ResolversObject<{
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineItems?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RmsProposalTemplateTierLines']>>>,
    ParentType,
    ContextType
  >;
  totalSellPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RmsProposalTemplateTierLinesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RmsProposalTemplateTierLines'] = ResolversParentTypes['RmsProposalTemplateTierLines']
> = ResolversObject<{
  tasks?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Task']>>>,
    ParentType,
    ContextType
  >;
  tier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalSellPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RmsProposalTemplateTierSitesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RmsProposalTemplateTierSites'] = ResolversParentTypes['RmsProposalTemplateTierSites']
> = ResolversObject<{
  categories?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RmsProposalTemplateTier']>>>,
    ParentType,
    ContextType
  >;
  siteId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  siteName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalSellPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RoleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Role'] = ResolversParentTypes['Role']
> = ResolversObject<{
  agiloftRole?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  cdwRateId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  coOwners?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RoleTeam']>>>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['IntOrString']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  owner?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RoleTeam']>>>,
    ParentType,
    ContextType
  >;
  practice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  projectManager?: Resolver<
    Maybe<ResolversTypes['ANY']>,
    ParentType,
    ContextType
  >;
  rate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  testers?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RoleTeam']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RoleAndRateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RoleAndRate'] = ResolversParentTypes['RoleAndRate']
> = ResolversObject<{
  agiloftRole?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  cdwRateId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  cdwRateName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  cost?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['IntOrString']>, ParentType, ContextType>;
  locationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  otCost?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  practice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  practiceName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  projectManager?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  rate?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  roleId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RoleHistoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RoleHistory'] = ResolversParentTypes['RoleHistory']
> = ResolversObject<{
  action?: Resolver<
    Maybe<ResolversTypes['DatabaseActions']>,
    ParentType,
    ContextType
  >;
  agiloftRole?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  cdwRateId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  coOwners?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RoleTeam']>>>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  owner?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RoleTeam']>>>,
    ParentType,
    ContextType
  >;
  practice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  projectManager?: Resolver<
    Maybe<ResolversTypes['ANY']>,
    ParentType,
    ContextType
  >;
  rate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roleId?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  testers?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RoleTeam']>>>,
    ParentType,
    ContextType
  >;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RoleRequestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RoleRequest'] = ResolversParentTypes['RoleRequest']
> = ResolversObject<{
  bill_Rate_Override?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cost_Rate_Override?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  estimateLineName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  estimateProduct?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  estimateProductId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  externalId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  externalResource?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  hours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  practice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role_Nickname?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  servicesItem?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  skills?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['SFDCSkillRequest']>>>,
    ParentType,
    ContextType
  >;
  startDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RoleTeamResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RoleTeam'] = ResolversParentTypes['RoleTeam']
> = ResolversObject<{
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SfdcEstimateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SFDCEstimate'] = ResolversParentTypes['SFDCEstimate']
> = ResolversObject<{
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Project_Type__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  action__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  ffscpq__Approval_Status__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  ffscpq__Billing_Type__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  ffscpq__End_Date__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  ffscpq__Is_Primary__c?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  ffscpq__Is_Template__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  ffscpq__Opportunity__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  ffscpq__Region__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  ffscpq__Stage_Name__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  ffscpq__Start_Date__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  ffscpq__Total_Hours__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  source__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SfdcEstimateProductResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SFDCEstimateProduct'] = ResolversParentTypes['SFDCEstimateProduct']
> = ResolversObject<{
  Contingency_amount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contingency_percentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  contractType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  external_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  proServOrManaged?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  travel_bill_rate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  travel_hours?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SfdcOpportunityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SFDCOpportunity'] = ResolversParentTypes['SFDCOpportunity']
> = ResolversObject<{
  AccountId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Account_Business_Segment__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Account_Owner_Title__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Amount_Override__c?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  Amount__c?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Assigned_to__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  At_Risk_Remaining_Amount__c?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  At_Risk_Status__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  At_Risk_Threshold_Amount__c?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  Billing_Frequency__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Budget_Confirmed__c?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  Business_Segment__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  CDWCreatedByUser__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  CDWLastModifiedUser__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  CDW_Order_Number__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  CDW_Purchase_Order__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  CampaignId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Cancellable__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Cancelled_Reason__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Case_Count__c?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  CloseDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Company_Code__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  ContactId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  ContractId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  CreatedById?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  CreatedDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Credit_Warning_Flag__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  CurrencyIsoCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Customer_Number__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  DB_Competitor__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Department__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Discovery_Completed__c?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  Do_you_need_ITS_support__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  End_Date_of_Contract__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  ExpectedRevenue?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  Expiration_Date__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Fiscal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FiscalQuarter?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  FiscalYear?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ForecastCategory?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  ForecastCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Forecast_Category__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  GLCode__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  GL_Department_Code__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Gross_Profit_Margin__c?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  Gross_Profit__c?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  Hardware_Part_Number__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  HasOpenActivity?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  HasOpportunityLineItem?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  HasOverdueTask?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsClosed?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  IsDeleted?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  IsExcludedFromTerritory2Filter?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  IsPrivate?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  IsSplit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  IsWon?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Is_ITS_Case_Created__c?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  Item_Count__c?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  LastActivityDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  LastAmountChangedHistoryId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  LastCloseDateChangedHistoryId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  LastModifiedById?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  LastModifiedDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  LastReferencedDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  LastStageChangeDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  LastViewedDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  LeadSource?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Level__c?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Loss_Reason__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Multiple_Billing_Agreement__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Net_Profit_Margin__c?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  Net_Profit__c?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  NextStep?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Number_of_Billings__c?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  Number_of_Licenses__c?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  Operations_Contact__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Operations_Status__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Opportunity_Number__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Opportunity_Owner_Title__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Opportunity_Type__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  OwnerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PSA_ID__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Parent_Opportunity__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Pricebook2Id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Probability?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Project_Manager__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  PushCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ROI_Analysis_Completed__c?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  Region__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  SBQQ__AmendedContract__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  SBQQ__Contracted__c?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  SBQQ__CreateContractedPrices__c?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  SBQQ__OrderGroupID__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  SBQQ__Ordered__c?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  SBQQ__PrimaryQuote__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  SBQQ__QuotePricebookId__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  SBQQ__Renewal__c?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  SBQQ__RenewedContract__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Serial_Number__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Service_Partner_Integrator__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Services_Manager__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Ship_Date__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Sirius_Opportunity__c?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  StageName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  SyncedQuoteId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  SystemModstamp?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Team__c?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Territory2Id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  TotalOpportunityQuantity?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  Transaction_Type__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  When_an_Opportunity_is_Likely_to_Win__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  pse__Forecast_Curve__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  pse__Group__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  pse__IsParentOpportunityServices__c?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  pse__Is_Change_Request__c?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  pse__Is_Services_Opportunity__c?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  pse__Parent_Opportunity__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  pse__Practice__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  pse__PreBilledAmount__c?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  pse__Pre_Billed_Amount__c?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  pse__Primary_Project__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  pse__Rate_Card_Discount_Limit__c?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  pse__Rate_Card_Percentage_Discount__c?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  pse__Rate_Card_Set__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  pse__Region__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  pse__Services_Attached_From_Products__c?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  pse__Services_Attached_Percent_From_Products__c?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  sbaa__ApprovalStatus__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  sbaa__ApprovalStep__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  sbaa__Approver__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  sbaa__StepApproved__c?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  sbaa__SubmittedDate__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  sbaa__SubmittedUser__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SfdcSkillRequestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SFDCSkillRequest'] = ResolversParentTypes['SFDCSkillRequest']
> = ResolversObject<{
  estimateRoleSkillRequestName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  externalId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  minimumRating?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SalesHistoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SalesHistory'] = ResolversParentTypes['SalesHistory']
> = ResolversObject<{
  customerCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  customerSeq?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  t12SalesDollarAmount?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  t24SalesDollarAmount?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  t36SalesDollarAmount?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  t48SalesDollarAmount?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  t60SalesDollarAmount?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  technologyCategoryDescription?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  technologySubCategoryDescription?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SalesRepTrendingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SalesRepTrending'] = ResolversParentTypes['SalesRepTrending']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SaveEstimateToPsaResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SaveEstimateToPsaResponse'] = ResolversParentTypes['SaveEstimateToPsaResponse']
> = ResolversObject<{
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ScopeGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScopeGroup'] = ResolversParentTypes['ScopeGroup']
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  legacyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  practiceId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ScopeItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScopeItem'] = ResolversParentTypes['ScopeItem']
> = ResolversObject<{
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  collateral?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TrainingOrCollateral']>>>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  legacyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  parentItemDataId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  parentItemDataValue?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  parentItemId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  practiceId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  questions?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Question']>>>,
    ParentType,
    ContextType
  >;
  scopeGroupId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trainings?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TrainingOrCollateral']>>>,
    ParentType,
    ContextType
  >;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ScopingSessionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScopingSession'] = ResolversParentTypes['ScopingSession']
> = ResolversObject<{
  createdBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdOn?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  customerId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  projectItemId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  section?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ScopingSessionSection']>>>,
    ParentType,
    ContextType
  >;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updatedOn?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ScopingSessionSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScopingSessionSection'] = ResolversParentTypes['ScopingSessionSection']
> = ResolversObject<{
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  questions?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['SessionQuestion']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SearchCustomersResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SearchCustomersResponse'] = ResolversParentTypes['SearchCustomersResponse']
> = ResolversObject<{
  customers?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Customer']>>>,
    ParentType,
    ContextType,
    Partial<SearchCustomersResponse_CustomersArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SearchServiceItemsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SearchServiceItemsResponse'] = ResolversParentTypes['SearchServiceItemsResponse']
> = ResolversObject<{
  serviceItems?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ServiceItem']>>>,
    ParentType,
    ContextType,
    Partial<SearchServiceItemsResponse_ServiceItemsArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SearchServiceSkillsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SearchServiceSkillsResponse'] = ResolversParentTypes['SearchServiceSkillsResponse']
> = ResolversObject<{
  serviceSkills?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ServiceSkill']>>>,
    ParentType,
    ContextType,
    Partial<SearchServiceSkillsResponse_ServiceSkillsArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SearchServiceSuppliersResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SearchServiceSuppliersResponse'] = ResolversParentTypes['SearchServiceSuppliersResponse']
> = ResolversObject<{
  serviceSuppliers?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ServiceSupplier']>>>,
    ParentType,
    ContextType,
    Partial<SearchServiceSuppliersResponse_ServiceSuppliersArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SearchSowSectionsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SearchSowSectionsResponse'] = ResolversParentTypes['SearchSowSectionsResponse']
> = ResolversObject<{
  sowSections?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['SowSection']>>>,
    ParentType,
    ContextType,
    Partial<SearchSowSectionsResponse_SowSectionsArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SearchTaskGroupsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SearchTaskGroupsResponse'] = ResolversParentTypes['SearchTaskGroupsResponse']
> = ResolversObject<{
  taskGroups?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TaskGroup']>>>,
    ParentType,
    ContextType,
    Partial<SearchTaskGroupsResponse_TaskGroupsArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SearchUsersResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SearchUsersResponse'] = ResolversParentTypes['SearchUsersResponse']
> = ResolversObject<{
  users?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['FBUser']>>>,
    ParentType,
    ContextType,
    Partial<SearchUsersResponse_UsersArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ServiceCategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServiceCategory'] = ResolversParentTypes['ServiceCategory']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ServiceItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServiceItem'] = ResolversParentTypes['ServiceItem']
> = ResolversObject<{
  className?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  externalId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  practice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serviceItemNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ServiceLocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServiceLocation'] = ResolversParentTypes['ServiceLocation']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ServiceLocationRoleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServiceLocationRole'] = ResolversParentTypes['ServiceLocationRole']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  rate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roleId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ServicePracticeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServicePractice'] = ResolversParentTypes['ServicePractice']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  relatedPractices?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ServiceSkillResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServiceSkill'] = ResolversParentTypes['ServiceSkill']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pseExternalId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ServiceSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServiceSummary'] = ResolversParentTypes['ServiceSummary']
> = ResolversObject<{
  costTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rate?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  total?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unit?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ServiceSummaryResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServiceSummaryResponse'] = ResolversParentTypes['ServiceSummaryResponse']
> = ResolversObject<{
  pmPercent?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  riskMitigation?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  roles?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ServiceSummary']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ServiceSupplierResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServiceSupplier'] = ResolversParentTypes['ServiceSupplier']
> = ResolversObject<{
  Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SupplierStatus__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Supplier_ID__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SessionQuestionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SessionQuestion'] = ResolversParentTypes['SessionQuestion']
> = ResolversObject<{
  createdBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdOn?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  scopeQuestionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SfdcAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SfdcAccount'] = ResolversParentTypes['SfdcAccount']
> = ResolversObject<{
  AccountNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  AnnualRevenue?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  CurrencyIsoCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  CustomerID__c?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Industry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NumberOfEmployees?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  ParentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SfdcRegionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SfdcRegion'] = ResolversParentTypes['SfdcRegion']
> = ResolversObject<{
  Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SiteResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Site'] = ResolversParentTypes['Site']
> = ResolversObject<{
  _id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expanded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  floor?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  hideZeroQuantity?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SiteBreakdownResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SiteBreakdown'] = ResolversParentTypes['SiteBreakdown']
> = ResolversObject<{
  pmHours?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  servicesTotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  site?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taskHours?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  thirdPartyServices?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  total?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalHours?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SiteTaskResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SiteTask'] = ResolversParentTypes['SiteTask']
> = ResolversObject<{
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dropDownIndex?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  listNames?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  siteId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taskGroupId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  taskId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SiteWithTasksResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SiteWithTasks'] = ResolversParentTypes['SiteWithTasks']
> = ResolversObject<{
  siteId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  siteName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tasks?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['SiteTask']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SkillSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SkillSummary'] = ResolversParentTypes['SkillSummary']
> = ResolversObject<{
  skillSummaryRows?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['SkillSummaryRow']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SkillSummaryRowResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SkillSummaryRow'] = ResolversParentTypes['SkillSummaryRow']
> = ResolversObject<{
  skill?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalHours?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SowSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SowSection'] = ResolversParentTypes['SowSection']
> = ResolversObject<{
  assumptions?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  assumptionsGlobal?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  coOwners?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['SowSectionTeam']>>>,
    ParentType,
    ContextType
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdOn?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  customerResponsibilities?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  customerResponsibilitiesGlobal?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  help?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  helpGlobal?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  highLevel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  inTestingSowSectionId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lastReviewedBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lastReviewedOn?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  originalSowSectionId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  outOfScope?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  outOfScopeGlobal?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  outcomeUnit?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  outcomeUnitDescription?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  owner?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['SowSectionTeam']>>>,
    ParentType,
    ContextType
  >;
  scope?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  testers?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['SowSectionTeam']>>>,
    ParentType,
    ContextType
  >;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  version?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SowSectionTeamResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SowSectionTeam'] = ResolversParentTypes['SowSectionTeam']
> = ResolversObject<{
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SowTaskGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SowTaskGroup'] = ResolversParentTypes['SowTaskGroup']
> = ResolversObject<{
  agiloftBlendedScopeType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftClauses?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftExhibits?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftManagedScopeType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftRecurringServicesFees?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftScopeType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftServiceCategory?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  businessOutcome?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  businessOutcomeHelp?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  businessOutcomeIncluded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  executiveSummaryDrivers?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  executiveSummaryDriversHelp?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  executiveSummaryDriversIncluded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  executiveSummaryOverview?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  executiveSummaryOverviewHelp?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  executiveSummaryOverviewIncluded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  outcomeIncluded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  outcomeProjectScope?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  outcomeProjectScopeIncluded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  outcomeProjectScopePhases?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  outcomeProjectScopePhasesHelp?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  outcomeProjectScopePhasesIncluded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  outcomeSowFooter?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  outcomeSowHeader?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  outcomeSummary?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  practice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  proposalContent?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  sowScopeFooter?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  sowScopeHeader?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  technicalOutcome?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  technicalOutcomeHelp?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  technicalOutcomeIncluded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  version?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SprintSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SprintSummary'] = ResolversParentTypes['SprintSummary']
> = ResolversObject<{
  sprintSummaryRows?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['SprintSummaryRow']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SprintSummaryRowResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SprintSummaryRow'] = ResolversParentTypes['SprintSummaryRow']
> = ResolversObject<{
  sprint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalHours?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalSellPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface StringOrArrayScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['StringOrArray'], any> {
  name: 'StringOrArray';
}

export type TaskResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Task'] = ResolversParentTypes['Task']
> = ResolversObject<{
  _id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  allowAdditionalGPMargin?: Resolver<
    Maybe<ResolversTypes['ANY']>,
    ParentType,
    ContextType
  >;
  allowAdminEdit?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  allowBlankText?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  allowMarkup?: Resolver<Maybe<ResolversTypes['ANY']>, ParentType, ContextType>;
  allowModifyPrimaryRoleRate?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  allowModifySecondaryRoleRate?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  allowModifyTertiaryRoleRate?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  allowTotalHoursAdjustment?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  alwaysShowOnProposal?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  catalogId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  catalogName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cost?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  costFormula?: Resolver<Maybe<ResolversTypes['ANY']>, ParentType, ContextType>;
  costType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  createdBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  currentDisableCost?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  currentDisableHours?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  currentDisableQuantity?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  currentDisableText?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  currentDropDownIndex?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  currentGrossProfit?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  currentHide?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  custom?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  customServiceCost?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  customServiceDivisorRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  customServiceSellPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  customServiceSupplierId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  defaultCost?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  defaultGrossProfit?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  disableCost?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  disableCostFormula?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  disableHours?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  disableHoursFormula?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  disableQuantity?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  disableQuantityFormula?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  dropDownIndex?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  dropDownIndexFormula?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  edc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrollmentCost?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  enrollmentCostFormula?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  enrollmentHours?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  enrollmentHoursFormula?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  enrollmentRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  enrollmentRole?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  enrollmentTotalHours?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  estimatorLocation?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  exclude?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  excludeToggle?: Resolver<
    Maybe<ResolversTypes['ANY']>,
    ParentType,
    ContextType
  >;
  fixedFeePrice?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  fixedFeeTotalHours?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  goalSeekError?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  grossProfit?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  hide?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  hideHours?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  hideShowFormula?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  hours?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  hoursFormula?: Resolver<
    Maybe<ResolversTypes['ANY']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  imported?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  inTestingTaskId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  instructionsText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  isExpense?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  isManagedServicesCostCalculation?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  isProjectManagement?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  isTravelTime?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  lastReviewedBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lastReviewedOn?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  latestVersion?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  list?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TaskList']>>>,
    ParentType,
    ContextType
  >;
  listNames?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  listValues?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  managedServices?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  managedServicesArcRrcFormula?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  managedServicesFteFormula?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  managedServicesTotalArcRrc?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  managedServicesTotalFte?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  managedServicesTotalTransition?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  managedServicesTransitionFormula?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  margin?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minGrossProfit?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  noValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  noteCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  notes?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TaskNote']>>>,
    ParentType,
    ContextType
  >;
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  originalTaskId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  overtime?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  phases?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TaskPhase']>>>,
    ParentType,
    ContextType
  >;
  pmPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  practice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  practiceId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  previousOrder?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  primaryPercent?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  primaryRole?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryRoleId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryRoleName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryRoleRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryRoleRateOverride?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryRoleRateOverrideFormula?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryTotalHours?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  productType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  proposalDescription?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  proposalNotes?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  proposalShowEmpty?: Resolver<
    Maybe<ResolversTypes['ANY']>,
    ParentType,
    ContextType
  >;
  quantity?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  quantityFormula?: Resolver<
    Maybe<ResolversTypes['ANY']>,
    ParentType,
    ContextType
  >;
  quantityMax?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  quantityMin?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  rate?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  renderIndex?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  replacedNamedExpression?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  secondaryPercent?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  secondaryRole?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  secondaryRoleId?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  secondaryRoleName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryRoleRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryRoleRateOverride?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryRoleRateOverrideFormula?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryTotalHours?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sellPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sellPriceFormula?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  serviceClassName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  serviceItem?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  serviceItemFormula?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  serviceItemId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  serviceItemName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  siteId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  siteQuantity?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  skill?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  skillFormula?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  skillId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sowSection?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  sowSectionId?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  sowSections?: Resolver<
    Maybe<ResolversTypes['SowSection']>,
    ParentType,
    ContextType
  >;
  sprint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subType?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  taskGroupId?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  taskGroupName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  taskGroups?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['IntOrString']>>>,
    ParentType,
    ContextType
  >;
  taskId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taskType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  technologyName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tertiaryPercent?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  tertiaryRole?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  tertiaryRoleId?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  tertiaryRoleName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tertiaryRoleRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  tertiaryRoleRateOverride?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  tertiaryRoleRateOverrideFormula?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tertiaryTotalHours?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  textDisabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  textDisabledFormula?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  textFormula?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  textType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tierId?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  tiers?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['IntOrString']>>>,
    ParentType,
    ContextType
  >;
  totalCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalHours?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalHoursAdjustment?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalHoursNoRounding?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  totalSellPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  units?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  vendor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  volumePricing?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['VolumePricing']>>>,
    ParentType,
    ContextType
  >;
  volumePricingEnabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  yesValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TaskGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskGroup'] = ResolversParentTypes['TaskGroup']
> = ResolversObject<{
  agiloftBlendedScopeType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftClauses?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftDefaultPrintTemplate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftExhibits?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftManagedScopeType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftOutcomeScopeType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftPrintTemplateTypeOptions?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  agiloftRecurringServicesFees?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftScopeType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agiloftServiceCategory?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  bidAssuranceGroup?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  bidAssuranceUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  businessOutcome?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  businessOutcomeHelp?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  businessOutcomeIncluded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  coOwners?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TaskGroupTeam']>>>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  dynamicSiteTableContent?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  dynamicSiteTableIncluded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  executiveSummaryDrivers?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  executiveSummaryDriversHelp?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  executiveSummaryDriversIncluded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  executiveSummaryOverview?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  executiveSummaryOverviewHelp?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  executiveSummaryOverviewIncluded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  fixedFee?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  fixedFeeSellPrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  inTestingTaskGroupId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  itemsProvidedToCustomers?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ItemsProvidedToCustomers']>>>,
    ParentType,
    ContextType
  >;
  lastReviewedBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lastReviewedOn?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  minPmHours?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minPmPercent?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  originalTaskGroupId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  outcomeIncluded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  outcomeProjectScope?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  outcomeProjectScopeIncluded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  outcomeProjectScopePhases?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  outcomeProjectScopePhasesHelp?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  outcomeProjectScopePhasesIncluded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  outcomeSowFooter?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  outcomeSowHeader?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  outcomeSummary?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  owner?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TaskGroupTeam']>>>,
    ParentType,
    ContextType
  >;
  pmPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pmRole?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  practice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  proposalContent?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  proposalTemplateIds?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  published?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  riskPercent?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  setPm?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sowScopeFooter?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  sowScopeHeader?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  sowSections?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taskGroupCategories?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Category']>>>,
    ParentType,
    ContextType
  >;
  taskGroupTeam?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TaskGroupTeam']>>>,
    ParentType,
    ContextType
  >;
  technicalOutcome?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  technicalOutcomeHelp?: Resolver<
    Maybe<ResolversTypes['HTML']>,
    ParentType,
    ContextType
  >;
  technicalOutcomeIncluded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  testers?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TaskGroupTeam']>>>,
    ParentType,
    ContextType
  >;
  version?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TaskGroupTeamResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskGroupTeam'] = ResolversParentTypes['TaskGroupTeam']
> = ResolversObject<{
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TaskGroupsPrintOptionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskGroupsPrintOptions'] = ResolversParentTypes['TaskGroupsPrintOptions']
> = ResolversObject<{
  agiloftDefaultTemplates?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  agiloftPrintTemplateTypeOptions?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TaskHasGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskHasGroup'] = ResolversParentTypes['TaskHasGroup']
> = ResolversObject<{
  groupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taskId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TaskListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskList'] = ResolversParentTypes['TaskList']
> = ResolversObject<{
  names?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  selectedIndex?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  values?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TaskNoteResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskNote'] = ResolversParentTypes['TaskNote']
> = ResolversObject<{
  createdAt?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  noteType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  projectItemId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  siteId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taskId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TaskOrderResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskOrder'] = ResolversParentTypes['TaskOrder']
> = ResolversObject<{
  order?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  taskGroup?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  taskId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TaskPhaseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskPhase'] = ResolversParentTypes['TaskPhase']
> = ResolversObject<{
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taskPercent?: Resolver<
    Maybe<ResolversTypes['IntOrString']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TierResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Tier'] = ResolversParentTypes['Tier']
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DATE']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TrainingOrCollateralResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TrainingOrCollateral'] = ResolversParentTypes['TrainingOrCollateral']
> = ResolversObject<{
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TravelExpenseSummaryResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TravelExpenseSummaryResponse'] = ResolversParentTypes['TravelExpenseSummaryResponse']
> = ResolversObject<{
  isExpenseTotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  travelExpenseTotal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  travelTimeRole?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ServiceSummary']>>>,
    ParentType,
    ContextType
  >;
  travelTimeTotal?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ServiceSummary']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateLeadResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateLeadResponse'] = ResolversParentTypes['UpdateLeadResponse']
> = ResolversObject<{
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateProjectResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateProjectResponse'] = ResolversParentTypes['UpdateProjectResponse']
> = ResolversObject<{
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdatedTaskMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatedTaskMutationResponse'] = ResolversParentTypes['UpdatedTaskMutationResponse']
> = ResolversObject<{
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  updatedTask?: Resolver<
    Maybe<ResolversTypes['Task']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']
> = ResolversObject<{
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['User']>,
    { __typename: 'User' } & GraphQLRecursivePick<ParentType, { id: true }>,
    ContextType
  >;
  admin?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  aud?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  auth_time?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  ciscoCcwToken?: Resolver<
    Maybe<ResolversTypes['UserCiscoCcwToken']>,
    ParentType,
    ContextType
  >;
  contentAdmin?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  department?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  displayName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email_verified?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  employeeId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  exp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iat?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  iss?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  managedServicesAdmin?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  managerEmail?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organization?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  picture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sub?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserCiscoCcwTokenResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserCiscoCcwToken'] = ResolversParentTypes['UserCiscoCcwToken']
> = ResolversObject<{
  expires?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  refreshToken?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VolumePricingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VolumePricing'] = ResolversParentTypes['VolumePricing']
> = ResolversObject<{
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxQuantity?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minQuantity?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ZuoraRateChangeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ZuoraRateChange'] = ResolversParentTypes['ZuoraRateChange']
> = ResolversObject<{
  adjustedSimCost?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  amendedByOrderOn?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  applyDiscountTo?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  billingDay?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  billingPeriod?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  billingPeriodAlignment?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  billingTiming?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  chargeModelConfiguration?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  chargedThroughDate?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  discountClass?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  discountLevel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  dmrc?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  done?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  dtcv?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  effectiveEndDate?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  effectiveStartDate?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  endDateCondition?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  entitlementId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  externalProductID?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  includedUnits?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  inputArgumentId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  invoiceLineComments?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  invoiceLineNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  isStackedDiscount?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  listPriceBase?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  marginPercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  model?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mrr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  msrp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  numberOfPeriods?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  orderTypeService?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  originalChargeId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  originalOrderDate?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  overageCalculationOption?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  overagePrice?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  overageUnusedUnitsCreditOption?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  partnerProvider?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  priceChangeOption?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  priceIncreasePercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  pricingTypeSVAR?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  processedThroughDate?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  productRatePlanChargeId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  productRatePlanChargeNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ratePlanChargeUsageIdentifier?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  ratingGroup?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  segment?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  serialNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  serviceID?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  shipFromLocation?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  shipToReference?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  shipToSource?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  smoothingModel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  specificBillingPeriod?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  specificEndDate?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  specificListPriceBase?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  subhubProductOrderId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tcv?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tiers?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ZuoraTier']>>>,
    ParentType,
    ContextType
  >;
  triggerDate?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  triggerEvent?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unitCost?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unusedUnitsCreditRates?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  uom?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  upToPeriods?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  upToPeriodsType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  usageRecordRatingOption?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ZuoraRatePlanResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ZuoraRatePlan'] = ResolversParentTypes['ZuoraRatePlan']
> = ResolversObject<{
  externallyManagedPlanId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  productId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  productName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  productRatePlanId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  productRatePlanNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  productSku?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  ratePlanCharges?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ZuoraRateChange']>>>,
    ParentType,
    ContextType
  >;
  ratePlanName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  ratePlanUsageIdentifier?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  subscriptionProductFeatures?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  subscriptionRatePlanNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ZuoraSubscriptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ZuoraSubscription'] = ResolversParentTypes['ZuoraSubscription']
> = ResolversObject<{
  accountId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  accountName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  accountNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  autoRenew?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  billToContact?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  billToEmailAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  billToEmailAddress2?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  billToEmailSource?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  billToMedia?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  billToReference?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  billToSource?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  cancelReason?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  commissionable?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  contactReference?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  contactSource?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  contractEffectiveDate?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  contractedMrr?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cpqBundleJsonId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  currentTerm?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  currentTermPeriodType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  customerAcceptanceDate?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  customerPO?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  daasType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  department?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  engagementId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  initialTerm?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  initialTermPeriodType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  invoiceComments?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  invoiceOwnerAccountId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  invoiceOwnerAccountName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  invoiceOwnerAccountNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  invoiceSeparately?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  invoiceTemplate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  invoiceTemplateId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  invoiceTemplateName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  isLatestVersion?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lastBookingDate?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  marginPercentage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  minimumContractValue?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opportunityCloseDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  opportunityName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  orderTypeService?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  paymentTerm?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  paymentTermCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  paymentType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  productHubLocation?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  quoteBusinessType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  quoteNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  quoteType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  ratePlans?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ZuoraRatePlan']>>>,
    ParentType,
    ContextType
  >;
  renewalSetting?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  renewalTerm?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  renewalTermPeriodType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  salesPerson?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  sequenceSetId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  sequenceSetName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  serviceActivationDate?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  serviceType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  shipFromLocation?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  shipToReference?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  shipToSource?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  soldToContact?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subHubSubscriptionId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  subscriptionEndDate?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  subscriptionId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  subscriptionNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  subscriptionStartDate?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  subscriptionUsageIdentifier?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  svarContractNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  termEndDate?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  termStartDate?: Resolver<
    Maybe<ResolversTypes['DATE']>,
    ParentType,
    ContextType
  >;
  termType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalContractedValue?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  vendor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vendorSubscriptionId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  zuoraId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ZuoraTierResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ZuoraTier'] = ResolversParentTypes['ZuoraTier']
> = ResolversObject<{
  endingUnit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priceFormat?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  startingUnit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  tier?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EstimateToPsaInputsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['estimateToPSAInputs'] = ResolversParentTypes['estimateToPSAInputs']
> = ResolversObject<{
  wrapperestimate?: Resolver<
    Maybe<ResolversTypes['wrapperEstimateToPSAInputs']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetPracticesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['getPracticesResponse'] = ResolversParentTypes['getPracticesResponse']
> = ResolversObject<{
  count?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType,
    Partial<GetPracticesResponse_CountArgs>
  >;
  practices?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Practice']>>>,
    ParentType,
    ContextType,
    Partial<GetPracticesResponse_PracticesArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetServiceCategoriesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['getServiceCategoriesResponse'] = ResolversParentTypes['getServiceCategoriesResponse']
> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  serviceCategories?: Resolver<
    Array<Maybe<ResolversTypes['ServiceCategory']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetServiceLocationsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['getServiceLocationsResponse'] = ResolversParentTypes['getServiceLocationsResponse']
> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  locations?: Resolver<
    Array<Maybe<ResolversTypes['ServiceLocation']>>,
    ParentType,
    ContextType,
    Partial<GetServiceLocationsResponse_LocationsArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetServiceLocationsRolesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['getServiceLocationsRolesResponse'] = ResolversParentTypes['getServiceLocationsRolesResponse']
> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  roles?: Resolver<
    Array<Maybe<ResolversTypes['ServiceLocationRole']>>,
    ParentType,
    ContextType,
    Partial<GetServiceLocationsRolesResponse_RolesArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetServicePracticesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['getServicePracticesResponse'] = ResolversParentTypes['getServicePracticesResponse']
> = ResolversObject<{
  count?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType,
    Partial<GetServicePracticesResponse_CountArgs>
  >;
  servicePractices?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ServicePractice']>>>,
    ParentType,
    ContextType,
    Partial<GetServicePracticesResponse_ServicePracticesArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WrapperEstimateToPsaInputsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['wrapperEstimateToPSAInputs'] = ResolversParentTypes['wrapperEstimateToPSAInputs']
> = ResolversObject<{
  estimate?: Resolver<
    Maybe<ResolversTypes['SFDCEstimate']>,
    ParentType,
    ContextType
  >;
  estimateLines?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['LineSet']>>>,
    ParentType,
    ContextType
  >;
  estimateProducts?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['SFDCEstimateProduct']>>>,
    ParentType,
    ContextType
  >;
  estimateRoleRequests?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RoleRequest']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = any> = ResolversObject<{
  ANY?: GraphQLScalarType;
  ActiveSiteTask?: ActiveSiteTaskResolvers<ContextType>;
  ActiveSiteWithTasks?: ActiveSiteWithTasksResolvers<ContextType>;
  AddEstimatorTasksByTaskGroupResponse?: AddEstimatorTasksByTaskGroupResponseResolvers<ContextType>;
  AddProjectUserResponse?: AddProjectUserResponseResolvers<ContextType>;
  AdminConfigSettings?: AdminConfigSettingsResolvers<ContextType>;
  AdminPractice?: AdminPracticeResolvers<ContextType>;
  AdminRole?: AdminRoleResolvers<ContextType>;
  AdminTaskGroup?: AdminTaskGroupResolvers<ContextType>;
  AdminUser?: AdminUserResolvers<ContextType>;
  AgiloftAPILog?: AgiloftApiLogResolvers<ContextType>;
  AgiloftApiResponse?: AgiloftApiResponseResolvers<ContextType>;
  AgiloftExpenseType?: AgiloftExpenseTypeResolvers<ContextType>;
  AgiloftRole?: AgiloftRoleResolvers<ContextType>;
  AgiloftSOW?: AgiloftSowResolvers<ContextType>;
  AgiloftToken?: AgiloftTokenResolvers<ContextType>;
  AgiloftTravelNoticeType?: AgiloftTravelNoticeTypeResolvers<ContextType>;
  BidAssuranceNote?: BidAssuranceNoteResolvers<ContextType>;
  BidAssuranceStatusHistory?: BidAssuranceStatusHistoryResolvers<ContextType>;
  Category?: CategoryResolvers<ContextType>;
  CdwOpenOrdersWorkList?: CdwOpenOrdersWorkListResolvers<ContextType>;
  CdwRate?: CdwRateResolvers<ContextType>;
  CiscoAnnuity?: CiscoAnnuityResolvers<ContextType>;
  CiscoAnnuityCategory?: CiscoAnnuityCategoryResolvers<ContextType>;
  CiscoAnnuityComment?: CiscoAnnuityCommentResolvers<ContextType>;
  CiscoAnnuityContact?: CiscoAnnuityContactResolvers<ContextType>;
  CiscoAnnuityLine?: CiscoAnnuityLineResolvers<ContextType>;
  CiscoAnnuityLineTerm?: CiscoAnnuityLineTermResolvers<ContextType>;
  CiscoAnnuityPartNumber?: CiscoAnnuityPartNumberResolvers<ContextType>;
  CiscoAnnuityPaymentSchedule?: CiscoAnnuityPaymentScheduleResolvers<ContextType>;
  CiscoAnnuityQuoteResponse?: CiscoAnnuityQuoteResponseResolvers<ContextType>;
  CiscoAnnuityQuoteSummary?: CiscoAnnuityQuoteSummaryResolvers<ContextType>;
  CiscoAnnuityServiceFeeSchedule?: CiscoAnnuityServiceFeeScheduleResolvers<ContextType>;
  CiscoAnnuityStatusHistory?: CiscoAnnuityStatusHistoryResolvers<ContextType>;
  CiscoApiResult?: CiscoApiResultResolvers<ContextType>;
  CiscoCcwToken?: CiscoCcwTokenResolvers<ContextType>;
  CiscoEATrueForward?: CiscoEaTrueForwardResolvers<ContextType>;
  CiscoOrder?: CiscoOrderResolvers<ContextType>;
  CiscoOrderApiResult?: CiscoOrderApiResultResolvers<ContextType>;
  CiscoOrderBatchHistory?: CiscoOrderBatchHistoryResolvers<ContextType>;
  CiscoOrderLine?: CiscoOrderLineResolvers<ContextType>;
  CiscoOrderSearch?: CiscoOrderSearchResolvers<ContextType>;
  CiscoOrdersWorkList?: CiscoOrdersWorkListResolvers<ContextType>;
  CiscoQuote?: CiscoQuoteResolvers<ContextType>;
  CiscoSmartAccount?: CiscoSmartAccountResolvers<ContextType>;
  CiscoSubscription?: CiscoSubscriptionResolvers<ContextType>;
  CiscoSubscriptionBatchHistory?: CiscoSubscriptionBatchHistoryResolvers<ContextType>;
  CiscoSubscriptionConsumptionSku?: CiscoSubscriptionConsumptionSkuResolvers<ContextType>;
  CiscoSubscriptionDetailsWorkList?: CiscoSubscriptionDetailsWorkListResolvers<ContextType>;
  CiscoSubscriptionHistory?: CiscoSubscriptionHistoryResolvers<ContextType>;
  CiscoSubscriptionHistoryWorkList?: CiscoSubscriptionHistoryWorkListResolvers<ContextType>;
  CiscoSubscriptionLine?: CiscoSubscriptionLineResolvers<ContextType>;
  CiscoVirtualAccount?: CiscoVirtualAccountResolvers<ContextType>;
  Collection?: CollectionResolvers<ContextType>;
  ContentRequest?: ContentRequestResolvers<ContextType>;
  CustomService?: CustomServiceResolvers<ContextType>;
  Customer?: CustomerResolvers<ContextType>;
  DATE?: GraphQLScalarType;
  DataCollectionItem?: DataCollectionItemResolvers<ContextType>;
  DefaultAddResponse?: DefaultAddResponseResolvers<ContextType>;
  DefaultMutationResponse?: DefaultMutationResponseResolvers<ContextType>;
  DefaultMutationResponseWithID?: DefaultMutationResponseWithIdResolvers<ContextType>;
  EmailTemplates?: EmailTemplatesResolvers<ContextType>;
  EnrollmentSummary?: EnrollmentSummaryResolvers<ContextType>;
  EstimatorTotals?: EstimatorTotalsResolvers<ContextType>;
  FBUser?: FbUserResolvers<ContextType>;
  FBUserMetaData?: FbUserMetaDataResolvers<ContextType>;
  FixedFeeMilestone?: FixedFeeMilestoneResolvers<ContextType>;
  GainsightSubscriptionsView?: GainsightSubscriptionsViewResolvers<ContextType>;
  GetAdminConfigSettingsResponse?: GetAdminConfigSettingsResponseResolvers<ContextType>;
  GetAgiloftExpenseTypesResponse?: GetAgiloftExpenseTypesResponseResolvers<ContextType>;
  GetAgiloftRolesResponse?: GetAgiloftRolesResponseResolvers<ContextType>;
  GetAgiloftTravelNoticeTypesResponse?: GetAgiloftTravelNoticeTypesResponseResolvers<ContextType>;
  GetCdwRates?: GetCdwRatesResolvers<ContextType>;
  GetCdwRatesResponse?: GetCdwRatesResponseResolvers<ContextType>;
  GetCiscoAnnuityPartNumbersResponse?: GetCiscoAnnuityPartNumbersResponseResolvers<ContextType>;
  GetCiscoCcwEstimateByIdResponse?: GetCiscoCcwEstimateByIdResponseResolvers<ContextType>;
  GetCiscoCcwQuoteByDealIdResponse?: GetCiscoCcwQuoteByDealIdResponseResolvers<ContextType>;
  GetCollectionsResponse?: GetCollectionsResponseResolvers<ContextType>;
  GetCustomersByOpportunityResponse?: GetCustomersByOpportunityResponseResolvers<ContextType>;
  GetCustomersResponse?: GetCustomersResponseResolvers<ContextType>;
  GetEmailTemplatesResponse?: GetEmailTemplatesResponseResolvers<ContextType>;
  GetEstimateToPSAResponse?: GetEstimateToPsaResponseResolvers<ContextType>;
  GetOpportunityResponse?: GetOpportunityResponseResolvers<ContextType>;
  GetPhasesResponse?: GetPhasesResponseResolvers<ContextType>;
  GetProjectItemEstimatorRatesResponse?: GetProjectItemEstimatorRatesResponseResolvers<ContextType>;
  GetProjectRatesResponse?: GetProjectRatesResponseResolvers<ContextType>;
  GetProjectUsersResponse?: GetProjectUsersResponseResolvers<ContextType>;
  GetProjectsResponse?: GetProjectsResponseResolvers<ContextType>;
  GetProposalTemplatesResponse?: GetProposalTemplatesResponseResolvers<ContextType>;
  GetRolesAndRatesResponse?: GetRolesAndRatesResponseResolvers<ContextType>;
  GetRolesResponse?: GetRolesResponseResolvers<ContextType>;
  GetSalesHistoryResponse?: GetSalesHistoryResponseResolvers<ContextType>;
  GetSalesRepsTrendingResponse?: GetSalesRepsTrendingResponseResolvers<ContextType>;
  GetScopeGroupsResponse?: GetScopeGroupsResponseResolvers<ContextType>;
  GetScopeItemsResponse?: GetScopeItemsResponseResolvers<ContextType>;
  GetServiceItemsResponse?: GetServiceItemsResponseResolvers<ContextType>;
  GetServiceSkillsResponse?: GetServiceSkillsResponseResolvers<ContextType>;
  GetServiceSuppliersResponse?: GetServiceSuppliersResponseResolvers<ContextType>;
  GetSowSectionsResponse?: GetSowSectionsResponseResolvers<ContextType>;
  GetTaskGroupSowResponse?: GetTaskGroupSowResponseResolvers<ContextType>;
  GetTaskGroupsResponse?: GetTaskGroupsResponseResolvers<ContextType>;
  GetTasksResponse?: GetTasksResponseResolvers<ContextType>;
  GetTiersResponse?: GetTiersResponseResolvers<ContextType>;
  GetTotalCustomersResponse?: GetTotalCustomersResponseResolvers<ContextType>;
  GetTotalSalesResponse?: GetTotalSalesResponseResolvers<ContextType>;
  GetUsersResponse?: GetUsersResponseResolvers<ContextType>;
  HTML?: GraphQLScalarType;
  HandledMutationResponse?: HandledMutationResponseResolvers<ContextType>;
  IntOrString?: GraphQLScalarType;
  InvestmentSummary?: InvestmentSummaryResolvers<ContextType>;
  ItemsProvidedToCustomers?: ItemsProvidedToCustomersResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  Lead?: LeadResolvers<ContextType>;
  LineSet?: LineSetResolvers<ContextType>;
  ManagedServicesRenewalResponse?: ManagedServicesRenewalResponseResolvers<ContextType>;
  ManagedServicesSummaryResponse?: ManagedServicesSummaryResponseResolvers<ContextType>;
  MansEnrollmentSummaryResponse?: MansEnrollmentSummaryResponseResolvers<ContextType>;
  MongoFilter?: GraphQLScalarType;
  Mutation?: MutationResolvers<ContextType>;
  Opportunity?: OpportunityResolvers<ContextType>;
  OpportunityTable?: OpportunityTableResolvers<ContextType>;
  Organization?: OrganizationResolvers<ContextType>;
  Phase?: PhaseResolvers<ContextType>;
  PhaseBreakdown?: PhaseBreakdownResolvers<ContextType>;
  Practice?: PracticeResolvers<ContextType>;
  ProjectComment?: ProjectCommentResolvers<ContextType>;
  ProjectCustomTaskPhase?: ProjectCustomTaskPhaseResolvers<ContextType>;
  ProjectEstimates?: ProjectEstimatesResolvers<ContextType>;
  ProjectItem?: ProjectItemResolvers<ContextType>;
  ProjectItemEstimators?: ProjectItemEstimatorsResolvers<ContextType>;
  ProjectItemQuote?: ProjectItemQuoteResolvers<ContextType>;
  ProjectItemQuoteGlobalSettings?: ProjectItemQuoteGlobalSettingsResolvers<ContextType>;
  ProjectItemQuoteLine?: ProjectItemQuoteLineResolvers<ContextType>;
  ProjectItemQuoteSettings?: ProjectItemQuoteSettingsResolvers<ContextType>;
  ProjectItemQuoteSystem?: ProjectItemQuoteSystemResolvers<ContextType>;
  ProjectPracticeAndRoles?: ProjectPracticeAndRolesResolvers<ContextType>;
  ProjectProposal?: ProjectProposalResolvers<ContextType>;
  ProjectSow?: ProjectSowResolvers<ContextType>;
  ProjectStatusHistory?: ProjectStatusHistoryResolvers<ContextType>;
  ProjectTeam?: ProjectTeamResolvers<ContextType>;
  ProjectUser?: ProjectUserResolvers<ContextType>;
  ProposalTemplate?: ProposalTemplateResolvers<ContextType>;
  PsaSyncHistory?: PsaSyncHistoryResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Question?: QuestionResolvers<ContextType>;
  QuestionOption?: QuestionOptionResolvers<ContextType>;
  RMSProposal?: RmsProposalResolvers<ContextType>;
  RmsGPAnalysis?: RmsGpAnalysisResolvers<ContextType>;
  RmsGPAnalysisTableData?: RmsGpAnalysisTableDataResolvers<ContextType>;
  RmsGPAnalysisTableDataLineItems?: RmsGpAnalysisTableDataLineItemsResolvers<ContextType>;
  RmsGPAnalysisTableSites?: RmsGpAnalysisTableSitesResolvers<ContextType>;
  RmsGPTaskUpdate?: RmsGpTaskUpdateResolvers<ContextType>;
  RmsOneTimeCost?: RmsOneTimeCostResolvers<ContextType>;
  RmsProposalPricing?: RmsProposalPricingResolvers<ContextType>;
  RmsProposalPricingTier?: RmsProposalPricingTierResolvers<ContextType>;
  RmsProposalTemplateStandardLines?: RmsProposalTemplateStandardLinesResolvers<ContextType>;
  RmsProposalTemplateStandardSites?: RmsProposalTemplateStandardSitesResolvers<ContextType>;
  RmsProposalTemplateTier?: RmsProposalTemplateTierResolvers<ContextType>;
  RmsProposalTemplateTierLines?: RmsProposalTemplateTierLinesResolvers<ContextType>;
  RmsProposalTemplateTierSites?: RmsProposalTemplateTierSitesResolvers<ContextType>;
  Role?: RoleResolvers<ContextType>;
  RoleAndRate?: RoleAndRateResolvers<ContextType>;
  RoleHistory?: RoleHistoryResolvers<ContextType>;
  RoleRequest?: RoleRequestResolvers<ContextType>;
  RoleTeam?: RoleTeamResolvers<ContextType>;
  SFDCEstimate?: SfdcEstimateResolvers<ContextType>;
  SFDCEstimateProduct?: SfdcEstimateProductResolvers<ContextType>;
  SFDCOpportunity?: SfdcOpportunityResolvers<ContextType>;
  SFDCSkillRequest?: SfdcSkillRequestResolvers<ContextType>;
  SalesHistory?: SalesHistoryResolvers<ContextType>;
  SalesRepTrending?: SalesRepTrendingResolvers<ContextType>;
  SaveEstimateToPsaResponse?: SaveEstimateToPsaResponseResolvers<ContextType>;
  ScopeGroup?: ScopeGroupResolvers<ContextType>;
  ScopeItem?: ScopeItemResolvers<ContextType>;
  ScopingSession?: ScopingSessionResolvers<ContextType>;
  ScopingSessionSection?: ScopingSessionSectionResolvers<ContextType>;
  SearchCustomersResponse?: SearchCustomersResponseResolvers<ContextType>;
  SearchServiceItemsResponse?: SearchServiceItemsResponseResolvers<ContextType>;
  SearchServiceSkillsResponse?: SearchServiceSkillsResponseResolvers<ContextType>;
  SearchServiceSuppliersResponse?: SearchServiceSuppliersResponseResolvers<ContextType>;
  SearchSowSectionsResponse?: SearchSowSectionsResponseResolvers<ContextType>;
  SearchTaskGroupsResponse?: SearchTaskGroupsResponseResolvers<ContextType>;
  SearchUsersResponse?: SearchUsersResponseResolvers<ContextType>;
  ServiceCategory?: ServiceCategoryResolvers<ContextType>;
  ServiceItem?: ServiceItemResolvers<ContextType>;
  ServiceLocation?: ServiceLocationResolvers<ContextType>;
  ServiceLocationRole?: ServiceLocationRoleResolvers<ContextType>;
  ServicePractice?: ServicePracticeResolvers<ContextType>;
  ServiceSkill?: ServiceSkillResolvers<ContextType>;
  ServiceSummary?: ServiceSummaryResolvers<ContextType>;
  ServiceSummaryResponse?: ServiceSummaryResponseResolvers<ContextType>;
  ServiceSupplier?: ServiceSupplierResolvers<ContextType>;
  SessionQuestion?: SessionQuestionResolvers<ContextType>;
  SfdcAccount?: SfdcAccountResolvers<ContextType>;
  SfdcRegion?: SfdcRegionResolvers<ContextType>;
  Site?: SiteResolvers<ContextType>;
  SiteBreakdown?: SiteBreakdownResolvers<ContextType>;
  SiteTask?: SiteTaskResolvers<ContextType>;
  SiteWithTasks?: SiteWithTasksResolvers<ContextType>;
  SkillSummary?: SkillSummaryResolvers<ContextType>;
  SkillSummaryRow?: SkillSummaryRowResolvers<ContextType>;
  SowSection?: SowSectionResolvers<ContextType>;
  SowSectionTeam?: SowSectionTeamResolvers<ContextType>;
  SowTaskGroup?: SowTaskGroupResolvers<ContextType>;
  SprintSummary?: SprintSummaryResolvers<ContextType>;
  SprintSummaryRow?: SprintSummaryRowResolvers<ContextType>;
  StringOrArray?: GraphQLScalarType;
  Task?: TaskResolvers<ContextType>;
  TaskGroup?: TaskGroupResolvers<ContextType>;
  TaskGroupTeam?: TaskGroupTeamResolvers<ContextType>;
  TaskGroupsPrintOptions?: TaskGroupsPrintOptionsResolvers<ContextType>;
  TaskHasGroup?: TaskHasGroupResolvers<ContextType>;
  TaskList?: TaskListResolvers<ContextType>;
  TaskNote?: TaskNoteResolvers<ContextType>;
  TaskOrder?: TaskOrderResolvers<ContextType>;
  TaskPhase?: TaskPhaseResolvers<ContextType>;
  Tier?: TierResolvers<ContextType>;
  TrainingOrCollateral?: TrainingOrCollateralResolvers<ContextType>;
  TravelExpenseSummaryResponse?: TravelExpenseSummaryResponseResolvers<ContextType>;
  UpdateLeadResponse?: UpdateLeadResponseResolvers<ContextType>;
  UpdateProjectResponse?: UpdateProjectResponseResolvers<ContextType>;
  UpdatedTaskMutationResponse?: UpdatedTaskMutationResponseResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserCiscoCcwToken?: UserCiscoCcwTokenResolvers<ContextType>;
  VolumePricing?: VolumePricingResolvers<ContextType>;
  ZuoraRateChange?: ZuoraRateChangeResolvers<ContextType>;
  ZuoraRatePlan?: ZuoraRatePlanResolvers<ContextType>;
  ZuoraSubscription?: ZuoraSubscriptionResolvers<ContextType>;
  ZuoraTier?: ZuoraTierResolvers<ContextType>;
  estimateToPSAInputs?: EstimateToPsaInputsResolvers<ContextType>;
  getPracticesResponse?: GetPracticesResponseResolvers<ContextType>;
  getServiceCategoriesResponse?: GetServiceCategoriesResponseResolvers<ContextType>;
  getServiceLocationsResponse?: GetServiceLocationsResponseResolvers<ContextType>;
  getServiceLocationsRolesResponse?: GetServiceLocationsRolesResponseResolvers<ContextType>;
  getServicePracticesResponse?: GetServicePracticesResponseResolvers<ContextType>;
  wrapperEstimateToPSAInputs?: WrapperEstimateToPsaInputsResolvers<ContextType>;
}>;
