/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  FormAutocomplete,
  FormAutocompleteOption,
  FormTextControlled,
} from '../formHelperFunctions';
import { Grid } from '@mui/material';
import { RoleAndRate } from '@cdw-selline/common/types';
import {
  useProjectItemEstimator,
  useSearchServiceItems,
} from '@cdw-selline/ui/hooks';
import { round } from 'lodash';
import FormFieldAutoComplete from '../autocomplete-form-field/FormFieldAutoComplete';
import { BILLING_TYPES } from '@cdw-selline/ui/constants';

export interface CustomServiceTaskFormProps {
  taskFormData: any;
  setTaskFormData: (value) => void;
  vendorOptions: FormAutocompleteOption[];
  roleOptions: RoleAndRate[];
  errorsArray: any;
  practiceOptions: FormAutocompleteOption[];
}

export function CustomServiceTaskForm({
  taskFormData,
  setTaskFormData,
  vendorOptions,
  roleOptions,
  errorsArray,
  practiceOptions,
}: CustomServiceTaskFormProps) {
  const { data: projectEstimator } = useProjectItemEstimator();
  const fixedFee = [BILLING_TYPES.FIXED_FEE].includes(
    projectEstimator?.billingType
  );

  useEffect(() => {
    if (!taskFormData?.id) {
      setTaskFormData((prevState) => ({
        ...prevState,      
        margin: prevState?.margin ?? 25,
        pmPercent: prevState?.pmPercent ?? 15,
        customServiceDivisorRate: prevState?.customServiceDivisorRate ?? 250,
        customServiceCost: prevState?.customServiceCost ?? 0,
        primaryRole: projectEstimator?.pmRole ?? '',
      }));
    }

    setTaskFormData((prevState) => ({
      ...prevState,
      custom: true,
      disableQuantity: true,
      disableHours: true,
      units: 'Hours',
      isProjectManagement: fixedFee ? true : false,
    }));
  }, [projectEstimator?.pmRole]);

  const [customServiceSellPrice, setCustomServiceSellPrice] = useState<number>(
    taskFormData?.customServiceSellPrice ?? 0
  );
  const [quantity, setQuantity] = useState<number>(taskFormData?.quantity ?? 0);


  useEffect(() => {
    if (taskFormData?.customServiceCost) {
      const sellPrice = round(
        taskFormData.customServiceCost / (1 - taskFormData.margin / 100),
        2
      );
      const quantity = Math.ceil(
        (taskFormData.customServiceCost /
          (taskFormData.customServiceDivisorRate ?? 250)) *
          (taskFormData.pmPercent / 100)
      );
      setTaskFormData((prevState) => ({
        ...prevState,
        customServiceSellPrice: sellPrice,
        quantity: fixedFee ? quantity : taskFormData.quantity,
      }));

      setCustomServiceSellPrice(sellPrice);

      if(fixedFee) {
        setQuantity(quantity);
      }
    }

    if (!taskFormData?.customServiceCost) {
      setCustomServiceSellPrice(0);
      if(fixedFee) {
        setQuantity(0);
      }
    }
  }, [
    taskFormData.customServiceCost,
    taskFormData.customServiceDivisorRate,
    taskFormData.pmPercent,
    taskFormData.margin,
    fixedFee,
  ]);

  const pmRoles =
    roleOptions
      .filter((role: RoleAndRate) => role.projectManager)
      .map((role: RoleAndRate) => {
        return {
          id: role.id,
          label: `${role.name}  (${role.practiceName})`,
        };
      }) ?? [];

  const allRoles =
    roleOptions
      .filter((role: RoleAndRate) => role.practice === taskFormData.practiceId)
      .map((role: RoleAndRate) => {
        return {
          id: role.id,
          label: `${role.name}  (${role.practiceName})`,
        };
      }) ?? [];

  return (
    <>
      <FormTextControlled
        name="name"
        label="Name"
        setFormData={setTaskFormData}
        formData={taskFormData}
        required={true}
        error={errorsArray.length > 0 && !taskFormData.name}
      />
      <FormAutocomplete
        name="practiceId"
        label="Practice"
        options={practiceOptions}
        setFormData={setTaskFormData}
        formData={taskFormData}
        data-testid="task-practices"
        required={true}
        error={errorsArray.length > 0 && !taskFormData.practiceId}
      />
      <FormAutocomplete
        name="vendor"
        label="Vendor"
        options={vendorOptions}
        setFormData={setTaskFormData}
        formData={taskFormData}
        // freeSolo={true}
        required={true}
        error={errorsArray.length > 0 && !taskFormData.vendor}
      />
      <FormTextControlled
        name="customServiceCost"
        label={fixedFee ? "Cost" : "Hourly Cost"}
        setFormData={setTaskFormData}
        formData={taskFormData}
        type="number"
        required={true}
        error={errorsArray.length > 0 && !taskFormData.customServiceCost}
      />
      <FormTextControlled
        name="margin"
        label="Margin"
        setFormData={setTaskFormData}
        formData={taskFormData}
        type="number"
        inputProps={{ min: 15 }}
      />
      {fixedFee && (
        <>
          <FormTextControlled
            name="pmPercent"
            label="PM Percent"
            setFormData={setTaskFormData}
            formData={taskFormData}
            type="number"
          />
          <FormTextControlled
            name="customServiceDivisorRate"
            label="Divisor Rate"
            setFormData={setTaskFormData}
            formData={taskFormData}
            type="number"
          />
        </>
      )}
      <FormAutocomplete
        name="primaryRole"
        label={fixedFee ? "PM Role" : "Primary Role"}
        options={fixedFee ? pmRoles : allRoles}
        setFormData={setTaskFormData}
        formData={taskFormData}
        required={true}
        error={errorsArray.length > 0 && !taskFormData.primaryRole}
      />
      <Grid item xs={12} sx={{ width: '100%' }}>
        <FormTextControlled
          name={'quantity'}
          id={'quantity'}
          setFormData={setTaskFormData}
          formData={taskFormData}
          label={fixedFee ? "PM Hours" : "Partner Hours"}
          disabled={fixedFee}
          type="number"
          sx={{ width: '100%' }}
        />
      </Grid>
      <Grid item xs={12} sx={{ width: '100%' }}>
        <FormTextControlled
          name={'customServiceSellPrice'}
          id={'customServiceSellPrice'}
          setFormData={setTaskFormData}
          formData={taskFormData}
          label={fixedFee ? 'Total Sell Price' : 'Hourly Sell Price'}
          disabled={true}
          type="number"
          sx={{ width: '100%' }}
        />
      </Grid>
      <FormTextControlled
        name="description"
        label="Description"
        setFormData={setTaskFormData}
        formData={taskFormData}
      />
      <FormFieldAutoComplete
        name="serviceItem"
        label="Service Item"
        setTaskFormData={setTaskFormData}
        taskFormData={taskFormData}
        useSearchHook={useSearchServiceItems}
        idColumnName="serviceItemId"
        isUpdateIdAndName={true}
      />
    </>
  );
}
