import { TaskInput } from '@cdw-selline/common/types';
import { BILLING_TYPES } from '@cdw-selline/ui/constants';
import {
  useCustomTasksList,
  useOpenState,
  useProjectItemEstimator,
  useProjectPracticesAndRatesQuery,
  useValidateTask,
} from '@cdw-selline/ui/hooks';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { HotTable } from '@handsontable/react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material';
import 'handsontable/dist/handsontable.full.min.css';
import { registerAllModules } from 'handsontable/registry';
import { HyperFormula } from 'hyperformula';
import { isEqual, pickBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DialogConfirm } from '../../../dialog-confirm/DialogConfirm';
import {
  FormAutocomplete,
  FormSelect,
  FormText,
} from '../../../formHelperFunctions';
import { formatBoolean } from '@cdw-selline/ui/helpers';

const hf = HyperFormula.buildEmpty({
  licenseKey: 'gpl-v3',
});

const sheetName = hf.addSheet('customTasks');
const sheetId = hf.getSheetId(sheetName);

export interface CustomTasksListProps {
  siteId: string;
  setIsCustomTasksList: (value: boolean) => void;
}

enum tableColumnsEnum {
  ID = 'ID',
  TYPE = 'Type',
  COST_TYPE = 'CostType',
  NAME = 'Name',
  CATEGORY = 'Category',
  HOURS = 'Hours',
  QUANTITY = 'Quantity',
  COST = 'Cost',
  PRACTICE = 'Practice',
  IS_MANAGED_SERVICES = 'isManagedServices',
  IS_PROJECT_MANAGEMENT = 'isProjectManagement',
  IS_TRAVEL_TIME = 'isTravelTime',
  PRIMARY_ROLE = 'PrimaryRole',
  PRIMARY_PERCENT = 'PrimaryPercent',
  PRIMARY_ROLE_RATE_OVERRIDE = 'PrimaryRoleRateOverride',
  SECONDARY_ROLE = 'SecondaryRole',
  SECONDARY_PERCENT = 'SecondaryPercent',
  SECONDARY_ROLE_RATE_OVERRIDE = 'SecondaryRoleRateOverride',
  TERTIARY_ROLE = 'TertiaryRole',
  TERTIARY_PERCENT = 'TertiaryPercent',
  TERTIARY_ROLE_RATE_OVERRIDE = 'TertiaryRoleRateOverride',
  OVERTIME = 'Overtime',
  SKILL = 'Skill',
  SERVICE_ITEM = 'ServiceItem',
  VENDOR = 'Vendor',
  CUSTOM_SERVICE_COST='CustomServiceCost',
  CUSTOM_SERVICE_DIVISOR_RATE='CustomServiceDivisorRate',
  CUSTOM_SERVICE_SELL_PRICE='CustomServiceSellPrice',
  MARGIN='Margin',
  PM_PERCENT='PM Percent'
};

enum tableTemplateEnum {
  ID = 'EXCLUDED',
  TYPE = 'Hours',
  COST_TYPE = '',
  NAME = '<Name of Task>',
  CATEGORY = 'Other',
  HOURS = '<Hours per quantity>',
  QUANTITY = '1',
  COST = '<Cost per quantity>',
  PRACTICE = '<Practice Name>',
  IS_MANAGED_SERVICES = '<true/false>',
  IS_PROJECT_MANAGEMENT = '<true/false>',
  IS_TRAVEL_TIME = '<true/false>',
  PRIMARY_ROLE = '<Primary Role>',
  PRIMARY_PERCENT = '<Percent of hours for primary role>',
  PRIMARY_ROLE_RATE_OVERRIDE = '<Primary role rate override>',
  SECONDARY_ROLE = '<Percentage of hours for secondary role>',
  SECONDARY_PERCENT = '<Secondary Role>',
  SECONDARY_ROLE_RATE_OVERRIDE = '<Secondary role rate override>',
  TERTIARY_ROLE = '<Percentage of hours for tertiary role>',
  TERTIARY_PERCENT = '<Tertiary Role>',
  TERTIARY_ROLE_RATE_OVERRIDE = '<Tertiary role rate override>',
  OVERTIME = '<true/false>',
  SKILL = '<Skill Name>',
  SERVICE_ITEM = '<Service Item Name>',
  VENDOR = '<Vendor Name>',
  CUSTOM_SERVICE_COST='<Custom Service Cost Amount>',
  CUSTOM_SERVICE_DIVISOR_RATE='<Custom Service Divisor Rate>',
  CUSTOM_SERVICE_SELL_PRICE='<Custom Service Sell Price>',
  MARGIN='<Margin>',
  PM_PERCENT='<PM Percent>'
};

export const CustomTasksList = (props: CustomTasksListProps) => {
  const { projectItemId } = useParams<{ projectItemId: string }>();
  const [rowToDelete, setRowToDelete] = useState(null);
  const [rowIndexToDelete, setRowIndexToDelete] = useState(null);
  const [tableColumns, setTableColumns] = useState(Array(3));
  const [tableHeaders, setTableHeaders] = useState([]);
  const { validateTasks } = useValidateTask();

  registerAllModules();
  const alertState = useAlertsState();
  const tableRef = useRef(null);
  const {
    data: estimator,
    updateProjectItemEstimator,
    getProjectItemEstimatorLoading
  } = useProjectItemEstimator();

  const {
    loading,
    error,
    tableData,
    handleCustomTasksSave,
    handleDeleteCustomTask,
    practices,
    roles,
    serviceSkills,
    serviceItems,
    vendors,
    practicesLoading,
    rolesLoading,
    serviceSkillsLoading,
    serviceItemsLoading,
    vendorsLoading,
  } = useCustomTasksList(props.siteId, props.setIsCustomTasksList);

  const {
    currentRolesAndRates,
  } = useProjectPracticesAndRatesQuery(projectItemId);

  const getPracticeNameById = (practiceId: string): string => {
    const practice = practices.practices.find((practice) => practice.id === practiceId);
    return practice ? practice.name : '';
  }

  const pmOptions = currentRolesAndRates
    .filter((rate) => rate.projectManager)
    .map((role) => ({
      id: role.id,
      label: `${role.name} (${getPracticeNameById(role.practice)}) `,
    }));

  const isPmRoleInOptions = Boolean(
    pmOptions.some((option) => option.id === estimator.pmRole)
  );

  const handleUpdate = (updatedFormData) => {
    const updatedRequest = {projectItemId, ...pickBy(updatedFormData, (v, k) => !isEqual(estimator[k], v))};
      updateProjectItemEstimator({
        variables: {
          params: updatedRequest,
        },
      });
    };

  const getTableColumns = (dataColumns) => {
    return dataColumns.map((column) => {
      switch (column?.trim()) {
        case tableColumnsEnum.PRACTICE:
          return {
            width: '200',
            type: 'autocomplete',
            source: practices.practices.map((practice) => practice.name),
            visibleRows: 5,
          };
        case tableColumnsEnum.SKILL:
          return {
            width: '200',
            editor: 'autocomplete',
            type: 'autocomplete',
            source: serviceSkills.serviceSkills.map((skill) => skill.name),
            strict: false,
            visibleRows: 5,
          };
        case tableColumnsEnum.SERVICE_ITEM:
          return {
            width: '300',
            type: 'autocomplete',
            source: serviceItems.serviceItems.map((item) => item.name),
            strict: false,
            visibleRows: 5,
          };
        case tableColumnsEnum.PRIMARY_ROLE:
        case tableColumnsEnum.SECONDARY_ROLE:
        case tableColumnsEnum.TERTIARY_ROLE:
          return {
            width: '200',
            type: 'autocomplete',
            source: roles.roles.map((role) => role.name),
            visibleRows: 5,
          };
        case tableColumnsEnum.IS_MANAGED_SERVICES:
        case tableColumnsEnum.IS_PROJECT_MANAGEMENT:
        case tableColumnsEnum.IS_TRAVEL_TIME:
        case tableColumnsEnum.OVERTIME:
          return {
            width: '100',
            type: 'dropdown',
            source: [true, false],
          };
      case tableColumnsEnum.VENDOR:
        return {
          width: '300',
          type: 'autocomplete',
          source: vendors.serviceSuppliers.map((item) => item.Name),
          strict: false,
          visibleRows: 5,
        };
        default: {
          return {
            width: '100',
          };
        }
      }
    });
  };

  useEffect(() => {
    if (
      !loading &&
      !practicesLoading &&
      !rolesLoading &&
      !serviceSkillsLoading &&
      !serviceItemsLoading &&
      !vendorsLoading
    ) {
      const headers = tableData[0][0] ? Object.values(tableColumnsEnum) : [];
      const columns = tableData[0][0]
        ? getTableColumns(Object.values(tableColumnsEnum))
        : Array(3);
      setTableColumns(columns);
      setTableHeaders(headers);
    }
  }, [
    loading,
    practicesLoading,
    rolesLoading,
    serviceSkillsLoading,
    serviceItemsLoading,
    vendorsLoading,
  ]);

  useEffect(() => {
    const rows = tableRef.current?.hotInstance?.getData();
    if (rows && rows?.length && rows[0][0] && tableColumns.length > 3) {
      rows.forEach((row, ind) => {
        const practiceId = getPracticeId(
          row[getHeaderIndex(tableColumnsEnum.PRACTICE)]?.trim()
        );
        const primaryRole =
          row[getHeaderIndex(tableColumnsEnum.PRIMARY_ROLE)]?.trim();
        const secondaryRole =
          row[getHeaderIndex(tableColumnsEnum.SECONDARY_ROLE)]?.trim();
        const primaryRoleId = getRoleId(primaryRole, practiceId);
        const secondaryRoleId = getRoleId(secondaryRole, practiceId);
        const tertiaryRole =
        row[getHeaderIndex(tableColumnsEnum.TERTIARY_ROLE)]?.trim();
        const tertiaryRoleId = getRoleId(tertiaryRole, practiceId);
        filterColumnsByPractice(practiceId, ind, {
          primaryRole: primaryRoleId ? primaryRole : '',
          secondaryRole: secondaryRoleId ? secondaryRole : '',
          tertiaryRole: tertiaryRoleId ? tertiaryRole : '',
        });
      });
    }
  }, [tableRef.current, tableColumns]);

  hf.setCellContents(
    {
      row: 0,
      col: 0,
      sheet: sheetId,
    },
    tableData
  );

  const {
    isOpen: confirmDelete,
    handleClose: handleDeleteConfirmClose,
    handleOpen: handleDeleteConfirmOpen,
  } = useOpenState();

  if (loading) {
    return (
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
        <CircularProgress />
      </Backdrop>
    );
  }

  if (error)
    return (
      <Typography>
        Error loading Custom Tasks List for site id {props.siteId}
      </Typography>
    );

  const handleDeleteConfirm = () => {
    if (getHeaderIndex(tableColumnsEnum.ID) > -1 && rowToDelete[0]) {
      handleDeleteCustomTask(rowToDelete[0]);
    } else {
      tableRef.current?.hotInstance.alter('remove_row', rowIndexToDelete);
    }
    handleDeleteConfirmClose();
    setRowToDelete(null);
    setRowIndexToDelete(null);
  };

  const handleDeleteClick = (row, rowIndex) => {
    setRowToDelete(row);
    setRowIndexToDelete(rowIndex);
    handleDeleteConfirmOpen();
  };

  const getHeaderIndex = (header: tableColumnsEnum) => {
    return tableHeaders.indexOf(header);
  };

  const getPracticeId = (value: string) => {
    return (
      practices.practices.find((practice) => practice.name === value)?.id || ''
    );
  };

  const getRoleId = (value: string, practiceId: string) => {
    return (
      roles.roles.find(
        (role) => role.name === value && role.practice === practiceId
      )?.id || ''
    );
  };

  const getServiceItemId = (value: string) => {
    return (
      serviceItems.serviceItems.find(
        (serviceItem) => serviceItem.name === value
      )?.id || ''
    );
  };

  const getServiceSkillId = (value: string) => {
    return (
      serviceSkills.serviceSkills.find(
        (serviceSkill) => serviceSkill.name === value
      )?.id || ''
    );
  };

  const getVendorId = (value: string) => {
    return (
      vendors.serviceSuppliers.find(
        (vendor) => vendor.Name === value
      )?.Id || ''
    );
  }

  const handleReset = () => {
    tableRef.current?.hotInstance.alter('remove_row', [
      [0, tableData.length - 1],
    ]);
    tableRef.current?.hotInstance.clear();
    setTableColumns(Array(3));
    setTableHeaders([]);
  };

  const validateCustomTasks = async (updatedTasks: TaskInput[]) => {
    const errorMessages = await validateTasks(updatedTasks);

    if (errorMessages.length) {
      alertState.setAlert({
        type: ALERT_TYPE.MODAL,
        severity: ALERT_SEVERITY.ERROR,
        message: errorMessages.join('\n'),
      });
    }
    return Boolean(!errorMessages.length);
  };

  const handleSave = async () => {
    if (tableColumns.length <= 3) {
      alertState.setAlert({
        type: ALERT_TYPE.MODAL,
        severity: ALERT_SEVERITY.WARNING,
        message: 'No tasks to save!',
      });
      return;
    }

    const customTasksTableRef = tableRef.current?.hotInstance.getData();
    const updatedCustomTasks = customTasksTableRef.map((row, ind) => {
      const practiceId = getPracticeId(
        row[getHeaderIndex(tableColumnsEnum.PRACTICE)]?.trim()
      );

      return {
        id: row[getHeaderIndex(tableColumnsEnum.ID)]?.trim(),
        type: row[getHeaderIndex(tableColumnsEnum.TYPE)]?.trim(),
        costType: row[getHeaderIndex(tableColumnsEnum.COST_TYPE)]?.trim(),
        name: row[getHeaderIndex(tableColumnsEnum.NAME)]?.trim(),
        category:
          row[getHeaderIndex(tableColumnsEnum.CATEGORY)]?.trim() || 'Other',
        quantity: Number(row[getHeaderIndex(tableColumnsEnum.QUANTITY)]),
        hours: Number(row[getHeaderIndex(tableColumnsEnum.HOURS)]),
        cost: Number(row[getHeaderIndex(tableColumnsEnum.COST)]),
        defaultCost: Number(row[getHeaderIndex(tableColumnsEnum.COST)]),
        managedServices: formatBoolean(row[getHeaderIndex(tableColumnsEnum.IS_MANAGED_SERVICES)]),
        isProjectManagement: formatBoolean(row[getHeaderIndex(tableColumnsEnum.IS_PROJECT_MANAGEMENT)]),
        isTravelTime: formatBoolean(row[getHeaderIndex(tableColumnsEnum.IS_TRAVEL_TIME)]),
        practiceId: practiceId,
        practice: row[getHeaderIndex(tableColumnsEnum.PRACTICE)]?.trim(),
        primaryRoleId: getRoleId(
          row[getHeaderIndex(tableColumnsEnum.PRIMARY_ROLE)]?.trim(),
          practiceId
        ),
        primaryRole: getRoleId(
          row[getHeaderIndex(tableColumnsEnum.PRIMARY_ROLE)]?.trim(),
          practiceId
        ),
        primaryRoleName:
          row[getHeaderIndex(tableColumnsEnum.PRIMARY_ROLE)]?.trim(),
        primaryRoleRateOverride: Number(
          row[getHeaderIndex(tableColumnsEnum.PRIMARY_ROLE_RATE_OVERRIDE)]
        ),
        primaryPercent:
          Number(
            row[getHeaderIndex(tableColumnsEnum.PRIMARY_PERCENT)]
          ) || 100,
        units: 'Hours',
        vendor: getVendorId(row[getHeaderIndex(tableColumnsEnum.VENDOR)]?.trim()) || row[getHeaderIndex(tableColumnsEnum.VENDOR)]?.trim() || 'CDW',
        secondaryRoleId: getRoleId(
          row[getHeaderIndex(tableColumnsEnum.SECONDARY_ROLE)]?.trim(),
          practiceId
        ),
        secondaryRole: getRoleId(
          row[getHeaderIndex(tableColumnsEnum.SECONDARY_ROLE)]?.trim(),
          practiceId
        ),
        secondaryRoleName:
          row[getHeaderIndex(tableColumnsEnum.SECONDARY_ROLE)]?.trim(),
        secondaryRoleRateOverride: Number(
          row[getHeaderIndex(tableColumnsEnum.SECONDARY_ROLE_RATE_OVERRIDE)]
        ),
        secondaryPercent:
          Number(
            row[getHeaderIndex(tableColumnsEnum.SECONDARY_PERCENT)]
          ) || 0,
        tertiaryRoleId: getRoleId(
          row[getHeaderIndex(tableColumnsEnum.TERTIARY_ROLE)]?.trim(),
          practiceId
        ),
        tertiaryRole: getRoleId(
          row[getHeaderIndex(tableColumnsEnum.TERTIARY_ROLE)]?.trim(),
          practiceId
        ),
        tertiaryRoleName:
          row[getHeaderIndex(tableColumnsEnum.TERTIARY_ROLE)]?.trim(),
        tertiaryRoleRateOverride: Number(
          row[getHeaderIndex(tableColumnsEnum.TERTIARY_ROLE_RATE_OVERRIDE)]
        ),
        tertiaryPercent:
          Number(
            row[getHeaderIndex(tableColumnsEnum.TERTIARY_PERCENT)]
          ) || 0,
        siteId: props.siteId,
        projectItemId: projectItemId,
        custom: true,
        order: 2000 + ind,
        exclude: false,
        allowMarkup: true,
        version: 1,
        taskGroupId: '',
        imported: true,
        skillId: getServiceSkillId(
          row[getHeaderIndex(tableColumnsEnum.SKILL)]?.trim()
        ),
        skill: row[getHeaderIndex(tableColumnsEnum.SKILL)]?.trim(),
        serviceItemId: getServiceItemId(
          row[getHeaderIndex(tableColumnsEnum.SERVICE_ITEM)]?.trim()
        ),
        serviceItem: row[getHeaderIndex(tableColumnsEnum.SERVICE_ITEM)]?.trim(),
        overtime: formatBoolean(row[getHeaderIndex(tableColumnsEnum.OVERTIME)]),
        totalHoursNoRounding: true,
        estimatorLocation: 'site',
        customServiceCost: Number(row[getHeaderIndex(tableColumnsEnum.CUSTOM_SERVICE_COST)] ?? 0),
        customServiceDivisorRate: Number(row[getHeaderIndex(tableColumnsEnum.CUSTOM_SERVICE_DIVISOR_RATE)] ?? 250),
        customServiceSellPrice: Number(row[getHeaderIndex(tableColumnsEnum.CUSTOM_SERVICE_SELL_PRICE)] ?? 0),
        margin: Number(row[getHeaderIndex(tableColumnsEnum.MARGIN)] ?? 0),
        pmPercent: Number(row[getHeaderIndex(tableColumnsEnum.PM_PERCENT)] ?? 0),
      } as TaskInput;
    });

    const isValid = await validateCustomTasks(updatedCustomTasks);
    if (!isValid) {
      return false;
    }
    if (updatedCustomTasks) {
      handleCustomTasksSave(updatedCustomTasks);
      props.setIsCustomTasksList(false);
    } else {
      alertState.setAlert({
        type: ALERT_TYPE.MODAL,
        severity: ALERT_SEVERITY.WARNING,
        message: 'No tasks to save!',
      });
    }
  };

  const handleExportClick = () => {
    const exportPlugin = tableRef.current?.hotInstance.getPlugin('exportFile');

    exportPlugin.downloadFile('csv', {
      bom: false,
      columnDelimiter: ',',
      columnHeaders: true,
      exportHiddenColumns: true,
      exportHiddenRows: true,
      fileExtension: 'csv',
      filename: 'Custom Tasks' + '-CSV-file_[YYYY]-[MM]-[DD]',
      mimeType: 'text/csv',
      rowDelimiter: '\r\n',
      rowHeaders: true,
    });
  };

  const downloadTemplate = () => {
    const templateFilter = [
      "ID",
      "COST",
      "COST_TYPE",
      "IS_MANAGED_SERVICES",
      "PRIMARY_PERCENT",
      "SECONDARY_ROLE",
      "SECONDARY_PERCENT",
      "SECONDARY_ROLE_RATE_OVERRIDE",
      "TERTIARY_ROLE",
      "TERTIARY_PERCENT",
      "TERTIARY_ROLE_RATE_OVERRIDE",
    ]

    const headerRow = Object.values(tableColumnsEnum).filter(
      (value, index) =>
        !templateFilter.includes(Object.keys(tableColumnsEnum)[index])
    );

    const templateSampleRow = Object.values(tableTemplateEnum).filter(
      (value, index) =>
        !templateFilter.includes(Object.keys(tableTemplateEnum)[index])
    );    

    const csvRows = [];
    csvRows.push(Object.values(headerRow).join(','));
    csvRows.push(Object.values(templateSampleRow).join(','));

    const csvString = csvRows.join('\n');

    const blob = new Blob([csvString], { type: 'text/csv' });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'CustomTasksTemplate.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleBeforePaste = (data, coords) => {
    const rows = tableRef.current?.hotInstance?.getData();
    if (rows.length > 1) return false;
    const headers = data[0];
    setTableHeaders(headers);
    setTableColumns(getTableColumns(headers));
    data.splice(0, 1);
  };

  const filterColumnsByPractice = (
    practiceId: string,
    row: number,
    data: { primaryRole: string; secondaryRole: string; tertiaryRole: string }
  ) => {
    const rolesByPractice = roles.roles
      .filter((role) => role.practice === practiceId)
      .map((role) => role.name);
    const primaryRoleColumnIndex = getHeaderIndex(
      tableColumnsEnum.PRIMARY_ROLE
    );
      if (primaryRoleColumnIndex > 0)
      {
        tableRef.current?.hotInstance?.setCellMeta(
          row,
          primaryRoleColumnIndex,
          'source',
          rolesByPractice
        );
        tableRef.current?.hotInstance?.setDataAtCell(
          row,
          primaryRoleColumnIndex,
          data.primaryRole,
          'internal'
        );
      }
    const secondaryRoleColumnIndex = getHeaderIndex(
      tableColumnsEnum.SECONDARY_ROLE
    );
      if (secondaryRoleColumnIndex > 0)
      {
        tableRef.current?.hotInstance?.setCellMeta(
          row,
          secondaryRoleColumnIndex,
          'source',
          rolesByPractice
        );
        tableRef.current?.hotInstance?.setDataAtCell(
          row,
          secondaryRoleColumnIndex,
          data.secondaryRole,
          'internal'
        );
      }
      const tertiaryRoleColumnIndex = getHeaderIndex(
        tableColumnsEnum.TERTIARY_ROLE
      );
        if (tertiaryRoleColumnIndex > 0)
        {
          tableRef.current?.hotInstance?.setCellMeta(
            row,
            tertiaryRoleColumnIndex,
            'source',
            rolesByPractice
          );
          tableRef.current?.hotInstance?.setDataAtCell(
            row,
            tertiaryRoleColumnIndex,
            data.tertiaryRole,
            'internal'
          );
        }
  };

  const handleAfterPaste = (data, coords) => {
    data.forEach((row, ind) => {
      const practiceId = getPracticeId(
        row[getHeaderIndex(tableColumnsEnum.PRACTICE)]?.trim()
      );
      const primaryRole =
        row[getHeaderIndex(tableColumnsEnum.PRIMARY_ROLE)]?.trim();
      const secondaryRole =
        row[getHeaderIndex(tableColumnsEnum.SECONDARY_ROLE)]?.trim();
      const primaryRoleId = getRoleId(primaryRole, practiceId);
      const secondaryRoleId = getRoleId(secondaryRole, practiceId);
      const tertiaryRole =
       row[getHeaderIndex(tableColumnsEnum.TERTIARY_ROLE)]?.trim();
      const tertiaryRoleId = getRoleId(tertiaryRole, practiceId);

      filterColumnsByPractice(practiceId, ind, {
        primaryRole: primaryRoleId ? primaryRole : '',
        secondaryRole: secondaryRoleId ? secondaryRole : '',
        tertiaryRole: tertiaryRoleId ? tertiaryRole : '',
      });
    });
  };

  const handleBeforeChange = (changes, source) => {
    if (source === 'loadData' || source === 'internal' || changes.length > 1) {
      return;
    }
    const row = changes[0][0];
    const columnIndex = changes[0][1];
    const prop = tableHeaders[columnIndex];
    const value = changes[0][3];

    if (prop === tableColumnsEnum.PRACTICE) {
      const practiceId = getPracticeId(value);
      filterColumnsByPractice(practiceId, row, {
        primaryRole: '',
        secondaryRole: '',
        tertiaryRole: '',
      });
    }
  };

  if(rolesLoading || practicesLoading || serviceSkillsLoading || serviceItemsLoading) {
    return (
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
        <CircularProgress />
      </Backdrop>
    );
  }
  
  return (
    <Grid container sx={{ width: '100%' }}>
      <Box sx={{ margin: '5px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              If this is a new import and you are unsure of Roles, Practices,
              Service Items, or Skills please click the provide feedback bellow
              for assistance.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              To import custom tasks paste your properly formated estimate into
              the first cell of the sheet. Be sure to include the header names.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<FileDownloadIcon />}
              onClick={downloadTemplate}
            >
              Download Template
            </Button>
          </Grid>
        </Grid>
      </Box>
      {loading && (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress />
        </Backdrop>
      )}

      {!getProjectItemEstimatorLoading && (
        <Grid container spacing={2} style={{ marginTop: '5px' }}>
          <Grid item xs={2}>
            <FormText
              name="pmPercent"
              label="PM %"
              setFormData={handleUpdate}
              formData={estimator}
              type="number"
            />
          </Grid>
          <Grid item xs={2}>
            <FormAutocomplete
              name="pmRole"
              label="PM Role"
              formData={isPmRoleInOptions ? estimator : {}}
              setFormData={handleUpdate}
              options={pmOptions}
            />
          </Grid>
          <Grid item xs={2}>
            <FormSelect
              name="billingType"
              label="Billing Type"
              setFormData={handleUpdate}
              formData={{ ...estimator, billingType: estimator.billingType || BILLING_TYPES.TIME_MATERIALS }}
            >
              {Object.keys(BILLING_TYPES).map((type) => (
                <MenuItem key={type} value={BILLING_TYPES[type]}>
                  {BILLING_TYPES[type]}
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
          <Grid item xs={2}>
            {estimator?.billingType === BILLING_TYPES.FIXED_FEE && (
              <FormText
                name="riskMitigation"
                label="Risk Mitigation %"
                setFormData={handleUpdate}
                formData={estimator}
                type="number"
              />
            )}
          </Grid>
        </Grid>
      )}

      <Grid item xs={12}>
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <Grid container alignItems={'flex-end'}>
            <Button onClick={() => props.setIsCustomTasksList(false)}>
              Cancel
            </Button>
            <Button onClick={handleSave}>Save</Button>
            <Button onClick={handleReset}>Reset</Button>
            <Button onClick={handleExportClick}>Export</Button>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            overflow: 'hidden',
            width: '100vw - 5px',
            height: '84vh',
            bgcolor: 'background.paper',
          }}
        >
          <HotTable
            {...{
              id: 'custom-tasks-table',
              rowHeaders: true,
              autoColumnSize: false,
              autoRowSize: false,
              trimDropdown: false,
              colHeaders: tableHeaders,
              columns: tableColumns,
              manualColumnFreeze: true,
              stretchH: 'all',
              manualRowMove: true,
              manualColumnResize: true,
              manualColumnHide: true,
              // hiddenColumns: {
              //   columns: [0],
              // },
              ref: tableRef,
              data: tableData,
              licenseKey: 'non-commercial-and-evaluation',
              tableClassName: 'table-template',
              className: 'table-cell',
              beforePaste: handleBeforePaste,
              beforeChange: handleBeforeChange,
              afterPaste: handleAfterPaste,
              contextMenu: {
                items: {
                  row_above: {
                    name: 'Insert row above this one',
                  },
                  row_below: {
                    name: 'Insert row below this one',
                  },
                  delete_custom_task: {
                    name: 'Delete Custom Task',
                    callback: function (key, options) {
                      const rowIndex = options[0].start.row;
                      const row = this.getDataAtRow(rowIndex);
                      handleDeleteClick(row, rowIndex);
                    },
                  },
                },
              },
            }}
          />
        </Box>
        {confirmDelete && (
          <DialogConfirm
            title="Delete this custom task?"
            isOpen={confirmDelete}
            handleClose={handleDeleteConfirmClose}
            handleYes={handleDeleteConfirm}
          >
            <Typography>
              Are you sure you want to delete this custom task?
            </Typography>
          </DialogConfirm>
        )}
      </Grid>
    </Grid>
  );
};

export default CustomTasksList;
