import {
  Button,
  Grid,
  TextField,
  Select,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Autocomplete,
  FormControl,
  FormLabel,
  InputLabel,
  Chip,
} from '@mui/material';
import React, { FocusEvent } from 'react';
import { RichTextEditor } from './rich-text-editor/RichTextEditor';
import { getDisplayValue } from '@cdw-selline/ui/helpers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export interface FormAutocompleteOption {
  id: string;
  label: string;
}

interface FormAutocompleteProps {
  options: FormAutocompleteOption[];
  name: string;
  type?: string;
  freeSolo?: boolean;
  label: string;
  setFormData: any;
  formData: any;
  autoSelect?: boolean;
  required?: boolean;
  error?: boolean;
  width?: string;
  disabled?: boolean;
  hide?: boolean;
  onInputChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: string | null
  ) => void;
}
interface FormRichTextEditorProps {
  name: string;
  label?: string;
  customEditorOptions?: any;
  value: any;
  handleEditorChange: any;
  width?: string;
  hide?: boolean;
}

//TODO: Move to theme
const errorSX = {
  borderColor: 'red',
  borderStyle: 'solid',
  borderWidth: 'thin',
  borderRadius: '4px',
};

const handleChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  setFormData,
  formData
) => {
  if (event.target.type === 'number') {
    setFormData({
      ...formData,
      [event.target.name]: Number(event.target.value),
    });
    return;
  }

  if (event.target.type === 'checkbox') {
    if (event.target.checked) {
      setFormData({ ...formData, [event.target.name]: true });
    } else {
      setFormData({ ...formData, [event.target.name]: false });
    }
  }

  if (event.target.type !== 'checkbox') {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  }
};

const handleMultiSelectChange = (newValue, name, setFormData, formData) => {
  setFormData({
    ...formData,
    [name]: newValue,
  });
};

const handleBlur = (
  event: FocusEvent<HTMLInputElement>,
  setFormData,
  formData,
  decimal = 0
) => {
  let updatedValue: string | number = event.target.value;

  if (event.target.type === 'number') {
    if (decimal > 0) {
      updatedValue = Number(updatedValue).toFixed(decimal).toString();
    } else {
      updatedValue = Number(updatedValue);
    }
  }

  setFormData({
    ...formData,
    [event.target.name]: updatedValue,
  });
};

export const handleAutocompleteChange = (
  value: string | FormAutocompleteOption,
  name: string,
  setFormData,
  formData
) => {
  let newValue = '';
  if (value && typeof value === 'string') {
    newValue = value;
  }

  if (value && typeof value === 'object') {
    newValue = value.id;
  }

  if (!value) {
    newValue = '';
  }

  setFormData({
    ...formData,
    [name]: newValue,
  });
};

export const getActionButtons = (
  handleFormClose,
  handleSaveClick?,
  handleDuplicateClick?: () => void,
  handleTestVersionClick?: () => void,
  handleDeleteClick?: () => void,
  handleApprovalClick?: () => void,
  handleShowchangesClick?: () => void
) => {
  return (
    <Grid container justifyContent="flex-end" spacing={2}>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={handleFormClose}
          data-testid="action-cancel-button"
        >
          Cancel
        </Button>
      </Grid>
      {handleSaveClick && (
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveClick}
            data-testid="action-save-button"
          >
            Save
          </Button>
        </Grid>
      )}
      {handleShowchangesClick && (
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleShowchangesClick}
            data-testid="action-duplicate-button"
          >
            Show Changes
          </Button>
        </Grid>
      )}
      {handleDuplicateClick && (
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDuplicateClick}
            data-testid="action-duplicate-button"
          >
            Duplicate
          </Button>
        </Grid>
      )}
      {handleTestVersionClick && (
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleTestVersionClick}
            data-testid="action-test-button"
          >
            Test Version
          </Button>
        </Grid>
      )}
      {handleDeleteClick && (
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDeleteClick}
            data-testid="action-delete-button"
          >
            Delete
          </Button>
        </Grid>
      )}
      {handleApprovalClick && (
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleApprovalClick}
            data-testid="action-approve-button"
          >
            Approve
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export const FormSelect = (props) => {
  if (props.hide) {
    return null;
  }

  return (
    <Grid item xs={12} sx={[{ width: props?.width ?? '100%' }, props.sx]}>
      <FormControl sx={{ width: '100%' }} error={props.error ?? false}>
        <InputLabel
          required={props.required}
          id={`form-select${props.name}-label`}
        >
          {props.label}
        </InputLabel>
        <Select
          required={props.required ?? false}
          labelId={`form-select${props.name}-label`}
          name={props.name}
          id={props.name}
          multiple={props.multiple}
          value={props.formData[props.name] ?? ''}
          label={props.label}
          disabled={props.disabled ?? false}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            props.onChange
              ? props.onChange(e)
              : handleChange(e, props.setFormData, props.formData)
          }
          sx={{ width: '100%' }}
          data-testid={props.name}
        >
          {props.children}
        </Select>
      </FormControl>
    </Grid>
  );
};

export const FormText = (props) => {
  if (props.hide) {
    return null;
  }

  return (
    <Grid item xs={12} sx={[{ width: props?.width ?? '100%' }, props.sx]}>
      <TextField
        required={props.required ?? false}
        error={props.error ?? false}
        name={props.name}
        id={props.name}
        multiline={props.multiline ?? false}
        rows={props.rows}
        disabled={props.disabled ?? false}
        defaultValue={props.formData[props.name] ?? ''}
        onBlur={(e: FocusEvent<HTMLInputElement>) =>
          handleBlur(e, props.setFormData, props.formData, props.decimal)
        }
        onWheel={(event) => (event.target as HTMLInputElement).blur()}
        label={props.label}
        type={props.type ?? 'text'}
        sx={{ width: '100%', direction: props?.direction ?? '' }}
        data-testid={props.name}
        InputProps={props.inputProps}
        variant={props?.variant ?? 'outlined'}
        title={props?.title ?? ''}
      />
    </Grid>
  );
};

export const FormTextControlled = (props) => {
  if (props.hide) {
    return null;
  }

  return (
    <Grid item xs={12} sx={{ width: props?.width ?? '100%' }}>
      <TextField
        error={props.error ?? false}
        required={props.required ?? false}
        name={props.name}
        id={props.name}
        multiline={props.multiline ?? false}
        disabled={props.disabled ?? false}
        value={props.formData[props.name] ?? ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChange(e, props.setFormData, props.formData)
        }
        onWheel={(event) => (event.target as HTMLInputElement).blur()}
        label={props.label}
        type={props.type ?? 'text'}
        sx={{ width: '100%' }}
        data-testid={props.name}
      />
    </Grid>
  );
};

export const FormCheckbox = (props) => {
  if (props.hide) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              name={props.name}
              disabled={props.disabled}
              checked={props.formData[props.name] || false}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e, props.setFormData, props.formData)
              }
              data-testid={props.name}
            />
          }
          label={props.label}
        />
      </FormGroup>
    </Grid>
  );
};

export const getAutocompleteDefaultValue = (
  name: string,
  options: FormAutocompleteOption[],
  formData
) => {
  if (!Array.isArray(options) || options.length === 0) {
    return '';
  }

  if (!formData[name] || formData[name] === 0 || formData[name] === null) {
    return '';
  }

  const defaultOption = options.find((option) => option.id === formData[name]);
  if (!defaultOption && formData[name]) {
    return formData[name];
  }

  if (!defaultOption) {
    return '';
  }

  return defaultOption.label;
};

export const FormAutocomplete = (props: FormAutocompleteProps) => {
  if (props.hide) {
    return null;
  }

  return (
    <Grid item xs={12} sx={{ width: props?.width ?? '100%' }}>
      <Autocomplete
        freeSolo={props.freeSolo ?? false}
        disabled={props.disabled}
        autoSelect={props.autoSelect ?? props.freeSolo ?? false}
        id={props.name}
        options={props.options}
        sx={[{ width: '100%' }, props.error && errorSX]}
        noOptionsText={`No ${props.label}s available`}
        data-testid={props.name}
        onInputChange={props?.onInputChange}
        onChange={(event, newValue) => {
          handleAutocompleteChange(
            newValue,
            props.name,
            props.setFormData,
            props.formData
          );
        }}
        value={getAutocompleteDefaultValue(
          props.name,
          props.options,
          props.formData
        )}
        isOptionEqualToValue={(option, value) => option.iso === value.iso}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label}
            required={props.required ? true : false}
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props} key={props.id}>
              {option.label}
            </li>
          );
        }}
      />
    </Grid>
  );
};

export const FormRichTextEditor = (props: FormRichTextEditorProps) => {
  if (props.hide) {
    return null;
  }

  return (
    <Grid item xs={12} sx={{ width: props?.width ?? '100%' }}>
      {props.label && (
        <FormControl>
          <FormLabel>{props.label}</FormLabel>
        </FormControl>
      )}
      <RichTextEditor
        width="100%"
        data-testid={props.name}
        documentId={props.name}
        currentValue={getDisplayValue(props.value)}
        handleChange={props.handleEditorChange}
        customEditorOptions={props.customEditorOptions}
      />
    </Grid>
  );
};

export const FormDateField = (props) => {
  if (props.hide) {
    return null;
  }

  return (
    <Grid item xs={12} sx={{ width: props?.width ?? '100%' }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={props.label}
          value={new Date(props.formData[props.name] ?? null)}
          onChange={(newValue) => {
            props.setFormData({
              ...props.formData,
              [props.name]: newValue,
            });
          }}
          slotProps={{ textField: { sx: { width: '100%' }, error: false } }}
        />
      </LocalizationProvider>
    </Grid>
  );
};

export const getMultiSelectAutocompleteDefaultValue = (
  name: string,
  options: FormAutocompleteOption[],
  formData
) => {
  if (!Array.isArray(options) || options.length === 0) {
    return [];
  }

  if (!formData[name] || formData[name] === 0 || formData[name] === null) {
    return [];
  }

  const defaultOption = options.find((option) => option.id === formData[name]);
  if (!defaultOption && formData[name]) {
    return formData[name];
  }

  if (!defaultOption) {
    return [];
  }

  return defaultOption.label;
};

export const FormMultiSelectAutocomplete = (props) => {
  if (props.hide) {
    return null;
  }

  return (
    <Grid item xs={12} sx={{ width: '100%' }}>
      <Autocomplete
        multiple
        id="tags-filled"
        options={props.options.map((option) => option.label)}
        value={getMultiSelectAutocompleteDefaultValue(
          props.name,
          props.options,
          props.formData
        )}
        freeSolo
        onChange={(event, newValue) => {
          handleMultiSelectChange(
            newValue,
            props.name,
            props.setFormData,
            props.formData
          );
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <Chip variant="outlined" label={option} key={key} {...tagProps} />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label}
            placeholder="Type Options"
            error={props?.error ?? false}
            required={props?.required ?? false}
          />
        )}
      />
    </Grid>
  );
};
