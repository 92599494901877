import { useQuery } from '@apollo/client';
import {
  GetServiceSuppliersResponse,
  SearchServiceSuppliersResponse,
} from '@cdw-selline/common/types';
import {
  GET_ADMIN_SERVICE_SUPPLIERS,
  SEARCH_SERVICE_SUPPLIERS_QUERY,
} from '@cdw-selline/ui/queries';

import { useApolloErrors } from '../useApolloErrors';

export interface UseServiceSuppliers {
  filters?: any;
  offset?: number;
  limit?: number;
  sort?: unknown;
}

export const useServiceSuppliers = ({
  filters,
  offset,
  limit,
  sort,
}: UseServiceSuppliers) => {
  const { handleErrorResponse } = useApolloErrors();

  const { loading, error, data, refetch } = useQuery<{
    getServiceSuppliers: GetServiceSuppliersResponse;
  }>(GET_ADMIN_SERVICE_SUPPLIERS, {
    variables: {
      offset: offset ?? 0,
      limit: limit ?? 2000,
      filters: filters ?? {},
      sort: sort ?? { name: 'asc' },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onError: (error) => handleErrorResponse(error, error?.message),
  });

  return {
    data: data?.getServiceSuppliers ?? { serviceSuppliers: [], count: 0 },
    error,
    loading,
    refetch,
  };
};

export const useSearchServiceSuppliers = (
  searchTerm: string,
  { skip = false } = {}
) => {
  const { handleErrorResponse } = useApolloErrors();
  const { data, loading, error } = useQuery<{
    searchServiceSuppliers: SearchServiceSuppliersResponse;
  }>(SEARCH_SERVICE_SUPPLIERS_QUERY, {
    variables: {
      searchTerm,
    },
    skip: skip || !searchTerm,
    onError: (error) =>
      handleErrorResponse(error, 'Failed to search for Service Suppliers!'),
  });

  return {
    data: data?.searchServiceSuppliers?.serviceSuppliers ?? [],
    loading,
    error,
  };
};

export default useServiceSuppliers;
