
import { Task, Practice } from '@cdw-selline/common/types';

import { usePractices } from '../usePractices'
import { useTaskGroups } from '../useTaskGroups'
import { useRoles } from '../useRoles'
import { useSowSections } from '../useSowSections'
import { useProjectItemVendors } from '../useProjectItemVendors'
import { useTaskById } from '../useTaskById'
import { useAdminTaskVersionList } from '../useAdminTaskVersionList'
import { useAdminTaskVersion } from '../useAdminTaskVersion'
import { useDistinctTiers } from '../useDistinctTiers'
import { useTaskDependencies } from './useTaskDependencies';
import { useServiceSuppliers } from '../service-suppliers';

  export const useTaskInitialData = (
    task: Task,
    selectedVersion: number,
    sowSectionName: string,
  ) => {

    const {data: taskDependencies} = useTaskDependencies(task.id);

    const {
        data: currentTask,
      } = useTaskById(task.custom ? '' : task.inTestingTaskId || task.originalTaskId);


    const { data: vendors } = useServiceSuppliers({});
    const vendorOptions =
        vendors?.serviceSuppliers.map((vendor) => {
            return { id: vendor.Id, label: vendor.Name };
        }) ?? [];

    const {
        data: practices,
        loading: practiceLoading,
        error: practiceError,
    } = usePractices({});

    const practiceOptions =
    practices.practices.map((practice) => {
        return {
          id: practice.id,
          label: practice.name,
        };
      }) ?? [];

    const {
        data: taskGroups,
        loading: taskGroupsLoading,
        error: taskGroupsError,
        } = useTaskGroups({});
    const taskGroupOptions =
        taskGroups.taskGroups.map((taskGroup) => {
        return {
        id: taskGroup.id,
        label: taskGroup.name,
        };
    }) ?? [];

    const {
        data: roles,
        loading: rolesLoading,
        error: rolesError,
    } = useRoles({ filters: {} });

    const roleOptions =
        roles.roles.map((role) => {
            return {
            id: role.id,
            label: `${role.name}  (${role.practiceName})`,
            };
        }) ?? [];

    const getFirstRole = (practiceId: string, practiceRoles) => {
        if (!practiceId || !practiceRoles.roles) {
            return null;
        }
        const filteredRoles = practiceRoles.roles.filter(
            (role) => role.practice === practiceId && !role.projectManager
        );
        if (filteredRoles.length > 0) {
            return filteredRoles[0].id;
        }
        return null;
    };
    const {
        task: currentTaskVersion,
        loading: currentTaskVersionLoading,
        } = useAdminTaskVersion(task.custom ? '' : task.id, Number(selectedVersion ?? 1));


    const { versionList } = useAdminTaskVersionList(task.custom ? '': task.id);

    const sowSectionFilters = {
            name: { $regex: sowSectionName || '' },
        };
    const {
        data: sowSections,
        loading: sowSectionsLoading,
        error: sowSectionsError,
    } = useSowSections({ filters: sowSectionFilters, offset: 0, limit: 10000 });
        const sowSectionOptions =
        sowSections.sowSections.map((sowSection) => {
            return {
            id: sowSection.id,
            label: sowSection.name,
            };
    }) ?? [];

    const {
        data: tiers,
        loading: tiersLoading,
        error: tiersError,
      } = useDistinctTiers();
      
    const tierOptions =
        tiers.map((tier) => {
            return {
                id: tier,
                label: tier,
        };
    }) ?? [];

    return {
        currentTask,
        vendorOptions,
        practiceOptions,
        taskGroupOptions,
        taskGroups,
        roleOptions,
        roles,
        getFirstRole,
        currentTaskVersion,
        versionList,
        sowSectionOptions,
        tierOptions,
        practiceLoading,
        practiceError,
        tiersLoading,
        tiersError,
        sowSectionsLoading,
        sowSectionsError,
        currentTaskVersionLoading,
        taskGroupsLoading,
        taskGroupsError,
        rolesLoading,
        rolesError,
        taskDependencies,
    };
  };