import { Task } from '@cdw-selline/common/types';
import { upperFirst } from 'lodash';
import { ValidationError, mixed, number, object, string } from 'yup';
import { TASK_COST_TYPES, TASK_TYPES } from '@cdw-selline/ui/constants';

let inEstimator = false;

const taskBaseSchema = object({
  type: string().required().test(
    'type-must-be-valid-value',
    'Type is invalid',
    (type) => {
      return !type || Object.values(TASK_TYPES).includes(type as TASK_TYPES);
    }
  ),
  name: string().required(),
  estimatorLocation: string().test(
    'location-when-not-custom',
    'Estimator Location is a required field',
    (estimatorLocation, context) => {
        const { custom } = context.parent;
        if(!custom && !estimatorLocation) {
            return false;
        }

        return true;
    }
  ),
  practiceId: string().required(),
  taskGroupId: string().test(
    'taskGroupId-when-not-custom',
    'Task Group Id is a required field',
    (taskGroupId, context) => {
        const { custom } = context.parent;

        if(!custom && !taskGroupId) {
            return false;
        }

        return true;
    }
  ),
  sellPrice: mixed().test(
    'sellprice-not-allowed',
    'sell price is only allowed for Misc Per Unit tasks',
    (sellPrice, context) => {
      const costType = context?.parent?.costType;
    
      if (inEstimator) {
        return true;
      }

      if (sellPrice && costType !== TASK_COST_TYPES.MISC_PER_UNIT) {
        return false;
      }
      return true;
    }
  ).notRequired()
});

const taskHoursSchema = object({
  primaryRole: string()
    .test(
      'is-task-not-excluded',
      'Primary Role is a required field',
      (primaryRole, context) => {
        const { exclude } = context.parent;
        return exclude ? true : !!primaryRole;
      }
    ),
    primaryPercent: string()
    .test(
      'is-role-percent-100',
      function (primaryPercent, context) {
        const { secondaryPercent, exclude, tertiaryPercent } = context.parent;
        const isValid = !exclude
          ? Number(primaryPercent ?? 0) + Number(secondaryPercent ?? 0) + Number(tertiaryPercent ?? 0) === 100
          : true;
        const message = !tertiaryPercent
          ? 'Primary and Secondary role total must be 100%'
          : 'Primary, Secondary, and Tertiary role total must be 100%';
  
        return isValid || this.createError({ message });
      }
    ),
  
});

const taskCostSchema = object({
  costType: string().required().test(
    'cost-type-must-be-valid-value',
    'Cost Type is invalid',
    (costType) => {
      return !costType || Object.values(TASK_COST_TYPES).includes(costType as TASK_COST_TYPES);
    }
  ),
  cost: string().test(
    'cost-required-value',
    'Cost is a required field and must be a number',
    (cost, context) => {
      const { custom, costType } = context.parent;
      if (costType === TASK_COST_TYPES.MISC_PER_UNIT) {
        return true;
      }
      
      const costValue = Number(cost);
      return cost && !isNaN(costValue) && (!custom || costValue > 0);
    }
  ).notRequired(),
  minGrossProfit: string()
    .test(
      'min-gross-profit-valid-value',
      'MinGrossProfit must be between 0 and 100%',
      (minGrossProfit) => {
        return valueRangeValidation(minGrossProfit, 0, 100);
      }
    ).notRequired(),
  grossProfit: string()
    .test(
      'gross-profit-valid-value',
      'GrossProfit must be between 0 and 100%',
      (grossProfit) => {
        return valueRangeValidation(grossProfit, 0, 100);
      }
    ).notRequired(),
  primaryRole: string()
    .test(
      'is-task-not-excluded',
      'Primary Role is required',
      (primaryRole, context) => {
        const { exclude, costType } = context.parent;
        if (costType !== TASK_COST_TYPES.MISC_PER_UNIT) return true;
        return exclude ? true : !!primaryRole;
      }
    )
    .notRequired(),
})

const taskListSchema = object({
  listNames: string().required().test(
    'listNames-must-be-comma-separated',
    'List names must be comma separated',
    (listNames) => {
        return !listNames || listNames.split(',').length > 0;
    }
  ),
  listValues: string().required().test(
    'listValues-must-match-listNames',
    'List values must match list names',
    (listValues, context) => {
      const { listNames } = context.parent;
      if(listNames) {
        const names = listNames.split(',');
        const values = listValues.split(',');

        return names.length === values.length;
      }
      
      return true;
    }
  ),
}).concat(taskHoursSchema);

const taskYesNoSchema = object({
  yesValue: string().required(),
  noValue: string().required(),
}).concat(taskHoursSchema);;

const taskTextSchema = object({
  textType: string().required(),
});

const taskCustomService = object({
  vendor: string().required(),
  customServiceCost: string().required()
    .test(
      'custom-service-cost-required-value',
      'CustomServiceCost must be greater than 0',
      (customServiceCost) => {
        return !customServiceCost || Number(customServiceCost) > 0;
      }
    ),
  margin: string().required()
    .test(
      'margin-valid-value',
      'Margin must be between 0 and 100',
      (margin) => {
        return valueRangeValidation(margin, 0, 100);
      }
    ),
  pmPercent: string().required()
    .test(
      'pm-percent-required-value',
      'PmPercent must be a number',
      (pmPercent) => {
        return !pmPercent || !isNaN(Number(pmPercent));
      }
    ),
  primaryRole: string()
    .test(
      'is-task-not-excluded',
      'PM Role is required',   ///storing Pm Role in Primary Role by design for custom service
      (primaryRole, context) => {
        const { exclude } = context.parent;
        return exclude ? true : !!primaryRole;
      }
    ),
  customServiceDivisorRate: string().required()
    .test(
      'customService-divisor-rate-required-value',
      'CustomServiceDivisorRate must be a number',
      (customServiceDivisorRate) => {
        return !customServiceDivisorRate || !isNaN(Number(customServiceDivisorRate));
      }
    ),
  quantity: string().required()
    .test(
      'quantity-required-value',
      'Quantity must be a number',
      (quantity) => {
        return !quantity || !isNaN(Number(quantity));
      }
    ),
});

const taskProduct = object({
  productType: string().required(),
  cost: string().required()
    .test(
      'cost-required-value',
      'Cost must be a number',
      (cost) => {
        return !cost || !isNaN(Number(cost));
      }
    ),
  grossProfit: string().required()
    .test(
      'gross-profit-valid-value',
      'GrossProfit must be between 0 and 99%',
      (grossProfit) => {
          return valueRangeValidation(grossProfit, 0, 99);
      }
    ),
  vendor: string().required(),
  costType: string().required().test(
    'cost-type-must-be-valid-value',
    'Cost Type is invalid',
    (costType) => {
      return !costType || Object.values(TASK_COST_TYPES).includes(costType as TASK_COST_TYPES);
    }
  ),
});

const valueRangeValidation = (valueToValidate: string, minValue: number, maxValue: number) => {
  return !valueToValidate || (Number(valueToValidate) >= minValue && Number(valueToValidate) <= maxValue);
}

export const useValidateTask = (siteId?: string) => {
  inEstimator = Boolean(siteId);

  const schemas = {
    'Hours': taskHoursSchema,
    'Cost': taskCostSchema,
    'List': taskListSchema,
    'Yes/No': taskYesNoSchema,
    'Text': taskTextSchema,
    'Custom Service': taskCustomService,
    'Product': taskProduct,
  };

  const validateTasks = async (tasks: Task[], displayFirstMessage?:boolean) => {
    let errorArray = [];
    let rowIndex = 1;
    for await (const task of tasks) {
      if (displayFirstMessage && errorArray?.length) {
        break;
      }
      const taskErrors = await validate(task);
      if (taskErrors?.length) {
        const currentRowIndex = rowIndex;

        errorArray = errorArray.concat(taskErrors.map(error=>
          (`Row ${currentRowIndex}: ${error}`)
        ));
      }
      rowIndex++;
    }
    return errorArray;
  }

  const validate = async (task: Task) => {
    const errorArray = [];
    try {
      await taskBaseSchema.validate(task, { abortEarly: false });
    } catch (error) {

      if (error && error instanceof ValidationError) {
        error.inner.forEach((err) => {
           errorArray.push(upperFirst(err.message));
        });
      }

    }
    const schema = schemas[task.type];
    if (schema === taskCostSchema && task?.costType === TASK_COST_TYPES.MISC_PER_UNIT) {
      delete task.cost;
      delete task.minGrossProfit;
      delete task.grossProfit;
    }
    try {
      await schema.validate(task, { abortEarly: false });
    } catch (error) {
      if (error && error instanceof ValidationError) {
          error.inner.forEach((err) => {
            errorArray.push(upperFirst(err.message));
        });      
      } 
    }

    return errorArray;
  };

  return {
    validate,
    validateTasks,
  };
};