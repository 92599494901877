import {
    FormText,
    FormCheckbox,
    FormTextControlled,
  } from '../formHelperFunctions';

import React, { Dispatch, SetStateAction } from 'react';

export interface SecondaryRoleOverrideIncludeProps {
    setFormData: Dispatch<SetStateAction<Record<string, unknown>>>;
    formData: Record<string, string>;
    isCustom: boolean;
}

export function SecondaryRoleOverrideInclude({
    setFormData,
    formData,
    isCustom,
}: SecondaryRoleOverrideIncludeProps) {


  return (
    <>
        <FormTextControlled
            name="secondaryRoleRateOverride"
            label="Secondary Role Rate Override"
            type='number'
            setFormData={setFormData}
            formData={formData}
        />
        {!isCustom && <FormTextControlled
            name="secondaryRoleRateOverrideFormula"
            label="Secondary Role Rate Override Formula"
            setFormData={setFormData}
            formData={formData}
        />}
        {!isCustom && <FormCheckbox
            name="allowModifySecondaryRoleRate"
            label={'Allow Modify Secondary Role Rate'}
            setFormData={setFormData}
            formData={formData}
        />}
    </>
  );
}

export default SecondaryRoleOverrideInclude;
