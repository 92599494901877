import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '../styles.css';

import { ApolloProvider } from '@apollo/client/react';
import { client } from '@cdw-selline/ui/apollo-client';
import { CDWFallback, ErrorFallback } from '@cdw-selline/ui/components';
import { BYPASS_AUTH } from '@cdw-selline/ui/constants';
import {
  AlertsOverlay,
  AppBaseContainer,
  LoginPage,
} from '@cdw-selline/ui/pages';
import {
  getCurrentUser,
  getStashedRoute,
  setStashedRoute,
  userState,
} from '@cdw-selline/ui/state';
import { getMuiTheme } from '@cdw-selline/ui/theme';
import { useState } from '@hookstate/core';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { SvgIconProps } from '@mui/material/SvgIcon';
import React, { FunctionComponent, Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  BrowserRouter,
  type Location,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { ProjectPage } from './pages/ProjectPage';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';

import { LicenseInfo } from '@mui/x-license';
import { useApp } from './useApp';

if (process.env.NODE_ENV === 'development') {
  loadDevMessages();
  loadErrorMessages();
}

LicenseInfo.setLicenseKey(process.env.NX_MUI_LICENSE_KEY);

export interface AppLayoutProps {
  title?: string;
  menu?: {
    icon?: (props: SvgIconProps) => JSX.Element;
    label?: string | number;
    path?: string;
  }[];
}
export const App: FunctionComponent<AppLayoutProps> = (props) => {
  const { routes, menus, projectRoutes } = useApp();
  const loggedIn = useState(userState?.loggedIn);
  const [theme, setTheme] = React.useState<'light' | 'dark'>(
    localStorage.darkMode === 'true' ? 'dark' : 'light'
  ); // TODO Attach to user state & browser settings
  const isLoggedIn = BYPASS_AUTH || loggedIn?.value;

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
        window.location.reload();
      }}
    >
      <Suspense fallback={<CDWFallback />}>
        <ThemeProvider theme={getMuiTheme(theme)}>
          <ApolloProvider client={client}>
            <CssBaseline />
            <BrowserRouter basename="/">
              <AppBaseContainer
                theme={theme}
                setTheme={setTheme}
                isLoggedIn={isLoggedIn}
                menus={menus}
              >
                <Routes>
                  <Route path="/sign-in" element={<LoginPageWithRedirect />} />
                  {routes.map(({ path, component: Component }, idx) => (
                    <Route
                      key={`route-${path}-${idx}`}
                      path={path}
                      element={<LoggedInRoute Cmp={Component} />}
                    />
                  ))}
                  <Route
                    path={'/project/:id'}
                    element={<LoggedInRoute Cmp={ProjectPage} />}
                  >
                    {projectRoutes.map(
                      ({ path, component: Component, index }, idx) => (
                        <Route
                          key={`project-route-${path}-${idx}`}
                          element={<Component />}
                          {...(index ? { index } : { path })}
                        />
                      )
                    )}
                  </Route>
                </Routes>
              </AppBaseContainer>
              <AlertsOverlay />
            </BrowserRouter>
          </ApolloProvider>
        </ThemeProvider>
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;

const REDIRECT_ROUTES = new Map<string, string>([
  ['/sign-in', '/'],
  ['/sign-out', '/'],
  ['sign-in', '/'],
  ['sign-out', '/'],
  ['404', '/'],
]);

function redirectTo(location: Location) {
  return (
    getStashedRoute(true) ?? {
      pathname: '/',
      state: { from: location },
    }
  );
}

const redirectOk = (location: Location) => {
  const currentUser = getCurrentUser();
  const isLoggedIn = currentUser?.loggedIn;

  if (location.pathname === '/sign-in' && !isLoggedIn) {
    return false;
  }

  return true;
};

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function LoggedInRoute({ Cmp }: { Cmp: React.FunctionComponent }): JSX.Element {
  const loggedIn = useState(userState?.loggedIn);
  const isLoggedIn = BYPASS_AUTH || loggedIn?.value;
  const location = useLocation();
  const { pathname, search, state, hash, key } = location;
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      const matchRoute = pathname.split('/')[1];
      if (REDIRECT_ROUTES.has(matchRoute)) {
        setStashedRoute({
          pathname: REDIRECT_ROUTES.get(matchRoute) || '/',
          search: '',
          hash: '',
          state,
        });
      }
      setStashedRoute({
        pathname,
        search,
        state,
        hash,
        key,
      });
      navigate('/sign-in', { state: { from: location, replace: true } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, location]);

  return <Cmp />;
}

function LoginPageWithRedirect() {
  const loggedIn = useState(userState?.loggedIn);
  const isLoggedIn = BYPASS_AUTH || loggedIn?.value;
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn && redirectOk(location)) {
      navigate(redirectTo(location));
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, location]);

  return <LoginPage />;
}
