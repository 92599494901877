import { useQuery } from '@apollo/client';
import {
  GetServiceSkillsResponse,
  SearchServiceSkillsResponse,
} from '@cdw-selline/common/types';
import {
  GET_ADMIN_SERVICE_SKILLS,
  SEARCH_SERVICE_SKILLS_QUERY,
} from '@cdw-selline/ui/queries';

import { useApolloErrors } from '../useApolloErrors';

export interface UseServiceSkills {
  filters?: any;
  offset?: number;
  limit?: number;
  sort?: unknown;
}

export const useServiceSkills = ({
  filters,
  offset,
  limit,
  sort,
}: UseServiceSkills) => {
  const { handleErrorResponse } = useApolloErrors();

  const { loading, error, data, refetch } = useQuery<{
    getServiceSkills: GetServiceSkillsResponse;
  }>(GET_ADMIN_SERVICE_SKILLS, {
    variables: {
      offset: offset ?? 0,
      limit: limit ?? 2000,
      filters: filters ?? {},
      sort: sort ?? { name: 'asc' },
    },
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    onError: (error) => handleErrorResponse(error, error?.message),
  });

  return {
    data: data?.getServiceSkills ?? { serviceSkills: [], count: 0 },
    error,
    loading,
    refetch,
  };
};

export const useSearchServiceSkills = (
  searchTerm: string,
  { skip = false } = {}
) => {
  const { handleErrorResponse } = useApolloErrors();
  const { data, loading, error } = useQuery<{
    searchServiceSkills: SearchServiceSkillsResponse;
  }>(SEARCH_SERVICE_SKILLS_QUERY, {
    variables: {
      searchTerm,
    },
    skip: skip || !searchTerm,
    fetchPolicy: 'network-only',
    onError: (error) =>
      handleErrorResponse(error, 'Failed to search for Service Skills!'),
  });

  return {
    data: data?.searchServiceSkills?.serviceSkills ?? [],
    loading,
    error,
  };
};

export default useServiceSkills;
