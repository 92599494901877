import { useQuery } from '@apollo/client';
import {
  Task,
} from '@cdw-selline/common/types';
import {
    GET_ESTIMATOR_CUSTOM_TASKS_BY_SITE_ID_QUERY
} from '@cdw-selline/ui/queries';
import { useEffect, useState } from 'react';
import { useUpdateEstimatorTask } from './useUpdateProjectTask'
import { useAddRemoveCustomTask } from './useAddRemoveCustomTask'
import { usePractices } from '../usePractices'
import { useRoles } from '../useRoles'
import { useServiceSkills } from '../service-skills/useServiceSkills'
import { useServiceItems } from '../service-items/useServiceItems';
import { useApolloErrors } from '../useApolloErrors';
import { useServiceSuppliers } from '../service-suppliers';

export const useCustomTasksList = (
  siteId: string,
  setIsCustomTasksList: (value: boolean) => void
) => {
  const { handleErrorResponse } = useApolloErrors();
  const { updateEstimatorTasks } = useUpdateEstimatorTask();
  const { handleRemoveCustomTask } = useAddRemoveCustomTask();

  const {
    data,
    loading: dataLoading,
    error,
  } = useQuery<{ getEstimatorCustomTasksBySiteId: Task[]; }>(GET_ESTIMATOR_CUSTOM_TASKS_BY_SITE_ID_QUERY, {
    variables: { siteId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch estimator custom tasks!'),
  });

  const getTableRows = (customTasks: Task[]) => {
    return customTasks && customTasks.length
      ? customTasks.map((task) => [
        task.id,
        task.type,
        task.costType,
        task.name,
        task.category,
        task.hours,
        task.quantity,
        task.defaultCost,
        task.practice,
        task.managedServices,
        task.isProjectManagement,
        task.isTravelTime,
        task.primaryRoleName,
        task.primaryPercent,
        task.primaryRoleRateOverride,
        task.secondaryRoleName,
        task.secondaryPercent,
        task.secondaryRoleRateOverride,
        task.tertiaryRoleName,
        task.tertiaryPercent,
        task.tertiaryRoleRateOverride,
        task.overtime,
        task.skill,
        task.serviceItem,
        task.vendor,
        task.customServiceCost,
        task.customServiceDivisorRate,
        task.customServiceSellPrice,
        task.margin,
        task.pmPercent,
      ])
      : [Array(3)];
  };

  const [tableData, setTableData] = useState(
    getTableRows(data?.getEstimatorCustomTasksBySiteId)
  );

  useEffect(() => {
    if (data?.getEstimatorCustomTasksBySiteId) {
      setTableData(getTableRows(data?.getEstimatorCustomTasksBySiteId));
    }
  }, [data?.getEstimatorCustomTasksBySiteId]);

  const handleCustomTasksSave = (updatedTask: Task[]) => {
    updateEstimatorTasks({
      variables: {
        params: updatedTask,
      },
    });
  };

  const { data: practices, loading: practicesLoading } = usePractices({});
  const { data: roles, loading: rolesLoading } = useRoles({});
  const { data: serviceSkills, loading: serviceSkillsLoading } = useServiceSkills({});
  const { data: serviceItems, loading: serviceItemsLoading } = useServiceItems({});
  const { data: vendors, loading: vendorsLoading } = useServiceSuppliers({});


  return {
    customTasks: data?.getEstimatorCustomTasksBySiteId ?? [],
    loading: dataLoading,
    error,
    tableData,
    handleCustomTasksSave,
    handleDeleteCustomTask: handleRemoveCustomTask,
    practices,
    roles,
    serviceSkills,
    serviceItems,
    vendors,
    practicesLoading,
    rolesLoading,
    serviceSkillsLoading,
    serviceItemsLoading,
    vendorsLoading,
  };
};
