import { gql } from '@apollo/client';

export const SEARCH_SERVICE_SUPPLIERS_QUERY = gql`
  query SearchServiceSuppliers($searchTerm: String!) {
    searchServiceSuppliers(searchTerm: $searchTerm) {
      serviceSuppliers {
        Id
        Name
        Supplier_ID__c
        SupplierStatus__c
      }
    }
  }
`;
