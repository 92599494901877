import { useMutation, useQuery } from '@apollo/client';
import { DefaultMutationResponse, EmailTemplatesInput, GetEmailTemplatesResponse } from '@cdw-selline/common/types';
import {
  DELETE_EMAIL_TEMPLATES_MUTATION,
  GET_EMAIL_TEMPLATES,
  UPDATE_EMAIL_TEMPLATES_MUTATION
} from '@cdw-selline/ui/queries';
import { useState } from 'react';
import { useApolloErrors } from '..';
import { GridColDef } from '@mui/x-data-grid';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { useOpenState } from '../estimator/useOpenState';

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 350,
    flex: 1,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 350,
    flex: 1,
  },
  {
    field: 'subject',
    headerName: 'Subject',
    width: 350,
    flex: 1,
  },
];

export const useEmailTemplates = ({filters={}}:any) => {
  const [editEmailTemplateId, setEditEmailTemplateId] = useState(null);
  const alertState = useAlertsState();

  const {
    isOpen: formOpen,
    handleClose: closeForm,
    handleOpen: openForm,
  } = useOpenState();

  const { handleErrorResponse } = useApolloErrors();

  const { loading, error, data, refetch } = useQuery<{
    getEmailTemplates: GetEmailTemplatesResponse;
  }>(GET_EMAIL_TEMPLATES, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    variables: { filters },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch project users!'),
  });

  const [
    updateEmailTemplates,
  ] = useMutation(UPDATE_EMAIL_TEMPLATES_MUTATION, {
    refetchQueries: [GET_EMAIL_TEMPLATES],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to update email templates!'),
    onCompleted: (data) => {
      if (data.updateEmailTemplates.success) {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully updated email templates!',
        });
      }
    },
  });

  const handleFormClose = () => {
    setEditEmailTemplateId(null);
    closeForm();
  };

  const handleEdit = (id: string) => {
    setEditEmailTemplateId(id);
    openForm();
  };
  const handleFormSave = (
    configSettings: EmailTemplatesInput
  ) => {
        updateEmailTemplates({
          variables: {
            params: {
              name: configSettings.name,
              subject: configSettings.subject,
              id: configSettings.id,
              body: configSettings.body,
            },
          },
      });
  };

  const [
    deleteEmailTemplate,
  ] = useMutation<{deleteEmailTemplate : DefaultMutationResponse}>(
    DELETE_EMAIL_TEMPLATES_MUTATION, 
    {
    refetchQueries: [GET_EMAIL_TEMPLATES],
    onError: (error) =>  handleErrorResponse(error, 'Failed to delete email templates!'),
    onCompleted: (data) => {
      if (data.deleteEmailTemplate.success) {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully delete email templates!',
        });
      }
    },
  });

  const handleDelete = (id :any ) => {
    deleteEmailTemplate({
      variables : { deleteEmailTemplateId:id },
    })
  }

  return {
    rows: data?.getEmailTemplates.emailTemplates || [],
    columns,
    handleEdit,
    formOpen,
    openForm,
    handleFormClose,
    handleFormSave,
    editEmailTemplateId,
    handleDelete
  };
};