/* eslint-disable @nx/enforce-module-boundaries */
import { useSiteBreakdown } from '@cdw-selline/ui/hooks';
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { formatCurrency } from '@cdw-selline/ui/helpers';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SiteBreakdownProps {}

export const SiteBreakdown: React.FC<SiteBreakdownProps> = (props) => {
  const { siteBreakdown, error, loading } = useSiteBreakdown();
  const theme = useTheme();

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card {...props}>
            <CardHeader
              title="Site Breakdown"
              sx={{
                backgroundColor: theme.palette.primary.dark,
                padding: '5px 15px',
              }}
              titleTypographyProps={{
                variant: 'h6',
                color: theme.palette.common.white,
              }}
            />
            <Divider />
            <Box sx={{ minWidth: 400 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Site</TableCell>
                      <TableCell align="right">CDW Eng Hrs</TableCell>
                      <TableCell align="right">CDW PM Hours</TableCell>
                      <TableCell align="right">CDW Total Hours</TableCell>
                      <TableCell align="right">CDW Services</TableCell>
                      <TableCell align="right">Partner Services</TableCell>
                      <TableCell align="right">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {error && (
                      <Typography color="error" variant="h6">
                        {error.message}
                      </Typography>
                    )}
                    {loading && (
                      <TableRow>
                        <TableCell>
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    )}
                    {!error &&
                      !loading &&
                      Array.isArray(siteBreakdown) &&
                      siteBreakdown
                        .filter((data) => data.site !== 'total_values')
                        .map((data) => (
                          <TableRow hover key={data.site}>
                            <TableCell>{data.site}</TableCell>
                            <TableCell align="right">
                              {data.taskHours?.toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                              {data.pmHours?.toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                              {data.totalHours?.toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                              {formatCurrency(data.servicesTotal)}
                            </TableCell>
                            <TableCell align="right">
                              {formatCurrency(data.thirdPartyServices)}
                            </TableCell>
                            <TableCell align="right">
                              {formatCurrency(data.total)}
                            </TableCell>
                          </TableRow>
                        ))}
                    {!error &&
                      !loading &&
                      Array.isArray(siteBreakdown) &&
                      siteBreakdown
                        .filter((data) => data.site === 'total_values')
                        .map((data) => (
                          <TableRow hover key={data.site}>
                            <TableCell>
                              <Typography
                                sx={{
                                  color: theme.palette.primary.dark,
                                  fontWeight: 'bold',
                                }}
                              >
                                Total
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography
                                sx={{
                                  color: theme.palette.primary.dark,
                                  fontWeight: 'bold',
                                }}
                              >
                                {data.taskHours?.toFixed(2)}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography
                                sx={{
                                  color: theme.palette.primary.dark,
                                  fontWeight: 'bold',
                                }}
                              >
                                {data.pmHours?.toFixed(2)}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography
                                sx={{
                                  color: theme.palette.primary.dark,
                                  fontWeight: 'bold',
                                }}
                              >
                                {data.totalHours?.toFixed(2)}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography
                                sx={{
                                  color: theme.palette.primary.dark,
                                  fontWeight: 'bold',
                                }}
                              >
                                {formatCurrency(data.servicesTotal)}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography
                                sx={{
                                  color: theme.palette.primary.dark,
                                  fontWeight: 'bold',
                                }}
                              >
                                {formatCurrency(data.thirdPartyServices)}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography
                                sx={{
                                  color: theme.palette.primary.dark,
                                  fontWeight: 'bold',
                                }}
                              >
                                {formatCurrency(data.total)}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
export default SiteBreakdown;
