import { useMutation } from '@apollo/client';
import {
  GET_ADMIN_SERVICE_SUPPLIERS,
  SYNC_SERVICE_SUPPLIERS_MUTATION,
} from '@cdw-selline/ui/queries';
import { useState } from 'react';
import { useApolloErrors } from '..';
import {
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
} from '@mui/x-data-grid';
import {
  getFilters,
  getFilterModelFromLocalStorage,
} from '@cdw-selline/ui/helpers';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { useServiceSuppliers } from './useServiceSuppliers';
import { PAGES } from '@cdw-selline/ui/constants';

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    editable: false,
    flex: 1,
  },
  {
    field: 'Name',
    headerName: 'Service Supplier Name',
    flex: 1,
  },
  {
    field: 'Supplier_ID__c',
    headerName: 'Supplier ID',
    flex: 1,
  },
  {
    field: 'SupplierStatus__c',
    headerName: 'Supplier Status',
    flex: 1,
  }
];

export const useAdminServiceSuppliers = () => {
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const [sortState, setSortState] = useState();

  const { data, loading, error, refetch } = useServiceSuppliers({
    filters: getFilters(PAGES.SERVICE_SUPPLIERS_COLLECTION),
    offset: paginationModel.page * paginationModel.pageSize,
    limit: paginationModel.pageSize,
    sort: sortState,
  });

  const [
    syncServiceSuppliers,
    { loading: syncServiceSuppliersLoading, error: syncServiceSuppliersError },
  ] = useMutation(SYNC_SERVICE_SUPPLIERS_MUTATION, {
    refetchQueries: [GET_ADMIN_SERVICE_SUPPLIERS],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to sync Service Suppliers!'),
    onCompleted: (data) => {
      if (data.syncServiceSuppliers.success) {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully synced Service Suppliers!',
        });
      }
    },
  });

  const filterModel: GridFilterModel = getFilterModelFromLocalStorage(
    `${PAGES.SERVICE_SUPPLIERS_COLLECTION}-filter`
  );

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    const pageSize = model?.pageSize ?? paginationModel?.pageSize;
    const page = model?.page ?? paginationModel?.page;
    setPaginationModel({
      page,
      pageSize,
    });
  };

  const onFilterModelChange = (filterModel) => {
    refetch({ filters: getFilters(PAGES.SERVICE_SUPPLIERS_COLLECTION) });
  };

  const handleSort = (args) => {
    let newSort;
    if (args.length) newSort = { [args[0].field]: args[0].sort };
    setSortState(newSort);
  };

  const returnValue = {
    columns,
    rows: data?.serviceSuppliers || [],
    handlePaginationModelChange,
    handleSort,
    onFilterModelChange,
    filterModel,
    serviceSuppliers: data?.serviceSuppliers,
    rowCount: data?.count || 0,
    loading: loading || syncServiceSuppliersLoading,
    error: error || syncServiceSuppliersError,
    syncServiceSuppliers,
  };

  if (loading || syncServiceSuppliersLoading) {
    return {
      ...returnValue,
      paginationModel: { pageSize: 100, page: 0 },
      rowCount: 0,
      rows: [],
    };
  } else {
    return { ...returnValue, paginationModel };
  }
};
