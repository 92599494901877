import { useQuery } from '@apollo/client';
import {
  GetServiceItemsResponse,
  SearchServiceItemsResponse,
} from '@cdw-selline/common/types';
import {
  GET_ADMIN_SERVICE_ITEMS,
  SEARCH_SERVICE_ITEMS_QUERY,
} from '@cdw-selline/ui/queries';

import { useApolloErrors } from '../useApolloErrors';

export interface UseServiceItems {
  filters?: any;
  offset?: number;
  limit?: number;
  sort?: unknown;
}

export const useServiceItems = ({
  filters,
  offset,
  limit,
  sort,
}: UseServiceItems) => {
  const { handleErrorResponse } = useApolloErrors();

  const { loading, error, data, refetch } = useQuery<{
    getServiceItems: GetServiceItemsResponse;
  }>(GET_ADMIN_SERVICE_ITEMS, {
    variables: {
      offset: offset ?? 0,
      limit: limit ?? 2000,
      filters: filters ?? {},
      sort: sort ?? { name: 'asc' },
    },
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    onError: (error) => handleErrorResponse(error, error?.message),
  });

  return {
    data: data?.getServiceItems ?? { serviceItems: [], count: 0 },
    error,
    loading,
    refetch,
  };
};

export const useSearchServiceItems = (
  searchTerm: string,
  { skip = false } = {}
) => {
  const { handleErrorResponse } = useApolloErrors();
  const { data, loading, error } = useQuery<{
    searchServiceItems: SearchServiceItemsResponse;
  }>(SEARCH_SERVICE_ITEMS_QUERY, {
    variables: {
      searchTerm,
    },
    skip: skip || !searchTerm,
    fetchPolicy: 'network-only',
    onError: (error) =>
      handleErrorResponse(error, 'Failed to search for Service Items!'),
  });

  return {
    data: data?.searchServiceItems?.serviceItems ?? [],
    loading,
    error,
  };
};

export default useServiceItems;
