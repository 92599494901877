import { useMutation } from '@apollo/client';
import { Task } from '@cdw-selline/common/types';
import {
  GET_ESTIMATOR_TASKS_BY_SITE_ID_QUERY,
  ADD_CUSTOM_TASK_TO_SITE_MUTATION,
  REMOVE_ESTIMATOR_TASK_BY_ID_MUTATION,
  GET_PROJECT_QUERY,
  UPDATE_ESTIMATOR_TASK_MUTATION,
  GET_LATEST_GROUP_TASK_VERSION_QUERY,
  GET_ESTIMATOR_CUSTOM_TASKS_BY_SITE_ID_QUERY,
  GET_ESTIMATOR_SUMMARY_TASKS_QUERY,
  GET_ESTIMATOR_INCLUDES_MANAGED_SERVICES_QUERY,
} from '@cdw-selline/ui/queries';
import { Dispatch, SetStateAction } from 'react';

import { useApolloErrors } from '..';
import omitDeep from 'omit-deep-lodash';

export const useAddRemoveCustomTask = (
  setFullSiteLoad?: Dispatch<SetStateAction<boolean>>
) => {
  const { handleErrorResponse } = useApolloErrors();
  const omitAttributes = ['__typename', 'allowAdminEdit'];

  const [
    addCustomTaskToSite,
    {
      client: addClient,
      loading: addCustomTaskkLoading,
      error: addCustomTaskError,
    },
  ] = useMutation(ADD_CUSTOM_TASK_TO_SITE_MUTATION, {
    onError: (error) =>
      handleErrorResponse(error, 'Failed to update Estimator Task!'),
    onCompleted: () => {
      setFullSiteLoad ?? setFullSiteLoad(true);
      addClient.refetchQueries({
        include: [GET_ESTIMATOR_TASKS_BY_SITE_ID_QUERY, GET_PROJECT_QUERY, GET_ESTIMATOR_INCLUDES_MANAGED_SERVICES_QUERY],
      });
    },
  });

  const handleAddCustomTask = (task: Task) => {
    addCustomTaskToSite({
      variables: {
        task: omitDeep(task, omitAttributes),
      },
    });
  };

  const [
    removeCustomTaskById,
    {
      client: removeClient,
      loading: removeCustomTaskkLoading,
      error: removeCustomTaskError,
    },
  ] = useMutation(REMOVE_ESTIMATOR_TASK_BY_ID_MUTATION, {
    onError: (error) =>
      handleErrorResponse(error, 'Failed to remove Estimator Task!'),
    onCompleted: () => {
      setFullSiteLoad && setFullSiteLoad(true);
      removeClient.refetchQueries({
        include: [GET_ESTIMATOR_TASKS_BY_SITE_ID_QUERY, GET_PROJECT_QUERY, GET_ESTIMATOR_CUSTOM_TASKS_BY_SITE_ID_QUERY],
      });
    },
  });

  const handleRemoveCustomTask = (id: string) => {
    removeCustomTaskById({
      variables: {
        id,
      },
    });
  };

  const [saveEstimatorTaskForm, { client: saveClient }] = useMutation(
    UPDATE_ESTIMATOR_TASK_MUTATION,
    {
      onError: (error) =>
        handleErrorResponse(error, 'Failed to update Estimator Task!'),
      onCompleted: (data) => {
        setFullSiteLoad && setFullSiteLoad(Boolean(data?.updateEstimatorTask?.updatedTask?.custom));
        saveClient.refetchQueries({
          include: [
            GET_ESTIMATOR_SUMMARY_TASKS_QUERY,
            GET_ESTIMATOR_TASKS_BY_SITE_ID_QUERY,
            GET_PROJECT_QUERY,
            GET_LATEST_GROUP_TASK_VERSION_QUERY
          ],
        });
      },
    }
  );

  const handleSaveTask = (task: Task) => {
    saveEstimatorTaskForm({
      variables: {
        params: omitDeep(task, omitAttributes),
        estimatorAdminUpdate: true,
      },
    });
  };

  const handleSaveNoAdminTask = (task: Task) => {
    saveEstimatorTaskForm({
      variables: {
        params: omitDeep(task, omitAttributes),
        estimatorAdminUpdate: false,
      },
    });
  };

  return {
    handleAddCustomTask,
    addCustomTaskkLoading,
    addCustomTaskError,
    handleRemoveCustomTask,
    removeCustomTaskkLoading,
    removeCustomTaskError,
    handleSaveTask,
    handleSaveNoAdminTask,
  };
};
