// eslint-disable-next-line @nx/enforce-module-boundaries
import { Task, RoleAndRate, Site, Practice } from '@cdw-selline/common/types';
import { TaskForm } from '../../task-form/TaskForm';
import { DialogConfirm } from '../../dialog-confirm/DialogConfirm';
import TaskNoteDialog from '../estimate-details/task-item/task-note-dialog/TaskNoteDialog';
import TaskSyncDialog from '../estimate-details/task-item/task-sync-dialog/TaskSyncDialog';
import TextTaskDialog from '../estimate-details/task-item/task-text-dialog/TextTaskDialog';
import TaskProposalDescriptionDialog from '../estimate-details/task-item/TaskProposalDescriptionDialog';
import { estimatorStrings } from '@cdw-selline/ui/constants';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  useEstimatorTaskById,
  useOpenState,
  useAddRemoveCustomTask,
  useRoles,
  useIncludesSummaryTasks,
} from '@cdw-selline/ui/hooks';
import { Grid, Typography, Skeleton, Box, Tab, useTheme } from '@mui/material';
import React, { CSSProperties, useEffect } from 'react';
import { SiteCard } from './site-card/SiteCard';
import { ApolloError } from '@apollo/client';
import SummaryCard from './site-card/SummaryCard';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Tooltip from '@mui/material/Tooltip';

export interface EstimateDetailsProps {
  projectItemId: string;
  style?: CSSProperties;
  currentRolesAndRates: RoleAndRate[];
  sites: Site[];
  sitesLoading: boolean;
  sitesError: ApolloError;
  handleAddTaskGroup: (siteId: string) => void;
  currentPractices: Practice[];
  isReadOnly: boolean;
}

export const EstimateDetails = ({
  projectItemId,
  style,
  currentRolesAndRates,
  sites,
  sitesLoading,
  sitesError,
  handleAddTaskGroup,
  currentPractices,
  isReadOnly,
}: EstimateDetailsProps) => {
  const { FETCH_SITE_ERROR } = estimatorStrings;
  const [activeTask, setActiveTask] = React.useState<Task>({});
  const [activeSiteId, setActiveSiteId] = React.useState(null);
  const [activeTaskId, setActiveTaskId] = React.useState(null);
  const [deleteTaskId, setDeleteTaskId] = React.useState(null);
  const [fullSiteLoad, setFullSiteLoad] = React.useState(true);
  const [fullSummarySiteLoad, setFullSummarySiteLoad] = React.useState(true);
  const theme = useTheme();
  const tabView = localStorage.getItem('TabView') === 'true' ? true : false;
  const [tabValue, setTabValue] = React.useState('1');
  const [isCustomTasksList, setIsCustomTasksList] = React.useState(false);

  const handleTabChange = (tabValue: string) => {
    setTabValue(tabValue);
  };

  const {
    data: roles,
    loading: rolesLoading,
    error: rolesError,
  } = useRoles({ filters: {} });

  const {
    isOpen: taskFormOpen,
    handleClose: closeTaskForm,
    handleOpen: openTaskForm,
  } = useOpenState();

  const {
    isOpen: textTaskFormOpen,
    handleClose: closeTextTaskForm,
    handleOpen: openTextTaskForm,
  } = useOpenState();

  const {
    isOpen: proposalDescriptionDialogOpen,
    handleClose: handleProposalDescriptionDialogClose,
    handleOpen: handleProposalDescriptionDialogOpen,
  } = useOpenState();

  const {
    data: task,
    loading: taskLoading,
    error: taskError,
  } = useEstimatorTaskById(activeTaskId);

  const {
    includesSummaryTasks,
    includesSummaryTasksLoading,
    includesSummaryTasksError,
  } = useIncludesSummaryTasks(projectItemId);

  const summarySite = { name: 'Summary', id: `summary-${projectItemId}` };

  useEffect(() => {
    if (tabView && !includesSummaryTasksLoading) {
      setTabValue(includesSummaryTasks ? '0' : '1');
    }
  }, [tabView, includesSummaryTasks]);

  const {
    handleAddCustomTask,
    handleRemoveCustomTask,
    handleSaveTask,
    handleSaveNoAdminTask,
  } = useAddRemoveCustomTask(setFullSiteLoad);

  const handleTaskCloseForm = () => {
    setActiveTaskId(null);
    setActiveSiteId(null);
    closeTaskForm();
  };

  const {
    isOpen: dialogConfirmOpen,
    handleClose: handleDialogConfirmClose,
    handleOpen: handleDialogConfirmOpen,
  } = useOpenState();

  const handleRemoveCustomTaskDialog = (taskId: string) => {
    setDeleteTaskId(taskId);

    handleDialogConfirmOpen();
  };

  const handleTaskSave = (task: Task) => {
    if (!task.id && task.custom) {
      handleAddCustomTask(task);
    } else {
      handleSaveTask(task);
    }

    handleTaskCloseForm();
  };

  const handleTextTaskSave = (task: Task) => {
    handleSaveNoAdminTask(task);
    handleTextTaskCloseForm();
  };

  const handleTaskEdit = (taskId: string, siteId: string) => {
    setActiveSiteId(siteId ?? null);
    setActiveTaskId(taskId ?? null);
    openTaskForm();
  };

  const handleTextTaskEdit = (taskId: string) => {
    setActiveTaskId(taskId ?? null);
    openTextTaskForm();
  };

  const handleTextTaskCloseForm = () => {
    setActiveTaskId(null);
    closeTextTaskForm();
  };

  const {
    isOpen: notesOpen,
    handleOpen: handleNotesOpen,
    handleClose: handleNotesClose,
  } = useOpenState();

  const {
    isOpen: syncOpen,
    handleOpen: handleSyncOpen,
    handleClose: handleSyncClose,
  } = useOpenState();

  const handleVersionSyncOpen = (currentTask: Task) => {
    setActiveTask(currentTask);
    handleSyncOpen();
  };

  const handleSyncTaskGroup = (siteId: string) => {
    setActiveSiteId(siteId ?? null);
    handleSyncOpen();
  };
  const handleTaskNoteOpen = (currentTask: Task) => {
    setActiveTask(currentTask);
    handleNotesOpen();
  };

  const handleProposalDescriptionOpen = (currentTask: Task) => {
    setActiveTask(currentTask);
    handleProposalDescriptionDialogOpen();
  };

  if (sitesError) {
    return <Typography>{FETCH_SITE_ERROR}</Typography>;
  }

  if (sitesLoading || includesSummaryTasksLoading) {
    return (
      <Grid item xs={12}>
        <Skeleton />
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      {includesSummaryTasks && !tabView && (
        <SummaryCard
          {...{
            site: summarySite,
            projectItemId: projectItemId,
            handleTaskEdit,
            handleVersionSyncOpen,
            handleNotesOpen: handleTaskNoteOpen,
            currentRolesAndRates: roles.roles,
            handleAddTaskGroup,
            handleSyncTaskGroup,
            handleRemoveCustomTask: handleRemoveCustomTaskDialog,
            activeTaskId: activeTask?.id ?? null,
            fullSiteLoad: fullSummarySiteLoad,
            setFullSiteLoad: setFullSummarySiteLoad,
            handleTextTaskEdit,
            handleProposalDescriptionDialogOpen: handleProposalDescriptionOpen,
            isReadOnly,
          }}
        />
      )}
      {tabView ? (
        <TabContext value={tabValue}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              width: '100%',
            }}
          >
            <TabList
              variant="scrollable"
              TabIndicatorProps={{
                style: {
                  background: `${theme.palette.secondary.main}`,
                },
              }}
              sx={{ borderBottom: 1, borderColor: 'divider' }}
            >
              {includesSummaryTasks && (
                <Tab
                  label={
                    <Tooltip
                      title={
                        <div
                          style={{ fontSize: 16, textAlign: 'center' }}
                          dangerouslySetInnerHTML={{ __html: 'Summary' }}
                        />
                      }
                    >
                      <span>Summary</span>
                    </Tooltip>
                  }
                  value="0"
                  onClick={() => handleTabChange('0')}
                />
              )}
              {sites.map?.((site, idx) => (
                <Tab
                  key={idx}
                  label={
                    <Tooltip
                      title={
                        <div
                          style={{ fontSize: 16, textAlign: 'center' }}
                          dangerouslySetInnerHTML={{ __html: site.name }}
                        />
                      }
                    >
                      <span
                        style={{
                          width: '70px',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                        }}
                      >
                        {site.name}
                      </span>
                    </Tooltip>
                  }
                  value={`${idx + 1}`}
                  onClick={() => handleTabChange(`${idx + 1}`)}
                />
              ))}
            </TabList>
            {includesSummaryTasks && (
              <TabPanel value={`0`} sx={{ width: '100%', padding: '0' }}>
                <SummaryCard
                  {...{
                    site: summarySite,
                    projectItemId: projectItemId,
                    handleTaskEdit,
                    handleVersionSyncOpen,
                    handleNotesOpen: handleTaskNoteOpen,
                    currentRolesAndRates: roles.roles,
                    handleAddTaskGroup,
                    handleSyncTaskGroup,
                    handleRemoveCustomTask: handleRemoveCustomTaskDialog,
                    activeTaskId: activeTask?.id ?? null,
                    fullSiteLoad: fullSummarySiteLoad,
                    setFullSiteLoad: setFullSummarySiteLoad,
                    handleTextTaskEdit,
                    handleProposalDescriptionDialogOpen:
                      handleProposalDescriptionOpen,
                    isReadOnly,
                    tabView,
                  }}
                />
              </TabPanel>
            )}
            {sites.map?.((site, idx) => (
              <TabPanel
                key={site.id}
                value={`${idx + 1}`}
                sx={{ width: '100%', padding: '0' }}
              >
                <SiteCard
                  {...{
                    site,
                    projectItemId: projectItemId,
                    key: `${site.id}-${site.expanded}-${idx.toString()}-card`,
                    handleTaskEdit,
                    handleVersionSyncOpen,
                    handleNotesOpen: handleTaskNoteOpen,
                    currentRolesAndRates: roles.roles,
                    handleAddTaskGroup,
                    handleSyncTaskGroup,
                    handleRemoveCustomTask: handleRemoveCustomTaskDialog,
                    activeTaskId: activeTask?.id ?? null,
                    fullSiteLoad,
                    setFullSiteLoad,
                    handleTextTaskEdit,
                    handleProposalDescriptionDialogOpen:
                      handleProposalDescriptionOpen,
                    isReadOnly,
                    tabView,
                    includesSummaryTasks,
                    handleRemoveSiteClick: () => handleTabChange('1'),
                    handleCopySiteClick: () =>
                      handleTabChange(
                        includesSummaryTasks
                          ? '0'
                          : (sites.length + 1).toString()
                      ),
                    setIsCustomTasksList,
                    isCustomTasksList,
                    setFullSummarySiteLoad,
                  }}
                />
              </TabPanel>
            ))}
          </Box>
        </TabContext>
      ) : (
        sites.map?.((site, idx) => (
          <SiteCard
            {...{
              site,
              projectItemId: projectItemId,
              key: `${site.id}-${site.expanded}-${idx.toString()}-card`,
              handleTaskEdit,
              handleVersionSyncOpen,
              handleNotesOpen: handleTaskNoteOpen,
              currentRolesAndRates: roles.roles,
              handleAddTaskGroup,
              handleSyncTaskGroup,
              handleRemoveCustomTask: handleRemoveCustomTaskDialog,
              activeTaskId: activeTask?.id ?? null,
              fullSiteLoad,
              setFullSiteLoad,
              handleTextTaskEdit,
              handleProposalDescriptionDialogOpen:
                handleProposalDescriptionOpen,
              isReadOnly,
              tabView,
              includesSummaryTasks,
              setIsCustomTasksList,
              isCustomTasksList,
              setFullSummarySiteLoad,
            }}
          />
        ))
      )}

      <TaskNoteDialog
        {...{
          isOpen: notesOpen,
          handleClose: handleNotesClose,
          task: activeTask,
          projectItemId,
        }}
      />
      <TaskSyncDialog
        isOpen={syncOpen}
        handleClose={handleSyncClose}
        currentTask={activeTask}
        siteId={activeSiteId}
        projectItemId={projectItemId}
        setFullSiteLoad={setFullSiteLoad}
        setFullSummarySiteLoad={setFullSummarySiteLoad}
      />

      {taskFormOpen && ((activeTaskId && task?.id && !taskLoading) || true) && (
        <TaskForm
          task={task}
          siteId={activeSiteId}
          isOpen={taskFormOpen}
          handleClose={handleTaskCloseForm}
          handleSubmit={handleTaskSave}
          projectItemId={projectItemId}
          currentPractices={currentPractices}
          taskLoading={taskLoading}
        />
      )}
      {textTaskFormOpen && task?.id && (
        <TextTaskDialog
          currentTask={task}
          isOpen={textTaskFormOpen}
          handleClose={handleTextTaskCloseForm}
          handleTaskSave={handleTextTaskSave}
        />
      )}

      <TaskProposalDescriptionDialog
        isOpen={proposalDescriptionDialogOpen}
        task={activeTask}
        handleClose={handleProposalDescriptionDialogClose}
        handleTaskSave={handleTaskSave}
      />

      <DialogConfirm
        title="Remove Task?"
        isOpen={dialogConfirmOpen}
        handleClose={() => {
          setDeleteTaskId(null);
          handleDialogConfirmClose();
        }}
        handleYes={() => {
          handleRemoveCustomTask(deleteTaskId);
          setDeleteTaskId(null);
          handleDialogConfirmClose();
        }}
      ></DialogConfirm>
    </Grid>
  );
};

export default EstimateDetails;
