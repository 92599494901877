import {
  Practice,
  ProjectItemEstimatorsInput,
  RoleAndRate,
} from '@cdw-selline/common/types';
import {
  useProjectItemEstimator,
  useAgiloftEstimatorVariables,
  useManagedServices,
  useEstimatorSow,
  useAgiloftTypeOptions
} from '@cdw-selline/ui/hooks';
import {
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormText, FormCheckbox, FormSelect, FormDateField } from '../../formHelperFunctions';
import { FormAutocomplete } from '../../formHelperFunctions';
import { ApolloError } from '@apollo/client';
import omitDeep from 'omit-deep-lodash';
import { pickBy, isEqual, isEmpty } from 'lodash';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { BILLING_TYPES } from '@cdw-selline/ui/constants';

export interface ProjectItemEstimatorDetailsFormProps {
  currentRolesAndRates: RoleAndRate[];
  practicesAndRatesLoading: boolean;
  practicesAndRatesError: ApolloError;
  practices: Practice[];
  isReadOnly: boolean
}

export const ProjectItemEstimatorDetailsForm = ({
  currentRolesAndRates,
  practicesAndRatesLoading,
  practices,
  isReadOnly,
}: ProjectItemEstimatorDetailsFormProps) => {
  const { id, projectItemId } = useParams<{ id: string, projectItemId: string }>();
  const [showExpenseMiles, setShowExpenseMiles] = useState(false);
  const [expenseClauses, setExpenseClauses] = useState([]);
  const [showTravelPenaltyAmount, setShowTravelPenaltyAmount] = useState(false);
  const [travelClauses, setTravelClauses] = useState([]);

  const {
    data: estimator,
    getProjectItemEstimatorLoading,
    updateProjectItemEstimatorVariables,
  } = useProjectItemEstimator();
  const { projectSow, projectSowLoading, projectSowError } = useEstimatorSow(projectItemId);
  const { includesManagedServices, includeManagedServicesLoading, includeManagedServicesError } = useManagedServices(projectItemId);
  const { data : printDetailedTemplateTypes, error, loading, agiloftDefaultTemplates } = useAgiloftTypeOptions();

  useEffect(() => {
    if (
      getProjectItemEstimatorLoading ||
      projectSowLoading ||
      !projectSow?.id ||
      !estimator?.id ||
      !estimator?.projectItemId
    ) return;
    if (estimator.agiloftPrintTemplateType || (projectSow.outcomeBased ? printOutcomeTemplateTypes : printDetailedTemplateTypes).includes(estimator.agiloftPrintTemplateType)) {
      updatePrintTemplateType(estimator.agiloftPrintTemplateType, estimator, projectSow.outcomeBased);
    }
  }, [estimator, projectSow.outcomeBased]);

  useEffect(()=>{
    if (estimator?.projectItemId && printDetailedTemplateTypes.length > 0 && (!estimator.agiloftPrintTemplateType || !(projectSow.outcomeBased ? printOutcomeTemplateTypes : printDetailedTemplateTypes).includes(estimator.agiloftPrintTemplateType))) {
      setFormData({...estimator, agiloftPrintTemplateType: projectSow.outcomeBased ? 'Outcome Based Services Proposal' : agiloftDefaultTemplates[0] ?? printDetailedTemplateTypes[0]});
    }
},[printDetailedTemplateTypes, estimator, projectSow.outcomeBased]);

  useEffect(() => {
    if (getProjectItemEstimatorLoading || practicesAndRatesLoading) {
      return;
    }
    if ((estimator.pmRole && !isPmRoleInOptions) || (!estimator.pmRole && pmOptions.length > 0)) {
      if (pmOptions.length > 0) {
        handleProjectItemEstimatorUpdate({
          ...estimator,
          pmRole: pmOptions[0].id,
        } as ProjectItemEstimatorsInput);
      }
    }
  }, [currentRolesAndRates, estimator.pmRole]);

  const isPracticeManagedServices = practices.some(
    (practice: Practice): boolean => practice.managedSvc
  );

  useEffect(() => {
    if(getProjectItemEstimatorLoading || includeManagedServicesLoading || !estimator?.id) return;
    updateExpenseClauses(estimator.agiloftExpenseType, estimator, includesManagedServices, isPracticeManagedServices);
    updateTravelClauses(estimator.agiloftTravelType, estimator);
  }, [estimator, includeManagedServicesLoading, includesManagedServices, isPracticeManagedServices]);

  const getPracticeNameById = (practiceId: string): string => {
    const practice = practices.find((practice) => practice.id === practiceId);
    return practice ? practice.name : '';
  }

  const pmOptions = currentRolesAndRates
    .filter((rate) => rate.projectManager)
    .map((role) => ({
      id: role.id,
      label: `${role.name} (${getPracticeNameById(role.practice)}) `,
    }));

  const isPmRoleInOptions = Boolean(
    pmOptions.some((option) => option.id === estimator.pmRole)
  );

  const handleProjectItemEstimatorUpdate = useCallback((
    updatedFormData: Partial<ProjectItemEstimatorsInput>
  ): void => {
    const updateRequest = omitDeep(updatedFormData,['__typename', 'rmsProposal', 'agiloftUrl']);
    updateProjectItemEstimatorVariables({
      variables: { params: { ...updateRequest } },
    });
  }, [updateProjectItemEstimatorVariables]);
  const setFormData = (updatedFormData) => {
    const updatedRequest = pickBy(updatedFormData, (v, k) => !isEqual(estimator[k], v));
    if (!isEmpty(updatedRequest)) {
      handleProjectItemEstimatorUpdate({
        id: updatedFormData.id,
        projectItemId: updatedFormData.projectItemId,
        ...updatedRequest
      });
    }
  };

  const {
    updateExpenseClauses,
    updateTravelClauses,
    updatePrintTemplateType,
    managedServicesCustomerTypeOptions,
    managedServicesDealTypeOptions,
    managedServicesTransitionFeeTypeOptions,
    expenseTypes,
    travelNoticeTypes,
  } = useAgiloftEstimatorVariables(
    setExpenseClauses,
    setShowExpenseMiles,
    setTravelClauses,
    setShowTravelPenaltyAmount,
    setFormData
  );

  const handleUpdateEstimatorClauses = (e)=>{
    const agiloftExpenseType = e.target.value;
    updateExpenseClauses(agiloftExpenseType, estimator, includesManagedServices, isPracticeManagedServices);
  };

  const handleUpdateTravelClauses = (e)=>{
    const agiloftTravelType = e.target.value;
    updateTravelClauses(agiloftTravelType, estimator);
  };

  const printOutcomeTemplateTypes = [
    'Outcome Based Services Proposal',
    'Outcome Based CSOW',
  ];

  // const [ serviceFeeClauses, setServiceFeeClauses ] = useState([]);
  // useEffect(() => {
  //   //services fees clause

  //   const twoWeekNoticeNoPenaltyClauses = [
  //     "Managed Services - MCA CCaaS - Services Fees (T&amp;M)",
  //     "Managed Services - MCA Webex Cloud - Services Fees (T&amp;M)",
  //     "Time And Materials - Unit Rates CSOW",
  //   ];
  //   const twoWeekNoticePenaltyVariableClauses = [
  //     "Time and Materials - Block of Hours CSOW",
  //     "Time And Materials - Consultant Rates CSOW",
  //   ];
  //   const twoWeekNoticePenaltyFixedClauses = [
  //     "Time And Materials - Unit Rates Not to Exceed CSOW"
  //   ];
  //   const noTravelClauses = [
  //     "Fixed Fee CSOW",
  //     "InfoSec RSA Service Fees Fee Structure (LARGE, MEDIUM, SMALL)",
  //     "Lifecycle Asset Mgmt Service Fees",
  //     "Managed Services - MCA CCaaS - Services Fees (Fixed Fees)",
  //     "Managed Services - MCA Webex Cloud - Services Fees (Fixed Fees)",
  //   ];
  // }, [estimator.agiloftTravelType, setShowTravelPenaltyAmount]);

  const pmClauses = [
    "Project Management_Program Manager",
    "Project Management_Project Oversight",
    "Project Management_Standard",
  ];

  if (
    getProjectItemEstimatorLoading ||
    practicesAndRatesLoading ||
    !estimator.id
  )
    return <CircularProgress />;

  return (
    <Grid
      container
      alignItems="flex-start"
      justifyContent="center"
      direction="row"
      data-testid="estimator-details-grid"
    >
      <Grid
        container
        alignItems="flex-start"
        justifyContent="center"
        direction="column"
        sx={{ width: '100%', mt: '10px' }}
        spacing={1}
      >

        <FormSelect
          name="billingType"
          label="Billing Type"
          setFormData={setFormData}
          formData={{ ...estimator, billingType: estimator.billingType || BILLING_TYPES.TIME_MATERIALS }}
          disabled={isReadOnly}
        >
          {Object.entries(BILLING_TYPES).map(([k, v]) => {
            return (
              <MenuItem key={k} value={v}>
                {v}
              </MenuItem>
            )
          })}
        </FormSelect>


        {estimator?.billingType === BILLING_TYPES.FIXED_FEE && (
          <>
            <FormCheckbox
              name="includeTAndEFixedFee"
              label="Include T&E Fixed Fee"
              setFormData={setFormData}
              formData={estimator}
              disabled={isReadOnly}
            />
            <FormText
              name="riskMitigation"
              label="Risk Mitigation %"
              setFormData={setFormData}
              formData={estimator}
              type="number"
              disabled={isReadOnly}
            />
          </>
        )}

        <FormText
          name="overtimePercent"
          label="Overtime %"
          setFormData={setFormData}
          formData={estimator}
          type="number"
          disabled={isReadOnly}
        />
        <FormText
          name="pmPercent"
          label="PM %"
          setFormData={setFormData}
          formData={estimator}
          type="number"
          disabled={isReadOnly}
        />
        <FormAutocomplete
          name="pmRole"
          label="PM Role"
          formData={isPmRoleInOptions ? estimator : {}}
          setFormData={setFormData}
          options={pmOptions}
          disabled={isReadOnly}
        />
        <FormText
          name="tAndETotal"
          label="T&E Total"
          setFormData={setFormData}
          formData={estimator}
          type="number"
          disabled={isReadOnly}
        />
        {process.env.NODE_ENV !== 'production' && <LocalizationProvider dateAdapter={AdapterDateFns}>
          <FormDateField
            name="startDate"
            label="Start Date"
            setFormData={setFormData}
            formData={estimator}
            disabled={isReadOnly}
          />
          <FormDateField
            name="endDate"
            label="End Date"
            setFormData={setFormData}
            formData={estimator}
            disabled={isReadOnly}
          />
        </LocalizationProvider>}
        <Grid item xs={12} sx={{ width: '100%', marginTop: '15px' }}>
          <Paper elevation={4}>
            <Grid container spacing={1}>
              <Grid item xs={12} sx={{ width: '100%' }}>
                <Typography variant="h6">Agiloft</Typography>
              </Grid>
              <FormCheckbox
                name="agiloftGenerateDocuments"
                label="Generate Documents"
                setFormData={setFormData}
                formData={estimator}
                disabled={isReadOnly}
              />
              {estimator.agiloftGenerateDocuments && (
                <FormSelect
                  name="agiloftPrintTemplateType"
                  label="Print Template Type"
                  setFormData={setFormData}
                  formData={estimator}
                  disabled={isReadOnly}
                >
                  {(projectSow.outcomeBased ? printOutcomeTemplateTypes : printDetailedTemplateTypes).map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </FormSelect>
              )}
              {includesManagedServices && (
                <FormCheckbox
                  name="agiloftSummaryTable"
                  label="Include Fee Summary Table"
                  setFormData={setFormData}
                  formData={estimator}
                  disabled={isReadOnly}
                />
              )}
              {/* <FormSelect
                name="agiloftServiceFeeClause"
                label="Service Fee Clause"
                setFormData={setFormData}
                formData={estimator}
              >
                {serviceFeeClauses.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </FormSelect> */}
              <FormSelect
                name="agiloftPmClause"
                label="PM Clause"
                setFormData={setFormData}
                formData={estimator}
                disabled={isReadOnly}
              >
                {pmClauses.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </FormSelect>
              <FormSelect
                name="agiloftExpenseType"
                label="Expense Type"
                onChange={handleUpdateEstimatorClauses}
                formData={estimator}
                disabled={isReadOnly}
              >
                {expenseTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </FormSelect>
              <FormSelect
                name="agiloftExpenseClause"
                label="Expense Clause"
                setFormData={setFormData}
                formData={estimator}
                disabled={isReadOnly}
              >
                {expenseClauses.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </FormSelect>
              {showExpenseMiles && (
                <FormText
                  name="agiloftExpenseMiles"
                  label="Expense Miles"
                  setFormData={setFormData}
                  formData={estimator}
                  type="number"
                  disabled={!showExpenseMiles || isReadOnly}
                  title="# of Miles from Designated Location"
                />
              )}
              <FormSelect
                name="agiloftTravelType"
                label="Travel Notice Type"
                onChange={handleUpdateTravelClauses}
                formData={estimator}
                disabled={isReadOnly}
              >
                {travelNoticeTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </FormSelect>
              <FormSelect
                name="agiloftTravelClause"
                label="Travel Clause"
                setFormData={setFormData}
                formData={estimator}
                disabled={isReadOnly}
              >
                {travelClauses.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </FormSelect>
              {showTravelPenaltyAmount && (
                <FormText
                  name="agiloftTravelPenaltyAmount"
                  label="Travel Penalty Amount"
                  setFormData={setFormData}
                  formData={estimator}
                  type="number"
                  title="Travel Penalty Amount"
                  disabled={isReadOnly}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              )}
              {/* <FormText
                name="agiloftFundingAmount"
                label="Funding Amount"
                setFormData={setFormData}
                formData={estimator}
                type="number"
                disabled={isReadOnly}
              /> */}
            </Grid>
          </Paper>
        </Grid>

        {(includesManagedServices || isPracticeManagedServices) && (
          <Grid
            item
            xs={12}
            sx={{ width: '100%', marginTop: '15px', marginBottom: '25px' }}
          >
            <Paper elevation={4}>
              <Grid container spacing={1}>
                <Grid item xs={12} sx={{ width: '100%' }}>
                  <Typography variant="h6">Managed Services</Typography>
                </Grid>
                <FormSelect
                  name="managedServicesContractLength"
                  label="Managed Services Contract Length"
                  setFormData={setFormData}
                  formData={estimator}
                  disabled={isReadOnly}
                >
                  <MenuItem value={12}>12 Months</MenuItem>
                  <MenuItem value={24}>24 Months</MenuItem>
                  <MenuItem value={36}>36 Months</MenuItem>
                  <MenuItem value={60}>60 Months</MenuItem>
                </FormSelect>

                <FormCheckbox
                  name="isManagedServicesRenewal"
                  label="Manage Services Renewal"
                  setFormData={setFormData}
                  formData={estimator}
                  disabled={isReadOnly}
                />
                {process.env.NX_PROJECT_ID === 'selline-dev' && <>
                  <FormSelect
                    name="managedServicesCustomerType"
                    label="Managed Services Customer Type"
                    setFormData={setFormData}
                    formData={estimator}
                    disabled={isReadOnly}
                  >
                    {managedServicesCustomerTypeOptions.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </FormSelect>
                  <FormSelect
                    name="managedServicesDealType"
                    label="Managed Services Deal Type"
                    setFormData={setFormData}
                    formData={estimator}
                    disabled={isReadOnly}
                  >
                    {managedServicesDealTypeOptions.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </FormSelect>

                  <FormCheckbox
                    name="managedServicesCustomDeal"
                    label="Managed Services Custom Deal"
                    setFormData={setFormData}
                    formData={estimator}
                    disabled={isReadOnly}
                  />
                  <FormDateField
                    name="managedServicesTransitionStartDate"
                    label="Managed Services Transition Start Date"
                    setFormData={setFormData}
                    formData={estimator}
                    disabled={isReadOnly}
                  />
                  <FormText
                    name="managedServicesTransitionEstimatedDuration"
                    label="Managed Services Transition Estimated Duration"
                    setFormData={setFormData}
                    formData={estimator}
                    type="number"
                    disabled={isReadOnly}
                  />
                  <FormSelect
                    name="managedServicesTransitionFeeType"
                    label="Managed Services Transition Fee Type"
                    setFormData={setFormData}
                    formData={estimator}
                    disabled={isReadOnly}
                  >
                    {managedServicesTransitionFeeTypeOptions.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </FormSelect>
                </>}
              </Grid>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
