import { CDWFallback } from '../../cdw-fallback/CDWFallback';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  useCiscoAnnuity,
  useCiscoAnnuityUpdate,
  useCiscoCcwQuotes,
  useCiscoCcwEstimates,
  useOpenState,
  useCiscoSmartAccounts,
  useCiscoAnnuityQuote,
} from '@cdw-selline/ui/hooks';
import {
  Backdrop,
  Box,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputAdornment,
  CircularProgress,
  Checkbox,
  Autocomplete,
  Button,
  Tooltip,
} from '@mui/material';
import Zoom from '@mui/material/Zoom';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import React, { Fragment, useEffect, useState } from 'react';
import {
  CiscoAnnuity,
  CiscoQuote,
  CiscoAnnuityContactType,
  CiscoAnnuityCommentInput,
  Opportunity,
  CiscoAnnuityLineTerm,
} from '@cdw-selline/common/types';
import { CiscoAnnuityMessages } from '../';
import DialogConfirm from '../../dialog-confirm/DialogConfirm';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { getCiscoAnnuityActiveQuote } from '@cdw-selline/ui/helpers';

import { ALERT_SEVERITY, ALERT_TYPE, useAlertsState } from '@cdw-selline/ui/state';
import { AnnuityContact } from './CiscoAnnuityContact';
import { CiscoSubscriptionView } from './CiscoSubscriptionView';
import { ciscoAnnuityStatusActiveStep } from '@cdw-selline/ui/constants';
import { CiscoAnnuityLineTerms } from './CiscoAnnuityLineTerms';

export interface CiscoAnnuityDetailsProps {
  projectItemId: string;
  project: Opportunity;
  ciscoAnnuity: CiscoAnnuity;
  userIsCloudFulfillment: boolean;
  loading?: boolean;
  isReadOnly?: boolean;
}

export function CiscoAnnuityDetails({
  projectItemId,
  project,
  userIsCloudFulfillment,
  isReadOnly,
}: CiscoAnnuityDetailsProps) {
  const alertState = useAlertsState();

  const {
    ciscoAnnuity,
    loading,
    error,
    refetch,
    removeCiscoQuoteById,
    reloadCiscoQuoteById,
    reloadCiscoQuoteLoading,
  } = useCiscoAnnuity({
    projectItemId: projectItemId,
  });
  const { ciscoSmartAccounts, addCiscoSmartAccount } = useCiscoSmartAccounts({
    customerId: project.customerId ?? '',
  });
  const smartAccountOptions = ciscoSmartAccounts?.map((ciscoSmartAccount) => ({
    label: ciscoSmartAccount.name,
    id: ciscoSmartAccount.name,
  }));
  const currentSmartAccount = ciscoSmartAccounts?.find(
    (smartAccount) => smartAccount.name === ciscoAnnuity.ciscoSmartAccount
  );
  const virtualAccountOptions = currentSmartAccount?.virtualAccounts?.map(
    (ciscoVirtualAccount) => ({
      label: ciscoVirtualAccount.name,
      id: ciscoVirtualAccount.name,
    })
  );

  const { handleCiscoAnnuityQuoteUpdate } = useCiscoAnnuityQuote();
  const {
    handleCiscoAnnuityUpdate,
    handleSaveCiscoAnnuityComment,
    handleDeleteCiscoAnnuityComment,
  } = useCiscoAnnuityUpdate();
  const {
    getCiscoCcwQuotesByDealId,
    data: ccwData,
    loading: ccwLoading,
    error: ccwError,
  } = useCiscoCcwQuotes();
  const {
    getCiscoCcwEstimateById,
    data: ccwEstimateData,
    loading: ccwEstimateLoading,
    error: ccwEstimateError,
  } = useCiscoCcwEstimates();
  const {
    isOpen: dialogConfirmOpen,
    handleClose: handleDialogConfirmClose,
    handleOpen: handleDialogConfirmOpen,
  } = useOpenState();
  const {
    isOpen: viewSubscriptionOpen,
    handleClose: handleViewSubscriptionClose,
    handleOpen: handleViewSubscriptionOpen,
  } = useOpenState();
  const {
    isOpen: viewLineTermsOpen,
    handleClose: handleLineTermsClose,
    handleOpen: handleLineTermsOpen,
  } = useOpenState();
  const [addQuote, setAddQuote] = useState({
    open: false,
    adding: false,
    quoteId: '',
  });

  const [displayError, setDisplayError] = useState(false);
  const activeQuote = getCiscoAnnuityActiveQuote(ciscoAnnuity);
  const [quoteLoading, setQuoteLoading] = useState(false);
  const [customerBillingAccount, setCustomerBillingAccount] = useState('');
  const currentIndex = ciscoAnnuityStatusActiveStep[ciscoAnnuity.status];

  useEffect(() => {
    setAddQuote({
      ...addQuote,
      open: !ciscoAnnuity.activeCiscoQuote ? true : false,
    });
  }, [ciscoAnnuity.activeCiscoQuote]);

  useEffect(() => {
    if (ccwData.success && activeQuote?.lineTerms?.length > 1) {
      handleLineTermsOpen();
    }
  }, [ciscoAnnuity.activeCiscoQuote]);

  useEffect(() => {
    if (!reloadCiscoQuoteLoading && activeQuote?.lineTerms?.length > 1) {
      handleLineTermsOpen();
    }
  }, [reloadCiscoQuoteLoading]);

  useEffect(() => {
    setCustomerBillingAccount(ciscoAnnuity.customerBillingAccount);
  }, [ciscoAnnuity.customerBillingAccount]);

  useEffect(() => {
    setDisplayError(!!(ccwEstimateError || ccwError));
  }, [ccwEstimateError, ccwError]);

  useEffect(() => {
    setQuoteLoading(reloadCiscoQuoteLoading || ccwLoading);
  }, [ccwLoading, reloadCiscoQuoteLoading]);

  if (loading) {
    return (
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer - 1,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
        open
        data-testid="progressbar"
      >
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Grid
            xs={12}
            item
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
            }}
          >
            <Typography> Loading Cisco Annuity...</Typography>
          </Grid>
          <Grid item xs={12}>
            <CDWFallback />
          </Grid>
        </Stack>
      </Backdrop>
    );
  }

  if (error) return <Typography>Error loading Cisco Annuity</Typography>;

  if (addQuote.open === true && addQuote.adding === true && !ccwLoading) {
    setAddQuote({ adding: false, open: false, quoteId: '' });
    refetch();
  }

  const handleQuoteAdd = () => {
    setAddQuote({ ...addQuote, open: !addQuote.open });
  };

  const handleQuoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddQuote({ ...addQuote, [event.target.name]: event.target.value });
  };

  const handleGetQuote = () => {
    const isDeal = /^\d+$/.test(addQuote.quoteId.trim());

    if (isDeal) {
      getCiscoCcwQuotesByDealId({
        variables: {
          ciscoAnnuityId: ciscoAnnuity.id,
          ciscoDealId: addQuote.quoteId.trim(),
        },
      });
    }

    if (!isDeal) {
      getCiscoCcwEstimateById({
        variables: {
          ciscoAnnuityId: ciscoAnnuity.id,
          ciscoEstimateId: addQuote.quoteId.trim(),
        },
      });
    }
  };

  const handleCancelGetQuote = () => {
    setDisplayError(false);
    setAddQuote({ adding: false, open: true, quoteId: '' });
  };

  const handleQuoteReload = () => {
    reloadCiscoQuoteById({
      variables: {
        ciscoAnnuityId: ciscoAnnuity.id,
        ciscoQuoteId: activeQuote.id,
      },
    });
  };

  const handleQuoteDelete = () => {
    removeCiscoQuoteById({
      variables: {
        ciscoAnnuityId: ciscoAnnuity.id,
        ciscoQuoteId: activeQuote.id,
      },
    });
    handleDialogConfirmClose();
  };

  const handleSelectLineTerm = (quoteTerm: CiscoAnnuityLineTerm) => {
    handleCiscoAnnuityQuoteUpdate(ciscoAnnuity.id, activeQuote.id, {
      term: quoteTerm.term,
      billingCycle: quoteTerm.billingCycle,
      autoRenew: quoteTerm.autoRenew,
    });
    handleLineTermsClose();
  };

  const handleUpdate = (event) => {
    let value: string | boolean;

    if (event.target.type === 'checkbox') {
      value = event.target.checked ? true : false;
    }

    const updatedCiscoAnnuity = {
      id: ciscoAnnuity.id,
      [event.target.name]: value ?? event.target.value,
    };

    handleCiscoAnnuityUpdate(updatedCiscoAnnuity, projectItemId);
  };

  const handleSaveComment = (message: CiscoAnnuityCommentInput) => {
    handleSaveCiscoAnnuityComment(ciscoAnnuity.id, message);
  };

  const handleDeleteComment = (commentId: string) => {
    handleDeleteCiscoAnnuityComment(ciscoAnnuity.id, commentId);
  };

  const handleCiscoVirtualAccountChange = (event: any) => {
    if (event.target.value === ciscoAnnuity.ciscoVirtualAccount) {
      return;
    }

    addCiscoSmartAccount({
      variables: {
        customerId: project.customerId,
        ciscoSmartAccount: {
          name: ciscoAnnuity.ciscoSmartAccount,
          virtualAccounts: [{ name: event.target.value }],
        },
      },
    });

    handleCiscoAnnuityUpdate(
      {
        id: ciscoAnnuity.id,
        ciscoVirtualAccount: event.target.value,
      },
      projectItemId
    );
  };

  const handleCiscoSmartAccountChange = (event: any) => {
    if (event.target.value === ciscoAnnuity.ciscoSmartAccount) {
      return;
    }

    addCiscoSmartAccount({
      variables: {
        customerId: project.customerId,
        ciscoSmartAccount: { name: event.target.value },
      },
    });

    handleCiscoAnnuityUpdate(
      {
        id: ciscoAnnuity.id,
        ciscoSmartAccount: event.target.value,
        ciscoVirtualAccount: '',
      },
      projectItemId
    );
  };

  const handleCiscoCapitalChange = (event: any) => {
    if (event.target.value === ciscoAnnuity.ciscoCapital) {
      return;
    }

    handleCiscoAnnuityUpdate(
      {
        id: ciscoAnnuity.id,
        ciscoCapital: event.target.checked,
        customerBillingAccount: '5908582',
      },
      projectItemId
    );
  };

  const ccwLoadingIcon = () => {
    if (quoteLoading) {
      return (
        <InputAdornment position="end">
          <CircularProgress />
        </InputAdornment>
      );
    }
  };

  const sellerContacts = project.projectTeam.filter((item) =>
    ['Primary Account Owner', 'Secondary Account Owner'].includes(item.type)
  );

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(activeQuote?.quoteId);
    alertState.setAlert({
      type: ALERT_TYPE.SNACKBAR,
      severity: ALERT_SEVERITY.SUCCESS,
      message: 'Successfully copied Cisco Annuity Deal Id',
    });
  };

  return (
    <Grid container>
      <Grid item xs={6}>
        <Grid container alignItems="center">
          {!addQuote.open && ciscoAnnuity.ciscoQuotes.length > 0 && (
            <>
              {!quoteLoading && (
                  <Select
                    onChange={handleUpdate}
                    style={{ width: '80%' }}
                    value={ciscoAnnuity.activeCiscoQuote ?? ''}
                    name="activeCiscoQuote"
                  >
                    {ciscoAnnuity.ciscoQuotes?.map((ciscoQuote: CiscoQuote) => (
                      <MenuItem key={ciscoQuote.id} value={ciscoQuote.id}>
                        {ciscoQuote.quoteId}-{ciscoQuote.name}
                      </MenuItem>
                    ))}
                  </Select>
              )}

              {quoteLoading && (
                <TextField
                  label=""
                  name="quoteId"
                  value={`${activeQuote.quoteId}-${activeQuote.name ?? ''}`}
                  onChange={handleQuoteChange}
                  style={{ width: '80%' }}
                  InputProps={{ endAdornment: ccwLoadingIcon() }}
                  disabled={isReadOnly}
                />
              )}
            </>
          )}

          {addQuote.open ? (
            <Fragment>
              <TextField
                label="Estimate/Deal ID"
                name="quoteId"
                value={addQuote.quoteId}
                onChange={handleQuoteChange}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleGetQuote();
                  }
                }}
                style={{ width: '80%' }}
                InputProps={{ endAdornment: ccwLoadingIcon() }}
                disabled={isReadOnly}
              />
              <IconButton onClick={handleGetQuote} disabled={isReadOnly}>
                <SaveIcon />
              </IconButton>
              <IconButton onClick={handleCancelGetQuote} disabled={isReadOnly}>
                <CancelIcon />
              </IconButton>
              {displayError && (
                <Box sx={{ padding: '15px' }}>
                  <Typography color="error">
                    Error getting {addQuote.quoteId}
                  </Typography>
                </Box>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <Tooltip enterDelay={300} title="Copy DealId">
                <IconButton onClick={handleCopyToClipboard} disabled={isReadOnly}>
                 <ContentCopyIcon />
                </IconButton>
              </Tooltip>
           
                <IconButton onClick={handleQuoteAdd} disabled={isReadOnly}>
                 <AddIcon />
                </IconButton>
            </Fragment>
          )}
          {ciscoAnnuity.activeCiscoQuote && !addQuote.open && (
            <Fragment>
              <IconButton onClick={handleQuoteReload} disabled={isReadOnly}>
                <AutorenewIcon />
              </IconButton>
              <IconButton
                onClick={handleDialogConfirmOpen}
                disabled={isReadOnly}
              >
                <DeleteIcon />
              </IconButton>
            </Fragment>
          )}
        </Grid>
        {activeQuote && (
          <Grid
            container
            mt={1}
            justifyContent="flex-start"
            style={{ width: '80%' }}
          >
            <Grid item xs={activeQuote.change ? 4 : 6}>
              <TextField
                fullWidth
                label="Start Date"
                value={activeQuote.start ?? ''}
                type="string"
                disabled={true}
                helperText="Updated in Cisco CCW"
              />
            </Grid>
            <Grid item xs={activeQuote.change ? 4 : 6}>
              <TextField
                fullWidth
                label="Status"
                value={activeQuote.status ?? ''}
                type="string"
                disabled={true}
              />
            </Grid>
            {activeQuote.change && (
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Sub"
                  value={activeQuote.subscriptionReferenceId ?? ''}
                  type="string"
                  disabled={true}
                />
                <Button onClick={handleViewSubscriptionOpen}>
                  View Subscription
                </Button>
              </Grid>
            )}
          </Grid>
        )}
        <Grid marginBottom={2}>
          <Checkbox
            name="ciscoCapital"
            checked={ciscoAnnuity.ciscoCapital}
            onChange={handleCiscoCapitalChange}
          />{' '}
          Cisco Capital?
        </Grid>
        <Grid marginBottom={2} style={{ width: '80%' }}>
          <TextField
            fullWidth
            label="Customer Billing Account#"
            name="customerBillingAccount"
            value={customerBillingAccount ?? ''}
            onChange={(e) => setCustomerBillingAccount(e.target.value)}
            type="string"
            disabled={ciscoAnnuity.ciscoCapital}
            placeholder="Customer Billing Account#(Only if billing to a lease account)"
            onBlur={handleUpdate}
          />
        </Grid>
        <Grid marginBottom={2} style={{ width: '80%' }}>
          <TextField
            fullWidth
            label="Customer Purchase Order"
            name="customerPurchaseOrder"
            defaultValue={ciscoAnnuity.customerPurchaseOrder ?? ''}
            type="string"
            onBlur={handleUpdate}
          />
        </Grid>
        <Grid marginBottom={2} style={{ width: '80%' }}>
          <TextField
            fullWidth
            label="State Contract"
            name="stateContract"
            defaultValue={ciscoAnnuity.stateContract ?? ''}
            type="string"
            onBlur={handleUpdate}
          />
        </Grid>
        <Grid style={{ width: '80%' }}>
          <Typography variant="h6">Customer Billing Contact</Typography>
        </Grid>
        {ciscoAnnuity.contacts?.filter(
          (contact) => contact.type === CiscoAnnuityContactType.CustomerBilling
        ).length === 0 && (
          <AnnuityContact
            ciscoAnnuityId={ciscoAnnuity.id}
            ciscoAnnuityContact={{
              type: CiscoAnnuityContactType.CustomerBilling,
            }}
          />
        )}
        {ciscoAnnuity.contacts
          ?.filter(
            (contact) =>
              contact.type === CiscoAnnuityContactType.CustomerBilling
          )
          .map((ciscoAnnuityContact) => (
            <AnnuityContact
              ciscoAnnuityId={ciscoAnnuity.id}
              key={ciscoAnnuityContact.id}
              ciscoAnnuityContact={ciscoAnnuityContact}
            />
          ))}

        <Grid marginTop={2} style={{ width: '80%' }}>
          <Typography variant="h6">CDW Contact</Typography>
        </Grid>

        {ciscoAnnuity.contacts?.filter(
          (contact) => contact.type === CiscoAnnuityContactType.Cdw
        ).length === 0 && (
          <AnnuityContact
            ciscoAnnuityId={ciscoAnnuity.id}
            ciscoAnnuityContact={{ type: CiscoAnnuityContactType.Cdw }}
          />
        )}

        {ciscoAnnuity.contacts
          ?.filter((contact) => contact.type === CiscoAnnuityContactType.Cdw)
          .map((ciscoAnnuityContact) => (
            <AnnuityContact
              ciscoAnnuityId={ciscoAnnuity.id}
              key={ciscoAnnuityContact.id}
              ciscoAnnuityContact={ciscoAnnuityContact}
            />
          ))}
        <Grid marginTop={2}>
          <Checkbox
            name="customerAdminSameAsBilling"
            checked={ciscoAnnuity.customerAdminSameAsBilling}
            onChange={handleUpdate}
          />{' '}
          Customer Admin Same as Billing?
        </Grid>
        {!ciscoAnnuity.customerAdminSameAsBilling && (
          <Grid style={{ width: '80%' }}>
            <Typography variant="h6">Webex Platform Administrator</Typography>
          </Grid>
        )}

        {!ciscoAnnuity.customerAdminSameAsBilling &&
          ciscoAnnuity.contacts?.filter(
            (contact) =>
              contact.type ===
              CiscoAnnuityContactType.WebexPlatformAdministrator
          ).length === 0 && (
            <AnnuityContact
              ciscoAnnuityId={ciscoAnnuity.id}
              ciscoAnnuityContact={{
                type: CiscoAnnuityContactType.WebexPlatformAdministrator,
              }}
            />
          )}

        {ciscoAnnuity.contacts
          ?.filter(
            (contact) =>
              contact.type ===
              CiscoAnnuityContactType.WebexPlatformAdministrator
          )
          .map((ciscoAnnuityContact) => (
            <AnnuityContact
              ciscoAnnuityId={ciscoAnnuity.id}
              key={ciscoAnnuityContact.id}
              ciscoAnnuityContact={ciscoAnnuityContact}
            />
          ))}

        <Grid marginTop={2} style={{ width: '80%' }}>
          <Typography variant="h6">eDelivery Contact</Typography>
        </Grid>

        {ciscoAnnuity.contacts?.filter(
          (contact) => contact.type === CiscoAnnuityContactType.EDelivery
        ).length === 0 && (
          <AnnuityContact
            ciscoAnnuityId={ciscoAnnuity.id}
            ciscoAnnuityContact={{ type: CiscoAnnuityContactType.EDelivery }}
          />
        )}

        {ciscoAnnuity.contacts
          ?.filter(
            (contact) => contact.type === CiscoAnnuityContactType.EDelivery
          )
          .map((ciscoAnnuityContact) => (
            <AnnuityContact
              ciscoAnnuityId={ciscoAnnuity.id}
              key={ciscoAnnuityContact.id}
              ciscoAnnuityContact={ciscoAnnuityContact}
            />
          ))}
        <Grid marginTop={2} style={{ width: '80%' }}>
          <Typography variant="h6">Cisco Smart Account</Typography>
        </Grid>
        <Grid container spacing={1} style={{ width: '80%' }}>
          <Grid item xs={12}>
            <Autocomplete
              freeSolo={true}
              options={smartAccountOptions ?? []}
              onBlur={handleCiscoSmartAccountChange}
              value={ciscoAnnuity.ciscoSmartAccount}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              renderInput={(params) => (
                <TextField {...params} label="Cisco Smart Account" />
              )}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={props.id}>
                    {option.label}
                  </li>
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {ciscoAnnuity.ciscoSmartAccount && (
              <Autocomplete
                freeSolo={true}
                options={virtualAccountOptions ?? []}
                onBlur={handleCiscoVirtualAccountChange}
                value={ciscoAnnuity.ciscoVirtualAccount}
                isOptionEqualToValue={(option, value) =>
                  option.label === value.label
                }
                renderInput={(params) => (
                  <TextField {...params} label="Cisco Virtual Account" />
                )}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={props.id}>
                      {option.label}
                    </li>
                  );
                }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={1}>
          {currentIndex > 4 && (
            <>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Cisco Web Order ID"
                  name="webOrderId"
                  defaultValue={ciscoAnnuity.webOrderId ?? ''}
                  type="string"
                  disabled={!userIsCloudFulfillment}
                  onBlur={handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="CDW Billing ID (Zuora)"
                  name="cdwBillingId"
                  defaultValue={ciscoAnnuity.cdwBillingId ?? ''}
                  type="string"
                  disabled={!userIsCloudFulfillment}
                  onBlur={handleUpdate}
                />
              </Grid>
            </>
          )}
          {currentIndex > 2 && (
            <Grid item xs={12} mt={1}>
              <Typography variant="h6">Seller Contacts</Typography>
              <Box sx={{ width: '100%' }} marginBottom={2}>
                {sellerContacts.length > 0 ? (
                  sellerContacts.map((item, index) => (
                    <Grid container mt={1} justifyContent="center" key={index}>
                      <Grid item xs={4}>
                        {item.name}
                      </Grid>
                      <Grid item xs={4}>
                        {item.type}
                      </Grid>
                      <Grid item xs={4}>
                        {item.email}
                      </Grid>
                    </Grid>
                  ))
                ) : (
                  <Grid container mt={1} justifyContent="center">
                    <Typography variant="h3">
                      Project Team members need to be added
                    </Typography>
                  </Grid>
                )}
              </Box>
            </Grid>
          )}
          <Grid item xs={12} mt={1}>
            <Typography mb={2} variant="h6">
              Contacts and Chat
            </Typography>
            <CiscoAnnuityMessages
              messages={ciscoAnnuity.comments ?? []}
              saveComment={handleSaveComment}
              deleteComment={handleDeleteComment}
            ></CiscoAnnuityMessages>
          </Grid>
        </Grid>
      </Grid>
      <DialogConfirm
        title="Remove Quote?"
        isOpen={dialogConfirmOpen}
        handleClose={handleDialogConfirmClose}
        handleYes={handleQuoteDelete}
      ></DialogConfirm>
      {viewSubscriptionOpen && (
        <CiscoSubscriptionView
          isOpen={viewSubscriptionOpen}
          subscriptionId={activeQuote.subscriptionReferenceId}
          projectItemId={projectItemId}
          handleClose={handleViewSubscriptionClose}
        />
      )}
      {viewLineTermsOpen && (
        <CiscoAnnuityLineTerms
          isOpen={viewLineTermsOpen}
          handleClose={handleLineTermsClose}
          handleSubmit={handleSelectLineTerm}
          quote={activeQuote}
        />
      )}
    </Grid>
  );
}
