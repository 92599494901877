import { gql } from '@apollo/client';

export const GET_ADMIN_SERVICE_SUPPLIERS = gql`
  query getServiceSuppliersQuery(
    $filters: MongoFilter
    $limit: Int
    $offset: Int
    $sort: MongoFilter
  ) {
    getServiceSuppliers(
      filters: $filters
      limit: $limit
      offset: $offset
      sort: $sort
    ) {
      count(filters: $filters)
      serviceSuppliers {
        id: Id
        Id
        Name
        Supplier_ID__c
        SupplierStatus__c
      }
    }
  }
`;
