import { ProjectItemEstimators } from '@cdw-selline/common/types';
import {
  BILLING_TYPES,
  ESTIMATOR_OUTPUT_FIELDS,
} from '@cdw-selline/ui/constants';
import { useProjectPracticesAndRatesQuery } from '@cdw-selline/ui/hooks';
import {
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Switch,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  FormAutocomplete,
  FormSelect,
  FormText
} from '../../formHelperFunctions';

export interface EstimatorVariablesProps {
  handleUpdate: (e, fixed) => void;
  projectItemEstimator: ProjectItemEstimators;
  isReadOnly: boolean;
  loading;
}

const formGroupCssConfig = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'right',
  marginTop: '10px',
  flexWrap: 'wrap',
};
const formDateFieldContainerCssConfig = {
  width: '150px',
  marginRight: '10px',
  marginTop: '10px',
};

export const EstimatorVariables: React.FC<EstimatorVariablesProps> = ({
  handleUpdate,
  projectItemEstimator,
  isReadOnly,
  loading: getProjectItemEstimatorLoading
}) => {
  const { projectItemId } = useParams<{ projectItemId: string }>();
  const {
    currentPractices,
    currentRolesAndRates,
    loading: practicesAndRatesLoading,
  } = useProjectPracticesAndRatesQuery(projectItemId);

  const getPracticeNameById = (practiceId: string): string => {
    const practice = currentPractices.find(
      (practice) => practice.id === practiceId
    );

    return practice ? practice.name : '';
  };

  const pmOptions = useMemo(
    () =>
      !practicesAndRatesLoading
        ? currentRolesAndRates
            ?.filter((rate) => rate.projectManager)
            ?.map((role) => ({
              id: role.id,
              label: `${role.name} (${getPracticeNameById(role.practice)}) `,
            }))
        : [],
    [currentRolesAndRates, currentPractices]
  );

  const isPmRoleInOptions = pmOptions
    ? Boolean(
        pmOptions.some((option) => option.id === projectItemEstimator?.pmRole)
      )
    : false;

  useEffect(() => {
    if (getProjectItemEstimatorLoading || practicesAndRatesLoading) {
      return;
    }
    if (
      (projectItemEstimator?.pmRole && !isPmRoleInOptions) ||
      (!projectItemEstimator?.pmRole && pmOptions.length > 0)
    ) {
      if (pmOptions.length > 0) {
        handleUpdate(ESTIMATOR_OUTPUT_FIELDS.PM_ROLE, pmOptions[0].id);
      }
    }
  }, [currentRolesAndRates, projectItemEstimator?.pmRole]);
  return (
    <FormGroup row sx={formGroupCssConfig} data-testid="estimator-variables-form-group">
      <Grid item sx={{ ...formDateFieldContainerCssConfig, width: '200px' }}>
        <FormSelect
          name={ESTIMATOR_OUTPUT_FIELDS.BILLING_TYPE}
          label="Billing Type"
          setFormData={(estimator) =>
            handleUpdate(
              ESTIMATOR_OUTPUT_FIELDS.BILLING_TYPE,
              estimator?.billingType
            )
          }
          formData={{ ...projectItemEstimator, billingType: projectItemEstimator.billingType || BILLING_TYPES.TIME_MATERIALS }}
          disabled={isReadOnly}
        >
          {Object.entries(BILLING_TYPES).map(([k, v]) => {
            return (
              <MenuItem key={k} value={v}>
                {v}
              </MenuItem>
            );
          })}
        </FormSelect>
      </Grid>
      {projectItemEstimator?.billingType === BILLING_TYPES.FIXED_FEE && (
        <FormControlLabel
          control={
            <Switch
              defaultChecked={projectItemEstimator?.includePartnerServicesInFixedFee ?? false}
              onChange={(e) => handleUpdate(e.target.value, e.target.checked)}
              value="includePartnerServicesInFixedFee"
              data-testid="includePartnerServicesInFixedFee"
            />
          }
          label="Include Partner Services"
        />
      )}
      {projectItemEstimator?.billingType === BILLING_TYPES.FIXED_FEE && (
        <FormControlLabel
          control={
            <Switch
              defaultChecked={Boolean(projectItemEstimator?.includeTAndEFixedFee)}
              onChange={(e) => handleUpdate(e.target.value, e.target.checked)}
              value="includeTAndEFixedFee"
            />
          }
          label="Include T&E"
        />
      )}
      {projectItemEstimator?.billingType === BILLING_TYPES.FIXED_FEE && (
          <Grid item sx={formDateFieldContainerCssConfig}>
            <FormText
              name={ESTIMATOR_OUTPUT_FIELDS.RISK_MITIGATION}
              label="Risk Mitigation %"
              setFormData={(e) => {
                handleUpdate(
                  ESTIMATOR_OUTPUT_FIELDS.RISK_MITIGATION,
                  e.riskMitigation
                );
              }}
              formData={projectItemEstimator}
              type="number"
              disabled={isReadOnly}
            />
          </Grid>
        )}
          <Grid item sx={formDateFieldContainerCssConfig}>
            <FormText
              name={ESTIMATOR_OUTPUT_FIELDS.OVERTIME_PERCENT}
              label="Overtime %"
              setFormData={(e) =>
                handleUpdate(
                  ESTIMATOR_OUTPUT_FIELDS.OVERTIME_PERCENT,
                  e.overtimePercent
                )
              }
              formData={projectItemEstimator}
              type="number"
              disabled={isReadOnly}
            />
          </Grid>
          <Grid item sx={formDateFieldContainerCssConfig}>
            <FormText
              name={ESTIMATOR_OUTPUT_FIELDS.PM_PERCENT}
              label="PM %"
              setFormData={(e) =>
                handleUpdate(ESTIMATOR_OUTPUT_FIELDS.PM_PERCENT, e.pmPercent)
              }
              formData={projectItemEstimator}
              type="number"
              disabled={isReadOnly}
            />
          </Grid>
          <Grid
            item
            sx={{ ...formDateFieldContainerCssConfig, width: '375px' }}
          >
            <FormAutocomplete
              name={ESTIMATOR_OUTPUT_FIELDS.PM_ROLE}
              label="PM Role"
              setFormData={(e) =>
                handleUpdate(ESTIMATOR_OUTPUT_FIELDS.PM_ROLE, e.pmRole)
              }
              formData={isPmRoleInOptions ? projectItemEstimator : {}}
              options={pmOptions}
              disabled={isReadOnly}
            />
          </Grid>
          <Grid item sx={formDateFieldContainerCssConfig}>
            <FormText
              name={ESTIMATOR_OUTPUT_FIELDS.T_AND_E_TOTAL}
              label="T&E Total"
              setFormData={(e) =>
                handleUpdate(
                  ESTIMATOR_OUTPUT_FIELDS.T_AND_E_TOTAL,
                  e.tAndETotal
                )
              }
              formData={projectItemEstimator}
              type="number"
              disabled={isReadOnly}
            />
          </Grid>
    </FormGroup>
  );
};
